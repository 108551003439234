.winter-fun-modal-you-collected {
  z-index: 1304 !important;

  & .MuiPaper-rounded {
    border-radius: 13px;
  }

  & .MuiDialog-paperFullWidth {
    width: calc(100% - 16px);
    min-width: calc(100% - 16px);
  }

  @media (min-width:400px) {
    & .MuiDialog-paperFullWidth {
      min-width: 400px;
      max-width: 400px;
    }
  }

  .wf-dialog-content {
    background-color: #fff;
    background: url(./assets/modal-collected-bg@3x.png);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100% auto;
    padding: 8px 35px;
  }

  .wf-header-icon {

    width: 165px;
    height: auto;
    display: block;
    margin: 30px auto 12px;
  }

  .wf-title {
    font-size: 20px;
    color: #182598;
    font-weight: bold;
    text-align: center;
    margin-bottom: 130px;
  }

  .wf-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .wf-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px;
    min-width: 180px;

    &>div {
      font-size: 16px;
      color: #000;
      font-weight: bold;
    }
  }

  .wf-coins {
    position: relative;
    background: linear-gradient(to bottom, #fcf81e 0%, #fca913 25.6%, #fcb915 42%, #b75f23 57.4%, #fcaa13 88.7%, #fce61b 100%);
    padding: 2px;
    border-radius: 27px;
    height: 54px;
    margin-bottom: 30px;

    .wf-inner-wrapper {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: 27px;
      background: radial-gradient(circle, rgba(209, 64, 204, 1) 0%, rgba(27, 20, 100, 1) 67%, rgba(27, 20, 100, 1) 100%);
      padding: 0 4px;

      img {
        width: 45px;
        height: 45px;
        display: block;
        margin-right: 5px;
      }

      .wf-text {
        color: #fff;
        font-size: 36px;
        font-weight: 600;
        text-shadow: 1px 1px rgba(0, 0, 0, 1);
        padding: 0 20px 0 10px;
      }
    }
  }

  .wf-button-wrapper {
    display: flex;
    justify-content: center;

    .wf-button {
      background: linear-gradient(to right, #0A44E4, #006FFF);
      color: #fff;
      min-width: 260px;

      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      padding: 13px;
      border-radius: 10px;
      margin-bottom: 15px;
    }
  }
}