.wf-referral-code {
  margin: 20px 0 0;

  .wf-header-top {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;

    .wf-icon svg {
      display: block;
      width: 26px;
      height: 26px;
      margin-right: 8px;
      color: #fff;
    }

    .wf-title {
      color: #fff;
      text-align: left;
      font-size: 22px;
      font-weight: bold;
      line-height: 22px;
    }
  }

  .wf-body {
    background-image: url(./assets/bg-collapsed.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #204DF1;

    border-radius: 12px;
    padding: 10px;

    &.wf-expanded {
      background-image: url(./assets/bg-expanded.jpg);
    }



    &>.wf-header {
      padding: 20px 10px 10px;
      background: url(./assets/bg-header.png), linear-gradient(to right, #FFD500, #FF8800);
      background-position: 20px 10px, center;
      background-repeat: no-repeat;
      background-size: 140px auto, cover;
      border-radius: 12px;

      &.wf-mb-10 {
        /*margin-bottom: 10px;*/
      }

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .wf-coins-holder {
        margin-bottom: 10px;

        &>img {
          display: block;
          width: 142px;
          height: auto;
          margin-bottom: -60px;
        }

        .wf-wrapper {
          position: relative;
          background: linear-gradient(to bottom, #fcf81e 0%, #fca913 25.6%, #fcb915 42%, #b75f23 57.4%, #fcaa13 88.7%, #fce61b 100%);
          padding: 2px;
          border-radius: 27px;
          height: 30px;
          margin: 0 10px;

          .wf-inner-wrapper {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: stretch;
            border-radius: 27px;
            background: radial-gradient(circle, rgba(209, 64, 204, 1) 0%, rgba(27, 20, 100, 1) 67%, rgba(27, 20, 100, 1) 100%);
            padding: 0 4px;

            img {
              width: 21px;
              height: 21px;
              display: block;
              margin-right: 5px;
            }

            .wf-text {
              color: #fff;
              font-size: 20px;
              font-weight: 600;
              text-shadow: 1px 1px rgba(0, 0, 0, 1);
              width: 100%;
              text-align: center;
              padding: 0 10px 0 0px;

            }
          }

          &.wf-full .wf-inner-wrapper {
            background: radial-gradient(circle, #DA494E 0%, #A51419 67%, rgb(102, 0, 4) 100%);
          }
        }
      }

      .wf-text {
        color: #000;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        padding: 0 30px 40px;
      }

      .wf-button {
        color: #fff;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        height: 35px;
        display: flex;
        align-items: center;
        position: relative;
        padding: 0 50px;
        cursor: pointer;
        border-radius: 7px;
        background: linear-gradient(to right, #FF8800, #CE00FF);
        box-shadow: -6px -6px 10px 0 rgba(180, 91, 16, .8);

        .wf-loader {
          display: none;
          margin-left: 10px;
          position: relative;
          top: 2px;

          &>div {
            display: inline-block;
            width: 16px !important;
            height: 16px !important;
            color: #fff
          }
        }

        &.wf-loading {
          pointer-events: none;

          .wf-loader {
            display: inline-block;
          }
        }
      }

      &.wf-special {
        padding-top: 10px;
        background-position: 20px 40px, center;

        .wf-button {
          background: linear-gradient(to right, #FF8800, #CE00FF) !important;
        }
      }
    }

    &.wf-expanded {
      .wf-header {
        margin-bottom: 30px;

        .wf-button {
          background: linear-gradient(to right, #FF8800, #FF3D00);
        }
      }
    }


    .wf-input-body {
      border-radius: 7px;
      background: rgba(0, 0, 0, .2);
      padding: 8px 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;

      .wf-text {
        text-align: center;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .wf-input {
        color: #fff;
        width: 100%;

        &>.MuiFormControl-root {
          width: 100%;

          .MuiInputLabel-root {
            color: rgba(255, 255, 255, .45);
          }

          .MuiInputBase-root {
            color: rgba(255, 255, 255, 0.87);
          }

          .MuiIconButton-label {
            color: #fff;
          }

          .MuiOutlinedInput-notchedOutline {
            border-color: rgba(255, 255, 255, 0.23);
          }

          .MuiTouchRipple-child {
            background-color: #fff;
          }

        }
      }
    }

    .wf-list-body {

      .wf-header {
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 10px;
      }

      .wf-list {
        .wf-element {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;
          padding: 12px;
          background-color: rgba(0, 0, 0, .3);
          border-radius: 12px;

          .wf-collect-button {
            height: 48px;
            width: 48px;
            flex: 0 0 48px;
            border-radius: 12px;
            border: 1px solid #465DF0;
            background: linear-gradient(225deg, #1D2D9E, #3D51CB);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: relative;
            z-index: 1;
            box-shadow: -6px -6px 8px 0 #2728AF, 6px 6px 8px 0 rgba(21, 20, 41, .7);
            margin-right: 20px;

            .wf-loader {
              display: none;
              margin-left: 10px;
              position: relative;
              top: 2px;

              &>div {
                display: inline-block;
                width: 16px !important;
                height: 16px !important;
                color: #fff
              }
            }

            &.wf-loading {
              pointer-events: none;

              .wf-loader {
                display: inline-block;
              }
            }

            &.wf-collected {
              background: linear-gradient(225deg, #3C8D00, #5AD500);
              border: 1px solid #5AD500;
              cursor: default;

              .wf-icon {
                color: #A7FF65;
              }

              .wf-coins {
                display: none
              }
            }

            &.wf-collect {
              max-width: none;
              flex: unset;
              width: auto;
              padding: 0 15px 0 5px;
              background: linear-gradient(225deg, #FF8800, #FFD500);
              border: 1px solid #FFD500;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;

              img {
                display: block;
                height: 29px;
                width: auto;
                margin-right: 5px;
              }

              .wf-coins {
                color: #fff;
                font-size: 20px;
                font-weight: bold;
                text-shadow: 1px 1px #000000;
              }
            }
          }

          &.wf-regular {
            .wf-lock-icon {
              color: #5B69E3;

              svg {
                display: block;
                width: auto;
                height: 12px;
              }
            }

            .wf-coins {
              color: #BAC1FF;
              font-size: 14px;
              font-weight: bold;
              padding-top: 3px;
            }
          }

          .wf-avatar {
            display: block;
            height: 27px;
            width: 27px;
            min-width: 27px;
            background-color: #fff;
            border-radius: 50%;

            img {
              display: block;
              height: 27px;
              width: 27px;
            }
          }

          .wf-nickname {
            padding: 0 10px;
            flex: 1 1 100%;
            text-align: left;
            font-size: 12px;
            color: #fff;
            font-weight: 600;
          }

          .wf-invite {
            text-align: left;
            font-size: 9px;
            color: #737DB4;
            font-weight: 600;
            display: flex;
            align-items: center;
            text-transform: uppercase;

            svg {
              transform: scaleX(-1);
              color: #737DB4;
              display: inline-block;
              width: auto;
              height: 24px;
              margin-left: 5px;
              margin-right: 10px;
            }
          }
        }
      }
    }

  }


}