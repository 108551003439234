.popover {
  z-index: 99999;
}
.WithdrawModal .modal-content .WithdrawModalBody .waSection.sBigButton.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
}

.TaxPopup{
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .TaxPage{
    background-color: #ffffff;
    max-width: 600px;
    width: 100%;
    border-radius: 5px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .warning-icon {
      svg {
        max-height: 4rem;
        height: 4rem;
        fill: #c62525;
        .cls-2 {
          fill: white;
        }
      }
    }
    h5 {
      width: 100%;
      text-align: center;
      font-size: 1.2rem;
      position: relative;
      padding: .5rem;
      padding-top: 0;
      box-sizing: border-box;
      margin: 0.75rem 0 1rem;
      text-transform: uppercase;
      letter-spacing: -.45px;
    }
    .TaxBox{
      background-color: #f5f8fa;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-self: center;
      align-self: center;
      width: fit-content;
      padding: 15px 30px;
      margin-bottom: 1rem;
      span{
        font-size: 12px;
        font-weight: 500;
        color: #6f777f;
        text-align: center;
      }
      h3{
        text-align: center;
        font-size: 22px;
        color: #c62525;
        small{
          font-size: 16px;
        }
      }
    }
    .LegalText{
      p{
        font-weight: 500;
        font-size: 11px;
        text-align: left;
        color: #6f777f;
  
      }
      .TaxLink{
        font-weight: 500;
        font-size: 11px;
        text-align: left;
        color: #6f777f;
        display: block;
        margin-top: 1rem;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .content-withdraw {
      padding: 0;
      width: 100%;
      .item {
        width: calc(50% - .5rem);
        display: inline-block;
        position: relative;
        text-align: left;
        background: #f5f8fa;
        padding: .5rem .5rem 1.25rem 1.75rem;
        border-radius: 7px;
        box-sizing: border-box;
        margin-bottom: .7rem;
        &:first-child {
          margin-right: 1rem;
        }
        .icon {
          position: absolute;
          left: .4rem;
          height: 1rem;
          width: 1rem;
          bottom: 2.125rem;
          svg {
            fill: #FFB400;
            .IconSVG {
              background: transparent !important;
            }
          }
        }
  
        .title {
          font-size: .825rem;
          color: #777777;
          font-weight: normal;
        }
  
        .value {
          font-size: 1.5rem;
          .bonus {
            color: #c62525;
            position: relative;
            &:after {
              position: absolute;
              width: 100%;
              height: 1px;
              background: #c62525;
              content: " ";
              left: 0;
              top: 50%;
            }
          }
          .currency {
            display: inline;
            padding-left: .25rem;
            font-size: 1rem;
          }
        }
      }
      p {
        font-weight: normal;
        text-align: left;
        font-size: 0.775rem;
        letter-spacing: -.2px;
        .red {
          font-weight: bold;
          color: #c62525;
        }
      }
      .buttons {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        button {
          text-transform: uppercase;
          font-weight: 600;
          padding: 1rem;
          box-shadow: none;
          margin-top: 1rem;
          width: 100%;
          border: 2px solid transparent;
          &.firstButton {
            background: #FFB400;
            color: white;
          }
          &.secondButton {
            background: white;
            color: #c62525;
            border: 2px solid #c62525;
          }
          &.old-method {
            background: transparent;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
.viva-step {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  padding: 2rem;
  .PlayerDataInput {
    width: 100%;
  }
  .WithdrawalButton-viva {
    width: 100%;
    margin-top: 2rem;
    button {
      width: 100%;
      .Loading {
        width: 20px !important;
        height: 20px !important;
        margin-bottom: -5px;
        margin-right: 1rem;
        margin-left: calc(-2rem - 20px);
      }
    }
  }
}
.viva-button {
  padding: 0 2rem;
  margin: 1rem 1.5rem;
  color: #333333;
  background: white;
  border: none;
  text-decoration: underline;
  width: calc(100% - 3rem);
}

.text-withdraw {
  padding-top: 2rem;
}