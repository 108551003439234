.component-items-lister {
  margin: 10px auto;
  padding-bottom: 10px;
  max-width: 1528px;

  .cgl-list {
    overflow-y: hidden;
    overflow-x: auto;

    .cgl-grid {
      width: 100%;
      display: grid;
      overflow: visible;
      white-space: nowrap;
      pointer-events: all;
      padding: 5px 0;

      grid-column-gap: 5px;
      grid-row-gap: 10px;
      grid-template-columns: 1fr 1fr 1fr;

      .cgl-item {
        min-width: 104px;
        width: 100%;
        margin-right: 2px;
        cursor: pointer;

        &.empty {
          max-width: 104px;
        }

        .outer {
          width: 100%;
          padding-top: 146%;
          position: relative;

          .inner {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }

        .inner>div {
          transition: transform .25s ease;
        }

        .inner>div:hover {
          position: relative;
          transform-origin: center;
          transform: translateZ(0) scale(1.05);
          z-index: 1;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        &:last-child {
          margin-right: 0px;
        }
      }

      .cgl-item.promote-1x2 {
        grid-column: span 2/auto;

        .outer {
          padding-top: 70.9%;
        }
      }

      .cgl-item.promote-1x3 {
        grid-column: span 3/auto;

        .outer {
          padding-top: 46.9%;
        }
      }

      .cgl-item.promote-2x2 {
        grid-column: span 2/auto;
        grid-row: span 2/auto;
      }

      .cgl-item.promote-2x3 {
        grid-column: span 3/auto;
        grid-row: span 2/auto;

        .outer {
          padding-top: 94.5%;
        }
      }

      .cgl-item.promote-3x3 {
        grid-column: span 3/auto;
        grid-row: span 3/auto;
      }

      @media screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      @media screen and (min-width: 992px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }

      @media screen and (min-width: 1366px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      }
    }


  }
}

@media screen and (min-width: 1528px) {
  .component-items-lister {
    .cgl-list {
      .cgl-grid {
        padding: 5px 5px;
      }
    }
  }
}