.wheel-components-product-selector {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  .product-item {
    cursor: pointer;

    img {
      display: block;
    }
  }
}