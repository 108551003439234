.wf-gratuities-hourly {
  .wf-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 22px;

    .wf-icon img {
      display: block;
      width: 26px;
      height: 26px;
      margin-right: 4px;
    }

    .wf-title {
      color: #fff;
      text-align: left;
      font-size: 22px;
      font-weight: bold;
      line-height: 22px;
    }
  }

  .wf-body {
    background-image: url(../../assets/gratuities-hourly-bg@3x.png);
    background-position: center center;
    border-radius: 12px;
    background-size: 100% 100%;

    display: flex;
    align-items: center;
    padding: 25px 18px;

    box-shadow: -6px -6px 15px 0 #3B3CBC, 6px 6px 20px 0 rgba(1, 0, 17, .8);
    border: 1px solid #341EDB;



    .wf-first {
      width: 50%;
      text-align: center;
      cursor: pointer;

      &>img {
        display: block;
        width: 142px;
        height: auto;
        margin-bottom: -30px;
      }

      .wf-wrapper {
        position: relative;
        background: linear-gradient(to bottom, #fcf81e 0%, #fca913 25.6%, #fcb915 42%, #b75f23 57.4%, #fcaa13 88.7%, #fce61b 100%);
        padding: 2px;
        border-radius: 27px;
        height: 30px;

        .wf-inner-wrapper {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: stretch;
          border-radius: 27px;
          background: radial-gradient(circle, rgba(209, 64, 204, 1) 0%, rgba(27, 20, 100, 1) 67%, rgba(27, 20, 100, 1) 100%);
          padding: 0 4px;



          img {
            width: 21px;
            height: 21px;
            display: block;
            margin-right: 5px;
          }

          .wf-text {
            color: #fff;
            font-size: 20px;
            font-weight: 600;
            text-shadow: 1px 1px rgba(0, 0, 0, 1);
            width: 100%;
            text-align: center;

            .wf-subtext {
              color: #fff;
              font-size: 10px;
              font-weight: 600;
              text-shadow: 1px 1px rgba(0, 0, 0, 1);
            }
          }
        }

        &.wf-full .wf-inner-wrapper {
          background: radial-gradient(circle, #DA494E 0%, #A51419 67%, rgb(102, 0, 4) 100%);
        }
      }

      .wf-tokens {
        position: relative;
        background: linear-gradient(to bottom, #d091ff 0%, #c37eff 0.84%, #7426e9 25.6%, #9b43ff 42%, #9b45fe 62.3%, #8013fc 88.7%, #841bfc 95.1%, #ba1bfc 100%);
        padding: 0 1px 1px 1px;
        border-radius: 0 0 8px 8px;
        margin: 0 20px;

        .wf-inner-tokens {
          border-radius: 0 0 8px 8px;
          background: radial-gradient(circle at top, rgba(209, 64, 204, 1) 0%, rgba(27, 20, 100, 1) 67%, rgba(27, 20, 100, 1) 100%);
          height: 9px;

          display: flex;
          align-items: center;
          justify-content: center;

          .wf-token {
            height: 3px;
            border-radius: 10px;
            width: 15px;
            background-color: #ADFF87;
            margin: 0 2px;
          }

          .wf-token-0 {
            background: #ADFF87;
          }

          .wf-token-1 {
            background: #72E53D;
          }

          .wf-token-2 {
            background: #28D906;
          }

          .wf-token-3 {
            background: #019B20;
          }

          .wf-token-4 {
            background: #019B20;
          }

          .wf-disabled {
            background: #4B41AE;
          }
        }

        &.wf-full {
          background: linear-gradient(to right, #FF6B6B 0%, #C52323 100%);

          .wf-inner-tokens {
            background: radial-gradient(circle, #DA494E 0%, #A51419 67%, rgb(102, 0, 4) 100%);
          }
        }
      }
    }

    .wf-second {
      width: 50%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;

      .wf-timer-text {
        text-align: center;
        font-size: 14px;
        font-weight: normal;
        color: rgba(255, 255, 255, 0.45);
      }

      .wf-timer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 158px;
        margin: 0 auto;

        &>div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 0 5px;

          .wf-title {
            color: #fff;
            font-size: 35px;
            text-align: center;
            font-weight: 600;
          }

          .wf-subtitle {
            color: rgba(255, 255, 255, 0.45);
            font-size: 14px;
            text-align: center;
            font-weight: normal;
          }

          .wf-title.off {
            color: rgba(255, 255, 255, 0.22);
          }
        }

        &>div:first-child {
          margin-left: 0;
        }

        &>div:last-child {
          margin-right: 0;
        }
      }

      .wf-title {
        color: #FF8900;
        font-size: 14px;
        font-weight: normal;
        text-align: center;
      }

      .wf-text {
        color: rgba(255, 255, 255, .75);
        font-size: 11px;
        font-weight: 600;
        text-align: center;
        margin: 8px 0 8px;
      }

      .wf-collect {
        background: linear-gradient(to right, #9B9B9B, #B9B9B9);
        pointer-events: none;
        text-align: center;
        font-size: 11px;
        font-weight: 600;
        color: #fff;
        padding: 6px 20px;
        margin-top: 17px;
        cursor: pointer;

        border-radius: 7px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16);
        max-width: 104px;
        white-space: nowrap;
        display: flex;
        align-items: center;

        &.wf-ready {
          background: linear-gradient(to right, #FF8800, #FFD500);
          pointer-events: all;
          box-shadow: -6px -6px 10px 0 #0D0D4A, 0 2px 2px 0 rgba(0, 0, 0, .16);
        }

        .wf-loader {
          display: none;
          margin-left: 10px;
          position: relative;
          top: 2px;

          &>div {
            display: inline-block;
            width: 16px !important;
            height: 16px !important;
            color: #fff
          }
        }

        &.wf-loading {
          max-width: 160px;
          pointer-events: none;

          .wf-loader {
            display: inline-block;
          }
        }
      }
    }
  }
}