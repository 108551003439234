.component-jackpot-collection {
  margin: 10px auto;
  padding-bottom: 10px;
  max-width: 1528px;

  .cgl-header {
    margin: 0 10px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      color: #353540;
      font-size: 18px;
      font-size: clamp(18px, 1.8vw, 26px);
      font-weight: 600;
      text-align: left;
    }

    .link {
      color: #353540;
      font-size: 12px;
      font-size: clamp(12px, 1.2vw, 17px);
      font-weight: 600;
      text-align: left;
      cursor: pointer;
      border: 1px solid #BEC9D6;
      border-radius: 11px;
      padding: 2px 12px 3px;

      span {
        padding: 0 5px;
      }

      svg {
        position: relative;
        top: -1px;
      }
    }
  }

  .cgl-list {
    overflow-y: hidden;
    overflow-x: auto;

    .cgl-grid {
      width: 100%;
      display: grid;
      overflow: visible;
      white-space: nowrap;
      pointer-events: all;
      padding-bottom: 5px;

      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      &::before,
      &::after {
        content: '\00a0';
        min-width: 9px;
        max-width: 9px;
      }

      .cgl-item {
        min-width: 173px;
        width: 100%;
        margin-right: 10px;
        border-radius: 10px;
        cursor: pointer;

        &.empty {
          max-width: 161px;
        }

        .outer {
          width: 100%;
          padding-top: 149%;
          position: relative;

          .inner {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 10px;
        }

        .info-wrapper {
          position: absolute;
          left: 5px;
          right: 5px;
          bottom: 10%;
          height: 55%;
          border-radius: 10px;
          background: linear-gradient(to right, rgba(10, 17, 120, .8), rgba(23, 0, 216, .8));

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          padding: 10px 2px;

          .txt {
            .number {
              .small-counter {
                transform-origin: center;
                transform: scale(1.7);
                margin-bottom: 3px;

                .digit-wrapper {
                  padding: 0 1px 0 0;
                }

                .digit-wrapper .digit {
                  font-size: 10px;
                  font-weight: 600;
                }

                .radix {
                  font-size: 10px;
                  font-weight: 600;
                  padding: 0 1px 0 1px;
                }
              }


            }

            .txt {
              font-size: 8px;
              font-size: calc(8px + (10 - 8) * ((100vw - 360px) / (1528 - 360)));
              color: rgba(255, 255, 255, .65);
              font-weight: bold;
              text-align: center;
            }
          }
        }

        @media screen and (min-width: 1528px) {
          .info-wrapper {
            padding: 15px 2px 15px;

            .txt {
              .number {
                .small-counter {
                  transform: scale(2.2);
                  margin-bottom: 8px;
                }
              }
            }
          }
        }

        &:last-child {
          margin-right: 0px;
        }
      }

      .cgl-item.soon {
        background-color: #F2F3F5;
        min-height: 100%;
        border-radius: 5px;

        .inner {
          border-radius: 5px;

          &>div {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #CECECE;
            font-size: 16px;
            font-weight: 600;
            border-radius: 5px;
          }
        }
      }
    }
  }
}