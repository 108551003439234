.winner-fun-drawer {
  box-sizing: border-box;

  & * {
    box-sizing: border-box;
    word-break: normal;
  }

  .winner-loader-w {
    fill: #fff;
  }

  .d-none {
    display: none !important;
  }

  &.wfd-modal {
    position: relative;
    margin: 10px auto 0 auto;
    width: 100%;
    max-width: 800px;
    min-height: calc(100% - 10px - var(--notch-top-offset, 0px));
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
    box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.35);
    overflow: visible;
    background: #eef0f1;
    height: calc(100% - 10px - var(--notch-top-offset, 0px));
    margin-top: calc(10px + var(--notch-top-offset, 0px));
    background-color: #0e1a6e;
  }

  .wf-display-none {
    display: none !important;
  }

  .wfd-header {
    height: 53px;
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
    background-image: url("../assets/img-bg-season-title@3x.png");
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100% auto;
    position: relative;

    &::after {
      content: "";
      background: linear-gradient(to right, #8b1c00, #ff3c01, #ff9d0a, #fff71a);
      height: 2px;
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
      box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.3);
    }

    .wfd-h-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 3px 26px;

      .wfd-h-w-left {
        display: flex;
        flex-direction: column;
        max-width: calc(100% - 40px);

        .wfd-h-w-l-title {
          font-size: 20px;
          color: #fff;
          font-weight: 600;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          //max-width: calc(100% - 50px);
        }

        .wfd-h-w-l-subtitle {
          font-size: 12px;
          color: #fff;
          font-weight: normal;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          //max-width: calc(100% - 50px);
        }
      }

      .wfd-h-w-right {
        padding-left: 18px;
        /*border-left: 1px solid rgba(251, 251, 251, .34);*/
        color: #fff;
        min-height: 29px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }

  .wfd-menu {
    position: relative;
    background: linear-gradient(to bottom,
        #101327,
        #131739 3.3%,
        #1f255f 11.4%,
        #252d72 24.2%,
        #1a1f4d 93.6%,
        #181c41 98.6%,
        #181a2b);

    .MuiTabs-indicator {
      background-color: #fff;
      height: 3px;
      border-radius: 2px;
    }

    .wfd-tabs {

      /*box-shadow: 0px 5px 15px 0px #000000;*/
      .MuiTabs-scrollButtonsDesktop {
        svg {
          fill: #fff;
        }
      }
    }

    .wfd-tab {
      height: 85px;
      margin: 0;

      span.MuiTab-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 76px;
        color: #5b69e3;
        font-size: 12px;
        font-weight: 600;
        position: relative;
        overflow: visible;
        text-transform: none;
      }

      &.Mui-selected {
        .MuiTab-wrapper {
          color: #fff;
          text-shadow: 2px 2px #000000;
        }
      }

      &.wfd-disabled {
        filter: grayscale(100%);
      }
    }

    .bets-tab {
      width: 90px;
      max-width: 90px;
      min-width: 90px;
      margin-right: 10px;
      margin-left: 10px;

      .bets-image {
        width: 86px;
        height: 45.93px;
        position: absolute;
        margin: 0;
        bottom: 22px;
      }
    }

    .collect-tab {
      max-width: 80px;
      min-width: 80px;

      position: relative;
      overflow: visible;
      margin-left: 0px;

      &::before {
        content: "";
        height: 40px;
        width: 3px;
        background-color: rgba(112, 112, 112, 0.12);
        position: absolute;
        top: 22px;
        left: -1px;
      }

      .collect-image {
        width: 65px;
        height: auto;
        position: absolute;
        margin: 0;
        bottom: 10px;
      }
    }

    .score-tab {
      max-width: 75px;
      min-width: 75px;

      .score-image {
        width: 65px;
        height: auto;
        position: absolute;
        margin: 0;
        bottom: 10px;
      }
    }

    .teams-tab {
      max-width: 75px;
      min-width: 75px;

      .teams-image {
        width: 65px;
        height: auto;
        position: absolute;
        margin: 0;
        bottom: 10px;
      }
    }

    .raffle-tab {
      max-width: 75px;
      min-width: 75px;

      .raffle-image {
        width: 65px;
        height: auto;
        position: absolute;
        margin: 0;
        bottom: 10px;
      }
    }

    .calendar-tab {
      max-width: 75px;
      min-width: 75px;

      .calendar-image {
        width: 65px;
        height: auto;
        position: absolute;
        margin: 0;
        bottom: 10px;
      }
    }

    .missions-tab {
      max-width: 75px;
      min-width: 75px;

      .missions-image {
        width: 65px;
        height: auto;
        position: absolute;
        margin: 0;
        bottom: 10px;
      }
    }
  }

  .wfd-scroller {
    height: calc(100vh - 60px);
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: linear-gradient(to bottom, #ffef00, #ff9900);
      border-radius: 9px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: linear-gradient(to bottom, #ffef00, #ff9900);
    }
  }

  .wfd-content {
    &>div {
      min-height: calc(100vh - 145px);
      max-width: 400px;
      margin: 0 auto;
    }

    background-color: #06084b;
  }

  @media (min-width: 500px) {
    .wfd-content {
      background: linear-gradient(to bottom, #002d71 0%, #101098 7%, #06084b 100%);

      &>div {
        background: transparent !important;
      }
    }
  }
}