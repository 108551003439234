.faq {

  ol {
    li {
      div,p {
        padding-top: 1rem;
        ol {
          &[type="a"] {
            li {
              list-style: lower-alpha;
            }
          }
          li {
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
}