.skeleton-loader-live {
  background: #fff;
  width: 100%;
  min-height: 195px !important;
  padding: 5px 12px 5px 18px;
  box-sizing: border-box;
  border-radius: 7px;

  .sk-bar {
    margin: -5px -12px 0 -18px;
    background: rgba(46, 45, 59, 0.03);
    height: 26px;
    width: calc(100% + 30px);
    border-radius: 7px 7px 0 0;
    margin-bottom: 10px;
  }

  .sk-header {
    height: 14px;
    width: 62px;
    margin-top: 9px;
    margin-bottom: 14px;
  }

  .sk-content-wrapper {
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;

    .sk-date {
      height: 48px;
      min-width: 20px;
      max-width: 20px;
      margin-right: 5px;
    }

    .sk-content {
      height: 48px;
      width: 50%;
    }
  }

  .sk-bets {
    width: 30px;
    height: 14px;
    margin-bottom: 3px;
  }

  .sk-progresses {
    display: flex;
    height: 5px;
    margin-bottom: 5px;

    .sk-progress {
      width: 49%;
      height: 5px;
      margin-right: 10px;
      border-radius: 7px;
    }
  }

  .sk-buttons {
    display: flex;

    .sk-button {
      width: 79px;
      height: 29px;
      margin-right: 10px;
      border-radius: 7px;
    }
  }

  .sk-loading {
    position: relative;
    background-color: #e7edf2;
    overflow: hidden;
    //background: url('../../assets/skeleton-loader.gif') no-repeat 100% 100%;
  }

}