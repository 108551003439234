.SubMenuItem {
    text-transform: capitalize;
    padding-left: 3px;
    padding-right: 3px;
    text-align: center;
    overflow: hidden;
    border-bottom: 0px;
    padding-right: 30px;
}

.SubMenuItem a,
.SubMenuItem a:hover {
    text-decoration: none;
}

.SubMenuItem a {
    display: block;
    font-weight: bold;
    height: 65px;
    font-size: 13px;
    padding-right: 24px;
    text-align: left;
}

.SubMenuItem a .IconSVG
{
    vertical-align: middle!important;
    margin-right: 10px;
}

.SubMenuItem a .coming-soon
{
    font-size: 11px;
    font-weight: normal;
    font-style: italic;
    position: relative;
    left: 30px;
    /*top: -22px;*/
    top: -22px;
    height: 14px;
    line-height: 14px;
    display: block;
    text-align: left;
}

.SubMenuItem a .coming-soon span {
    line-height: 14px;
    text-transform: uppercase;
    border: 1px solid #9a271e;
    border-radius: 20px;
    padding: 2px 7px;
    color: #941b20;
    font-size: 11px;
    font-weight: normal;
    font-style: italic;
}

/*@media only screen and (max-width: 1236px) {*/
    /*.SubMenuItem a .item-text,*/
    /*.SubMenuItem a .coming-soon {*/
        /*display: none;*/
    /*}*/

    /*.SubMenuItem a {*/
        /*padding-left: 10px;*/
        /*padding-right: 10px;*/
    /*}*/

    /*.SubMenuItem a .IconSVG {*/
        /*margin-right: 0px;*/
    /*}*/
/*}*/
