@import "./../../../../../variables";

.stepCurrentPassword {
    .step-footer {
        .nextButton {
            color: $buttonGreen;
            font-weight: bold;
            background-color: $buttonYellow;

            &:hover {
                background-color: lighten($buttonYellow, 5%);
            }

            .text {
                padding-left: 5px;
            }
        }
    }
}