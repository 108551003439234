.big-counter {
	padding-top: 4px;
	display: inline-block;
	//position: relative;
	//vertical-align: middle;
	border-radius: 0.34em;
	//padding: 0.2em 0 0;
	font-weight: bold;
	color: #f6a700;

	.counter-wrapper {
		display: flex;
		align-items: flex-end;
	}

	.digit-wrapper {
		height: 1.75em;
		line-height: 1.7em;
		overflow-y: hidden;
		background-image: linear-gradient(#3a006f, #290046);
		background-size: 100%;
		text-align: center;
		//padding: 0.12em 0.35em 0;
		padding: 3px 0.35em 3px;
		border-radius: 0.2em;
		border: 1px solid #4e2174;

		.disabled {
			color: #442665;
		}
	}

	.digit {
		//height: 1.75em;
		//line-height: 1.7em;
	}

	.radix {
		height: 1.75em;
		line-height: 1.7em;
		display: inline-block;
		width: 0px;
		overflow: visible;
		position: relative;
		z-index: 1;
		top: 0px;
		left: -4px;
	}
}
