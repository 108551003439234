$border-radius: 5px;

.ticker-players {
    padding: 24px 31px;
    background: linear-gradient(45grad, #1C2136, #313A5B);
    border-radius: $border-radius;
    height: 100%;
    width: 100%;
    box-shadow: 3px 3px 4px 1px rgba(0, 0, 0, 0.28);

    .slick-list {
        overflow: hidden;
        max-height: 350px !important;
    }

    .top {
        text-align: left;
        border-bottom: 2px solid #0C8EA7;
        margin-bottom: .5rem;

        .title {
            color: #FFF;
        }

    }

    .bottom {
        padding: 5px 0;
        height: calc(100% - 26px);

        .element {
            padding: 0;
            min-width: 400px;
            width: 100%;
            color: #fff;
            font-size: 16px;
            display: flex !important;
            align-items: center;
            margin-bottom: 10px;

            .DinamicImageContainer{
                width: auto;
                img {
                    width: 82px;
                    margin-right: 1rem;
                }
            }

            .color-text {
                color: #687377;
            }

            .color-amount {
                color: #fff;
            }

            .color-game {
                color: #fff;
            }
        }
    }
}