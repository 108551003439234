.component-egt_jackpot_details {
  box-sizing: content-box;

  * {
    box-sizing: content-box;
  }

  @media screen and (min-width: 992px) {
    box-sizing: border-box;

    * {
      box-sizing: border-box;
    }

    &.jackpot-headerV2.regular.desktop-int {
      top: -235px;
      min-height: 386px;
    }
  }

}