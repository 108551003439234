.wheel-main-view-dialog {
  .MuiPaper-root {
    border-radius: 10px;
    background-color: transparent;
    max-height: calc(100% - 12px);
    margin: 6px;

    .wheel-main-view-wrapper {
      position: relative !important;
      width: 375px !important;
      min-height: 642px;
      border-radius: 10px;
      overflow: hidden;
      overflow-y: auto;
      display: block;

      .wheel-main-view {
        .close-header {
          position: absolute;
          top: 0px;
          cursor: pointer;
          z-index: 100;
        }

        .spin-wrapper {
          .wheel-canvas-element {
            margin-left: 16px;
          }
        }
      }
    }
  }
}

.wheel-real {
  .wheel-result-dialog {
    .MuiPaper-root {
      border-radius: 10px;
      background-color: transparent;

      .result-dialog-body {
        width: 375px !important;
        min-height: 700px;
      }
    }
  }

  .wheel-award-dialog {
    .MuiPaper-root {
      border-radius: 10px;
      background-color: transparent;

      .award-dialog-body {
        width: 375px !important;
        min-height: 700px;
      }
    }
  }
}