.registerV2 {
  .error {
    svg {
      fill: #FF196E;
    }
  }
  .errors {
    color: red;
    font-size: .75rem;
    text-align: center;
    &:not(:empty) {
      margin-bottom: 1rem;
    }
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: green;
  }

}