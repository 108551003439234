@import "../variables";

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

img,
svg {
  vertical-align: middle;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body.bets {
  background: #EDF0F0;
  touch-action: manipulation;
}

body.bets .page-wrapper {
  text-align: initial;
  line-height: normal;
}

body.bets .betsModal,
body .betsModal {
  text-align: initial;
  line-height: normal;

  div.match-modal-top,
  div.search-modal-top {
    padding-top: calc(var(--notch-top-offset, 0px));
    height: calc(56px + var(--notch-top-offset, 0px));
    box-sizing: border-box;
  }

  div.league-header {
    padding-top: calc(5px + var(--notch-top-offset, 0px));
    height: calc(27px + var(--notch-top-offset, 0px));
    box-sizing: border-box;
  }

  div.league-alert {
    margin-top: calc(5px + var(--notch-top-offset, 0px));
    box-sizing: border-box;
  }
}

body.bets .betsModal.betsModalDialog,
body .betsModal.betsModalDialog {
  padding-top: 0;
}

body.bets * {
  box-sizing: border-box;
  font-family: "OpenSans", "Open Sans", sans-serif;
}

body.bets .App {
  background: inherit;
}

body.bets .App .language-select {
  box-sizing: content-box;
}

body.bets .page-wrapper svg {
  vertical-align: initial;
}

body.bets .MainMenu {
  line-height: 85px;
}

body.bets .page-wrapper img {
  vertical-align: initial;
}

body.dark {
  background: #1b1e2c;
}

.boxWrapper * {
  box-sizing: border-box;
}

#root {
  width: 100%;
  min-height: 100vh;
  //background: #edf0f0;
}

@media (max-width: 600px) {
  #root {
    //overflow-x: hidden;
  }
}

.betslip-on #root {
  margin-bottom: 170px;
}

.page-wrapper {
  position: relative;
}

.betslip-on.quick-bet .spacer {
  height: 193px;
}

.betslip-on.lotto-quick-bet .spacer {
  height: 203px;
}

.betslip-on.summary-bet .spacer {
  height: 120px;
}

.nav__link {
  text-decoration: none !important;
  cursor: pointer;
}

.nav__link.custom-nav-element {
  .button-graphic {
    img {
      height: 30px;
      width: auto;
      max-width: 47px;
      object-fit: contain;
    }
  }
}

.nav__link.active {
  background-color: #f6f6f6;
  border-radius: 5px;
}

.darkTemp {
  .nav__link.active {
    background-color: #15297e;
    border-radius: 5px;
  }
}

.nav__link .button-text {
  color: #17214D;
}

.darkTemp {
  .nav__link .button-text {
    color: #fff;
  }
}

.nav__link.active .button-text {
  color: #f8991c;
}

.nav__link .button-graphic svg {
  fill: #17214D;
}

.darkTemp {
  .nav__link .button-graphic svg {
    fill: #fff;
  }
}

.nav__link.active .button-graphic svg {
  color: #f8991c;
  fill: #f8991c;
}

@media (hover: hover) {
  .nav__link:hover {
    background-color: #f6f6f6;
    border-radius: 5px;
  }

  .darkTemp {
    .nav__link:hover {
      background-color: #15297e;
      border-radius: 5px;
    }
  }
}


body.no-scroll {
  overflow-y: hidden;
}

.page--fixed {
  height: 100vh;
  width: 100vw !important;
  overflow: hidden;
  z-index: 1;
}

.page {
  will-change: transform;
  width: 100%;
  transition: transform 300ms ease-out;
}

.page-enter {
  position: absolute;
  transform: translate(-100%, 0);
  min-height: 100vh;
  z-index: 1;
}

.page-enter-active {
  transform: translate(0, 0);
  z-index: 1;
}

@media (max-width:800px) {
  .page-min-height {
    min-height: 100vh;
  }
}

.page-exit {
  position: absolute;
  transform: translate(0, 0);
  min-height: 100vh;
  z-index: 1;
}

.page-exit-active {
  transform: translate(-100%, 0);
  z-index: 1;
}

.page--prev.page-enter {
  position: absolute;
  transform: translate(100%, 0);
  min-height: 100vh;
}

.page--prev.page-enter-active {
  position: absolute;
  transform: translate(0, 0);
}

.page--prev.page-exit {
  position: absolute;
  transform: translate(0, 0);
  min-height: 100vh;
}

.page--prev.page-exit-active {
  position: absolute;
  transform: translate(100%, 0);
}

.section {
  will-change: transform;
  width: 100%;
  transition: transform 300ms ease-out;
}

.section-enter {
  position: absolute;
  transform: translate(-100%, 0);
}

.section-enter-active {
  position: absolute;
  transform: translate(0, 0);
}

.section-exit {
  position: absolute;
  transform: translate(0, 0);
}

.section-exit-active {
  position: absolute;
  transform: translate(-100%, 0);
}

.section--prev.section-enter {
  position: absolute;
  transform: translate(100%, 0);
}

.section--prev.section-enter-active {
  position: absolute;
  transform: translate(0, 0);
}

.section--prev.section-exit {
  position: absolute;
  transform: translate(0, 0);
}

.section--prev.section-exit-active {
  position: absolute;
  transform: translate(100%, 0);
}

.fade-enter {
  opacity: 0;
  height: 0;
}

.fade-enter-active {
  opacity: 1;
  height: auto;
  transition: opacity 300ms ease-in, height 300ms ease-in;
}

.fade-exit {
  height: auto;
  opacity: 1;
}

.fade-exit-active {
  height: 0;
  opacity: 0;
  transition: opacity 200ms ease-out, height 200ms ease-out;
}

@keyframes smallAnim {
  0% {
    background-position: -133px 0;
  }

  100% {
    background-position: 0 0;
  }
}

@keyframes mediumAnim {
  0% {
    background-position: 0px 0;
  }

  100% {
    background-position: -133px 0;
  }
}

@keyframes largeAnim {
  0% {
    background-position: -133px 0;
  }

  100% {
    background-position: -266px 0;
  }
}

@keyframes progress-bar-animated {
  0% {
    background-position: 200% 100%;
  }

  50% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 100%;
  }
}

@keyframes buttonAnim {
  0% {
    background-position: 0% 50%;
  }

  35% {
    background-position: 100% 50%;
  }

  70% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes animArrowRedFrames {
  0% {
    transform: translate3d(0, 0, 0);
  }

  25% {
    transform: translate3d(0, -3px, 0);
  }

  50% {
    transform: translate3d(0, 0, 0);
  }

  75% {
    transform: translate3d(0, 3px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes animArrowRedFramesUK2 {
  0% {
    transform: translate3d(0, 0, 0);
  }

  25% {
    transform: translate3d(0, -2px, 0);
  }

  50% {
    transform: translate3d(0, 0, 0);
  }

  75% {
    transform: translate3d(0, 1px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes animArrowRedFramesScale {
  from {
    animation-timing-function: ease-out;
    transform: scale(0.1);
  }

  to {
    animation-timing-function: ease-out;
    transform: scale(1);
  }
}

.animArrowRed {
  animation-timing-function: ease-out;
  animation-name: animArrowRedFrames;
  transform-origin: center bottom;
  animation-duration: 1.2s;
  animation-iteration-count: 2;
  animation-fill-mode: both;
}

.animArrowRed svg {
  animation-name: animArrowRedFramesScale;
  transform-origin: center up;
  animation-duration: 400ms;
  animation-fill-mode: both;
}

.animArrowRedUK2 {
  animation-timing-function: ease-out;
  animation-name: animArrowRedFramesUK;
  transform-origin: center bottom;
  animation-duration: 1s;
  animation-iteration-count: 2;
  animation-fill-mode: both;
}

.animArrowRedUK2 svg {
  animation-name: animArrowRedFramesScale;
  transform-origin: center up;
  animation-duration: 400ms;
  animation-fill-mode: both;
}

@keyframes animArrowGreenFrames {
  0% {
    transform: translate3d(0, 0, 0);
  }

  25% {
    transform: translate3d(0, 3px, 0);
  }

  50% {
    transform: translate3d(0, 0, 0);
  }

  75% {
    transform: translate3d(0, -3px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes animArrowGreenFramesUK2 {
  0% {
    transform: translate3d(0, 0, 0);
  }

  25% {
    transform: translate3d(0, 2px, 0);
  }

  50% {
    transform: translate3d(0, 0, 0);
  }

  75% {
    transform: translate3d(0, -1px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes animArrowGreenFramesScale {
  from {
    animation-timing-function: ease-out;
    transform: scale(0.1);
  }

  to {
    animation-timing-function: ease-out;
    transform: scale(1);
  }
}

.animArrowGreen {
  animation-timing-function: ease-out;
  animation-name: animArrowGreenFrames;
  transform-origin: center up;
  animation-duration: 1.2s;
  animation-iteration-count: 2;
  animation-fill-mode: both;
}

.animArrowGreen svg {
  animation-name: animArrowGreenFramesScale;
  transform-origin: center up;
  animation-duration: 400ms;
  animation-fill-mode: both;
}

.animArrowGreenUK2 {
  animation-timing-function: ease-out;
  animation-name: animArrowGreenFramesUK;
  transform-origin: center up;
  animation-duration: 1s;
  animation-iteration-count: 2;
  animation-fill-mode: both;
}

.animArrowGreenUK2 svg {
  animation-name: animArrowGreenFramesScale;
  transform-origin: center up;
  animation-duration: 400ms;
  animation-fill-mode: both;
}


.ScrollUpButton__Container {
  background-color: #fff !important;
  border-radius: 50% !important;
  width: 45px !important;
  height: 45px !important;
  padding: 3px 8px !important;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
  margin: 10px !important;

  svg {
    fill: #333 !important;
  }
}

.ScrollUpButton__Container:focus {
  outline: none;
}

.betslip-on.quick-bet .ScrollUpButton__Toggled {
  bottom: 155px !important;
}

.betslip-on.lotto-quick-bet .ScrollUpButton__Toggled {
  bottom: 170px !important;
}

.betslip-on.summary-bet .ScrollUpButton__Toggled {
  bottom: 85px !important;
}

.betslip-on.mini-bet .ScrollUpButton__Toggled {
  bottom: 70px !important;
}

$match-live-animation-speed: 400ms;

.match-details-search-animate {
  .match-details-header {
    overflow: hidden;
    *will-change: max-height;
    *transition: max-height $match-live-animation-speed ease-in-out;

    .match-live-header {
      will-change: transform;
      *transition: transform $match-live-animation-speed ease-in-out;

      .bar-top {
        transform: translateY(0);
        z-index: 4;
      }

      .bar-details {
        transform: translateY(0);
        z-index: 3;
        will-change: transform;
        *transition: transform $match-live-animation-speed ease-in-out;
      }

      .bar-divider {
        transform: translateY(0);
        z-index: 2;
        will-change: transform;
        *transition: transform $match-live-animation-speed ease-in-out;
      }

      .bar-action {
        transform: translateY(0);
        z-index: 1;
        will-change: transform;
        *transition: transform $match-live-animation-speed ease-in-out;
      }
    }

    .bar-tabs-menu {
      transform: translateY(0);
      will-change: transform;
      *transition: transform $match-live-animation-speed ease-in-out,
        max-height $match-live-animation-speed ease-in-out;
    }
  }

  .actualSearch {
    max-height: 46px;
    overflow: hidden;
    *will-change: max-height;
    *transition: max-height $match-live-animation-speed ease-in-out;

    .topSearch {
      *transition: margin 300ms ease-in-out, border-radius 300ms ease-in-out;
    }

    .fakeSearch {
      padding-bottom: 0px;
    }

    .middleSearch {
      transform: translateY(95px);
      will-change: transform;
      *transition: transform $match-live-animation-speed ease-in-out;
    }

    .bottomSearch {
      transform: translateY(195px);
      will-change: transform;
      *transition: transform $match-live-animation-speed ease-in-out;
    }

    .teamsSearch {
      *display: none;
    }

    .searchInput {
      *display: none;

      .match-search-input {
        height: 30px;
        transform: translateY(-70px);
        opacity: 0.01;
      }
    }
  }
}

.match-details-search-animate.animate {
  .match-details-header {
    max-height: 0px !important;

    .match-live-header {
      transform: translateY(-56px);

      .bar-details {
        transform: translateY(-50px);
      }

      .bar-divider {
        transform: translateY(-100px);
      }

      .bar-action {
        transform: translateY(-150px);
      }
    }

    .bar-tabs-menu {
      transform: translateY(-200px);
    }
  }

  .actualSearch {
    max-height: initial;

    .topSearch {
      margin: 0;
      border-radius: 0;
    }

    .middleSearch {
      transform: translateY(0);
    }

    .bottomSearch {
      transform: translateY(0);
    }

    .fakeSearch {
      display: none !important;
    }

    .teamsSearch {
      display: block;
    }

    .searchInput {
      display: flex;

      .match-search-input {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}

.match-details-search-animate.prematch.animate {
  .match-details-header {
    .bar-tabs-menu {
      transform: translateY(-60px);
    }
  }
}

.home-logo {
  height: 27px;
  position: relative;
  top: -3px;
}

.filter-league {
  .league-actual-search {
    display: none;
  }

  .league-filtered {
    display: none;
  }
}

.filter-league.animated {
  .league-actual-search {
    display: block;
  }

  .league-filtered {
    display: block;
  }

  .league-alert {
    display: none;
  }

  .league-title {
    display: none;
  }

  .league-fake-search {
    display: none;
  }
}

@keyframes live-icon-big-anim {
  0% {
    opacity: 0;
  }

  18.181818% {
    opacity: 0;
  }

  27.272727% {
    opacity: 1;
  }

  36.363636% {
    opacity: 0;
  }

  45.454545% {
    opacity: 0;
  }

  54.545455% {
    opacity: 0;
  }

  63.636364% {
    opacity: 1;
  }

  72.727273% {
    opacity: 0;
  }

  81.818182% {
    opacity: 0;
  }

  90.909091% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes live-icon-medium-anim {
  0% {
    opacity: 0;
  }

  9.090909% {
    opacity: 0;
  }

  18.181818% {
    opacity: 1;
  }

  27.272727% {
    opacity: 1;
  }

  36.363636% {
    opacity: 0;
  }

  45.454545% {
    opacity: 0;
  }

  54.545455% {
    opacity: 1;
  }

  63.636364% {
    opacity: 1;
  }

  72.727273% {
    opacity: 0;
  }

  81.818182% {
    opacity: 0;
  }

  90.909091% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes live-icon-small-anim {
  0% {
    opacity: 0;
  }

  9.090909% {
    opacity: 1;
  }

  18.181818% {
    opacity: 1;
  }

  27.272727% {
    opacity: 1;
  }

  36.363636% {
    opacity: 0;
  }

  45.454545% {
    opacity: 1;
  }

  54.545455% {
    opacity: 1;
  }

  63.636364% {
    opacity: 1;
  }

  72.727273% {
    opacity: 0;
  }

  81.818182% {
    opacity: 1;
  }

  90.909091% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.live-icon-big {
  animation: live-icon-big-anim 3000ms ease-out 1 normal forwards;
}

.live-icon-medium {
  animation: live-icon-medium-anim 3000ms ease-out 1 normal forwards;
}

.live-icon-small {
  animation: live-icon-small-anim 3000ms ease-out 1 normal forwards;
}

#status {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  color: black;
  z-index: 9999;
}

.colorGreen {
  color: #01ae3b !important;
  margin-left: 3px;
}

.colorRed {
  color: #df2222 !important;
  margin-left: 3px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.systems-padding-live {
  margin-top: 15px;
}

.systems-padding-prematch {
  margin-top: 15px;
}

@keyframes animateLiveText {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate-live-text {
  animation-timing-function: ease-out;
  animation-name: animateLiveText;
  transform-origin: center up;
  animation-duration: 500ms;
  animation-fill-mode: both;
}

.pre-match-hide-extra .pre-match-toolbar,
.pre-match-hide-extra .pre-match-calendar-filter {
  display: none;
}

@keyframes dhEllipsis {
  to {
    width: 1em;
  }
}

body.bets #settings {
  top: auto;
  bottom: 3rem;
}

body.bets .MuiButtonBase-root {
  outline: none;
}

.match-modal-top {
  transition: top 0.2s ease-in-out;
}

.fixed-title .match-modal-top {
  position: fixed;
  top: 0;
}

.lottoEvent-exit {
  opacity: 1;
  overflow: hidden;
  padding: 6px 0 !important;
  text-align: center;
  width: 60px;
}

.lottoEvent-exit-active {
  opacity: 0;
  width: 0;
  padding: 6px 0 !important;
  text-align: center;
  transition: opacity 1s ease-in, width 1s ease-in;
}

@keyframes animate-drum-loop {
  100% {
    background-position: 0 -14800px;
  }
}

.MuiPaper-rounded {
  border-radius: 10px;
}

.overlay__content {
  z-index: 0;
}


.betsModal.mobile-match {
  overflow: auto;

  .match-details-header {
    position: sticky;
    top: 56px;
    z-index: 1;
  }

  .match-details-swipeable {
    height: unset !important;
  }
}


.hide-footer-language {
  .Footer {
    display: none !important
  }
}

/*
.betsModal {
  overflow: auto;

  .match-details-header {
    position: sticky;
    top: 56px;
    z-index: 1;
  }

  .match-details-swipeable {
    height: unset !important;
  }
}
*/
.bets-top-live {
  margin: 0;
  padding: 10px 0;
  background-color: #edf0f0;

  .grid.grid-top-live {
    grid-auto-columns: 376px;

    .grid-item-match {
      min-width: 376px;

      &>div {
        min-height: 189px;
      }
    }
  }

  @media (min-width: 1920px) {
    .grid.grid-top-live {
      grid-auto-columns: 376px;

      .grid-item-match {
        min-width: 376px;

        &>div {
          min-height: 205px;
        }
      }
    }
  }
}

.no-scroll-bets,
.no-scroll-modals {
  overflow: hidden;
}

.bets-home {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 0;
  margin-bottom: 10px;
  padding: 12px 0 0;
  text-align: left;
  text-transform: none;

  .bets-icon {
    margin-right: 10px;

    svg {
      display: block;
      fill: #000;
    }
  }

  .bets-text {
    color: #22262D;
    font-size: 22px;
    line-height: 22px;
    font-weight: bold;
  }
}

body.bets {
  .space-fix {
    height: 12px;
  }

  .bets-top-live {
    padding-bottom: 0px;

    .text-header .icon svg {
      display: block;
      position: relative;
      top: 2px;

    }

    .text-header .txt {
      color: #22262D;
      display: block;
      font-size: 22px;
      line-height: 22px;
    }
  }
}

.olympics-icon {
  height: 22px;
  width: auto;
  display: block;
}

.stat-score-widget.bar-tabs-menu {
  background: #101533;
}

.bets {
  .pulse-anim {
    box-shadow: 0 0 0 0 rgba(255, 199, 26, 1);
    animation: pulse_anim_keys 3s infinite;
    border-radius: 50%;
  }

  @keyframes pulse_anim_keys {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 199, 26, 0.7);
    }

    50% {
      box-shadow: 0 0 0 10px rgba(255, 199, 26, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 199, 26, 0);
    }
  }
}

@import "winner-fun.scss";