.gameColumn
{
    z-index: 1;
}

.intermGameCard {
    position: relative;
}

.intermGameCard .greyedOut {
    filter: grayscale(100%);
}

@keyframes gameCardHover {
    0% {
        position: relative;
        z-index: 1;
        width: 100%;
        top: 0;
        left: 0;
        background-color: #000000;
    }
    1% {
        position: absolute;
        z-index: 9;
        width: 100%;
        top: 0;
        left: 0;
    }
    100% {
        position: absolute;
        width: 110%;
        z-index: 9;
        transform: translate(-4.5%, -4.5%);
        background-color: #ffffff;
    }
}

@keyframes gameCardOut {
    0% {
        position: absolute;
        width: 110%;
        z-index: 9;
        transform: translate(-4.5%, -4.5%);
        background-color: #ffffff;
    }
    99% {
        position: absolute;
        z-index: 1;
        width: 100%;
        top: 0;
        left: 0;
    }
    100% {
        position: relative;
        z-index: 1;
        width: 100%;
        top: 0;
        left: 0;
        background-color: #000000;
    }
}

.GameCard
{
    min-height: 60px;
    /* height: 100%; */
    min-width: 100px;
    /* background-color: rgba(0, 0, 0, 0.9) !important; */
    background: #000000;
    box-shadow: 3px 3px 4px 1px rgba(0,0,0,0.28);
    cursor: pointer;
    border: 0;
    border-radius: 7px;
    /* animation: gameCardOut 200ms ease-out;
    animation-fill-mode: backwards; */
    overflow: hidden;
    transition: transform .2s;
}

.GameCard:hover{
    transform: scale(1.03);
}

.GameCard.greyedOut {
    filter: grayscale(100%);
}

/* .GameCard:hover {
    animation: gameCardHover 200ms ease-in-out;
    animation-fill-mode: forwards;
} */

.GameCard .gameCardOverlay
{
    padding: 0;
}

.ImgLoader
{
    display: inline-flex;
    margin-top: 18%;
    margin-bottom: 30%;
}

.GameCard .gameCardOverlay .card-text
{
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    text-align: left;
    padding-left: 5px;
    padding-right: 5px;
    height: 43px;
    border-radius: 0 0 5px 5px;
    padding-top: 14px;
    color: #ffffff;
    overflow: hidden;
}

.GameCard .gameCardOverlay .card-text p
{
    margin: 0;
    line-height: 33px;
    font-size: 12px;
    font-weight: normal;
    padding-left: 0;
    padding-right: 0;
}

.GameCard .gameCardOverlay
{
    overflow: hidden;
    background-color: transparent;
    /* background-image: none;
    background-position-y: bottom;
    background-repeat: repeat-x;
    -webkit-transition : background 500ms ease-in-out; 
    -moz-transition : background 500ms ease-in-out;
    -o-transition : background 500ms ease-in-out; */
    position: absolute;
    top: 0;
    left: 0;
    float: left;
}

/* .GameCard.active .gameCardOverlay {
    bac
} */

/* .GameCard .gameCardOverlay:hover
{
    background-color: rgba(0, 0, 0, 0.6);
} */

.GameCard .gameCardOverlay .card-overlay {
    opacity: 0;
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 43px;
    transition: all .5s ease-in-out;
}

.GameCard.active .gameCardOverlay .card-overlay {
    opacity: 1;
}

.GameCard .gameCardOverlay .playGame
{
    position: relative;
    vertical-align: middle;
    top: 0;
    opacity: 0;
    width: 10%;
    height: 10%;
    -webkit-transition : top 300ms ease-in-out, opacity 300ms ease-in-out, width 300ms ease-in-out, height 300ms ease-in-out; 
    -moz-transition : top 300ms ease-in-out, opacity 300ms ease-in-out, width 300ms ease-in-out, height 300ms ease-in-out;
    -o-transition : top 300ms ease-in-out, opacity 300ms ease-in-out, width 300ms ease-in-out, height 300ms ease-in-out;
    transition: top 300ms ease-in-out, opacity 300ms ease-in-out, width 300ms ease-in-out, height 300ms ease-in-out;
}

.GameCard.active .gameCardOverlay .playGame
{
    top: 20%;
    opacity: 1;
    width: 30%;
    height: 30%;
}

/* .GameCard .gameCardOverlay:hover .playGame
{
    top: 20%;
    opacity: 1;
    width: 30%;
    height: 30%;
} */

.GameCard .card-img img
{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
}

/**
* New game design animations
*/
.gameScene .card-top {
    position: relative;
    top: 0;
    left: 0;
    height: 60%;
}

.playIcon {
    top: 36%;
    width: 25%;
    opacity: 0;
    transition: all .9s cubic-bezier(0.4, 0, 0.2, 1);
    position: absolute;
    left: 40%;
}

.playIcon .circle {
    fill-opacity:0.54;
    fill: #000000;
    stroke:#f2f2f2;
    stroke-miterlimit:10;
    stroke-width:2px;

    stroke-dasharray: 534;
    stroke-dashoffset: 534;
    stroke-linecap: butt;
    /* transition: all 2s cubic-bezier(0.4, 0, 0.2, 1); */
    transition: all 1s ease-in-out;

    /* fill: transparent; */
}

.playIcon .play {
    fill: #fff;
    transition: all .5s cubic-bezier(0.4, 0, 0.2, 1);
    transform: scale(1, 0) translateY(120%);
}

.GameCard.active .gameScene .playIcon {
    opacity: 1;
}

.GameCard.active .gameScene .circle {
    stroke-dashoffset: 0;
    stroke-dasharray: 534;
}

.GameCard.active .gameScene .play {
    transform: scale(1, 1) translateY(0%);
}

/*
.gameScene:hover .playIcon {
    opacity: 1;
}

.gameScene:hover .circle {
    stroke-dashoffset: 0;
    stroke-dasharray: 534;
}

.gameScene:hover .play {
    transform: scale(1, 1) translateY(0%);
}
*/

/** Like feature **/
.likeIcon .heart {
    fill-opacity:0.5;
    stroke:#fff;
    stroke-miterlimit:10;
    stroke-width:2px;
    fill: #000000;
    transform: scale(1)
}

.likeButton {
    position: absolute;
    bottom: 5px;
    /* right: 5px; */
    display: block;
    width: 16px;
    right: -20px;
    opacity: 0;
    transition: all .3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.GameCard.active .likeButton {
    right: 10px;
    opacity: 1;
}

.likeButton:hover {
    width: 22px;
    bottom: 5px;
    right: 7px !important;
}

@keyframes animLike {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.3) translateX(-3%);
    }
}

.likeButton.liked .likeIcon .heart {
    fill-opacity: 1;
    fill: #ffffff;
    /* transform: scale(1.3) 500ms ease-in-out; */
    transition: all 500ms ease-in-out;
}

.likeButton.liked {
    animation: animLike 500ms ease-in-out;
}

.likeButton.unliked .likeIcon .heart {
    fill-opacity:0.5;
    stroke:#fff;
    fill: #000000;
    transition: all 500ms ease-in-out;
}

.innerFavGameTooltip {
    text-align: center;
    font-size: 13pt;
    text-transform: uppercase;
    margin-bottom: 0;
}

.favPopover {
    border-radius: 3px;
}

.hotPlace {
    position: absolute;
    top: 10px;
    left: 10px;
}

.hotPlace .IconSVG {
    width: 27px;
    height: 27px;
}

.hotPlace .IconSVG .cls-1 {
    fill:#fff;
}

.hotPlace .IconSVG .cls-2 {
    fill:#c20002;
}