@import "./../../variables";

.MainBanner {
    position: relative;
    //padding-top: 26.04%;
    //overflow: hidden;
    //height: 0;
    //margin-top: -600px;
    opacity: 0;
    //top: -600px;

    .Banner {
        //position: absolute;
        //top: 0;
        //left: 0;
        width: 100%;
        //height: 100%;
        /* opacity: 0; */
        display: block;

        &.hide {
            display: none;
        }

        /*
        img {
            width: 100%;
            height: 100%;
            display: flex;
        }

        span {
            line-height: 1.5;
            position: absolute;
            top: 0;
            color: white;
            font-size: 20pt;
            font-weight: bold;
            padding-top: 10%;
        }

        .bannerCanvasSupport {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 100%;
        }
        */
    }
}

#SpineBannerSupport {
    //span {
    //    display: none;
    //}

    .bannerMessage {
        position: absolute;
        top: 0;
        color: white;
        font-size: 51pt;
        font-weight: 600;
        //padding-top: 5%;
        //padding-top: 74pt;
        font-family: 'Oswald', 'Montserrat', sans-serif;
        text-transform: uppercase;
        left: -500px;
        padding-left: 40px;
        color: $buttonYellow;
        padding-right: 90px;
        background: rgb(0, 0, 0);
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.8463760504201681) 0%, rgba(0, 0, 0, 0.7035189075630253) 76%, rgba(0, 0, 0, 0.008841036414565795) 100%);
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .messageSupp {
            text-align: left;
            border-bottom: 2px solid $buttonYellow;
            padding-bottom: 16px;

            p {
                padding: 0;
                margin: 0;
                line-height: 57pt;
                letter-spacing: 1pt;
            }
        }

        button {
            text-transform: uppercase;
            font-size: 21pt;
            position: relative;
            top: 0;

            &.btn-warning {
                padding: 12px 40px;
                font-weight: 700;
                font-family: 'Montserrat', sans-serif;
                color: $firstColor;
            }

            &:hover,
            &:active {
                background-color: lighten(#ffc107, 8%);
            }

            &:active {
                top: 2px;
                outline: none !important;
                box-shadow: none !important;
            }

            &:focus {
                outline: none !important;
                box-shadow: none !important;
            }
        }

        .buttonSupp {
            padding-top: 24pt;
        }

        &.show {
            left: 0;
            transition: left .5s cubic-bezier(0.190, 1.000, 0.220, 1.000);
        }
    }
}

#CustomerBannerSupport {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    /*opacity: 0;*/
    height: 100%;

    .IconSVG {
        .cls-1 {
            fill: #3fa9f5;
        }
    }

    .htmlBanner {
        display: none;//flex;
        justify-content: center;
        flex-direction: row;
        height: 100%;
        border-bottom: 2px solid #3fa9f5;

        .customerSupportLogo {
            height: 78%;
            position: relative;
            bottom: -22%;
        }

        .csLogo {
            width: 20%;
        }

        .csButtons {
            width: 80%;
            display: flex;
            justify-content: center;

            .btns {
                width: 90%;
                display: flex;
                justify-content: center;
                flex-direction: row;
                margin-left: auto;
                margin-right: 0;
                padding-top: 10%;

                button,
                a {
                    color: $firstColor;
                    font-weight: 700;
                    font-size: 1.4rem;
                    //padding: 1rem 2rem;
                    width: 92%;
                    padding-top: 2rem;
                    padding-bottom: 2rem;
                    background-color: $buttonYellow;
                    position: relative;
                    top: 0;

                    &.liveChat {
                        .cls-1 {
                            fill: #017048;
                        }

                        text-transform: uppercase;
                        text-decoration: none;
                        border: 1px solid #ffc107;
                        border-radius: .25rem;
                        padding-top: 1.8rem;
                        padding-bottom: 1.8rem;
                    }

                    &:hover {
                        background-color: darken($buttonYellow, 3%);
                        outline: none !important;
                        box-shadow: none !important;
                        text-decoration: none;
                    }

                    &:focus {
                        outline: none !important;
                        box-shadow: none !important;
                    }

                    &:active {
                        top: 3px;
                        outline: none !important;
                        box-shadow: none !important;
                    }

                    &.btn-secondary {
                        background-color: $iconGray;
                        border-color: $iconGray;
                    }
                }

                .btSupport {
                    width: 31%;

                    p {
                        margin: 0;
                        padding-top: 10px;
                        color: $firstColor;
                        font-weight: 500;
                    }

                    .liveChat {
                        display: flex;
                        justify-content: center;
                        flex-direction: row;
                        align-items: center;

                        span {
                            color: $firstColor;
                            position: relative;
                            padding: 0;
                            font-size: 1.4rem;

                            &.icon {
                                width: 40%;

                                .IconSVG {
                                    height: 40px;
                                }
                            }

                            &.text {
                                width: 60%;
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }
    }
}