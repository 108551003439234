@import "../../../../variables";
$primary-color: #b11adc;
$animation-duration: 0.4s;

@mixin label-active() {
  font-size: 0.75rem;
  transform: translateY(-20px);
}

.textarea-field {
  padding-left: 20px;
  padding-right: 20px;

  .space-top {
    min-height: 40px;
    width: 100%;
    display: block;
  }
  .input-centered {
    display: block;
    margin-bottom: 16px;
    overflow: hidden;
    position: relative;
    width: 100%;

    label {
      display: block;
      font-size: 1rem;
      font-weight: normal;
      left: 0;
      margin: 0;
      padding: 18px 0px 0 ;
      position: absolute;
      top: 0;
      width: 100%;
      transform: translateY(-20px);
    }
    &::after {
      border-bottom: 2px solid $primary-color;
      bottom: 0;
      content: "";
      display: block;
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      transform: scaleX(0);
      transition: all $animation-duration;
      width: 1%;
    }

    textarea {
      background-color: rgba(240,240,255,1);
      border: 0;
      border-bottom: 2px solid #cfcfcf;
      color: #333;
      display: block;
      font-size: 1.2rem;
      margin-top: 24px;
      outline: 0;
      padding: 0 12px 10px 12px;
      width: 100%;
      height: 150px;
      &:active,
      &:focus {
        background-color: rgba(240,240,255,1);
        &~ .input-group {
          &:after {
            border-bottom: 2px solid green;
            transform: scaleX(150);
          }
        }
        &~ label {
          color: green;
          @include label-active();
        }

      }
      &:not(empty) {
        ~ label {
          @include label-active();
        }
      }
    }

  }

}