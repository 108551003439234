$success: green;
$error: red;
$warning: #eba646;

.eldorado-dark-theme {
  #ClaimPopup {
    &.popup {
      .ClaimWrapper {
        background: #0a1520;
      }
    }
  }
  .PromotionsScreenContent{
    #ClaimPopup {
      &.popup {
        .ClaimWrapper {
          background: white;
          color: #0A122B;
        }
      }
    }
  }
}
#ClaimPopup {
  padding: 4rem 2rem 0 2rem;
  white-space: normal;

  &.popup {
    width: 100vw;
    height: 100vh;
    background: rgba(1,1,1,.25);
    position: fixed;
    z-index: 999999;
    top:0;
    left:0;
    pointer-events: all;
    overflow: hidden;
    .ClaimWrapper {
      position: absolute;
      top: calc(50vh - 200px);
      left: calc(50vw - 200px);
      width: 400px;
      padding: 0 2rem 2rem ;
      height: auto;
      z-index: 999999;
      background: white;
      box-sizing: border-box;
      border-radius: .5rem;
    }
  }
  .ClaimWrapper {
    .Content {
      &.Success {
        svg {

          path {
            fill: white;
          }
          circle {
            fill: $success;
          }
        }
        h6 {
          color: $success;
        }
      }
      &.Error {
        svg {
          .cls-1 {
            fill: $error;
          }
          .cls-2 {
            fill: white;
          }
        }
        h6 {
          color: $error;
        }
      }

      &.Warning {
        svg {
          .cls-1 {
            fill: $warning;
          }
          .cls-2 {
            fill: white;
          }
        }
        h6 {
          color: $warning;
        }
      }

      svg {
        max-width: 4rem;
      }
      h6 {
        font-size: 1.25rem;
      }
      p {
        padding: 4rem 0;
        font-weight: normal;
      }

      .play {
        background: #0a284b;
        background: linear-gradient(135deg, #295a92 0% ,  #2174df 100%);
        color: white;
      }
      .see-bonuses {
        background: transparent;
        color: #939393;
        text-decoration: underline;
      }
      .activate-later, .activate{
        background: #c30f26;
        background: linear-gradient(135deg, #ec0101 0% ,  #981c4c 100%);
        color: white;
      }
      .cancel {
        background: transparent;
        color: #dd174a;
        text-decoration: underline;
      }
    }
  }
}