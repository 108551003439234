@import "./../../../variables";

.MainMenu {
  height: 86px;
  line-height: 86px;
  position: relative;
  max-width: 100vw;
  border-bottom: 1px solid transparent;
  font-family: "Open Sans";

  span {
    line-height: 1.5;
  }

  .ImgLoader {
    margin-top: 40px;
  }

  .col-2:first-child {
    padding-left: 0px;
  }

  .left-menu-space {
    flex-grow: 0.5;
    flex: auto;
    max-width: 83px;
    min-width: 83px;
  }

  .logo-link {
    display: block;
    min-width: 143px;

    img {
      max-height: 85px;
      max-width: 149px;
      display: block;
    }


    .IconSVG {
      height: 70px;

      .cls-1 {
        fill: #0d0d0d;
      }

      .cls-2 {
        fill: #941b20;
      }

      .cls-3 {
        fill: #fccd00;
      }

      .cls-4 {
        fill: #ffef00;
      }
    }
  }

  .topMenu {
    display: flex;
    padding-left: 0;
    align-content: center;
    text-align: center;
    align-items: center;

    .MenuItem {
      padding: 0 6px;
      width: auto;

      a {
        letter-spacing: 1px;

        svg {
          width: 26px;
          height: 26px;
        }
      }
    }

    .divider {
      width: 1px;
      background: white;
      opacity: .3;
      height: 60%;
    }

    &.small {
      .MenuItem {
        width: auto !important;

        a {
          padding-left: 1rem !important;
          padding-right: 1rem !important;

          span {
            display: none !important;
          }

          svg {
            margin-top: 15px !important;
          }
        }

        .coming-soon {
          display: none !important;
        }
      }
    }

    &.big {
      .MenuItem {
        @media screen and (min-width: 1616px) {

          svg {
            &.pinMapNew {
              max-width: 1.4rem;
              display: inline-block;
              float: left;
            }

            &.world {
              width: 1.4rem;
            }
          }

          a {
            span {
              line-height: 28px;
            }

            .coming-soon {
              top: -59px;
            }
          }
        }
      }
    }

    &.hide {
      display: none;
    }
  }

  .logoSupport {
    min-width: 145px;
    width: 145px;
    margin-right: 15px;
    display: flex;
    align-items: center;
  }

  .locationSupportSmall {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-left: 13px;
    min-width: 110px;

    .menuLocationsSmall {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 0px 15px;
      border-bottom: 4px solid transparent;
      transition: border 500ms ease-out;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 15px;
        height: 70%;
        width: 1px;
        background-color: #4d4d4d;
      }

      &::before {
        content: "";
        position: absolute;
        right: 0;
        top: 15px;
        height: 70%;
        width: 1px;
        background-color: #4d4d4d;
      }

      &:hover {
        text-decoration: none;
        border-bottom: 4px solid #ffffff;
      }

      .name {
        font-size: 12px;
        font-weight: bold;
        color: #fff;
        text-transform: uppercase;
      }

      .pin-address {
        width: 27px;
        height: 27px;
        margin: 0 0 3px 0;

        .cls-1 {
          fill: #ffaa0b;
        }

        .cls-2 {
          fill: #000;
        }
      }
    }
  }

  .locationSupport {
    text-align: left;
    margin-left: 13px;

    @media only screen and (max-width: 984px) {
      display: none;
    }

    .locationsIn {
      padding-left: 35px;
      padding-top: 3px;
      height: 47px;
      border-left: 1px solid #4d4d4d;

      .menu-locations {
        height: 43px;
        background-color: #131b25;
        border: 1px solid #213a5b;
        border-radius: 20px;
        display: flex;
        padding-left: 20px;
        padding-right: 66px;
        line-height: 40px;
        text-decoration: none;
        overflow: hidden;
        position: relative;

        @media only screen and (max-width: 1560px) {
          //padding-left: 12px;
          //padding-right: 12px;
        }

        &:hover {
          text-decoration: none;
        }

        .arrow-down {
          background: #213a5b;
          position: absolute;
          right: 0;
          top: 0;
          height: 60px;
          padding-left: 12px;
          padding-right: 15px;

          svg {
            width: 22px;
            height: 18px;
            fill: $buttonYellow;
          }
        }

        span {
          line-height: 40px;
          text-transform: uppercase;

        }

        span.name {
          color: white;
          font-size: 18px;
          font-weight: bolder;
          padding-left: 10px;

          @media only screen and (max-width: 1560px) {
            //display: none;
          }
        }

        span.icon {
          flex-grow: 1;

          .IconSVG {
            //width: 20px;
            height: 25px;
            position: relative;
            top: -2px;
          }

          .cls-1 {
            fill: $buttonYellow;
          }

          .cls-2 {
            fill: #0c1d18;
          }
        }
      }
    }
  }
}