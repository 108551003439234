.digflag2 {
  background-image: url(../../bets/assets/flags-digitain-orig.png);
  width: 24px;
  height: 24px;
  background-position: 0 -3552px;
  overflow: hidden;
}


.f77 {
  background-position: 0 0
}

.f78 {
  background-position: 0 -24px
}

.f81 {
  background-position: 0 -48px
}

.f162430 {
  background-position: 0 -72px
}

.f82 {
  background-position: 0 -96px
}

.f83 {
  background-position: 0 -120px
}

.f84 {
  background-position: 0 -144px
}

.f85 {
  background-position: 0 -168px
}

.f72 {
  background-position: 0 -192px
}

.f73 {
  background-position: 0 -216px
}

.f74 {
  background-position: 0 -240px
}

.f90 {
  background-position: 0 -264px
}

.f88 {
  background-position: 0 -288px
}

.f89 {
  background-position: 0 -312px
}

.f91 {
  background-position: 0 -336px
}

.f92 {
  background-position: 0 -360px
}

.f160999 {
  background-position: 0 -384px
}

.f345425 {
  background-position: -25px -384px
}

.f94 {
  background-position: 0 -408px
}

.f95 {
  background-position: 0 -432px
}

.f96 {
  background-position: 0 -456px
}

.f97 {
  background-position: 0 -480px
}

.f98 {
  background-position: 0 -504px
}

.f93 {
  background-position: 0 -528px
}

.f154175 {
  background-position: 0 -552px
}

.f128 {
  background-position: 0 -576px
}

.f129 {
  background-position: 0 -600px
}

.f210 {
  background-position: 0 -624px
}

.f134 {
  background-position: 0 -648px
}

.f135 {
  background-position: 0 -672px
}

.f136 {
  background-position: 0 -696px
}

.f137 {
  background-position: 0 -720px
}

.f207 {
  background-position: 0 -744px
}

.f138 {
  background-position: 0 -768px
}

.f132 {
  background-position: 0 -792px
}

.f209 {
  background-position: 0 -816px
}

.f111 {
  background-position: 0 -840px
}

.f112 {
  background-position: 0 -864px
}

.f214 {
  background-position: 0 -888px
}

.f114 {
  background-position: 0 -912px
}

.f178 {
  background-position: 0 -936px
}

.f80 {
  background-position: 0 -960px
}

.f215 {
  background-position: 0 -984px
}

.f157678 {
  background-position: 0 -1008px
}

.f113 {
  background-position: 0 -1032px
}

.f203 {
  background-position: 0 -1056px
}

.f205 {
  background-position: 0 -1080px
}

.f206 {
  background-position: 0 -1104px
}

.f110 {
  background-position: 0 -1128px
}

.f105 {
  background-position: 0 -1152px
}

.f103 {
  background-position: 0 -1176px
}

.f106 {
  background-position: 0 -1200px
}

.f109 {
  background-position: 0 -1224px
}

.f104 {
  background-position: 0 -1248px
}

.f107 {
  background-position: 0 -1272px
}

.f108 {
  background-position: 0 -1296px
}

.f100 {
  background-position: 0 -1320px
}

.f123 {
  background-position: 0 -1344px
}

.f117 {
  background-position: 0 -1368px
}

.f118 {
  background-position: 0 -1392px
}

.f121 {
  background-position: 0 -1416px
}

.f120 {
  background-position: 0 -1440px
}

.f122 {
  background-position: 0 -1464px
}

.f116 {
  background-position: 0 -1488px
}

.f125 {
  background-position: 0 -1512px
}

.f222 {
  background-position: 0 -1536px
}

.f223 {
  background-position: 0 -1560px
}

.f119 {
  background-position: 0 -1584px
}

.f127 {
  background-position: 0 -1608px
}

.f131 {
  background-position: 0 -1632px
}

.f178686 {
  background-position: 0 -1656px
}

.f139 {
  background-position: 0 -1680px
}

.f140 {
  background-position: 0 -1728px
}

.f141 {
  background-position: 0 -1752px
}

.f142 {
  background-position: 0 -1776px
}

.f143 {
  background-position: 0 -1800px
}

.f144 {
  background-position: 0 -1824px
}

.f145 {
  background-position: 0 -1848px
}

.f163784 {
  background-position: 0 -1872px
}

.f146 {
  background-position: 0 -1896px
}

.f147 {
  background-position: 0 -1920px
}

.f159746 {
  background-position: 0 -1944px
}

.f149 {
  background-position: 0 -1968px
}

.f154 {
  background-position: 0 -2016px
}

.f167805 {
  background-position: 0 -2040px
}

.f208 {
  background-position: 0 -2064px
}

.f150 {
  background-position: 0 -2088px
}

.f153 {
  background-position: 0 -2112px
}

.f155 {
  background-position: 0 -2136px
}

.f156 {
  background-position: 0 -2160px
}

.f158 {
  background-position: 0 -2184px
}

.f160 {
  background-position: 0 -2208px
}

.f164597 {
  background-position: 0 -2232px
}

.f157 {
  background-position: 0 -2256px
}

.f161 {
  background-position: 0 -2280px
}

.f157628,
.f157629 {
  background-position: 0 -5232px
}

.f164 {
  background-position: 0 -2328px
}

.f165 {
  background-position: 0 -2352px
}

.f166 {
  background-position: 0 -2376px
}

.f167 {
  background-position: 0 -2400px
}

.f168 {
  background-position: 0 -2424px
}

.f169 {
  background-position: 0 -2448px
}

.f204 {
  background-position: 0 -2472px
}

.f170,
.f171 {
  background-position: 0 -2496px
}

.f172 {
  background-position: 0 -2520px
}

.f173 {
  background-position: 0 -2544px
}

.f130 {
  background-position: 0 -2568px
}

.f176 {
  background-position: 0 -2592px
}

.f174 {
  background-position: 0 -2616px
}

.f175 {
  background-position: 0 -2640px
}

.f179 {
  background-position: 0 -2664px
}

.f177,
.f180 {
  background-position: 0 -2688px
}

.f213 {
  background-position: 0 -2712px
}

.f117033 {
  background-position: 0 -2736px
}

.f183 {
  background-position: 0 -2760px
}

.f184 {
  background-position: 0 -2784px
}

.f186 {
  background-position: 0 -2808px
}

.f187 {
  background-position: 0 -2832px
}

.f216 {
  background-position: 0 -2856px
}

.f218,
.f220,
.f221 {
  background-position: 0 -2880px
}

.f219 {
  background-position: 0 -2904px
}

.f124 {
  background-position: 0 -2928px
}

.f168109 {
  background-position: 0 -2952px
}

.f150590 {
  background-position: 0 -2976px
}

.f212 {
  background-position: 0 -3000px
}

.f211 {
  background-position: 0 -3024px
}

.f185 {
  background-position: 0 -3048px
}

.f175063 {
  background-position: 0 -3072px
}

.f191 {
  background-position: 0 -3096px
}

.f189 {
  background-position: 0 -3120px
}

.f193 {
  background-position: 0 -3144px
}

.f194 {
  background-position: 0 -3168px
}

.f196 {
  background-position: 0 -3192px
}

.f195 {
  background-position: 0 -3216px
}

.f197 {
  background-position: 0 -3240px
}

.f199,
.f200 {
  background-position: 0 -3264px
}

.f99 {
  background-position: 0 -3288px
}

.f188 {
  background-position: 0 -3312px
}

.f201 {
  background-position: 0 -3336px
}

.f198 {
  background-position: 0 -3360px
}

.f101 {
  background-position: 0 -3384px
}

.f102 {
  background-position: 0 -3408px
}

.f202 {
  background-position: 0 -3432px
}

.f126 {
  background-position: 0 -3456px
}

.f115 {
  background-position: 0 -3480px
}

.f152,
.f87 {
  background-position: 0 -3552px
}

.f76 {
  background-position: 0 -3504px
}

.f86 {
  background-position: 0 -4034px
}

.f79 {
  background-position: 0 -4056px
}

.f75 {
  background-position: 0 -3504px
}

.f205550 {
  background-position: 0 -4080px
}

.f181 {
  background-position: 0 -3504px
}

.f182 {
  background-position: 0 -3600px
}

.f163 {
  background-position: -24px -264px
}

.f217 {
  background-position: 0 -3528px
}

.f151 {
  background-position: 0 -1992px
}

.f87,
.f69,
.f71,
.f68,
.f159 {
  background-position: 0 -3552px
}

.f360247 {
  background-position: -23px -336px
}

.f162 {
  background-position: 0 -3576px
}

.f194348 {
  background-position: 0 -3624px
}

.f192825 {
  background-position: 0 -3648px
}

.f159 {
  background-position: 0 -3672px
}

.f133 {
  background-position: 0 -3696px
}

.f153424 {
  background-position: 0 -3720px
}

.f221797 {
  background-position: 0 -3744px
}

.f148 {
  background-position: 0 -3768px
}

.f192609 {
  background-position: 0 -3840px
}

.f191778 {
  background-position: 0 -3864px
}

.f190 {
  background-position: 0 -3888px
}

.f218572 {
  background-position: 0 -3912px
}

.f269102 {
  background-position: 0 -3936px
}

.f300147 {
  background-position: 0 -3960px
}

.f301347 {
  background-position: 0 -3984px
}

.f251264 {
  background-position: 0 -3936px
}

.f227248 {
  background-position: 0 -4008px
}

.f211524 {
  background-position: -24px -24px
}

.f211526 {
  background-position: -24px -120px
}

.f211525 {
  background-position: -24px 0
}

.f211527 {
  background-position: -24px -48px
}

.f211528 {
  background-position: -24px -144px
}

.f2877 {
  background-position: -24px -360px
}

.f211529 {
  background-position: -24px -96px
}

.f211530 {
  background-position: -24px -72px
}

.f211532 {
  background-position: -24px -168px
}

.f249061 {
  background-position: 0 -3792px
}

.f252906 {
  background-position: 0 -3816px
}

.f2459 {
  background-position: 0 -4008px
}

.f2459 {
  background-position: 0 -4008px
}

.f306716 {
  background-position: 0 -4104px
}

.f313153 {
  background-position: 0 -4128px
}

.f345534 {
  background-position: -24px -310px
}

.f384246 {
  background-position: 0 -4152px
}

.f375265 {
  background-position: 0 -4176px
}

.f380627 {
  background-position: 0 -4200px
}

.f310630 {
  background-position: 0 -4224px
}

.f330686 {
  background-position: 0 -4248px
}

.f330687 {
  background-position: 0 -4272px
}

.f211531 {
  background-position: 0 -4320px
}

.f410725 {
  background-position: 0 -4344px
}

.f375122 {
  background-position: 0 -4392px
}

.f368333,
.f2694,
.f2843 {
  background-position: 0 -4416px
}

.f256652 {
  background-position: 0 -4440px
}

.f364752 {
  background-position: 0 -4464px
}

.f410741 {
  background-position: 0 -4488px
}

.f364753 {
  background-position: 0 -4512px
}

.f330697 {
  background-position: 0 -4536px
}

.f370979 {
  background-position: 0 -4560px
}

.f371216 {
  background-position: 0 -4584px
}

.f211527 {
  background-position: 0 -4608px
}

.f318836 {
  background-position: 0 -4632px
}

.f330688 {
  background-position: 0 -4656px
}

.f330690 {
  background-position: 0 -4680px
}

.f330691 {
  background-position: 0 -4704px
}

.f330692 {
  background-position: 0 -4728px
}

.f330693 {
  background-position: 0 -4752px
}

.f330694 {
  background-position: 0 -4776px
}

.f330695 {
  background-position: 0 -4800px
}

.f330696 {
  background-position: 0 -4824px
}

.f367228 {
  background-position: 0 -4848px
}

.f378278 {
  background-position: 0 -4872px
}

.f410724 {
  background-position: 0 -4896px
}

.f410740 {
  background-position: 0 -4920px
}

.f410746 {
  background-position: 0 -4944px
}

.f410748 {
  background-position: 0 -4968px
}

.f410749 {
  background-position: 0 -4992px
}

.f410750 {
  background-position: 0 -5016px
}

.f414769,
.f2830 {
  background-position: 0 -5040px
}

.f269562 {
  background-position: 0 -5064px
}

.f320642 {
  background-position: 0 -5088px
}

.f429707 {
  background-position: 0 -5112px
}

.f2874 {
  background-position: 0 -5136px
}

.f473568 {
  background-position: 0 -5160px
}

.f416739 {
  background-position: 0 -5184px
}

.f2870 {
  background-position: 0 -5208px
}

.f442885 {
  background-position: 0 -5256px
}

.f439512 {
  background-position: 0 -5280px
}

.f382377 {
  background-position: 0 -5304px
}

.f494968 {
  background-position: 0 -5328px
}

.f317857 {
  background-position: 0 -5352px
}

.f363453 {
  background-position: -24px -408px
}

.f3126 {
  background-position: -24px -432px
}

.f491910 {
  background-position: -24px -456px
}

.f431288 {
  background-position: -24px -480px
}

.f562890,
.f3085 {
  background-position: -24px -504px
}

.f3023 {
  background-position: -24px -528px
}

.f390057 {
  background-position: -24px -552px
}

.f3194 {
  background-position: -24px -576px
}

.f562813 {
  background-position: 0 -5376px
}

.f562815 {
  background-position: 0 -5400px
}

.f562814 {
  background-position: 0 -5424px
}

.f2524 {
  background-position: 0 -5448px
}

.f593441 {
  background-position: 0 -5472px
}

.f695275 {
  background-position: 0 -5496px
}