body {
    /*color: white;*/
}

.App-header {
    color: white;
    background-color: #0d0d0d;
}

.MainMenu {
    background-color: #0d0d0d;
}

.SubMenu {
    background-color: #f4e200;
}

.MenuItem a {
    color: #ffffff;
    border-bottom: 4px solid transparent;
    transition: border 500ms ease-out;
    -webkit-transition : border 500ms ease-out;
    -moz-transition : border 500ms ease-out;
    -o-transition : border 500ms ease-out;
    display: block;
    border-left: 1px solid rgba(255, 255, 255, 0);
    border-right: 1px solid rgba(255, 255, 255, 0);
}

.MenuItem a span
{
    color: #ffffff;
}

.MenuItem a svg
{
    fill: #ffffff;
}

.MenuItem a.selected,
.MenuItem a:hover {
    border-bottom: 4px solid #ffffff;
}

.MenuItem a:active {
    border-bottom: 8px solid #ffffff;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    border-right: 1px solid rgba(255, 255, 255, 0.2);

}

@keyframes animateItemText {
    0% {
        font-size: 1rem;
    }
    100% {
        font-size: 0.5rem;
    }
}

/*.MenuItem a:active span {*/
    /*animation: animateItemText 500ms ease-out;*/
/*}*/

@keyframes animateSVGitem {
    0% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
    100% {
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
    }
}

/*.MenuItem a:active svg*/
/*{*/
    /*animation: animateSVGitem 500ms ease-out;*/
/*}*/

.SubMenuItem,
.SubMenuItem a,
.SubMenuItem a span
{
    color: #941b20;
    border-bottom: 4px solid transparent;
    -webkit-transition : border 500ms ease-out; 
    -moz-transition : border 500ms ease-out;
    -o-transition : border 500ms ease-out;
}

.SubMenuItem a:hover,
.SubMenuItem a.selected {
    border-bottom: 4px solid #941b20;
}

.SubMenuItem svg
{
    fill: #941b20;
}

.SubMenuItem svg#svg116 #layer1 .st6
{
    fill: #f4e200 !important;
}

.AccountModal
{
    color: #000000;
}

 .account-menu .account-button .btn-success
{
    background: #1449c5;
    background: -webkit-linear-gradient(11deg,#1449c5,#0f9efe);
    background: linear-gradient(79deg,#1449c5,#0f9efe);
}

.account-menu .account-button .btn-success:hover
{
    background: #1449c5;
    background: -webkit-linear-gradient(51deg,#1449c5,#0f9efe);
    background: linear-gradient(119deg,#1449c5,#0f9efe);
}
.account-menu .account-button button {
    transition: all .5s ease-in-out .2s;
    border: none;

}
.account-menu .account-button .btn-warning
{
    background: #ff7701;
    background: -webkit-linear-gradient(11deg,#ff7701,#ffc200);
    background: linear-gradient(79deg,#ff7701,#ffc200);
    
}

.account-menu .account-button .btn-warning:hover
{
    background: #ff7701;
    background: -webkit-linear-gradient(51deg,#ff7701,#ffc200);
    background: linear-gradient(119deg,#ff7701,#ffc200);
}

.account-menu .account-button .btn-connect,
.account-menu .account-button .btn-connect:hover {
    background: #0e8541;
    background: -webkit-linear-gradient(11deg,#0e8541,#15d600);
    background: linear-gradient(79deg, #0e8541 0%, #15d600 100%);
}


.account-menu .account-button .btn-warning .txt,
.account-menu .account-button .btn-success .txt,
.account-menu .account-button .btn-connect .txt
{
    font-weight: 600;
    color: #ffffff;
}

.account-menu .account-button .btn-success.logout .txt
{
    font-weight: bold;
}

.AccountModal .modal-dialog .modal-content .modal-header
{
    background-color: #fccd00;
}

.AccountModal .modal-dialog .modal-content .modal-header .modal-title
{
    color: #0a4230;
}

.AccountModal .modal-dialog .modal-content .modal-header .close
{
    color: #0a4230;
}

.AccountModal .modal-dialog .modal-content .modal-footer
{
    background-color: #f7f7f7;
}

.AccountModal .modal-dialog .modal-content .modal-footer .modal-footer-bottom .info-text
{
    color: #127954;
}

.AccountModal .modal-dialog .modal-content .modal-footer .modal-footer-body .sms-action-message
{
    color: #0fbc8d;
}

.input-centered .input-group.valid .IconSVG .fillMe
{
    fill: #127954;
}

.input-centered .input-group.error .IconSVG .fillMe
{
    /* fill: #ff0000; */
    fill: #cccccc;
}

.login_register_link,
.login_register_link:hover,
.login_register_link:focus,
.login_register_link:active
{
    color: #06734c;
}

.ColumnMessage p
{
    color: #cccccc;
}

.ColumnMessage p.success
{
    color: #06734c;
}

#ContentPage .icon .IconSVG
{
    /* fill: #97282d; */
    fill: #017048;
}

#ContentPage .text
{
    color: #017048;
    /* color: #97282d; */
    font-weight: bold;
}

/** Content loader **/
.ContentLoader div *
{
    background-color: #941b20;
}

.splash-logo div *
{
    background-color: #fccd00;
}

.ImgLoader div
{
    /* background-color: #017048; */
    background-color: #fccd00;
}

#PlayerProfileIcon .cls-2,
#PlayerProfileIcon .cls-3
{
    fill:#000000 !important;
}

#PlayerProfileIcon .cls-3
{
    stroke:#000000 !important;
}

.playerProfileButton{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    cursor: pointer;
}

.playerProfileButton a
{
    padding-top: 0;
    padding-bottom: 0;
    padding: 0;
}

.playerProfileButton.selected a {
    border-bottom: 4px solid #ffffff;
}

.PlayerProfile .gray {
    background-color: #f7f7f7;
}

.pac-container {
    z-index: 99991;
}
