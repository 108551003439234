#live-chat {
  &.position-2 {
    div {
      div {
        margin-right: 4rem;
      }
    }
  }
  div {
    div {
      transition: all 0.5s ease-in-out 0s;
    }
  }
}