@import "./../../../../variables.scss";

.PopularGames {
  padding-bottom: 40px;

  h1 {
    .texts {
      float: left;

      .text {
        color: $firstColor !important;
        display: block;
      }
    }

    .icon {
      .IconSVG {
        width: 24px;
        fill: $firstColor !important;

        .st22 {
          stroke-width: 0.26458332;
        }
      }
    }
  }

  .GamesCards {
    .gameColumn.big {
      grid-column: span 2;
      grid-row: span 2;

      .intermGameCard {
        height: 100%;
      }
    }
  }

}