.ebb-iconWrapper {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  .ebb-icon {
    width: 18px;
    height: 18px;
    transform: rotate(0deg);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    fill: #000;

    &:first-child {
      position: relative;
      top: 4px;
    }

    &:last-child {
      position: relative;
      top: -4px;
    }

    &.dark {
      fill: #979DAB;
    }
  }

  &.ebb-expanded {
    width: 36px;

    & .icon {
      transform: rotate(180deg);
    }
  }
}