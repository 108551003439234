$borderColor: #cccccc;
$borderWidth: 2px;
$bonusColor: #9b36aa;
$moneyColor: #007048;
$activeColor: #07ba8a;
$activeBackground: #cef1e8;
$waitingColor: #f7931e;
$waitingBackground: #fde9d2;
$normalFontSize: 1rem;
$black: rgb(13, 13, 13);
$progressHeight: 0.7rem;
$cancelColor: #c21301;
$cancelBackground: #f3cccc;

svg.IconSVG.withdraw-alert-icon {
  max-width: 1.5rem;
  max-height: 1.4rem;
}

#wallet {
  display: flex;
  flex-direction: column;
  padding: 0.2rem 2.5rem 3rem 2.5rem;

  .WalletButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0;

    .btn {
      background-color: $moneyColor;
      font-size: $normalFontSize;
      width: 30%;
      color: white;
      border: $borderWidth solid $moneyColor;
      font-weight: 600;
      -webkit-transition: all .2s ease-in;
      -moz-transition: all .2s ease-in;
      -ms-transition: all .2s ease-in;
      -o-transition: all .2s ease-in;
      transition: all .2s ease-in;
      padding: .7rem 1.4rem;

      &:last-child {
        margin-right: 0px;
      }

      &.outer {
        width: 66%;
        background-color: transparent;
        color: $moneyColor;
      }

      &:hover {
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        transform: scale(1.1, 1.1);
      }
    }
  }

  .col-3 {
    width: 25%;
    float: left;
  }

  .col-9 {
    width: 75%;
    float: left;
  }

  .total {
    border-bottom: $borderWidth solid $borderColor;

    .value {
      font-size: 3.2rem;
      font-weight: bold;
      line-height: 1;

      small {
        font-weight: inherit;
        font-size: 60%;
        color: gray;
      }
    }

    .description {
      color: gray;
      margin-bottom: 20px;
    }

  }

  .money-widget {
    text-align: left;
    width: 50%;
    float: left;
    padding: 2.8rem 0 3.35rem 0.5rem;
    min-height: 310px;

    &.real {
      border-right: $borderWidth solid $borderColor;

      .title {
        color: $moneyColor;
      }
    }

    &.bonus {
      .title {
        color: $bonusColor;
      }
    }

    .title {
      font-weight: 600;
      font-size: $normalFontSize;
      text-transform: capitalize;
    }

    .value {
      font-weight: 700;
      font-size: 2.1rem;
      line-height: 1;
      padding-bottom: 0.825rem;
      display: inline-block;
      padding-right: 35px;

      small {
        font-weight: inherit;
        font-size: 60%;
        color: gray;
      }

      .popover-money-widget {
        position: relative;
        display: block;
        float: right;
        height: 0px;
        margin-top: -2.1rem;
        margin-right: -2rem;
        //top: 10px;
        //right: 0px;
      }
    }

    &.bonus {
      .buttons {
        .btn {
          background-color: $bonusColor;
          border-color: $bonusColor;
          padding: .7rem 1.4rem;

          &.outer {
            color: $bonusColor;
          }
        }
      }
    }

    .buttons {
      padding-top: 0.825rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 90%;

      .BonusProducts {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60px;
        margin-bottom: 10px;
        border-top: 1px solid #acacac;
        padding-top: 20px;

        .ProductItem {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          p {
            color: #777;
            font-size: 14px;
            font-weight: 500;
            margin: 0;
            padding: 0;

            span {
              font-weight: 500;
            }
          }
        }
      }

      .PendingContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        .PendingProducts {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          min-height: 60px;
          border-top: 1px solid #acacac;
          padding: 10px 0px 0px 0px;

          .ProductItem {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-bottom: 3px;

            &:last-of-type {
              margin-bottom: 0;
            }

            .LinkStyle {
              color: #004085;
              text-decoration: underline;
              font-size: 14px;
              font-weight: 500;
              cursor: pointer;
            }

            p {
              color: #777;
              font-size: 14px;
              font-weight: 500;
              margin: 0;
              padding: 0;
              display: flex;

              span {
                font-weight: 500;
              }
            }

            &.Icons {
              .ApprovedIcon {
                .cls-2 {
                  fill: #fff;
                }

                fill: #a0e03c;
                width: 13px;
                margin: 3px 3px;
              }

              .PendingIcon {
                fill: #ffb400;
                width: 13px;
                margin: 3px 3px;
              }
            }
          }
        }
      }

      .withdraw-alert-icon {
        margin-right: 9px;
        height: 20px;
        margin-top: -3px;
      }
    }

    .col-3 {
      .IconSVG {
        margin-left: 65%;
        margin-top: .31rem;
        width: 100%;
        height: 100%;
        position: relative;

        &.real-money-icon {
          margin-left: 40%;
          margin-top: 0;
        }

        .fillMe {
          fill: $borderColor;
        }
      }
    }

    .right {
      float: right;
    }

  }
}

#bonus-popover {
  position: relative;
  padding: 0 !important;

  .green-info {
    width: 1.5rem !important;
    position: absolute;
    left: 0;
    top: 0;
    right: auto;
    margin-left: 0 !important;
    margin-top: 0 !important;
    height: auto !important;

    .st1 {
      fill: $bonusColor !important;
    }
  }
}

#bonus,
.wallet-bonus {
  text-align: left;
  padding-top: 5.2rem;
  position: relative;

  .errors {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    color: darkred;
  }

  .bonus-widget {
    padding: 1rem 2.5rem 1rem 2.5rem;
    -webkit-transition: all .2s ease-in;
    -moz-transition: all .2s ease-in;
    -ms-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    transition: all .2s ease-in;

    &.pending {
      background-color: lighten($borderColor, 15);
      pointer-events: none !important;
    }

    &.removed {
      height: 0px;
      padding: 0px;
      overflow: hidden;
    }

    .col-4 {
      width: 33%;
      float: left;
    }

    .status {
      background-color: $waitingBackground;
      color: $waitingColor;
      background-color-opacity: 0.5;
      display: inline-block;
      font-size: $normalFontSize;
      padding: 0.5rem 1.7rem;
      border-radius: $normalFontSize*1.2;
      font-weight: 800;
      margin-bottom: $normalFontSize;
      text-transform: uppercase;

      &.active,
      &.wagered {
        background-color: $activeBackground;
        color: $activeColor;
      }

      &.expired,
      &.forfeited,
      &.lost {
        background-color: $cancelBackground;
        color: $cancelColor;
      }
    }

    .statusIcon {
      width: 3rem;
      display: inline-block;
      margin-left: 1rem;
      margin-top: -.5rem;

      .fillMe {
        fill: $borderColor;
      }

    }

    .title {
      width: 100%;
      clear: both;
      font-size: $normalFontSize;
      font-weight: 700;
      line-height: 1;
      letter-spacing: 0.1rem;
      text-align: right;
      padding-bottom: calc($normalFontSize / 2);
    }

    .timeago {
      &.error {
        color: $cancelColor;
      }

      font-weight: 500;
      font-size: 0.875rem;
      margin: 0 0 1.225rem;
      text-indent: 2.2rem;
      position: relative;

      .IconSVG {
        position: absolute;
        left: .4rem;
        width: 1.3rem;
        top: -.1rem;

        &.checkmark {
          .fillMe {
            fill: $moneyColor !important;
          }
        }
      }
    }

    .value {
      font-size: $normalFontSize;
      font-weight: 500;
      color: $bonusColor;
      text-align: right;

      .currency-value {
        display: inline-block;
      }
    }

    .details {
      font-size: $normalFontSize;
      letter-spacing: 0.1rem;
      color: $borderColor;
      padding: 0.3rem;
      font-weight: 500;

      .progress {
        height: $progressHeight;
        margin-top: calc($progressHeight / 4);
        margin-bottom: calc($progressHeight / 4);

        .progress-bar {
          background-color: $moneyColor;
          border-radius: $progressHeight;
        }
      }

      .percent {
        font-weight: 700;
        font-size: $normalFontSize * 1.6;
        letter-spacing: 0rem;
      }

      strong {
        line-height: 1.9;
        font-size: $normalFontSize * 1.2;
      }

      &.active {
        .percent {
          color: $moneyColor;
        }

        strong {
          color: $black;
        }
      }
    }

    .current {
      font-size: $normalFontSize;
      letter-spacing: 0.1rem;
      color: $borderColor;
      padding: 0.3rem;
      font-weight: 500;
      text-align: left;
      clear: both;
      width: 100%;
      margin-bottom: 10px;

      .value {
        letter-spacing: 0.05rem;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        line-height: 1;
        margin-bottom: 5px;
        margin-top: 5px;

        &.RingFence {
          color: #ccc;

          small {
            color: #ccc;
          }

          svg {
            path {
              fill: #ccc;
            }
          }
        }

        svg {
          width: 20px;
          height: 20px;
          margin-right: 5px;

          path {
            fill: #9b36aa;
          }
        }
      }
    }

    .left {
      margin-left: 6px;
    }

    .footer {
      margin: 3rem 1rem 1.5rem;
      border-bottom: $borderWidth solid $borderColor;
      height: 1.8rem;

      &.simple-footer {
        margin-top: -1.125rem;
      }

      .left {
        float: left;
      }

      a {
        color: $moneyColor;
        font-weight: 500;
        font-size: $normalFontSize;
        text-indent: 2.1rem;
        position: relative;
        display: inline-block;
      }
    }

    .forfeit {
      cursor: pointer;
      width: 2.3rem;
      padding: .3rem .5rem;
      border: 1px solid lighten($borderColor, 12);
      margin-top: -1rem;
      margin-right: 1rem;
      border-radius: .3rem;
      -webkit-transition: all 0.2s ease-in;
      -moz-transition: all 0.2s ease-in;
      -ms-transition: all 0.2s ease-in;
      -o-transition: all 0.2s ease-in;
      transition: all 0.2s ease-in;

      .fillMe {
        -webkit-transition: all 0.2s ease-in;
        -moz-transition: all 0.2s ease-in;
        -ms-transition: all 0.2s ease-in;
        -o-transition: all 0.2s ease-in;
        transition: all 0.2s ease-in;
        fill: darken($borderColor, 50);
      }

      &:hover {
        background-color: lighten($borderColor, 12);

        .fillMe {
          fill: $cancelColor;
        }
      }
    }
  }

  .right {
    float: right;
  }

  .bonusAvailability {
    margin-top: -.5rem;
    margin-right: .825rem;
  }

}

.wallet-bonus {
  padding-top: 0;
}

#NoBonus {
  font-weight: 500;
  font-size: 14px;
  width: fit-content;
  margin: 0px auto 50px auto;
  color: #004085;
  background-color: #cce5ff;
  padding: 10px 25px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 5px;

  a {
    font-weight: 600;
    color: #004085;
    text-decoration: underline;
    margin: 0px;
  }
}

.npb {
  padding-bottom: 0 !important;
}