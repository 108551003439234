.tournament-missions-components-sport-betslip-component {
  background: #fff;
  border-radius: 7px;
  text-align: left;
  border: 3px solid #1F83E6;
  margin-bottom: 10px;

  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  .tm-header {
    margin: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tm-type {
      display: flex;
      align-items: center;

      text-align: left;
      font-size: 12px;
      font-weight: bold;
      color: #596783;
      text-transform: uppercase;

      img {
        display: block;
        height: 13px;
        width: 13px;
        margin-right: 5px;
      }
    }

    .tm-name {
      text-align: left;
      font-size: 12px;
      font-weight: bold;
      color: #17214D;
    }
  }

  .tm-line {
    border-bottom: 1px solid #e6eef1;
    margin: 5px 0px;
  }

  &.info {
    .info-panel {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .star-up {
        margin: 5px 10px 10px;

        img {
          display: block;
          width: 25px;
          height: 33px;
          object-fit: contain;
        }
      }

      .txt {
        flex: 1 1 100%;
        text-align: left;
        font-size: 10px;
        color: #000000;
        padding-bottom: 7px;

        &.ml {
          margin-left: 10px;
        }
      }

      .qm {
        margin: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;

        background-color: #D2DBE7;
        width: 16px;
        min-width: 16px;
        max-width: 16px;
        height: 16px;

        font-size: 11px;
        font-weight: bold;
        color: #fff;
      }
    }

    .scores {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 10px 10px;

      .score {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        .tm-container {

          min-width: 57px;
          margin-left: 10px;
          width: 100%;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .tm-indicator {
            position: relative;
            overflow: visible;
            border-radius: 2px;
            background: #D2DBE7;
            height: 2px;
            min-height: 2px;
            max-height: 2px;
            width: 100%;
            margin-bottom: 5px;


            img {
              width: 28px;
              height: 7px;
              position: absolute;
              top: 0;
              left: -5px;
              opacity: 0;
              transition: left 200ms ease-in, opacity 200ms ease-out;
            }
          }

          .stars {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              display: block;
              width: 19px;
              height: auto;
            }

            img.should-blink {
              opacity: .7;
              animation: blinkerFilter 600ms linear infinite;
            }


          }

          .value {
            white-space: nowrap;
            text-align: center;

            font-size: 17px;
            font-weight: bold;
            color: #8E95B2;

            small {
              font-size: 10px;
            }

            &.should-blink {
              color: #586682;
              //animation: blinkerColor 600ms linear infinite;
            }
          }

          @keyframes blinkerFilter {
            50% {
              filter: grayscale(.7);
            }
          }

          @keyframes blinkerColor {
            50% {
              color: #586682;
            }
          }

          .title {
            font-size: 10px;
            font-weight: bold;
            color: #586682;
            text-align: center;
            white-space: nowrap;
          }
        }

        .filled {
          .tm-container {
            .wrapper {
              background-color: #0C81B0;

              .value {
                color: #fff;
              }
            }
          }

          .title {
            color: #2EC6FF;
          }
        }

        .stars-5 {
          .tm-container {
            min-width: 95px;
          }
        }

        .stake {
          margin: 0 3px 0 0;


        }

        .odd {
          margin: 0 3px;
        }

        .eventNo {
          margin: 0 0 0 3px;
        }
      }

      .score:first-child .tm-container {
        margin-left: 0;
      }

      .points {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;

        .title {
          font-size: 10px;
          font-weight: normal;
          color: #586682;
          line-height: 12px;
          margin-bottom: -3px;
          white-space: nowrap;
        }

        .points-txt {
          white-space: nowrap;
        }

        .star-points {
          white-space: nowrap;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: nowrap;
          margin-top: -5px;

          img {
            display: block;
            width: 18px;
            height: 18px;
            object-fit: contain;
          }

          font-size: 10px;
          font-weight: bold;
          color: #2EC6FF;
          line-height: 10px;
        }
      }
    }
  }


  &.not-enrolled {
    .tm-info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .progress-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding-bottom: 5px;
      }

      .progress-title {
        font-size: 12px;
        color: #888;
        font-weight: 600;
        text-align: center;
      }

      .progress-text {
        font-size: 12px;
        color: #FF5B00;
        font-weight: bold;


        .muted {
          color: #8D441B;
        }

        .days {
          color: #FF975D;
        }
      }

      .progress-text.begins {
        color: #111;


        .muted {
          color: #666;
        }

        .days {
          color: #111
        }
      }

      .tm-ranking {
        background-image: url(./assets/top-bg.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 45px;
        height: 35px;
        margin: 10px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .tm-sub-text,
        .tm-high-text {
          text-align: center;
          font-size: 6px;
          line-height: 6px;
          font-weight: bold;
          color: #fff;
          text-shadow: 1px 1px #000
        }

        .tm-sub-text {
          padding-top: 4px;
        }

        .tm-high-text {
          font-size: 11px;
          line-height: 11px;
        }
      }

      .tm-text {
        text-align: left;
        font-size: 10px;
        color: #1F84E6;
        font-weight: 600;
      }

      .tm-btn-wrapper {
        margin: 0 10px;

        .tm-btn {
          cursor: pointer;
          border-radius: 5px;
          background: linear-gradient(to right, #F36900, #EEB003);
          height: 34px;
          width: 150px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 11px;
          color: #fff;
          font-weight: bold;
          text-transform: uppercase;

        }

        .tm-locked {
          border-radius: 5px;
          background: linear-gradient(to right, #797979, #787878);
          font-size: 11px;
          color: #fff;
          font-weight: bold;
          text-transform: uppercase;
          width: 150px;
          height: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  &.error {
    border-color: #FF9940;

    .tm-header {
      .tm-type {
        text-decoration: line-through;
      }
    }

    .tm-error {
      text-align: left;
      font-size: 10px;
      font-weight: 600;
      color: #FF7700;
      margin: 10px;
    }

    .tm-errors {
      margin: 10px;

      .tm-error-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        text-align: left;
        color: #FF7700;
        font-size: 10px;
        font-weight: 600;

        img {
          display: block;
          width: auto;
          height: 20px;
          margin-right: 7px;
        }
      }
    }

    .tm-footer {
      margin: 10px;
      text-align: right;
      font-size: 10px;
      font-weight: 600;
      color: #003BDE;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}