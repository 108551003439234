.rsc-ticket-switch {
  width: 100%;
  color: #000;

  text-align: center;

  .rsc {
    height: 0;
    width: 0;
    display: none;
  }

  .rsl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    cursor: pointer;
    width: 323px;
    height: 36px;
    padding: 3px;
    background: #FFFFFF;
    border-radius: 10px;
    position: relative;
    transition: background-color .2s;
    fill: #586682;

    .rls-icon {
      width: 162px;
      font-size: 10px;
      font-weight: bold;

      svg {
        height: 12px;
        position: relative;
        top: 2px;
        fill: #B7CDD8;
      }
    }
  }

  .rsl .rsb {
    display: block;
    position: absolute;
    text-align: center;
    line-height: 30px;
    top: 3px;
    left: 3px;
    width: 154px;
    height: 30px;
    border-radius: 9px;
    transition: 0.2s;
    background-color: #586682; //linear-gradient(90deg, #F8991C 0%, #FF196E 100%);
    color: #fff;
    fill: #fff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    font-size: 10px;
    font-weight: bold;

    svg {
      display: inline-block;
      margin-right: 5px;
      position: relative;
      height: 18px;
      top: 5px;
    }

  }

  .rsc:checked+.rsl .rsb {
    transform: translateX(163px);
    background-color: #586682; //linear-gradient(90deg, #FF19F7 0%, #5819FF 100%);
    color: #fff;
    fill: #fff;

    svg {
      display: inline-block;
      margin-right: 5px;
      position: relative;
      height: 18px;
      top: 5px;
    }
  }
}

.rsc-ticket-switch:focus,
.rsc-ticket-switch:active {
  outline: none;
}

.rsc-ticket-switch.rsc-ticket-switch-disabled {
  pointer-events: none;
  opacity: .6;
}