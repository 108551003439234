.frame {
  .sidebar {
    position: fixed;
    top: 100vh;
    right: 0;
    width: 412px;
    height: 100%;
    padding-top: 40px;
    overflow: hidden;
    border-left: 1px solid var(--ns-sidebar-bg);
    background-color: var(--ns-sidebar-bg);
    z-index: 92;
    transition: top 0.2s linear;
    overflow: auto;

    scrollbar-color: var(--ns-scrollbar-thumb-bg) var(--ns-scrollbar-track-bg);
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 5px
    }

    &::-webkit-scrollbar-track {
      background: var(--ns-scrollbar-track-bg);
      border-radius: 0px
    }

    &::-webkit-scrollbar-thumb {
      background: var(--ns-scrollbar-thumb-bg);
      border-radius: 3px
    }
  }

  .sidebar.expanded {
    width: 100%;
    top: 0;
    border: 0;
  }

  @media (min-width: 848px) {
    .sidebar {
      position: absolute;
      padding-top: 0;
      top: 0;
      width: 412px;
    }
  }

  @media (min-width: 1920px) {
    .sidebar {
      top: 0;
      width: 412px;
    }
  }

  @media (max-width: 847px) {
    .sidebar {
      z-index: 1302;
    }
  }
}