.BonusWidget {
  background: rgba(12, 33, 148, 0.17);
  margin: 0.25rem 1rem 1rem;
  border-radius: .5rem;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .3);
  max-width: calc(100% - .5rem);

  .details {
    min-height: 100px;
    display: grid;
    grid-template-columns: 100px 3fr;
    margin: 0 1rem 0 0;
    border-radius: .5rem .5rem 0 0;
    width: 100%;
    overflow: hidden;

    .img {
      overflow: hidden;
      height: auto;
      display: block;
      position: relative;
      border-radius: .5rem 0 0 0;

      img {
        width: 100%;
        margin: 0 0 -.3rem 0;
      }
    }

    .other-details {
      background: rgb(88, 46, 60);
      background: linear-gradient(135deg, rgba(88, 46, 60, 1) 0%, rgba(127, 33, 65, 1) 34.5%, rgba(243, 48, 100, 1) 100%);
      height: 100%;

      .title {
        font-weight: lighter;
        text-align: right;
        font-size: .95rem;
        padding: .5rem .75rem;
        color: #ffffff;
        position: relative;

        &:after {
          content: " ";
          background: rgb(255, 255, 255);
          background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
          width: calc(100% - 1.5rem);
          height: 1px;
          position: absolute;
          bottom: .25rem;
          left: .75rem;
        }
      }

      .bonus {
        color: white;
        text-align: left;
        padding: .25rem .75rem;
        font-size: .75rem;
        display: grid;
        grid-template-columns: 3fr 2fr;
        margin-top: 0.6rem;

        .value {
          text-align: right;
          font-size: 1.25rem;
          letter-spacing: -0.9px;
          line-height: 1;
        }

        .name {
          .type {
            font-weight: normal;
            font-size: .65rem;
          }
        }
      }

    }
  }

  .bonus-conflicts {
    background: #ffe5ec;
    text-align: left;
    padding: .5rem;
    font-size: .75rem;
    font-weight: normal;

    svg {
      max-width: 1rem;
      margin-right: .5rem;
      float: left;

      .cls-1 {
        fill: #feb400;
      }

      .cls-2 {
        fill: white;
      }
    }

    div {
      padding-left: 1.5rem;
      padding-bottom: .25rem;
      color: #c00b42;

      &:first-child {
        padding-left: 0;
      }
    }
  }

  .cta {
    background: #E7EDF3;
    border-radius: 0 0 .5rem .5rem;
    padding: 0 .75rem;
    display: grid;
    grid-template-columns: 7fr 5fr;

    &.disabled {
      filter: grayscale(1);
      opacity: .5;
    }

    .button {
      cursor: pointer;
      background: rgb(244, 22, 96);
      background: linear-gradient(135deg, rgba(244, 22, 96, 1) 0%, rgba(255, 180, 0, 1) 100%);
      font-size: .75rem;
      color: white;
      padding: .5rem .75rem;
      margin: 1.2rem 0;
      border-radius: .15rem;
      font-weight: 600;

      &:disabled,
      &.disabled {
        filter: grayscale(1);
        pointer-events: none;
      }
    }

    .timer {
      font-size: 1rem;
      margin: 1.2rem 0;
      display: inline-flex;
      text-align: left;
      font-weight: 600;
      color: #484848;
      align-items: center;

      div {
        display: inline-block;
        padding: .25rem .4rem;

        &.time {
          font-size: .75rem;
          font-weight: normal;
          background: #ACBFD2;
          color: #ffffff;
          margin-right: .25rem;
        }

        &.days {
          background: #ACBFD2;
          color: #FFFFFF;
          font-size: .65rem;
          font-weight: normal;
          margin: .25rem 0;
        }
      }

    }
  }

  &.empty {
    .details {
      display: block;
      min-height: auto;
      position: relative;

      .img {
        position: absolute;
        width: 1.5rem;
        left: 1rem;
        top: .5rem;

        svg {
          fill: white;
        }
      }

      .other-details {
        background: linear-gradient(185deg, #312828 0%, #786060 100%)
      }

      .title {
        font-size: 110%;
        font-weight: bold;

        &:after {
          display: none;
        }
      }
    }

    .cta {
      grid-template-columns: 1fr 1fr;
    }
  }

  &.compact {
    margin: 0;
    max-width: 100%;

    .details {
      display: block;
      position: relative;
      min-height: auto;
      border-radius: .5rem;
      margin: 0;

      .other-details {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;

        h2 {
          margin: 0;
          padding: .25rem .5rem;
          text-align: left;
          background: rgba(0, 0, 0, 0.2);
          text-shadow: 1px 1px 0px rgba(1, 1, 1, 1);
          font-size: .8rem;

          .change {
            font-size: 90%;
            color: #dddddd;
            text-decoration: underline;
            float: right;
          }

          &:after {
            display: none;
          }
        }
      }

      .deposit-details {
        font-size: .75rem;
        text-align: right;
        padding-right: .5rem;
        font-weight: normal;
        color: #5311D6;
        padding-top: 0.55rem;

        .bonus-received {
          font-size: initial;
        }

        .alert {
          div {
            font-weight: 600;
            color: #F81057;

            &.atitle {
              text-transform: uppercase;
              font-weight: 700;
            }

            strong {
              font-weight: bold;
            }
          }
        }

        div {
          span {
            &:not(.icon) {
              display: inline-block;
              vertical-align: bottom;
              padding-bottom: 0.1rem;
            }
          }
        }

        .icon {
          margin-right: .25rem;

          svg {
            fill: #5311D6;
            max-width: 1.5rem;
          }
        }
      }
    }
  }

  &.grayscale {
    border: 1px solid #F81057;

    .details {
      .img {
        img {
          filter: grayscale(1);
        }
      }
    }
  }
}

.bonusCarousel {
  .slick-dots {
    margin: -1.2rem 0 1.2rem 0;

    li {
      display: inline-block;

      &.slick-active {
        button {
          opacity: 1;
        }
      }

      button {
        /* margin: -2rem 0 1rem; */
        width: 3rem;
        font-size: 0;
        background: white;
        border: 0px;
        margin-left: 1px;
        opacity: .4;
      }
    }
  }
}

.DepositOnlineContent {
  .BonusWidget {
    &.compact {
      margin: 0;
      max-width: calc(100% + 2rem);
      margin: 0 -1rem;

      .details {
        .img {
          img {
            margin-bottom: -.4rem;
          }
        }
      }
    }
  }
}

.BonusWidget {
  &.loading {
    .details {
      background: linear-gradient(0deg, #582e3c 0%, #7f2141 50%, #f33064 100%);
      grid-template-columns: 8rem 1fr;
      grid-gap: 1rem;
      height: 11rem;
      max-height: 11rem;
      border-radius: .5rem;
      position: relative;
      color: white;
      font-size: .85rem;
      padding-right: 1rem;

      &.no-bonus {
        background: linear-gradient(0deg, #50333d 0%, #633f4b 50%, #ac6a7b 100%);

        .message {
          opacity: .85;
        }
      }

      .img {
        position: absolute;
        width: 16rem;
        height: 12rem;
        bottom: 0;
        mix-blend-mode: luminosity;
        background-size: cover;
      }

      .message {
        height: 100%;
        display: flex;
        align-items: center;
        z-index: 2;
        position: relative;
        text-align: center;
        width: 100%;
      }
    }
  }
}

.bonusAvailable {
  .BonusWidget {
    .button {
      background: rgb(88, 46, 60);
      background: linear-gradient(135deg, #5f16f4 0%, #c012fe 100%);
    }
  }
}

.PromotionsScreenContent {
  .BonusWidget {
    margin: 1rem 0;
  }
}

.bonus-available-more {
  text-align: left;
  background: white;
  border-radius: .5rem;
  padding: 1rem;
  display: grid;
  align-content: center;
  grid-template-columns: 2rem 1fr 2rem;
  grid-gap: .5rem;
  margin: 1rem 0;

  >div {
    align-self: center;
    font-size: .875rem;

    span,
    >div {
      background: #ff337f;
      color: white;
      padding: .25rem .5rem;
      border-radius: .5rem;
      text-align: center;

      svg {
        padding: .25rem;
        fill: white;
        max-width: 1.5rem;
      }
    }
  }

  &.grayscale {
    filter: grayscale(1);
  }
}

.DepositOnlineScreen {
  .BonusWidget {
    &.loading {
      .details {
        padding-right: 0;
      }
    }
  }
}