.bets-list {
  margin-bottom: 10px;
  border-right: 1px solid var(--ns-content-element-border);
  border-left: 1px solid var(--ns-content-element-border);
  border-top: 1px solid var(--ns-content-element-border);

  > .bets-header {
    padding: 5px 10px;
    color: var(--ns-bets-header-color);
    background-color: var(--ns-bets-header-bg-color);
    background-image: var(--ns-bets-header-bg-image);
    text-align: left;
    font-size: 0.8125em;
    font-weight: 700;
    border-bottom: 1px solid var(--ns-content-element-border);
    cursor: pointer;
    text-transform: uppercase;
  }
}

.odds-container {
  padding: 5px;
  padding-bottom: 0;
  background: var(--ns-bets-odds-bg-color);
  border-bottom: 1px solid var(--ns-content-element-border);

  .outcome {
    width: 50%;
    padding-bottom: 5px;

    .type {
      width: 100%;
      padding-right: 40px;
      float: left;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      font-size: 13px;
      font-weight: 600;
    }

    .value {
      position: absolute;
      right: 10px;
      color: var(--ns-outcome-value-color);
      font-weight: 700;
      text-align: right;
      font-size: 13px;

      svg {
        height: 15px;
        vertical-align: middle;
      }
    }

    @media screen and (max-width: 640px) {
      .value {
        right: 5px;
      }
    }
  }

  .button-wrapper {
    float: left;
    padding-left: 5px;

    .button {
      position: relative;
      overflow: hidden;
      display: inline-block;
      margin: 0;
      padding: 6px 8px 0;
      border: 1px solid var(--ns-outcome-button-border-color);
      box-shadow: 0 1px 2px 0 var(--ns-outcome-button-shadow-color1),
        0 1px 0 0 var(--ns-outcome-button-shadow-color2) inset;
      color: var(--ns-outcome-button-color);
      font-weight: 400;
      font-size: 0.75em;
      text-align: center;
      vertical-align: middle;
      touch-action: manipulation;
      cursor: pointer;
      white-space: nowrap;
      -webkit-touch-callout: none;
      user-select: none;
      width: 100%;
      min-height: 32px;
      background: var(--ns-outcome-button-bg);
      transition: background 0.5s;
    }

    .button.change-up:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 8px 0;
      border-color: transparent var(--ns-outcome-button-up-color) transparent transparent;
      animation: cssAnimationHide 0s ease-in 3s forwards;
    }

    .button.change-down:after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 8px 8px;
      border-color: transparent transparent var(--ns-outcome-button-down-color) transparent;
      animation: cssAnimationHide 0s ease-in 3s forwards;
    }

    @keyframes cssAnimationHide {
      to {
        opacity: 0;
      }
    }
  }

  .button-wrapper:first-child {
    padding-left: 0;
  }

  .button-wrapper:not(.active):hover {
    .button {
      background: var(--ns-bets-list-button-bg);
      color: var(--ns-bets-list-button-color);
    }
  }

  .button-wrapper.active {
    .button {
      background-color: var(--ns-outcome-button-active-bg-color);
      background-image: var(--ns-outcome-button-active-bg-image);
      box-shadow: 0 1px 2px 0 var(--ns-outcome-button-active-shadow);
      border: 1px solid var(--ns-outcome-button-active-border-color);
      color: var(--outcome-button-active-color);
    }
  }

  .button-wrapper[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
}

.odds-container.bets-three > .outcome {
  width: 33.3%;
}

.odds-container > .outcome:nth-child(2n + 1) {
  padding-left: 0;
}

.odds-container.bets-three > .outcome:nth-child(2n + 1) {
  padding-left: 5px;
}

.odds-container.bets-three > .outcome:nth-child(3n + 1) {
  padding-left: 0;
}

.table-row.mobile {
  .odds-container {
    background: transparent;
  }
}
