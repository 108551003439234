@import "./../../../variables";

.CheckoutPage {
  opacity: 0;
  -webkit-transition: all .5s ease-in-out .5s ;
  -moz-transition: all .5s ease-in-out .5s ;
  -ms-transition: all .5s ease-in-out .5s ;
  -o-transition: all .5s ease-in-out .5s ;
  transition: all .5s ease-in-out .5s ;
  &.show {
    opacity: 1;
  }
}
.Content {

  &.Success {
    .underlineText{
      text-decoration: underline;
      font-weight: inherit;
    }
    svg {
      fill: $thirdColor;
    }
    h6 {
      color: $thirdColor;
    }
  }
  &.Failed {
    svg {
      fill: $warningColor;
      .cls-2 {
        fill: white;
      }
    }

    h6 {
      color: $warningColor;
    }
  }
  svg {
    max-width: 150px;
    margin: 50px 0 25px;
  }
  h6 {
    font-size: 2rem;
  }
  a {
    color: $grayColor;
    text-decoration: underline;
  }
  .btn {
    margin-top: 25px;
    min-width: 200px;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
  }
}
