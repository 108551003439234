#register {
  .winner-fun {
    #reward {
      #reward-box {
        height: 17rem;
      }
    }
    .wincoins {
      transform: scale(1.4);
      .value {
        display: block;
        margin-top: -4.5rem;
        width: 100%;
        text-align: center;
        .winCoins {
          margin-bottom: .5rem;
          margin-right: .5rem;
        }
        .no {
          display: inline-block;
          font-size: 2rem;
          color: white;
          font-weight: bold;
        }
      }
      .description {
        font-size: .7rem;
        color: white;
      }
      .btn {
        background: rgb(254,28,107);
        background: -moz-linear-gradient(90deg, rgba(254,28,107,1) 0%, rgba(248,150,29,1) 100%);
        background: -webkit-linear-gradient(90deg, rgba(254,28,107,1) 0%, rgba(248,150,29,1) 100%);
        background: linear-gradient(90deg, rgba(254,28,107,1) 0%, rgba(248,150,29,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fe1c6b",endColorstr="#f8961d",GradientType=1);
        color: white;
        text-transform: uppercase;
        font-weight: normal;
        padding: 1rem;
        margin-top: 1rem;
        border-radius: .25rem;
        transform: scale(.8);
      }
    }
  }
}