.component-happy_hours {
  margin: 10px auto;
  padding-bottom: 5px;
  max-width: 1528px;

  .cgl-super-header {
    margin: 0 10px;

    .category {
      color: #353540;
      font-size: 10px;
      font-weight: bold;
      text-align: left;
    }
  }

  .cgl-header {
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      color: #353540;
      font-size: 18px;
      font-weight: 600;
      text-align: left;
    }

    .link {
      color: #353540;
      font-size: 12px;
      font-weight: 600;
      text-align: left;
      cursor: pointer;
      border: 1px solid #BEC9D6;
      border-radius: 11px;
      padding: 2px 12px 3px;

      span {
        padding: 0 5px;
      }

      svg {
        position: relative;
        top: -1px;
      }
    }
  }

  .carousel-grid-wrapper {
    word-break: normal;
    position: relative;
    margin: 5px -10px 0;

    .grid {
      display: grid;
      grid-column: 2 / -2;
      grid-gap: calc(var(--home-grid-gutter) / 2);
      grid-template-columns: 10px;
      grid-template-rows: minmax(100px, 1fr);
      grid-auto-flow: column;
      grid-auto-columns: calc(33% - var(--home-grid-gutter) * 2);

      overflow-x: auto;
      scroll-snap-type: x mandatory;
      padding-bottom: 10px;
    }

    .grid.one {
      display: flex;
      align-items: center;
      justify-content: center;

      .grid-item-happy-hour {
        width: 340px;
      }

      @media screen and (min-width: 1024px) {
        .grid-item-happy-hour {
          width: 100%;

          .hh-front-desktop {
            max-width: 100%;
          }
        }
      }
    }

    .grid-happy-hours {
      grid-auto-columns: 320px;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .grid-item-happy-hour {
        scroll-snap-align: center;

        &>div {
          width: 100%;
        }
      }

      &.one {
        grid-auto-columns: 340px;
      }
    }

    .grid:before,
    .grid:after {
      content: '';
      width: 10px;
    }
  }
}