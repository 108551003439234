.registerV2 {
  .email-placeholder {
    padding: 0 1rem 1rem 1rem;
    color: #A7B2BF;
  }
  .step-3 {
    .loading-screen {
      position: fixed;
      top: 0;
      left: 0;
      background: white;
      width: 100vw;
      height: 100vh;
      .gamesLoadingProgress {
        margin-top: 50vh;
        margin-left: -2.5rem;
        left: 50%;
      }
    }
    .passwordField {
      .messages {
        display: block;
        margin-top: 1rem;
        margin-bottom: 1rem;

        .ColumnMessage {

          &:empty {
            display: none;
          }
          .error {
          }
        }
      }
    }
    .ColumnMessage {
      .password-strength {
        margin-top: -1.5rem;
      }
    }
  }
}