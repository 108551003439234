.lobby-special-component-favorites {
  margin: 10px auto;
  padding: 0 10px 10px;
  max-width: 1528px;

  .cgl-list {
    overflow-y: hidden;
    overflow-x: auto;

    .cgl-grid {
      width: 100%;
      display: grid;
      white-space: nowrap;
      pointer-events: all;
      padding-top: 5px;
      padding-bottom: 5px;

      grid-column-gap: 5px;
      grid-row-gap: 5px;
      grid-template-columns: 1fr 1fr 1fr;
      overflow: hidden;

      .cgl-item {
        min-width: 69px;
        width: 100%;
        cursor: pointer;
        overflow: visible;

        &.empty {
          max-width: 70px;
        }

        .outer {
          width: 100%;
          padding-top: 145.7%;
          position: relative;
          overflow: visible;

          .inner {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: visible;

            & * {
              width: 100%;
              height: 100%;
            }

            img {
              object-fit: cover;
            }
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          transition: transform .25s ease;
        }

        img:hover {
          position: relative;
          transform-origin: center;
          transform: translateZ(0) scale(1.05);
          z-index: 1;
        }

        &:last-child {
          margin-right: 0px;
        }
      }

      .cgl-item.promote-1x2 {
        grid-column: span 2/auto;

        .outer {
          padding-top: 70.9%;
        }
      }

      .cgl-item.promote-1x3 {
        grid-column: span 3/auto;

        .outer {
          padding-top: 46.9%;
        }
      }

      .cgl-item.promote-2x2 {
        grid-column: span 2/auto;
        grid-row: span 2/auto;
      }

      .cgl-item.promote-2x3 {
        grid-column: span 3/auto;
        grid-row: span 2/auto;

        .outer {
          padding-top: 94.5%;
        }
      }

      .cgl-item.promote-3x3 {
        grid-column: span 3/auto;
        grid-row: span 3/auto;
      }

      @media screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      @media screen and (min-width: 992px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }

      @media screen and (min-width: 1366px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      }
    }

    .cgl-grid.larger {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-column-gap: 2px;

      .cgl-item {
        max-width: 190px;
      }

      @media screen and (min-width: 992px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-column-gap: 5px;

        .cgl-item {
          max-width: 214px;
        }
      }
    }

  }

  .lscf-header {
    font-size: 18px;
    color: #353540;
    font-weight: 600;
    text-align: left;
    white-space: normal;
    margin: 20px 0 10px;
  }
}