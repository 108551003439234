.scanner-wrapper {
    position: relative;

    margin-bottom: 10px;

    .scanner-error {
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: #ff196e;
    }

    .hud {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &>div {
            width: 100%;
        }

        .top {
            height: 64px;
            min-height: 64px;
            background: rgba(0, 0, 0, .4);

            .barcode {
                background: rgba(0, 0, 0, .1);
                font-size: 12px;
                font-weight: bold;
                text-align: center;
                color: #fff;
                padding: 4px;
                margin: 20px 12px 0 12px;
                //padding-top: 32px;
            }
        }

        .middle {
            flex: 1 1 auto;
            position: relative;
            display: flex;
            justify-content: space-between;
            height: 100%;

            .left {
                padding-left: 10px;
                display: flex;
                height: 100%;
                align-items: center;

                div {
                    height: 50%;
                    border: 3px solid #fff;
                    border-right: 0;
                    width: 18px;
                }
            }

            .right {
                padding-right: 10px;
                display: flex;
                height: 100%;
                align-items: center;

                div {
                    height: 50%;
                    border: 3px solid #fff;
                    border-left: 0;
                    width: 18px;
                }
            }
        }

        .bottom {
            height: 64px;
            min-height: 64px;
            background: rgba(0, 0, 0, .4);
        }
    }
}

.overlay__content {
    position: relative;
}

.overlay__content video {
    max-width: 100%;
}

.overlay__content canvas {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
}