@import "./../../../../variables";

@keyframes button-loading-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(-360deg); }
}

.PhoneChangeModal {
    .modal-content {
        border-radius: 0;
        border-color: rgba(0, 0, 0, 0.0);

        .PhoneChangeModalBody {
            height: 500px;
            overflow: hidden;
            color: $black;

            .step-support {
                max-width: 360px;

                .step-header {
                    text-align: center;
                    padding-top: 20px;

                    h2 {
                        font-weight: normal;
                        font-size: 26px;

                    }
                }

                .step-content {
                    height: 200px;
                }

                .step-message {
                    height: 80px;
                }

                .step-footer {
                    height: 140px;
                    overflow: hidden;

                    .cancelButton {
                        border-color: #d05c61;
                        color: #941b20;
                        margin-top: 16px;
                        text-transform: capitalize;
                        font-weight: bold;

                        &:hover {
                            color: $white;
                        }
                    }

                    .requestPhoneChange {
                        color: $buttonGreen;
                        font-weight: bold;
                        background-color: $buttonYellow;

                        &:hover {
                            background-color: lighten($buttonYellow, 5%);
                        }

                        .text {
                            padding-left: 10px;
                        }

                        .icon {
                            width: 32px;
                            display: inline-block;

                            &.animate {
                                animation: button-loading-spin infinite 1s linear;
                            }

                            .IconSVG {
                                g {
                                    g {
                                        fill: $buttonGreen;
                                    }
                                }
                            }
                        }
                    }

                    .backButton {
                        margin-top: 16px;
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 16px;

                        &:hover {
                            color: $white;
                        }
                    }
                }
            }
        }

        .PhoneChangeModalHeader {
            color: $black;
            text-align: center;
            text-transform: uppercase;
            border-bottom: 0;

            h5 {
                width: 100%;
                font-size: 14px;
                font-weight: bold;
            }

            button.close {
                position: absolute;
                top: -5px;
                right: 0;
                font-size: 60px;
                font-weight: normal;
                opacity: 1;
                color: $thirdColor;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}