.ContentPage {
    opacity: 0;
    top: 40px;
    position: relative;
    z-index: 10;
}

.ContentPage.noTop {
    top: 0 !important;
}

.ContentPage h1
{
    text-align: left;
    color: #017048;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 20px;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 0px;
    padding-bottom: 15px;
}

.ContentPage h1 .icon
{
    padding-right: 10px;
    height: 40px;
    line-height: 40px;
    display: block;
    width: 40px;
    margin-right: 0px;
    float: left;
}

.ContentPage h1 .text
{
    padding-top: 12px;
    float: left;
}

.ContentPage p
{
    padding-left: 20px;
    padding-right: 20px;
    text-align: left;
}

.loadMoreGames {
    padding-top: 30px;
    padding-bottom: 20px;
}

.loadMoreGames .btn-warning {
    background-color: #fccd00;
    color: #000000;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
}