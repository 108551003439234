@import "../../../../../variables";

#PlayerProfileIcon {
  height: 42px;
  width: 42px;
  circle {
    fill: black;
  }
  .cls-3{
    fill:#fff !important;
    stroke: none !important;
    color: #213a5b;
  }
  .cls-1{
    stroke: #213a5b !important;
    stroke-miterlimit:10;
    stroke-width:0.26px;
    color: #213a5b;

  }
  .cls-2{
    fill: #213a5b !important;
    stroke: #213a5b !important;
    color: #213a5b;
  }
}

@media screen and (max-width: 1315px) {
  #PlayerProfileIcon {
    height: 42px;
    width: 42px;
  }
}
