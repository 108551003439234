@import "./../../../variables";

.left-trigger-menu-support {
  max-width: 77px;
  height: 78px;
  background: transparent;
  position: fixed;
  //position: absolute;
  z-index: 1021;
  left: 0;
  top: 0;
  //transition: all .3s ease-in;
  //&.opened {
  //  left: 409px;
  //}
  //&.velocity-animating {
  //  transition: none;
  //}

  .left-menu-trigger {
    border-color: transparent;
    background-color: transparent;
    cursor: pointer;
    display: block;
    height: 40px;
    margin: 0px;
    margin-top: 20px;

    .leftMenuTrigger {
      width: 32px;
      height: 32px;
      display: block;
      padding: 5px 17px;
      box-sizing: content-box;

      .cls-1 {
        fill:#e6e6e6;
        //stroke:#333;
        stroke-miterlimit:10;
      }
    }

    &:focus,
    &:active {
      outline: none !important;
      box-shadow: none !important;
    }
  }
}
