.winter-time-rules {
  z-index: 1304 !important;

  & .MuiPaper-rounded {
    border-radius: 13px;
  }

  & .MuiDialog-paperFullWidth {
    width: calc(100% - 20px);
    margin: 10px;
  }

  .dialogContent {
    font-size: 12px;
    color: #17214D;
    font-weight: bold;
    text-align: center;
    padding: 15px !important;

    .dialogOK {
      width: 100%;
      padding: 13px 0;
      border-radius: 3px;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      margin: 0 0 0;
      background: linear-gradient(90deg, #295A91 0%, #2174E0 100%);
      height: 45px;
      margin-left: 5px;
      text-transform: uppercase;
    }
  }
}