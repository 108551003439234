.bets-special-prize {
  height: 122px;
  border-radius: 7px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: #08121E;

  * {
    box-sizing: border-box;
  }

  .hh-image {
    position: absolute;
    top: 0;
    bottom: 18px;
    left: 0;
    right: 0;
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;

    &>img {
      height: auto;
      width: 346px;
      display: block;
    }
  }

  .hh-title {
    width: 60px;
    overflow: visible;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: left;
    line-height: 14px;
    padding-bottom: 18px;
    margin-left: 35px;
    position: relative;
  }

  @media screen and (min-width: 1200px) {
    .hh-title {
      width: 100px;
    }
  }

  .hh-footer {
    height: 18px;
    background: linear-gradient(to right, #1b375e 0%, #1a4075 38.5%, #152843 72.2%, #03152e 100%);

    text-align: center;
    line-height: 18px;
    color: #F2C44E;
    font-size: 11px;
    font-weight: 600;

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .hh-help {
    width: 21px;
    height: 21px;

    border: 2px solid #08121E;
    background: #3B6093;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 15px;
    border-radius: 50%;
    cursor: pointer;

    position: absolute;
    left: 10px;
    bottom: 6px;
  }
}