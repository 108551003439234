$width: 1rem;
$padding: 1.3rem;
.registerWizard .checkbox-container {
  padding: $padding $padding 0 $padding*2;
  .input-centered {
    .input-group {
      margin-bottom: 0;
      input {
        float: left;
        width: $width;
        margin-left: -$padding;
        opacity: 0.01;
        height: 25px;
        pointer-events: none;
        &:valid,
        &:active,
        &:focus {
          width: $width;
        }
      }
      &:after {
        -webkit-transition: all 0.2s ease-in ;
        -moz-transition: all 0.2s ease-in ;
        -ms-transition: all 0.2s ease-in ;
        -o-transition: all 0.2s ease-in ;
        transition: all 0.2s ease-in ;
        content: ' ';
        width: $width;
        height: $width;
        border: 2px solid;
        position: absolute;
        left: -$padding;
        top: 4px;
        pointer-events: none;
      }
      &:hover {
        &:after {
          background-color: #68a18873;
        }
      }
      &.checked {
        &:after {
          border-color: #007048;
          height: $width*1.3;
          width: $width*0.6;
          top: 0;
          left: -$padding*0.9;
          border-width: 3px;
          border-bottom: none;
          border-right: none;
          -webkit-transform: rotate(-145deg);
          -moz-transform: rotate(-145deg);
          -ms-transform: rotate(-145deg);
          -o-transform: rotate(-145deg);
          transform: rotate(-145deg);
        }
        &:hover {
          &:after {
            background-color: transparent;
          }
        }
      }
      .input-label {
        position: relative;
        float: left;
        padding: 0 0 0 .5rem;
        font-size: 0.875rem;
        text-align: left;
        width: 100%;
        font-weight: 500;
        color: rgb(102,102,102);
        a {
          color: rgb(1,112,72);
          font-weight: 600;
        }
      }
      &.error {
        &:after {

            border-color: #ff1d27;
        }
      }
    }
  }
  .messages {
    height: auto;
    padding-top: 0rem;
    margin-top: -0.2rem;
    font-size: 0.75rem;
    padding-left: 0.1rem;
  }
}
