@import "../../../variables.scss";

#LanguageSwitcher {
  list-style-type: none;
  padding: 0 0 1rem 0;

  li {
    width: 2.5rem;
    border-radius: .3rem;
    overflow: hidden;
    height: 1.6rem;
    transition: all 0.3s ease-in;
    margin: 0 .75rem 0 .75rem;

    &.active {
      margin-top: .75rem;
      height: 1.6rem;
      pointer-events: none;
      cursor: pointer;
    }

    &:not(.active) {
      height: 0;
    }
  }

  &:hover {
    li {
      margin-top: .5rem;
      height: 1.6rem;
      cursor: pointer;
    }
  }

  &.FooterLanguage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;

    &.left {
      justify-content: left;
      padding-top: 1rem;

      li {
        &:first-child {
          padding-left: 0;
          margin-left: 0;
        }

        &:not(.active) {
          filter: grayscale(.5);
        }
      }
    }

    li {
      width: 50px;
      height: 30px;
      margin: 0px 0px 0px 15px;

      &.active {
        margin-top: 0;
        width: 50px;
        height: 30px;
      }

      &:not(.active) {
        width: 0;
        height: 30px;
      }

      svg {
        width: 50px;
      }
    }

    &:hover {
      li {
        width: 50px;
        height: 30px;
        margin-top: 0;
      }
    }
  }

}