.wf-teams {
  padding: 20px 14px 0;

  background-color: #06084b;
  background-image: url("../assets/bg-winner-fun2@3x.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }


  .wf-wrapper {
    box-shadow: -6px -6px 15px 0 #3B3CBC, 6px 6px 20px 0 rgba(1, 0, 17, .8);
    border-radius: 12px;
    padding: 18px 3px 3px;

    background-image: url("../assets/img-bg-winners-daily.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 100%;

    .wf-header {
      .wf-title {
        font-size: 20px;
        color: #fff;
        text-align: center;
        font-weight: bold;
      }

      .wf-logo {
        text-align: center;

        img {
          display: inline-block;
          width: 274px;
          height: auto;
        }
      }

      .wf-subtitle {
        font-size: 12px;
        color: #fff;
        text-align: center;
        font-weight: 600;
        padding: 15px 18px;
        border-radius: 12px;
        background: rgba(0, 0, 0, .3);
        margin: 0 5px;
      }
    }

    .wf-teams-select {
      margin-top: 5px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 1fr;
      grid-column-gap: 6px;
      grid-row-gap: 6px;

      .wf-team-select {
        padding: 3px;
        cursor: pointer;
        height: 100%;


        &>div {
          border-radius: 12px;
          background-color: #fff;
          height: 100%;
        }

        .wf-ts-logo {
          padding: 6px 3px 2px;
          text-align: center;
          border-radius: 12px 12px 0 0;

          img {
            width: 62px;
            height: 62px;
            display: inline-block;
            transform: translateZ(0);
          }
        }

        .wf-ts-name {
          color: #000;
          background-color: #E8E8E8;
          text-align: center;
          font-size: 11px;
          font-weight: 600;
          text-align: center;
          padding: 2px;
          border-radius: 0 0 12px 12px;
          height: calc(100% - 75px);
        }
      }
    }
  }

  .wf-header-title {
    font-size: 13px;
    font-weight: bold;
    text-align: left;
    color: #fff;
    margin-bottom: 7px;
  }

  .wf-tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;

    .wf-tab {
      font-size: 10px;
      font-weight: bold;
      opacity: .5;
      text-align: left;
      color: #fff;
      margin-right: 20px;
      cursor: pointer;
    }

    .wf-tab.wf-active {
      opacity: 1;
    }
  }

  .wf-wrapper-list {
    .wf-regulations {
      border-radius: 7px;
      background: #fff;
      color: #000;
      padding: 12px;
    }
  }

  .wf-ranking {
    border-radius: 7px;
    background: #fff;

    .wf-rows {
      border-radius: 0 0 7px 7px;
    }

    .wf-row {
      display: flex;
      align-items: center;
      justify-content: stretch;
      cursor: pointer;

      padding: 0 10px;

      width: 100%;
      height: 48px;
      border-bottom: 1px solid rgba(183, 205, 216, .35);

      &.wf-row-0 {
        background: #EAF4FB;
      }

      &.wf-row-1 {
        background: #F0F7FC;
      }

      &.wf-row-2 {
        background: #FAFCFE;
      }

      &>div {
        flex: 1 1 auto;
      }

      .wf-header {
        color: #979DAB !important;
        font-size: 9px !important;
        font-weight: bold !important;
        padding: 0 2px;
      }

      .wf-col.wf-left {
        text-align: left;
      }

      .wf-col.wf-right {
        text-align: right;
      }

      .wf-col.wf-center {
        text-align: center;
      }

      .wf-col1 {
        width: 25px;
        min-width: 25px;
        max-width: 25px;

        &>div {
          font-size: 10px;
          font-weight: 600;
          color: #586682;
          text-align: center;
          border-radius: 50%;
          margin: 0 auto;
        }

        .wf-gold {
          background: linear-gradient(to right, #FF971D, #FFB400);
          border: 1px solid #FCC735;
          width: 17px;
          height: 17px;
          color: #FFF;
          line-height: 14px;
        }

        .wf-silver {
          background: linear-gradient(to right, #B5C9DC, #9AC7F2);
          border: 1px solid #81A0BE;
          width: 17px;
          height: 17px;
          color: #FFF;
          line-height: 14px;
        }

        .wf-bronze {
          background: linear-gradient(to right, #EB7120, #C65F05);
          border: 1px solid #F6A169;
          width: 17px;
          height: 17px;
          color: #FFF;
          line-height: 14px;
        }

        .wf-regular {
          border: 1px solid rgba(255, 255, 255, 0);
          height: 16px;
        }
      }

      .wf-col2 {
        width: 32px;
        min-width: 32px;
        max-width: 32px;
        padding-left: 5px;

        img {
          display: block;
          width: 27px;
          height: 27px;
          transform: translateZ(0);
        }
      }

      .wf-col3 {
        padding: 0 10px;
        color: #17214D;
        font-size: 12px;
        font-weight: 600;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        position: relative;
        height: 46px;
        line-height: 46px;

        .wf-stars {
          position: absolute;
          display: flex;
          bottom: 0;
          left: 8px;
          transform: scale(.7);
          transform-origin: left center;
        }
      }

      .wf-col4 {
        width: 70px;
        min-width: 70px;
        max-width: 70px;
        color: #17214D;
        font-size: 10px;
        font-weight: bold;
      }

      .wf-col5 {
        width: 32px;
        min-width: 32px;
        max-width: 32px;
        color: #74797D;
      }

      &.wf-col-full {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 10px;
        color: #17214D;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .wf-row.wf-header {
      height: 29px;
    }

    .wf-row:last-child {
      border-radius: 0 0 7px 7px;
    }

    .wf-row.wf-active {
      background: linear-gradient(to right, #FF7700, #FFC400);
      padding: 0 5px 0 10px;
      border-bottom: 1px solid transparent;

      position: sticky;
      bottom: 0;
      top: 0;

      .wf-col1>div {
        color: #fff;
      }

      .wf-col2 {
        width: 35px;
        min-width: 35px;
        max-width: 35px;

        img {
          display: block;
          width: 35px;
          height: 35px;
        }
      }

      .wf-col3 {
        font-size: 14px;
        color: #fff;

        .MuiRating-root {
          color: #fff;
        }
      }

      .wf-col4 {
        width: auto;
        min-width: unset;
        max-width: unset;
        border-radius: 11px;
        border: 1px solid #000000;
        background: #fff;
        color: #17214D;
        font-size: 12px;
        text-align: center;
        height: 22px;
        line-height: 22px;
      }
    }
  }

  .wf-sub-header {
    margin-bottom: 8px;
    border-radius: 7px;
    background-color: #fff;
    padding: 8px 14px 8px 14px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &.pl-10 {
      padding-left: 10px;
    }

    .wf-sh-back {
      color: #0460FF;
      border-right: 1px solid #E5E5E5;
      padding-right: 10px;
      margin-right: 10px;
      min-height: 31px;
      display: flex;
      align-items: center;
    }

    .wf-sh-logo {
      margin-right: 6px;

      img {
        display: block;
        width: 31px;
        height: 31px;
        transform: translateZ(0);
      }
    }

    .wf-text {
      flex: 1 1 100%;

      .wf-title {
        font-size: 15px;
        font-weight: bold;
        color: #000;
        text-align: left;

        .wf-sc {
          font-size: 11px;
          font-weight: 600;
          color: #5A5A5A;

        }
      }

      .wf-subtitle {
        font-size: 10px;
        font-weight: normal;
        color: #808289;
        text-align: left;
        font-style: italic;
      }
    }

    .wf-q {
      width: 16px;
      min-width: 16px;
      max-width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: italic;
      font-size: 12px;
      line-height: 12px;
      font-weight: 600;
      color: #6EA9FD;
      background-color: #EAF1FB;
      border-radius: 50%;
      padding-right: 2px;
    }
  }

  .wf-info {
    border-radius: 7px;
    padding: 10px;
    background-color: #fff;

    .wf-info-header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;

      .wf-logo {
        margin-right: 10px;

        img {
          display: block;
          width: 78px;
          height: 78px;
        }
      }

      .wf-info-text {
        flex: 1 1 100%;
        padding-right: 24px;


        .wf-name {
          color: #000;
          font-size: 15px;
          font-weight: bold;
          text-align: left;
          margin-bottom: 12px;
        }

        .wf-stars {
          color: #C9C9C9;
          font-size: 11px;
          font-weight: bold;
          text-align: left;

          .MuiRating-root {
            position: relative;
            top: 3px;
          }
        }

        .wf-subtext {
          color: #808289;
          font-size: 10px;
          font-weight: normal;
          font-style: italic;
          text-align: left;
        }
      }

      .wf-info-close {
        position: absolute;
        color: #000;
        cursor: pointer;
        right: 0;
        top: 0;
      }

    }

    .wf-info-body {
      font-size: 12px;
      text-align: left;
      margin-bottom: 15px;
      color: #000;

      h2 {
        color: #6B6B6B;
        font-size: 15px;
        font-weight: bold;
        text-align: left;
      }
    }

    .wf-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 7px 15px 17px;
      background: #E5E8EA;
      border-radius: 7px;
      margin-bottom: 10px;

      .wf-txt {
        flex: 1 1 100%;
        color: #808289;
        font-size: 10px;
        font-weight: normal;
        font-style: italic;
        text-align: left;
      }

      .wf-btn {
        background: radial-gradient(#4053FF, #2A18C9);
        color: #fff;
        border-radius: 7px;
        cursor: pointer;
        font-size: 11px;
        font-weight: 600;
        text-align: left;
        padding: 6px 20px;
      }
    }

    .wf-footer-leave {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: 15px 17px;
      background: #E5E8EA;
      border-radius: 7px;

      &.wf-disabled {
        opacity: .4;
        pointer-events: none;
      }

      .wf-txt {
        flex: 1 1 100%;
        color: #808289;
        font-size: 10px;
        font-weight: normal;
        font-style: italic;
        text-align: center;
      }

      .wf-btn {
        background: #FF0000;
        color: #fff;
        border-radius: 7px;
        cursor: pointer;
        font-size: 11px;
        font-weight: 600;
        text-align: left;
        padding: 6px 20px;
        margin-top: 7px;
        white-space: nowrap;

        &.wf-disabled {
          pointer-events: none;
          opacity: .7;
        }

        .wf-loader {
          display: inline-block;
          margin-left: 10px;
          position: relative;
          top: 2px;

          &>div {
            display: inline-block;
            width: 12px !important;
            height: 12px !important;
            color: #fff
          }
        }
      }
    }
  }

  .wf-spacer {
    height: 130px;
  }
}