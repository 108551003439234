.info-message-druid {
  color: #009245;
  background: #E3FFF0;
  border-radius: .25rem;
  font-size: .85rem;
  padding: .85rem;
  width: calc(100% - 1rem);
  box-sizing: border-box;
  margin-bottom: 1rem;
}
.druid-scanner-wrapper {
  width: calc(100% - 2rem);
  &.success {
    .druid-scanner {
      border-color: #DCFFED;
    }
  }
  &.error {
    .druid-scanner {
      border-color: #FFE5E7;
    }
  }
  .druid-scanner {
    border:  3px solid #F1F3FE;
    border-radius: 9px;
    position: relative;
    padding: .75rem .25rem .25rem ;
    .labels {
      position: absolute;
      width: 100%;
      top: -.75rem;
      font-size: .75rem;
      left: -0.5rem;
      &>div {
        display: inline-block;
        margin-right: .5rem;
        padding: .2rem .7rem;
        border-radius: .35rem;
        box-shadow: 0 3px 5px #0000003D;

        &.danger {
          background: #C11823;
          color: white;
        }

        &.warning {
          background: #EB6E00;
          color: white;
        }

        &.safety {
          border-radius: 1rem;
          background: #009245;
          color: white;
          float: right;
          margin: 0 -.5rem;
          padding-left: 2rem;
          position: relative;

          .circle {
            position: absolute;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            background: white;
            left: -.25rem;
            top: -0.1rem;
            color: #009245;
            border: 2px solid;

            svg {
              width: 1rem;
              height: 1rem;
              position: absolute;
              top: 0.25rem;
              left: 0.25rem;
            }
          }
        }
      }
    }
    .content {
      display: grid;
      grid-template-columns: 1fr 80px;

      .text {
        color: #6B7892;
        padding: 1.75rem 1rem .5rem;
        .title {
          text-decoration: underline;
          font-size: .9rem;
          text-align: left;
        }
        .description {
          font-size: .9rem;
          font-weight: normal;
        }
      }
      .upload-image-wrapper {
        height: 72px;
        padding: 2px 4px 4px 4px;
        .upload-image {
          background: linear-gradient(54deg,#6B7892,#3D4963);
          border-radius: 6px;
          width: 72px;
          height: 72px;
          display: flex;
          align-items: center;
        //  margin: .25rem;
        //  height: 72px;
        //  width: 72px;
          border: 1px solid #7888A8;
          img {
            margin: 0 auto;
          }
          div {
            margin: 0 auto;
            color: white;
          }
        }
      }
    }
  }
  .error {
    font-size: .75rem;
    color: #D80000;
  }
  .success {
    font-size: .75rem;
    color: #009245;
  }
}
.druid-modal {
  .MuiPaper-rounded {
    border-radius: 1rem;
  }

  .dialog-content {
    padding: 4rem 2rem !important;
    text-align: center;
    color: #285C98;
    .IconSVG {
      margin: 0 auto;
      width: 5rem;
      height: 5rem;
    }
    .dialog-title {
      font-size: 1.25rem;
      font-weight: 600 !important;
      margin-top: -1.4rem;
      letter-spacing: -.3px;
    }
    .druid-modal-message {
      padding: 3.5rem 0 7rem 0;
      font-size: .85rem;
      color: black;
      .ProgressBar {
        height: 3px !important;
        margin: 1.15rem auto 0 auto;
        width: 60%;
        > div {
          height: 3px !important;
        }
      }
    }

    .error {
      font-size: .75rem;
      color: #D80000;
      & + div .MuiLinearProgress-bar{
        background: #D80000;
      }
    }
    .success {
      font-size: .75rem;
      color: #009245;
      & + div .MuiLinearProgress-bar{
        background: #009245;
      }
    }
  }

}