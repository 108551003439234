.PlayerPromoTickets {
  margin: 2rem 0;
  .title {
    margin-bottom: .5rem;
  }
  .ticketsNumber {

    div {


      text-align: center;
      &.count {
        font-size: 2rem;
        color: white;
        line-height: 2.7;
        margin-top: -7.7rem;
        margin-bottom: 0.4rem;
      }
      &.desc {
        font-size: 1.2rem;
        text-transform: uppercase;
        color: rgba(0,0,0,.58);
      }
    }
  }
}