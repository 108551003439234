@import "./../../variables";

$colorType: #cce3f2;
$greenColor: #07ba8a;
$grayColor: #b3b3b3;

.cs-page {
  margin: 0;

  @media screen and (max-width: 1600px) {
    .col {
      &.hide-custom {

        &:last-child {
          display: none;
        }
      }
    }
    .expand-custom {
      flex: 0 0 75%;
      max-width: 75%;
    }
  }
  @media screen and (max-width: $hideMenuMaxWidth) {
    .col {
      &.hide-custom {
        display: none;
      }
    }
    .expand-custom {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}



.GameRules {
  .page-top {
    font-weight: 600;
    font-size: 14px;
    padding-bottom: 16px;
    border-bottom: 1px solid $firstColor;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-left: 20px;
  }

  .group-rules {
    padding-bottom: 40px;
    padding-left: 20px;
    //padding-right: 10px;
    .rules-list {
      display: flex;
      flex-wrap: wrap;
      align-content: stretch;

      .game-rule-doc {
        width: 25%;

        a {
          width: 90%;
          color: $thirdColor;
          font-size: 13px;
          font-weight: 500;
          display: block;
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }
    }
  }
}

.PaymentMethods {
  color: $firstColor;
  width: 100%;
  text-align: center;

  .header-limits {
    width: 100%;
    background-color: lighten($colorType,5);
    margin: 0 auto;
    padding-top: 3rem;
    border-radius: 5px;
    .limit-item {
      display: inline-block;
      width: 26%;
      background-color: lighten($colorType,10);
      padding: .5rem 0;
      margin: 0 .25rem .75rem;
      cursor: pointer;
      font-weight: 500;
      color: darken($grayColor,20);
      position: relative;
      text-transform: capitalize;
      &.active {
        color: darken($greenColor,15);
        font-weight: 600;
        position: relative;
        background-color: white;
        &:before {
          content: " ";
          position: absolute;
          bottom: -.75rem;
          left: calc(50% - .75rem);
          width: 0;
          height: 0;
          border-left: .75rem solid transparent;
          border-right: .75rem solid transparent;
          border-top: .75rem solid white;
        }
      }
      &.coming-soon {
        transform: translateY(-.5rem);
        line-height: .5rem;
        padding-bottom: 1.5rem;
        margin-bottom: -1rem;
        .coming-soon {
          font-size: 9px;
          font-weight: normal;
          font-style: italic;
          position: absolute;
          left: 10%;
          bottom: 7px;
          height: 7px;
          line-height: 7px;
          display: block;
          text-align: center;
          width: 80%;

          span {
            line-height: 7px;
            text-transform: uppercase;
            border: 1px solid $grayColor;
            border-radius: 20px;
            padding: 2px 7px;
            color: #565109;
            font-size: 8px;
            font-weight: normal;
            font-style: italic;
          }
        }
      }

    }
  }

  .payment-methods-content {
    padding-top: 40px;

    .methods-table {
      width: 100%;
      border-collapse: collapse;
      border: 0;

      thead {
        border-bottom: 1px solid $firstColor;

        th {
          text-transform: uppercase;
          color: $firstColor;
          padding-left: 8px;
          padding-right: 8px;
          font-size: 12px;
          padding-bottom: 10px;
          font-weight: 600;
        }
      }

      tbody {
        tr{
          border-bottom: 1px solid #ececec;
          &:last-of-type{
            border: none;
          }
        }
        td {
          font-size: 13px;
          padding: 20px 0px;

          img{
            max-height: 70px;
          }

          .IconSVG {
            height: 40px;

            .cls-1
            {
              fill:#0d0d0d;
            }
            .cls-2
            {
              fill:#941b20;
            }
            .cls-3
            {fill:#fccd00;}
            .cls-4
            {fill:#ffef00;}
          }

          &.pmDesc {
            // width: 40%;
            text-align: left;
          }

          &:first-child {
            // padding-top: 34px;
            width: 25%;
          }

          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5){
            width: 10%;
          }

          p {
            padding-top: 0;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.faq {
  h1 {
    font-size: 18px;
    font-weight: 700;
  }

  ol {
    list-style: none;
    counter-reset: li;

    li {
      counter-increment: li;

      &::before {
        content: counter(li) ". ";
        display: inline-block;
        color: $firstColor;
        font-weight: bolder;
        font-size: 20px;
        //direction: rtl;
        width: 2em;
        margin-left: -2em;
        text-align: right;
        position: relative;
        top: 24px;
        padding-right: 5px;
      }

      .taxesTable {
        table {
          border-top: 1px solid $firstColor;
          border-left: 1px solid $firstColor;
          //border-right: 1px solid $firstColor;
          border-collapse: collapse;

          td {
            border-bottom: 1px solid $firstColor;
            border-right: 1px solid $firstColor;
            padding: 5px 10px;
          }
        }
      }
    }
  }
}

.gdpr-privacy-policy {
  ol {
    counter-reset: item;
    padding-left: 1.0rem;

    li {
      display: block;

      &:before {
        content: counters(item, ".") ". ";
        counter-increment: item;
        font-weight: bold;
        font-size: 1.2rem;
      }

      h1 {
        font-size: 1.2rem;
        display: inline-block;
        font-weight: 600;
      }

      p {
        padding-left: 1.2rem;
      }

      ol {
        padding-left: 1.2rem;

        li {
          padding-bottom: 16px;

          &:before {
            font-weight: normal;
          }

          p {
            padding-left: 0;
            &:first-child {
              display: inline;
            }
          }
          table {
            width: 100%;
            text-align: center;
          }
          ol {
            padding-left: 0;
          }
        }
      }
    }
  }
}

.terms-and-conditions {
  .important-message {
    color: red;
    font-size: 0.8rem;
    font-weight: 500;
    padding-bottom: 30px;
  }

  ol {
    counter-reset: item;
    padding-left: 0rem;

    &.title-links {
      li {
        a {
          color: $firstColor;

          &:hover {
            color: lighten($firstColor, 30%);
          }
        }
      }
    }

    li {
      display: block;

      &:before {
        content: counters(item, ".") " ";
        counter-increment: item;
        font-weight: 700;
        font-size: 1rem;
      }

      h1 {
        font-size: 1rem;
        display: inline-block;
        font-weight: 700;
      }

      section {
        padding-bottom: 78px;

        &.no-padding {
          padding-bottom: 0;
        }
      }

      p {
        padding-left: 1.2rem;
      }

      ol {
        counter-reset: item;
        padding-left: 1.2rem;

        li {
          padding-bottom: 16px;

          &:before {
            font-weight: normal;
            content: counters(item, ".") ". ";
          }

          p {
            padding-left: 0;
            padding-top: 10px;
            margin: 0;

            &:first-child {
              display: inline;
            }
          }

          ol {
            padding-left: 0;
          }
        }
      }
    }
  }
}

.reformatType {
  counter-reset: alphaStyle;

  &.reformatIndent {
    padding-left: 21px;
  }

  ol {
    &.type-a {
      counter-reset: alphaStyle;

      li {
        &:before {
          content: counter(alphaStyle, lower-latin) ". ";
          counter-increment: alphaStyle;
        }
      }
    }
  }
}

.reformatLine {
  counter-reset: lineStyle;
  ul {
    counter-reset: lineStyle;
    li {
      counter-increment: none;
      &:before {
        content: "- " !important;
        counter-increment: lineStyle;
      }
    }
  }
}

.reformatAlphaP {
  counter-reset: nAlphaStyle;

  ol {
    &.type-a {
      counter-reset: nAlphaStyle;

      li {
        &:before {
          content: '('counter(nAlphaStyle, lower-latin)') ';
          counter-increment: nAlphaStyle;
        }
      }
    }
  }
}

.reformatBullet {
  counter-reset: lineStyle;
  ul {
    counter-reset: lineStyle;
    li {
      counter-increment: none;
      &:before {
        content: "\2022 " !important;
        counter-increment: lineStyle;
      }

      p {
        padding-left: 0;
        display: inline;
      }
    }
  }
}

.reformatAlphaH {
  counter-reset: hAlphaStyle;

  ol {
    &.type-a {
      counter-reset: hAlphaStyle;

      li {
        &:before {
          content: counter(hAlphaStyle, lower-latin)') ';
          counter-increment: hAlphaStyle;
        }
      }
    }
  }
}
