@import "./../../../variables";

.SpentTimeModal {
    z-index: 99999;

    .modal-content {
        border-radius: 0px;
        border-width: 0px;

        .SpentTimeModalBody {
            .messageSupport {
                p {
                    color: #000;
                    font-weight: 600;
                    font-size: 16px;
                    text-align: center;
                }
            }

            .buttonSupport {
                .verifyButton {
                    margin-right: 20px;
                }
            }
        }
    }
}