@import "./../../variables";

.Locations {
  //opacity: 0;
  //top: 40px;
  //position: relative;
  z-index: 10;
  min-width: $minPageWidth;
  background-color: #ffffff;
  display: flex;
  width: 100%;

  .locationsLeft {
    width: 26%;
    border-right: lighten($firstColor, 90%) 0px solid;
    height: 100%;
    box-shadow: 0px 0px 20px $firstColor;
    z-index: 3;
    min-width: 350px;

    .leftTitle {
      background: $withdrawModalGreen;
      width: 100%;
      color: #ffffff;
      height: 74px;
      line-height: 74px;
      display: flex;

      .titleButton {
        background: transparent;
        border: 0;
        color: #ffffff;
        width: 100%;

        .IconSVG {
          height: 22px;
          position: relative;

          path {
            fill: #ffffff;
          }
        }

        .title-info {
          .cls-1{fill:#fff;}
          .cls-2{fill:#0a3d30;}
        }

        &:hover {
          .IconSVG {
            top: 2px;
          }
        }

        &:active {
          .IconSVG {
            top: 3px;
          }
        }
      }

      .titleBack {
        width: 20%;
        text-align: center;
      }

      .titleName {
        width: 60%;
        text-align: center;
        font-weight: 600;
        text-transform: uppercase;
      }

      .titleInfo {
        width: 20%;
      }
    }

    .SearchContainer{
      position: relative;
      width: 100%;
      box-sizing: border-box;
      border: 5px solid #121921;
      border-top: none;
      border-left: none;

      #SearchLocations {
        width: 100%;
        padding: 10px 15px;
        font-size: 14px;
        font-weight: 500;
      }

      .SearchIcon{
        position: absolute;
        right: 15px;
        max-width: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
        fill: #555;
      }
    }

    .LocationsList {
      height: 100%;
      overflow-y: scroll;
      color: #000;
      width: 100%;
      background-color: $bgListLocations;


      &::-webkit-scrollbar-track{
        background: $bgListLocations;
      }

      &::-webkit-scrollbar{
        width: 6px;
        background: $bgListLocations;
      }

      &::-webkit-scrollbar-thumb{
        background: $withdrawModalGreen;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        height: 100%;

        li {
          border-bottom: 2px solid $withdrawSideLine;

          &:last-child {
            margin-bottom: 0px;
            border-bottom: none;
          }

          .SideLocation {
            padding-top: 10px;
            border-left: 5px solid #ffffff;
            border-right: 7px solid #ffffff;
            animation: normalBorderColor 500ms ease-out;
            background-color: #ffffff;



            .top {
              display: flex;
              cursor: pointer;

              .left {
                width: 80%;

                .details {
                  width: 100%;
                  padding-left: 14px;
                  display: flex;
                  min-height: 64px;

                  h1 {
                    font-size: 13px;
                    margin-bottom: 0;
                  }

                  p {
                    font-size: 11px;
                    color: $withdrawSideGray;
                    padding-right: 8px;
                  }

                  .icon {
                    overflow: hidden;
                    width: 0px;
                    animation: normalPin 200ms ease-in;
                    position: relative;
                    //left: -5px;

                    .IconSVG {
                      height: 30px;
                      fill: $withdrawPin;
                      //margin-right: 8px;
                    }
                  }
                }

                .side-location-eta {
                  padding-left: 14px;
                  padding-top: 6px;
                  display: flex;
                  justify-content: stretch;
                  color: $grayColor;
                  font-weight: normal;

                  .carEta,
                  .walkEta {
                    height: 12px;

                    .cls-1 {
                      fill: $grayColor;
                    }
                  }

                  .walkEta {
                    height: 14px;
                  }

                  span {
                    padding-left: 5px;
                    font-size: 11px;
                  }

                  p {
                    &:first-child {
                      span {
                        padding-right: 20px;
                      }
                    }
                  }
                }
              }

              .right {
                padding-right: 14px;
                img {
                  max-width: 6rem;
                }
              }
            }

            .button {
              height: 0;
              overflow: hidden;
              animation: normalElem 500ms ease-out;
              //cursor: pointer;
              width: 100%;
              padding-left: 12px;
              padding-right: 14px;

              .selectArea {
                display: flex;
                height: 85px;
                align-items: center;
                justify-content: center;
                text-align: center;
                font-weight: normal;
                font-size: 12px;
                text-transform: uppercase;
                width: 100%;
                color: $withdrawSideGray;
                border-top: 1px solid #f8f8f8;
                padding-top: 10px;

                a {
                  text-decoration: none;
                  color: $withdrawSideGray;

                  &:hover {
                    font-weight: 500;
                    color: $firstColor;
                  }
                }

                .txt {
                  padding-top: 10px;
                }

                .dAction {
                  cursor: pointer;
                  &:hover {
                    font-weight: 500;
                    color: $firstColor;

                    a {
                      font-weight: 500;
                      color: $firstColor;
                    }
                  }
                }

                .wIcon {
                  width: 33%;

                  .IconSVG {
                    height: 36px;

                    .withdraw-1{fill:$fourthColor;}
                    .withdraw-2{fill:#fff;}
                  }
                }

                .wText {
                  width: 33%;

                  .deposit {
                    height: 36px;

                    .cls-1{fill:#017048;}
                    .cls-2{fill:#fff;}
                  }
                }

                .wAmount {
                  width: 33%;

                  .mapRouteBtn {
                    height: 36px;

                    .cls-1 {
                      fill: $infoColor;
                    }
                  }
                }
              }
            }

            &.selected {
              border-left: 5px solid $thirdColor;
              border-right: 7px solid $thirdColor;
              animation: selectedBorderColor 1000ms ease-out;

              .button {
                height: 95px;
                //animation: selectedElem 400ms cubic-bezier(.17,.67,.83,.67);
                animation: selectedElem 500ms ease-out;
              }

              .top {
                cursor: default;

                .details {
                  .icon {
                    width: 38px;
                    animation: selectedPin 200ms ease-in;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .locationsMap {
    width: 74%;
    height: 100%;
    display: block;
    z-index: 1;
  }

  button,
  a {
    &:focus,
    &:active {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .IconSVG {
    &.activateLocation {
      .cls-1{fill:#474747;}
    }
  }
}