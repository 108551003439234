$first-border-size : 19px;
$any-border-size: 15px;

.component-breadcrumbs {
  margin: 10px auto;
  padding: 0 10px 10px;
  max-width: 1528px;

  .do-scroll-if-necessary {
    overflow-x: auto;
  }

  .breadcrumbs-list {
    list-style: none;
    display: flex;
    align-items: center;
    background: linear-gradient(to right, #FFFFFF, rgba(234, 234, 239, 0));

    .over {
      z-index: 2;
    }

    li.cb-item {
      position: relative;
      text-indent: 10px;
      height: 38px;
      background-color: #F8F8FA;
      display: inline-block;
      margin-left: 10px;
      color: rgba(16, 16, 16, .47);
      font-size: 18px;
      text-align: center;
      line-height: 38px;
      white-space: nowrap;
    }

    .txt {
      white-space: nowrap;
    }

    li.cb-item.first-element {
      margin-left: 0;
      font-size: 11px;
      font-weight: 600;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;

      display: flex;
      align-items: center;
      text-indent: 0px;

      .cb-back {
        width: 32px;
        height: 32px;
        color: #fff;
        background: #1B45E3;
        border-radius: 3px;
        margin-left: 3px;
        margin-right: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          fill: #fff;
          height: 15px;
          width: auto;
          margin-left: 5px;
        }
      }
    }

    li.cb-item {
      @for $i from 1 through 20 {
        &:nth-child(#{$i}) {
          z-index: #{20 - $i};
        }
      }
    }

    li.cb-item.first-element:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-top: $first-border-size solid transparent;
      border-left: $first-border-size solid #F8F8FA;
      border-bottom: $first-border-size solid transparent;
      top: 0;
      right: -19px;
    }

    li.cb-item.regular-element {
      font-size: 13px;
      font-weight: 600;
      text-indent: 25px;
      height: 30px;
      line-height: 30px;
      color: #001D8A;
      background-color: #EAEAEF;
    }

    li.cb-item.regular-element:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      left: 0;
      border-top: $any-border-size solid transparent;
      border-left: $any-border-size solid white;
      border-bottom: $any-border-size solid transparent;
      top: 0;
    }

    li.cb-item.regular-element:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-top: $any-border-size solid transparent;
      border-left: $any-border-size solid #EAEAEF;
      border-bottom: $any-border-size solid transparent;
      top: 0;
    }
  }

  .cb-footer {
    color: #1E1E2B;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    line-height: 20px;
    padding: 28px 26px 56px;
  }
}