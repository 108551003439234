.passwordField {
    .messages {
        -webkit-transition: all .2s ease-in;
        -moz-transition: all .2s ease-in;
        -ms-transition: all .2s ease-in;
        -o-transition: all .2s ease-in;
        transition: all .2s ease-in;
        height: auto;
        padding-top: 0rem;
        margin-top: -1.2rem;
        font-size: 0.75rem;
    }
}
.password-strength {
    background: #f2f2f2;
    padding-top: 0px;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    min-height: 6.7rem;
    -webkit-transition: all 0.2s ease-in ;
    -moz-transition: all 0.2s ease-in ;
    -ms-transition: all 0.2s ease-in ;
    -o-transition: all 0.2s ease-in ;
    transition: all 0.2s ease-in ;
    position: relative;
    &.hide {
        display: block;
        min-height: 0px;
        overflow: hidden;
        opacity: 0;
        height: 0px;
    }
    &.valid {
        background-color: #EBF6EF;
    }
    .header {
        padding-top: 0.25rem;
        font-size: 0.7rem;
        font-style: italic;
        background: white;
        padding: 0.2rem .5rem;
        .right {
            float: right;
        }
        .lines {
            .line {
                height: 0.25rem;
                background-color: #b3b3b3;
                width: 33%;
                float: left;
                margin-left: 0.5%;
                position: relative;
                &:first-child {
                    margin-left: 0px;
                }
                .fill {
                    position: relative;
                    height: 0.25rem;
                    -webkit-transition: all 0.5s ease-in-out ;
                    -moz-transition: all 0.5s ease-in-out ;
                    -ms-transition: all 0.5s ease-in-out ;
                    -o-transition: all 0.5s ease-in-out ;
                    transition: all 0.5s ease-in-out ;
                }
            }
        }
    }
    .list {
        position: relative;
        margin-top: 0.8rem;
        li {
            color: rgb(77,77,77);
            position: relative;
            font-size: 0.875rem;
            font-weight: 500;
            font-style: italic;
            margin-left: 1rem;
            .IconSVG {
                opacity: 0;
                display: inline-block;
                width: .8rem;
                position: absolute;
                left: -1.1rem;
                top: 0.2rem;
                -webkit-transition: opacity .2s ease-in ;
                -moz-transition: opacity .2s ease-in ;
                -ms-transition: opacity .2s ease-in ;
                -o-transition: opacity .2s ease-in ;
                transition: opacity .2s ease-in ;
            }
            &.title {
                margin: 0;
                margin-left: 0;
                list-style: none;
                font-size: 0.75rem;
                font-weight: 600;
                color: rgb(153,153,153);
                font-style: normal;
            }
            &.success,
            &.valid {
                color: rgb(1,112,72);
                list-style-type: none;
                .IconSVG {
                    opacity: 1;
                }
            }
            &.error,
            &.notValid {
                color: #ff0000;
            }
        }
    }
    .space {
        display: block;
        min-height: 20px;
        width: 100%;
    }
}
.valid {
    .IconSVG {
        .fillMe {
            fill: #127954;
        }
    }
}
.error {
    .IconSVG {
        .fillMe {
            fill: #ff0000;
        }
    }
}


.input-centered {
    .input-group {
        .register-password-hide {
            opacity: 0;
        }
        input{
            &:focus {
                ~ {
                    .register-password-hide {
                        opacity: 1;
                        border: 2px solid #0171bc;
                        padding: .2rem .7rem;
                        box-sizing: content-box;
                        margin-top: -.25rem;
                        pointer-events: none;
                        background-color: white;
                        .fillMe {
                            fill: #0171bc;
                        }
                    }
                }
            }
        }
    }
}
.passwordField {
    position: relative;
    .password-button {
        width: 3.35rem;
        height: 2.1rem;
        position: absolute;
        right: 1.25rem;
        top: 2.25rem
    }
}
.registerV2 {
    .passwordField {
        position: relative;
        .password-button {

            top: -.25rem
        }
    }
}
