@import "./../../../variables";

#StaticPage {
  padding: 2rem 2rem 6rem;

  &.subStaticPage {
    padding-left: 0;
    padding-right: 0;
    padding-top: 6rem;
  }

  .page-header {
    padding-bottom: 46px;

    .page-header-top {
      display: block;
      width: 100%;
      text-align: left;
      margin-left: -1.5rem;

      .pageIcon {
        float: left;
        text-align: right;
        width: 3rem;

        .icon {
          width: 26px;
          height: 26px;
          float: right;

          .default {
            fill: $thirdColor;
          }

          .transactionsHistory {
            .cls-1 {
              fill: #fff;
            }
            .cls-2,
            .cls-3 {
              fill: none;
              stroke: #017048;
              stroke-miterlimit: 10;
              stroke-width: 1.5px;
            }
            .cls-2 {
              stroke-linecap: round;
            }
          }
        }
      }

      .pageName {
        display: inline-block;
        text-align: left;
        padding-left: 16px;
        text-transform: uppercase;
        color: $thirdColor;
        font-weight: bold;
        font-size: 14px;
      }

      .clear {
        clear: both;
      }
    }

    .pageDescription {
      text-align: left;
      padding-left: 3rem;
      margin-left: -2rem;

      .description {
        padding-left: 16px;
        font-size: 12px;
        color: $grayColor;
        text-transform: capitalize;
      }
    }
  }

  .page-content {
    ul.shortcuts {
      padding: 0;
      list-style: none;
      margin-bottom: 2.5rem;
      li {
        line-height: 1.2rem;
      }
    }

    .example {
      background-color: $exampleBg;
      font-size: 15px;
      color: $firstColor;

      h4 {
        text-transform: uppercase;
        color: $exampleFont;
        font-size: 16px;
        padding: 0 0 5px;
        margin: 0;
      }

      p {
        margin-bottom: 0;
      }
    }

    .bottomExp {
      margin: 1.2rem 4rem 3rem !important;
    }

    .singleBlock {
      display: block;
      padding: 1.2rem 1rem;
      margin: 3rem 4rem;
      text-align: left;
      border: 1px solid $exampleBg;
      border-radius: 5px;
    }

    .tresholdsTable {
      display: block;
      padding: 0rem 1rem 1.2rem;
      margin: 1.2rem 4rem 3rem;

      table {
        text-align: center;
        width: 100%;
        font-size: 15px;
        border: 0;

        thead {
          border-bottom: 2px solid $firstColor;

          tr {
            &:last-child {
              th {
                padding-bottom: 10px;
              }
            }
          }
        }

        th {
          width: 50%;
          font-weight: normal;
          padding-bottom: 0;

          &.hsp {
            font-size: 17px;
            font-weight: 700;
            text-transform: uppercase;
          }
        }

        tbody {
          tr {
            &:first-child {
              td {
                padding-top: 26px;
              }
            }

            td {
              padding: 8px 8px;

              &:first-child {
                padding-left: 0;
              }

              &:last-child {
                padding-right: 0;
              }

              p {
                background-color: $exampleBg;
                font-size: 15px;
                color: $firstColor;
                border: 1px solid $exampleBg;
                border-radius: 5px;
                height: 80px;
                vertical-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-left: 2rem;
                padding-right: 2rem;
              }
            }
          }
        }
      }
    }
  }
}