@import "../../../../variables.scss";

.sms-container {
  margin-top: 20px;

  .UsernameField {
    float: left;
    max-width: 70%;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    * {
      -webkit-transition: all 0.2s ease-in;
      -moz-transition: all 0.2s ease-in;
      -ms-transition: all 0.2s ease-in;
      -o-transition: all 0.2s ease-in;
      transition: all 0.2s ease-in;
    }
    .space-top {
      display: none;
    }
    .input-group {

      .IconSVG {
        opacity: 0;
      }

      input {

        font-size: 0.875rem;
        font-weight: 400;
        color: rgb(77,77,77);
        &:disabled {
          background-color: transparent;
          border-bottom: none !important;
          margin-bottom: -20px;
        }
      }
      .input-bar {
        height: 0px;
      }
      label {
        opacity: 0;
      }

    }
    .messages {
      height: 0px;
    }
  }
  .change-btn {
    cursor: pointer;
    font-weight: bold;
    font-size: 0.875rem;
    color: #007048;
    font-weight: 500;
  }
  .left {
    float: left;
    margin-left: 20px;
  }
  .right {
    float: right;
    margin-right: 20px;
    max-width: 50%;
    .UsernameField {
      float: right;
      max-width: 100%;
      padding: 0 0 1rem 0;
      margin-top: -0.2rem;
      input {
        text-align: right;
        padding: 0;
        font-size: 0.85rem;
      }
    }
  }
  .new-request-btn {
    background-color: rgb(252,205,0);
    color: rgb(13,13,13);
    font-weight: 600;
    font-size: 1rem;
    width: 100%;
    -webkit-transition: background-color 0.2s ease-in;
    -moz-transition: background-color 0.2s ease-in;
    -ms-transition: background-color 0.2s ease-in;
    -o-transition: background-color 0.2s ease-in;
    transition: background-color 0.2s ease-in;

    &.disabled {
      background-color: rgb(242,242,242);
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
  .cancel-request-number {
    display: none;
    border: 2px solid $warningColor;
    margin-top: 10px;
    &.active {
      display: block;
    }
  }

  .title {
    font-size: 0;
    overflow: hidden;
  }
  &.active {
    background-color: #ffffff;
    padding: 3rem 1rem 1rem 1rem;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: -2rem;
    .title {
      font-size: 1rem;
      font-weight: 500;
    }
    .change-btn {
      opacity: 0;
      pointer-events: none;
    }
    .right {
      width: 100%;
      float: none;
      max-width: 100%;
      .UsernameField {
        max-width: 100%;
        width: 100%;
        padding: 1rem;
        input {
          text-align: left;
        }
      }
    }

    .input-group {
      .IconSVG {
        opacity: 1;
      }
      .input-bar {
        height: 2px;
      }
    }
    .messages {
      height: auto;
      p {
        font-size: 12px;
      }
    }
  }
}
.registerV2 {
  .fields {
    .sms-container {
      padding: 0 1rem;
      .title {
        font-size: 0;
      }
      .right {
        max-width: 50%;
        .UsernameField {
          float: left;
          .input-group {
            width: 100%;
            input {
              text-align: right;
              padding: 0;
              font-size: 0.85rem;
              width: 100%;
              height: 1rem;
              border: none;
            }
          }
        }
      }
      &:not(.active) {
        .new-request-btn {
          margin: 0 auto;
          display: block;
          background: none;
          font-size: .85rem;
          font-weight: normal;
          &:disabled {
            background: none;
          }
        }
      }
      &.active {
        margin-left: -1rem;
        top: 0;
        .new-request-btn {
          color: white;
          background: -webkit-linear-gradient(277deg, #FF971D, #FFB400);
          background: -o-linear-gradient(277deg, #FF971D, #FFB400);
          background: linear-gradient(173deg, #FF971D, #FFB400);
        }
        .title {
          font-size: 1rem;
        }
        .right {
          max-width: 100%;
          .UsernameField {
            width: 100%;
            .input-group {
              input {
                text-align: left;
                height: initial;
                font-size: initial;
                padding: initial;
                margin: initial;
                margin-top: -2px;
                margin-bottom: -30px;
              }
            }
          }
        }
      }
    }
  }
}