.ns-rsc-default {
  height: 21px;

  .rsc {
    height: 0;
    width: 0;
    display: none;
  }

  .rsl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 75px;
    height: 21px;
    background: var(--ns-sold-card-switch-bg);
    //border-radius: 6px;
    position: relative;
    transition: background-color .2s;
    color: var(--ns-sold-card-switch-color);

    .rls-icon {
      font-size: 11px;
      font-weight: 600;
      color: var(--ns-sold-card-switch-color);
      width: 35px;
    }

    .rls-icon.first {
      padding-left: 10px;
    }

    .rls-icon.second {
      text-align: right;
      padding-right: 10px;
    }
  }

  .rsl .rsb {
    position: absolute;
    text-align: center;
    line-height: 19px;
    top: 0px;
    left: 0px;
    width: 41px;
    height: 21px;
    //border-radius: 6px;
    transition: 0.2s;
    background-color: var(--ns-sold-card-switch-selected-bg);
    color: var(--ns-sold-card-switch-selected-color);
    box-shadow: 0 0 2px 0  var(--ns-sold-card-switch-shadow-color);
    font-size: 11px;
    font-weight: 600;
  }

  .rsc:checked+.rsl .rsb {
    transform: translateX(34px);
    background-color: var(--ns-sold-card-switch-selected-bg);
    color: var(--ns-sold-card-switch-selected-color);
    box-shadow: 0 2px 3px  var(--ns-sold-card-switch-shadow-color);
  }
}