.prematch-breadcrumbs {
  display: none;
  align-items: center;
  margin: 12px 5px;

  .separator,
  .bread-cell {
    cursor: pointer;
    color: #001D8A;
    font-size: 12px;
    font-weight: 600;
  }

  .separator {
    margin: 0 10px;
  }

  .bread-cell {
    cursor: pointer
  }
}

@media screen and (min-width: 1281px) {
  .prematch-breadcrumbs {
    display: flex;

  }
}