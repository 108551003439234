.simple-progress {
  width: 100%;
  height: 3px;
  overflow: hidden;
  position: relative;
  margin-top: 7px;
  margin-right: 4px;
  border-radius: 2px;
  background-color: #DFE7EF;

  &:last-child {
    margin-right: 0;
  }

  .simple-progress-bar {
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    position: absolute;
    transform-origin: left;
    transition: transform .4s linear;
    background: linear-gradient(90deg, #1F83E6, #06D2BD, #1F83E6);
    border-radius: 3px;
    background-size: 200% 100%;
  }
}