$titleBarGradientStartColor: #17436E;
$titleBarGradientEndColor: #21A2CC;
.paysafe-popup  {
  padding: 1rem;
  overflow: hidden;
  .header {
    text-align: center;
    padding-top: 3rem;
    svg {
      display: block;
      height: 5rem;
      margin: 0 auto 1rem;
      float: none;
      .cls-1 {
        fill: $titleBarGradientStartColor;
      }
      .cls-2 {
        fill: #fff;
      }
    }
    h2 {
      font-size: 1.25rem;
      color: $titleBarGradientStartColor;
    }
  }
  .info {
    margin-top: 3rem;
    svg {
      width: 100%;
      transform: scale(1.5);
    }
  }
  .description {
    margin: 1rem 0 4rem;
    padding: 0 2rem;
    font-weight: normal;
    font-size: .825rem;
    color: black;
    text-align: center;
  }
  .old {
    background: transparent;
    color: $titleBarGradientStartColor;
    border: 2px solid;
    margin-top: 1rem;
    padding: 1rem;
  }
  .new {
    background: $titleBarGradientStartColor;
    background: -webkit-gradient(left bottom, right top, color-stop(0%, $titleBarGradientStartColor), color-stop(100%, $titleBarGradientEndColor));
    background: linear-gradient(60deg, $titleBarGradientStartColor 0%, $titleBarGradientEndColor 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#df5519', endColorstr='#9d14bc', GradientType=1 );
    color: white;
    margin-top: 1rem;
    padding: 1rem;
    svg {
      margin-left: .5rem;
      min-width: 1.25rem;
      min-height: 1.25rem;
      path {
        fill: white;
      }
    }
  }
  .Loading {
    margin: 0;
  }
}