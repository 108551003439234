@import "./../../../../../../../variables";

.SelfExclusionModal {
    .modal-content {
        border-radius: .25rem;
        border-color: rgba(0, 0, 0, 0.0);
        padding: 0 1rem;

        .SelfExclusionModalBody {
            height: 700px;
            overflow: hidden;
            color: $black;

            .step-header {
                text-align: center;
                padding-top: 20px;

                h2 {
                    font-weight: normal;
                    font-size: 26px;

                }
            }

            .step-text {
                .customBold{
                    font-weight: 700;
                    color: #000;
                }
            }

            .step-exclusion-ends {
                margin-top: 30px;
                h3{
                    width: 100%;
                    margin: 10px 0px;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 600;
                    
                    .ExclusionDate{
                        font-weight: 600;
                        font-size: 22px;
                        color: #000;
                    }
                    
                }
            }

            .step-content {
                margin-top: 30px;
                margin-bottom: 30px;
                small{
                    min-height: 19px;
                }
                .ResendSMS{
                    align-self: flex-end;
                    text-align: right;
                    font-weight: 700;
                    font-size: 12px;
                    text-decoration: underline;
                    cursor: pointer;
                    color: #ff971d;
                    width: fit-content;
                }
            }

            .step-message {
                height: 80px;
            }

            .step-footer {
                .checkbox-container{
                    padding: 1rem 0 0 0 ;
                    .input-group {
                        display: grid;
                        grid-template-columns: 2rem 1fr;
                        grid-gap: 1rem;
                    }
                }
                .nextButton {
                    color: $buttonGreen;
                    font-weight: bold;
                    background-color: $buttonYellow;

                    .fillMe{
                        fill: $buttonGreen !important;
                    }
        
                    &:hover {
                        background-color: lighten($buttonYellow, 5%);
                    }
        
                    .text {
                        padding-left: 5px;
                    }
                    .animate {
                        display: inline-block;
	                    animation: rotate-center 1s linear infinite both;
                    }
                    
                   
                }
            }
        }

        .SelfExclusionModalHeader {
            color: $black;
            text-align: center;
            text-transform: uppercase;
            border-bottom: 0;

            h5 {
                width: 100%;
                font-size: 14px;
                font-weight: bold;
                padding-top: .75rem;
            }

            button.close {
                position: absolute;
                top: .5rem;
                right: 1rem;
                font-size: 60px;
                font-weight: normal;
                opacity: 1;
                color: $thirdColor;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}

@keyframes rotate-center {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}