.wf-gratuities-daily {
  .wf-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;

    .wf-icon img {
      display: block;
      width: 26px;
      height: 26px;
      margin-right: 4px;
    }

    .wf-title {
      color: #fff;
      text-align: left;
      font-size: 22px;
      font-weight: bold;
      line-height: 22px;
    }
  }

  .wf-body {
    background-image: url(../../assets/gratuities-daily-bg@3x.jpg);
    background-position: center center;
    border-radius: 12px;
    background-size: 100% 100%;

    &>div {
      border-radius: 12px;
      padding: 7px;
      box-shadow: -6px -6px 15px 0 #3B3CBC, 6px 6px 20px 0 rgba(1, 0, 17, .8);
    }


    .wf-days {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px 5px 25px;
      position: relative;

      .wf-paths {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }

      .wf-day {
        width: 48px;
        height: 60px;
        position: relative;
        border-radius: 12px;
        border: 1px solid #465DF0;
        background: linear-gradient(225deg, #1D2D9E, #3D51CB);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: default;
        position: relative;
        z-index: 1;
        box-shadow: -6px -6px 8px 0 #2728AF, 6px 6px 8px 0 rgba(21, 20, 41, .7);

        &::before {
          content: " ";
          border-radius: 12px;
          position: absolute;
          z-index: -1;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          border: 1px solid #2E43E5;
        }

        .wf-icon {
          svg {
            width: 29px;
            height: 29px;
          }
        }

        .wf-subtitle {
          color: #fff;
          font-weight: bold;
          text-align: center;
          font-size: 10px;
          line-height: 10px;
          margin-bottom: 1px;
        }

        .wf-title {
          color: #BAC1FF;
          font-weight: bold;
          text-align: center;
          font-size: 14px;
          line-height: 14px;
          margin-bottom: 5px;
        }

        .wf-lock-icon {
          color: #5B69E3;
          height: 12px;
          margin-bottom: 12px;

          svg {
            width: auto;
            height: 12px;
          }
        }

        .wf-progress {
          display: none;
        }
      }

      .wf-day.wf-selected {
        border: 1px solid #4E52E9;
        background: linear-gradient(225deg, #3C8D00, #5AD500);

        .wf-icon {
          color: #A7FF65;
        }

        .wf-title {
          color: #A7FF65;
        }
      }

      .wf-day.wf-selected::before {
        border: 1px solid #4FB900;
      }

      .wf-day.wf-current {
        width: 69px;
        height: 83px;
        border: 1px solid #4252AB;
        background: url(../../assets/gratuities-current-bg@3x.png), linear-gradient(225deg, #FF8800, #FFD500);
        background-repeat: no-repeat;
        background-position: center center, center center;
        background-size: 80% auto, 100% 100%;
        transition: background 500ms;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

        cursor: pointer;
        //width: 69px;
        //height: 95px;
        position: relative;
        z-index: 2;

        .wf-lock-open-icon {
          img {
            width: 29px;
            height: auto;
          }

          margin-top: 10px;
        }

        .wf-title {
          text-shadow: 1px 1px #000000;
          padding-bottom: 10px;
          font-size: 20px;
          color: #fff;
        }


        .wf-collect {
          min-width: calc(100% - 4px);
          margin: 0 2px 2px;
          padding: 7px 0;
          font-size: 7px;
          font-weight: 600;
          text-align: center;
          color: #fff;
          border-radius: 5px 5px 12px 12px;
          background: linear-gradient(to right, #6501FC, #FF0090);
          position: absolute;
          bottom: 0px;
        }
      }

      .wf-day.wf-next {
        width: 69px;
        height: 83px;

        .wf-lock-icon {
          height: 29px;

          svg {
            width: 29px;
            height: auto;
          }

          margin-bottom: 5px;
        }

        .wf-title {
          font-size: 20px;
          margin-bottom: 7px;
        }

        .wf-progress {
          display: block;
          height: 3px;
          width: 56px;
          border-radius: 3px;

          &.MuiLinearProgress-colorPrimary {
            background-color: #1B0F81;
          }

          .MuiLinearProgress-bar {
            background-color: #A7FF65;
          }
        }
      }

      .wf-day.wf-current.wf-animate {
        background: url(../../assets/gratuities-current-grey-bg@3x.png), #fff;
        background-repeat: no-repeat;
        background-position: center center, center center;
        background-size: 80% auto, 100% 100%;
      }

      .wf-day.wf-current::before {
        transition: border 500ms;
        border: 1px solid #FFDC00;
      }

      .wf-day.wf-current.wf-animate::before {
        border: 1px solid #FFFFFF;
      }
    }

    .wf-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .wf-text {
        color: rgba(255, 255, 255, .81);
        font-size: 11px;
        font-weight: normal;
        text-align: center;
      }

      .wf-button {
        background: linear-gradient(to right, #FF8800, #FFD500);
        height: 43px;
        padding: 0 60px;
        color: #fff;
        font-size: 15px;
        font-weight: 600;
        display: flex;
        align-items: center;
        border-radius: 12px;
        margin-top: 8px;
        box-shadow: -6px -6px 10px 0 #17189A;
        margin-bottom: 5px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        cursor: pointer;

        .wf-loader {
          display: none;
          margin-left: 10px;
          position: relative;
          top: 2px;

          &>div {
            display: inline-block;
            width: 16px !important;
            height: 16px !important;
            color: #fff
          }
        }

        &.wf-loading {
          pointer-events: none;

          .wf-loader {
            display: inline-block;
          }
        }
      }

      .wf-timer {
        background: linear-gradient(to right, #0034AC, #040896);
        height: 50px;
        padding: 0 10px;
        color: #fff;
        font-size: 17px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        margin-top: 8px;
        margin-bottom: 5px;
        width: 100%;
      }
    }
  }
}