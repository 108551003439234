.wheel-cta-promotions-wrapper {
  display: block;

  font-family: "Open Sans", OpenSans;

  .wheel-cta-promo-header {
    h1 {
      padding-bottom: 0;

      svg {
        margin-right: 5px;
      }
    }
  }

  .wheel-cta-promotions {
    background-image: url("../assets/wheel-cta-big.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 136px;
    width: 100%;
    max-width: 380px;
    border-radius: 7px;
    margin: 0 auto;

    display: flex;
    align-items: flex-end;
    justify-content: center;
    cursor: pointer;

    &.mb10 {
      margin: 0 0 20px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .3);
    }

    .wheel-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 45px;
      width: 100%;
      border-radius: 0 0 7px 7px;
      padding-right: 12px;
      background: rgba(0, 0, 0, .5);

      .wheel-spin-button-denied,
      .wheel-spin-button {
        border-radius: 3px;
        padding: 0 22px;
        height: 35px;
        background: linear-gradient(to right, #E66F1F, #FF167B);
        border: 1px solid #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #fff;
        font-weight: 600;
        white-space: nowrap;
      }

      .wheel-spin-button-denied {
        background: linear-gradient(to right, #920000, #FF362F);
        text-transform: uppercase;
      }

      .wheel-count-down {
        display: flex;
        align-items: center;

        .txt {
          height: 28px;
          background: #2E3D4B;
          border-radius: 3px 0 0 3px;
          font-size: 13px;
          color: #fff;
          font-weight: normal;
          white-space: nowrap;
          padding: 0 7px 0 10px;
          display: flex;
          align-items: center;
        }

        .count {
          height: 28px;
          background: #FFC400;
          border-radius: 0 3px 3px 0;
          font-size: 16px;
          color: #000;
          font-weight: bold;
          white-space: nowrap;
          padding: 0 10px 0 7px;
          display: flex;
          align-items: center;
        }
      }

    }
  }
}