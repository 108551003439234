.jackpot-headerV2 {
    margin-bottom: 10px;


    &.regular {
        position: sticky;
        top: -10px;
        z-index: 2;

        @media screen and (min-width: 360px) {
            top: -16px;
        }

        @media screen and (min-width: 370px) {
            top: -18px;
        }

        @media screen and (min-width:560px) {
            top: -25px;
        }

        @media screen and (min-width:812px) {
            top: -35px;
        }
    }

    &.regular.variant-theme {
        position: sticky;
        top: -10px;
        z-index: 2;

        @media screen and (min-width: 360px) {
            top: -44px;
        }

        @media screen and (min-width: 370px) {
            top: -48px;
        }

        @media screen and (min-width:560px) {
            top: -60px;
        }

        @media screen and (min-width:812px) {
            top: -60px;
        }
    }

    &.regular.desktop-int {
        top: -140px;
    }

    .jackpot-scale {
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
    }

    .jackpot-side {
        flex: 1 1 auto;
        min-width: 0;
    }

    .jackpot-wrapper {
        min-width: 100vw;
        background-image: none;

        @media screen and (min-width: 370px) {
            min-width: 370px;
        }

        .jackpot-header-wrap {
            padding-top: 70%;
            position: relative;

            .jackpot-header-inner {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                .jackpot-header-gradient {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }

                .jackpot-main-value {
                    cursor: pointer;
                    margin: 0 4px;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;

                    .jackpot-image-bg-wrapper {
                        width: 100%;

                        .jackpot-image-bg {
                            width: 100%;
                            height: auto;
                            display: block;
                        }
                    }

                    .jackpot-big-counter {
                        position: absolute;
                        top: 0;
                        bottom: 14px;
                        left: 0;
                        right: 0;
                        display: flex;
                        align-items: flex-end;
                        justify-content: center;
                    }

                    @media screen and (max-width:1024px) {
                        .jackpot-big-counter {
                            bottom: 11px;
                        }
                    }

                    .big-counter2 {
                        padding-left: 10px;
                    }

                    .big-counter2 .radix,
                    .big-counter2 .digit-wrapper .digit {
                        font-size: 18px;
                    }

                    .jackpot-main-currency {
                        font-size: 5px;
                        font-weight: bold;
                        padding-left: 4px;
                        position: relative;
                        top: 2px;
                        color: #F6A700;
                    }
                }

                .jackpot-state-wrapper.full {
                    height: 100%;
                }

                .jackpot-secondary-values {
                    margin: 13px 1px 0;
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: center;

                    &.full {
                        height: 100%;
                        margin: 0 auto;
                    }

                    .jackpot-elem-0 {
                        display: none;
                    }

                    .jackpot-secondary-value {
                        margin: 0 3px;
                        cursor: pointer;
                        z-index: 2;
                        position: relative;

                        .jackpot-image-bg-wrapper {
                            width: 100%;

                            .jackpot-image-bg {
                                width: 100%;
                                height: auto;
                                display: block;
                            }
                        }

                        .jackpot-secondary-counter {
                            color: #fff;
                            position: absolute;
                            top: 0;
                            bottom: 10px;
                            left: 0;
                            right: 0;
                            display: flex;
                            align-items: flex-end;
                            justify-content: center;

                            .small-counter .radix,
                            .small-counter .digit-wrapper .digit {
                                font-size: 13px;
                                font-weight: 600;
                            }
                        }
                    }

                    .wrapperText {
                        display: none;
                        flex-direction: column;
                        padding: 20px 7px 5px;
                        border-bottom-left-radius: 7px;
                        border-bottom-right-radius: 7px;
                        background-color: #39006C;
                        z-index: 1;
                        cursor: pointer;
                        margin: -10px 6px 0;

                        .muted-text {
                            color: #6E767E;
                            font-size: 7px;
                            font-weight: 600;
                        }

                        .text-right {
                            text-align: right;
                        }

                        .text-left {
                            text-align: left;
                        }

                        .amount {
                            color: #FFA811;
                            font-size: 10px;
                            font-weight: bold;
                        }

                        .cap {
                            text-transform: capitalize;
                        }

                        .date {
                            color: #FFF;
                            font-size: 8px;
                            padding: 2px 0;
                        }

                        .delimiter {
                            height: 1px;
                            margin: 10px 0 10px;
                            background-color: #C4B3D3;
                        }
                    }

                    &.full {
                        .wrapperText {
                            display: flex;
                        }
                    }
                }

                .jackpot-secondary-wrapper.full {
                    height: auto !important;
                }
            }
        }

        .loader {
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;

            .MuiCircularProgress-root {
                width: 30px !important;
                height: 30px !important;
                color: #ffb400 !important;
            }
        }
    }
}

.jackpot-headerV2.front {
    border: 0 !important;
    border-radius: 3px;
    margin: 0;
    overflow: visible;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: OpenSans, "Open Sans";

    & * {
        box-sizing: content-box;
    }

    .jackpot-side {
        display: none;
    }

    .jackpot-scale {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
    }

    .jackpot-wrapper {
        width: 100%;
        min-width: 100%;
        height: 100%;
        position: relative;


        .jackpot-header-wrap {
            padding-top: 0;
            width: 100%;
            height: 100%;


            .jackpot-header-inner {
                position: static;
                width: 100%;
                height: 100%;
                border-radius: 8px;
                background-size: 100% 100%;
            }

            .jackpot-header-gradient {
                border-radius: 8px;
                background-image: none !important;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                flex-direction: column;

                .jackpot-state-wrapper {
                    padding-bottom: 7px;
                }

                .jackpot-main-value {
                    box-sizing: border-box;
                    position: relative;

                    .jackpot-main-currency {
                        padding-left: 1px;
                    }

                    .big-counter2 {
                        padding-left: 0;
                    }

                    .big-counter2 .radix,
                    .big-counter2 .digit-wrapper .digit {
                        font-size: 17px;
                    }

                    .big-counter2 .radix,
                    .big-counter2 .digit-wrapper {
                        height: 22px;
                        line-height: 22px;
                        padding: 3px 3px;
                        margin-right: 0px;
                    }

                    .big-counter2 .radix {
                        padding: 3px 1px;
                    }


                    @media screen and (max-width: 1024px) {

                        .jackpot-big-counter {
                            bottom: 12px;
                        }


                        .big-counter2 .digit-wrapper .digit {
                            font-size: 17px;
                            line-height: 19px;
                        }

                        .big-counter2 .radix,
                        .big-counter2 .digit-wrapper {
                            height: 18px;
                            line-height: 19px;
                            padding: 3px 3px;
                            margin-right: 0px;
                        }
                    }

                }

                .jackpot-secondary-values {
                    margin-top: 4px;

                    .jackpot-elem-0 {
                        display: none
                    }

                    .jackpot-secondary-value {

                        .jackpot-secondary-text {
                            padding-top: 8px;
                        }

                        .jackpot-secondary-counter {

                            .small-counter {
                                position: relative;
                                top: 2px;
                            }

                            @media screen and (min-width:1528px) {
                                .small-counter {
                                    position: relative;
                                    top: 3px;
                                }
                            }

                            .small-counter .radix,
                            .small-counter .digit-wrapper .digit {
                                font-size: 10px;
                                font-weight: 600;
                            }

                            .small-counter .radix,
                            .small-counter .digit-wrapper {
                                height: 10px;
                                line-height: 10px;
                                padding: 0 1px 0 0;
                                margin-top: 5px;
                            }
                        }
                    }
                }

                .jackpot-info-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    background-color: rgba(57, 0, 108, .45);
                    border-radius: 5px;
                    width: 100%;
                }

                .jackpot-name {
                    color: #fff;
                }

                @media screen and (min-width: 821px) {
                    .jackpot-name {
                        font-size: 20px;
                        font-weight: 600;
                    }
                }

                .wrapperHistory {
                    padding-top: 5px;
                    max-width: 460px;
                    width: 100%;
                    margin: 0 auto;

                    @media screen and (min-width: 1024px) {
                        max-width: 300px;
                    }

                    .jackpot-counter {
                        text-align: center;
                        font-size: 14px;
                        margin-bottom: 10px;
                        position: relative;

                        .currency {
                            position: absolute;
                            color: #F6A700;
                            font-size: 8px;
                            top: 16px;
                            padding-left: 2px;
                        }
                    }

                    .biggestWin,
                    .latestWin {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        padding: 5px 7px 5px;
                        border-radius: 5px;
                        margin: 0 7px;
                        background-color: rgba(57, 0, 108, .85);
                    }

                    .biggestWin {
                        margin-bottom: 5px;
                    }

                    .muted-text {
                        color: #6E767E;
                        font-size: 10px;
                        font-weight: 600;
                    }

                    .user {
                        color: #6E767E;
                        font-size: 10px;
                        font-weight: 600;
                    }

                    .text-right {
                        text-align: right;
                    }

                    .text-left {
                        text-align: left;
                    }

                    .amount {
                        color: #FFA811;
                        font-size: 11px;
                        font-weight: 600;
                    }

                    .cap {
                        text-transform: capitalize;
                    }

                    .date {
                        color: #D5A146;
                        font-size: 11px;
                        padding: 2px 0;
                        font-weight: 600;
                    }


                    @media screen and (min-width: 415px) {
                        .muted-text {
                            font-size: 11px;
                        }

                        .user {
                            font-size: 11px;
                        }

                        .amount {
                            font-size: 12px;
                            font-weight: 600;
                        }

                        .date {
                            font-size: 12px;
                            font-weight: 600;
                        }
                    }

                    @media screen and (min-width: 768px) {
                        .jackpot-counter {
                            font-size: 16px;
                        }

                        .muted-text {
                            font-size: 12px;
                        }

                        .user {
                            font-size: 12px;
                        }

                        .amount {
                            font-size: 14px;
                            font-weight: 600;
                        }

                        .date {
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }

                    @media screen and (min-width: 1366px) {
                        .jackpot-counter {
                            font-size: 20px;
                        }

                        .muted-text {
                            font-size: 14px;
                        }

                        .user {
                            font-size: 14px;
                        }

                        .amount {
                            font-size: 16px;
                            font-weight: 600;
                        }

                        .date {
                            font-size: 16px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }


    &.mobile-int {
        .jackpot-wrapper {
            .jackpot-header-wrap {
                .jackpot-header-gradient {
                    .jackpot-state-wrapper {
                        width: 197px;
                    }

                    @media screen and (min-width: 415px) {
                        .jackpot-state-wrapper {
                            transform-origin: center bottom;
                            transform: scale(1.15);
                        }
                    }

                    @media screen and (min-width: 768px) {
                        .jackpot-state-wrapper {
                            transform-origin: center bottom;
                            transform: scale(1.3);
                        }
                    }

                    @media screen and (min-width: 821px) {
                        .jackpot-state-wrapper {
                            transform-origin: center bottom;
                            transform: scale(1.45);
                        }
                    }
                }
            }
        }
    }

    &.desktop-int {
        .jackpot-wrapper {
            .jackpot-header-wrap {
                .jackpot-header-gradient {
                    .jackpot-state-wrapper {
                        width: 229px;
                        transform-origin: center bottom;
                        transform: scale(0.87);
                    }

                    @media screen and (min-width: 821px) {
                        .jackpot-state-wrapper {
                            transform-origin: center bottom;
                            transform: scale(1.5);
                        }
                    }


                }
            }
        }
    }
}

.jackpot-headerV2.regular {

    .jackpot-wrapper {

        .jackpot-header-wrap {

            .jackpot-header-inner {

                .jackpot-header-gradient {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    padding-bottom: 0;
                }

                .jackpot-main-value {
                    max-width: 300px;
                    margin: 0 auto;
                    position: relative;
                    zoom: 1.4;

                    .jackpot-big-counter {
                        bottom: 10%;

                        .big-counter2 .radix,
                        .big-counter2 .digit-wrapper .digit {
                            font-size: 23px;
                            line-height: 40px;
                        }

                        .big-counter2 .radix,
                        .big-counter2 .digit-wrapper {
                            height: 40px;
                            line-height: 40px;
                            padding: 3px 4px;
                            margin-right: 0px;
                        }

                        .big-counter2 .radix {
                            padding: 3px 1px;
                        }


                        @media screen and (max-width: 1024px) {

                            .big-counter2 .radix,
                            .big-counter2 .digit-wrapper .digit {
                                font-size: 23px;
                                line-height: 30px;
                            }

                            .big-counter2 .radix,
                            .big-counter2 .digit-wrapper {
                                height: 30px;
                                line-height: 30px;
                                padding: 3px 4px;
                                margin-right: 0px;
                            }
                        }

                    }
                }

                .jackpot-secondary-values {
                    margin-top: 10px;
                    overflow: visible;

                    .jackpot-secondary-value {
                        position: relative;
                        overflow: visible;

                        .jackpot-secondary-counter {
                            bottom: 17%;

                            .small-counter .radix,
                            .small-counter .digit-wrapper .digit {
                                font-size: 20px;
                                line-height: 22px;
                                font-weight: 600;
                            }

                            .small-counter .radix,
                            .small-counter .digit-wrapper {
                                height: 22px;
                                line-height: 22px;
                                padding: 0 1px 0 0;
                            }
                        }
                    }
                }

            }
        }
    }

    &.mobile-int {
        .jackpot-wrapper {
            .jackpot-header-wrap {
                padding-top: 82%;
            }
        }

        @media screen and (orientation:landscape) {
            .jackpot-wrapper {
                min-width: 560px;

                .jackpot-header-wrap {
                    padding-top: 50%;
                }

                .jackpot-state-wrapper {
                    width: 70%;
                }
            }
        }

        @media screen and (min-width:560px) {
            .jackpot-wrapper {
                min-width: 560px;

                .jackpot-header-wrap {
                    padding-top: 60%;

                    .jackpot-state-wrapper {
                        width: 70%;
                    }
                }
            }
        }
    }

    &.desktop-int {
        @media screen and (min-width:959px) {
            .jackpot-wrapper {
                min-width: 959px;

                .jackpot-header-wrap {
                    padding-top: 40%;

                    .jackpot-state-wrapper {
                        width: 70%;
                    }

                    .jackpot-header-inner .jackpot-secondary-values .jackpot-secondary-wrapper {
                        max-width: 160px;
                        min-width: 160px;
                        height: 82px;
                    }
                }
            }
        }
    }
}

.jackpot-headerV2.jackpot-stuck {
    .jackpot-wrapper {
        min-width: 959px;

        .jackpot-header-wrap {
            .jackpot-header-gradient {
                //justify-content: flex-end !important;

                .jackpot-state-wrapper {
                    overflow: visible;
                    //width: 500px !important;

                    .jackpot-secondary-values {
                        align-items: flex-end;
                    }
                }
            }
        }

        .jackpot-header-wrap .jackpot-header-inner .jackpot-secondary-values {
            width: 100%;

            .jackpot-secondary-value {
                margin: 0 2px;
            }
        }

        .jackpot-header-wrap .jackpot-header-inner .jackpot-secondary-values .jackpot-elem-0 {
            display: block;
        }

        .jackpot-header-wrap .jackpot-header-inner .jackpot-secondary-values .jackpot-elem-0 {
            display: block;
        }

        .jackpot-header-wrap .jackpot-header-inner .jackpot-secondary-values .jackpot-secondary-wrapper {
            max-width: 160px;
            min-width: 160px;
            overflow: visible;

            .wrapperText {
                display: flex;
                opacity: 0;
                transform: translate3d(0, -100%, 0);
                transition: transform 300ms ease-in-out, opacity 200ms ease-in-out;
            }
        }

        .jackpot-header-wrap .jackpot-header-inner .jackpot-secondary-values .jackpot-secondary-wrapper:hover {
            .wrapperText {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
        }
    }
}

.jackpot-headerV2.front.desktop-int {
    @media screen and (min-width: 1280px) {
        .jackpot-wrapper .jackpot-header-wrap .jackpot-header-inner .jackpot-secondary-values .jackpot-secondary-value .jackpot-secondary-counter {
            bottom: 12px;
        }

        .jackpot-wrapper .jackpot-header-wrap .jackpot-header-inner .jackpot-main-value .jackpot-big-counter {
            bottom: 14px;

            .big-counter2 .radix,
            .big-counter2 .digit-wrapper .digit {
                font-size: 17px;
            }

            .big-counter2 .radix,
            .big-counter2 .digit-wrapper {
                height: 23px;
                line-height: 23px;
            }
        }
    }

    @media screen and (min-width: 1366px) {
        .jackpot-wrapper .jackpot-header-wrap .jackpot-header-inner .jackpot-main-value .jackpot-big-counter {
            bottom: 14px;
        }
    }

    @media screen and (min-width: 1600px) {
        .jackpot-wrapper .jackpot-header-wrap .jackpot-header-inner .jackpot-secondary-values .jackpot-secondary-value .jackpot-secondary-counter {
            bottom: 12px;
        }
    }

    @media screen and (min-width: 1920px) {
        .jackpot-wrapper .jackpot-header-wrap .jackpot-header-inner .jackpot-secondary-values .jackpot-secondary-value .jackpot-secondary-counter {
            bottom: 12px;
        }
    }
}

.jackpot-headerV2.regular.desktop-int {
    @media screen and (min-width: 1280px) {
        .jackpot-wrapper .jackpot-header-wrap .jackpot-header-inner .jackpot-secondary-values .jackpot-secondary-value .jackpot-secondary-counter {
            bottom: 17px;
        }
    }
}

.jackpot-headerV2.front.mobile-int {
    .jackpot-wrapper .jackpot-header-wrap .jackpot-header-inner .jackpot-secondary-values .jackpot-secondary-value .jackpot-secondary-counter {
        bottom: 9px;
    }

    @media screen and (min-width: 768px) {
        .jackpot-wrapper .jackpot-header-wrap .jackpot-header-inner .jackpot-main-value .jackpot-big-counter {
            bottom: 10px;
        }
    }

    @media screen and (min-width: 1024px) {
        .jackpot-wrapper .jackpot-header-wrap .jackpot-header-inner .jackpot-main-value .jackpot-big-counter {
            bottom: 8px;
        }
    }

    @media screen and (orientation: landscape) and (min-width: 812px) {
        .jackpot-wrapper .jackpot-header-wrap .jackpot-header-inner .jackpot-main-value .jackpot-big-counter {
            bottom: 12px;
        }
    }

    @media screen and (orientation: landscape) and (min-width: 1024px) {
        .jackpot-wrapper .jackpot-header-wrap .jackpot-header-inner .jackpot-main-value .jackpot-big-counter {
            bottom: 10px;
        }
    }

    @media screen and (orientation: landscape) and (min-width: 1366px) {
        .jackpot-wrapper .jackpot-header-wrap .jackpot-header-inner .jackpot-main-value .jackpot-big-counter {
            bottom: 9px;


            .big-counter2 .radix,
            .big-counter2 .digit-wrapper .digit {
                font-size: 17px;
            }

            .big-counter2 .radix,
            .big-counter2 .digit-wrapper {
                height: 19px;
                line-height: 19px;
            }

        }
    }
}

.jackpot-headerV2.regular.mobile-int {
    @media screen and (min-width: 320px) {
        .jackpot-wrapper .jackpot-header-wrap .jackpot-header-inner .jackpot-secondary-values .jackpot-secondary-value .jackpot-secondary-counter {
            bottom: 8px;

            .small-counter .radix,
            .small-counter .digit-wrapper .digit {
                font-size: 17px;
            }

            .small-counter .radix,
            .small-counter .digit-wrapper {
                height: 22px;
                line-height: 22px;
            }
        }
    }

    @media screen and (min-width: 375px) {
        .jackpot-wrapper .jackpot-header-wrap .jackpot-header-inner .jackpot-secondary-values .jackpot-secondary-value .jackpot-secondary-counter {
            bottom: 10px;
        }
    }

    @media screen and (min-width: 768px) {
        .jackpot-wrapper .jackpot-header-wrap .jackpot-header-inner .jackpot-secondary-values .jackpot-secondary-value .jackpot-secondary-counter {
            bottom: 12px;
        }
    }

    @media screen and (orientation: landscape) and (min-width: 568px) {
        .jackpot-wrapper .jackpot-header-wrap .jackpot-header-inner .jackpot-secondary-values .jackpot-secondary-value .jackpot-secondary-counter {
            bottom: 12px;
        }
    }

    @media screen and (min-width: 320px) {
        .jackpot-wrapper .jackpot-header-wrap .jackpot-header-inner .jackpot-main-value .jackpot-big-counter {
            bottom: 14px;


            .big-counter2 .radix,
            .big-counter2 .digit-wrapper .digit {
                font-size: 23px;
                line-height: 25px;
            }

            .big-counter2 .radix,
            .big-counter2 .digit-wrapper {
                height: 25px;
                line-height: 25px;
            }

        }
    }

    @media screen and (min-width: 375px) {
        .jackpot-wrapper .jackpot-header-wrap .jackpot-header-inner .jackpot-main-value .jackpot-big-counter {
            bottom: 17px;


            .big-counter2 .radix,
            .big-counter2 .digit-wrapper .digit {
                font-size: 23px;
                line-height: 28px;
            }

            .big-counter2 .radix,
            .big-counter2 .digit-wrapper {
                height: 28px;
                line-height: 28px;
            }

        }
    }

    @media screen and (min-width: 768px) {
        .jackpot-wrapper .jackpot-header-wrap .jackpot-header-inner .jackpot-main-value .jackpot-big-counter {
            bottom: 18px;


            .big-counter2 .radix,
            .big-counter2 .digit-wrapper .digit {
                font-size: 23px;
                line-height: 30px;
            }

            .big-counter2 .radix,
            .big-counter2 .digit-wrapper {
                height: 30px;
                line-height: 30px;
            }

        }
    }

    @media screen and (orientation: landscape) and (min-width: 568px) {
        .jackpot-wrapper .jackpot-header-wrap .jackpot-header-inner .jackpot-main-value .jackpot-big-counter {
            bottom: 18px;


            .big-counter2 .radix,
            .big-counter2 .digit-wrapper .digit {
                font-size: 23px;
                line-height: 30px;
            }

            .big-counter2 .radix,
            .big-counter2 .digit-wrapper {
                height: 30px;
                line-height: 30px;
            }

        }
    }
}