.lobby-special-component-search {
  margin: 10px auto;
  padding: 0 10px;
  max-width: 1528px;
  width: 100%;

  .d-none {
    display: none !important;
  }

  .no-results {
    padding: 10px;

    &>div {
      color: #D60032;
      font-size: clamp(16px, 1.6vw, 36px);
      font-weight: normal;
      text-align: left;
      white-space: normal;
    }

    @media screen and (min-width: 1528px) {
      padding: 10px 0;
    }
  }

  .search-in {
    position: sticky;
    top: 0px;
    margin: 0 -10px;
    background: #fff;
    z-index: 100;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #EAEAEF;

    input {
      flex: 1 1 auto;
      background: #EAEAEF;
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid transparent;
      font-size: 16px;
      color: #000;
      outline: none;

      @media screen and (min-width: 1528px) {
        font-size: 40px;
        padding: 10px 20px;
      }
    }

    input:focus {
      border: 1px solid transparent;
    }

    .cancel-text {
      display: none;
      color: #0043DE;
      font-size: 16px;
      font-weight: 600;
      white-space: nowrap;
      text-align: center;
      cursor: pointer;
      padding: 0 0 0 8px;
    }

    .cancel-text.popup {
      display: block;
    }

    &.drop-shadow {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, .2);
    }
  }

  .cgl-header {
    margin-top: 10px;
    font-size: 14px;
    color: #848486;
    font-weight: 600;
    text-align: left;
    white-space: normal;

    .number {
      font-size: 16px;
      color: #848486;
      font-weight: 600;
      text-align: left;
      white-space: normal;
    }
  }

  .cgl-list {
    margin: 10px 0;

    .cgl-grid {
      width: 100%;
      display: grid;
      overflow: visible;
      white-space: nowrap;
      pointer-events: all;
      padding-bottom: 5px;

      grid-column-gap: 5px;
      grid-row-gap: 15px;
      grid-template-columns: 1fr 1fr 1fr 1fr;

      .gtxt {
        margin-top: 5px;
        font-size: 10px;
        font-size: calc(10px + (15 - 10) * ((100vw - 360px) / (1528 - 360)));
        font-weight: bold;
        white-space: normal;
        text-align: center;
        color: #000;
      }

      .cgl-item {
        min-width: 69px;
        width: 100%;
        cursor: pointer;

        .outer {
          width: 100%;
          padding-top: 145.7%;
          position: relative;

          .inner {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            & * {
              width: 100%;
              height: 100%;
            }

            img {
              object-fit: cover;
            }
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          transition: transform .25s ease;
        }

        img:hover {
          position: relative;
          transform-origin: center;
          transform: translateZ(0) scale(1.05);
          z-index: 1;
        }

        &:last-child {
          margin-right: 0px;
        }
      }

      .cgl-item.promote-1x2 {
        grid-column: span 2/auto;

        .outer {
          padding-top: 70.9%;
        }
      }

      .cgl-item.promote-1x3 {
        grid-column: span 3/auto;

        .outer {
          padding-top: 46.9%;
        }
      }

      .cgl-item.promote-2x2 {
        grid-column: span 2/auto;
        grid-row: span 2/auto;
      }

      .cgl-item.promote-2x3 {
        grid-column: span 3/auto;
        grid-row: span 2/auto;

        .outer {
          padding-top: 94.5%;
        }
      }

      .cgl-item.promote-3x3 {
        grid-column: span 3/auto;
        grid-row: span 3/auto;
      }

      @media screen and (min-width: 992px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }

      @media screen and (min-width: 1366px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      }
    }
  }

  &.popup {
    margin: 0;
    padding: 0;

    .search-in {
      margin: 0;
      padding: 10px;
      padding-top: calc(10px + var(--notch-top-offset, 0px));
    }

    .cgl-header {
      margin: 10px 10px 0;
    }

    .cgl-list {
      margin: 10px;
    }
  }

  .search-probe {
    width: 1px;
    height: 0px;
  }
}