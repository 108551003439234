.tournaments-missions-main-view {

  min-height: 100vh;
  box-sizing: border-box;
  background:
    linear-gradient(to bottom,
      #111326 0%,
      #252C70 25px,
      #222967 90px,
      #16192C 188px,
      #0A1520 200px,
    );
  background-position: 0 51px;
  padding-bottom: 10px;
  text-align: left;


  * {
    box-sizing: border-box;
  }

  .muted {
    opacity: .7;
  }

  .cnt-inner-wrapper {
    max-width: 400px;
    margin: 0 auto;
  }

  .header-wrapper {
    height: 52px;
    overflow: visible;

    background: linear-gradient(to right, #8B1C00, #FF3C01 25%, #FF9D0A 55%, #FFF71A);

    .header {
      height: 50px;
      overflow: visible;
      position: relative;

      display: flex;
      align-items: center;
      justify-content: space-between;

      background: linear-gradient(to right, #261D4D, #391E63, #422283, #362CA8, #293AC3);

      .logo {
        position: relative;

        transform: translate(0, 10px);

        .img {
          min-width: 110px;
          max-width: 110px;
          height: 87px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            height: 70px;
            width: 70px;
            //object-fit: contain;
          }

          .txt {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .subtitle {
              font-size: 9px;
              line-height: 9px;
              color: #DFE3E7;
              font-weight: bold;
            }

            .title {
              font-size: 15px;
              line-height: 15px;
              color: #DFE3E7;
              font-weight: bold;
              text-shadow: 1px 2px rgb(0, 0, 0);
            }
          }
        }

        .timer {
          position: absolute;
          bottom: 10px;
          left: 0;
          color: #FF5B00;
          font-size: 12px;
          font-weight: bold;
          background: linear-gradient(to right, #13181E00, rgba(19, 24, 30, .88) 20%, rgba(19, 24, 30, .88) 80%, #13181E00);
          width: 110px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 19px;
          white-space: nowrap;

          .muted {
            color: #8D441B;
          }

          .days {
            color: #FF975D;
          }
        }
      }

      .center {
        flex: 1 1 auto;

        .title {
          font-size: 20px;
          color: #fff;
          font-weight: 600;
          text-align: left;
        }

        .subtitle {
          font-size: 12px;
          color: rgba(255, 255, 255, .62);
          font-weight: normal;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: calc(100vw - 160px);
          text-align: left;
        }
      }

      .close {
        color: #fff;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }

  .tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin: 33px 19px 5px;

    .tab {
      color: rgba(255, 255, 255, .5);
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;
      margin: 0 10px;
      cursor: pointer;
      position: relative;

      .claim-indicator {
        position: absolute;
        top: 2px;
        right: -17px;
        height: 11px;
        width: 11px;
        border-radius: 50%;
        border: 1px solid #fff;
        background: #ff005f;

        display: none;
        align-items: center;
        justify-content: center;

        font-size: 7px;
        color: #fff;
        font-weight: 600;
      }

      &.tab.has-claim .claim-indicator {
        display: flex;
      }
    }

    .tab:first-child {
      margin-left: 0;
    }

    .tab:last-child {
      margin-right: 0;
    }

    .tab.selected,
    .tab:hover {
      color: #fff;
    }
  }

  .content {
    display: block;
    color: #fff;
    background: #213348;
    border-radius: 7px;
    margin: 6px 14px 0;
    padding: 10px;
    min-height: 60vh;

    &.no-padding {
      padding: 0;
      min-height: auto;
    }

    &.calendar {
      min-height: auto;
      padding-bottom: 15px;
      padding-left: 0;
    }

    .date-range {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      height: 21px;

      background: linear-gradient(to right, #A51040, #002495);
      border-radius: 4px;
      padding: 5px;
      margin-bottom: 7px;

      .icon {
        margin: 0 5px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .txt {
        font-size: 10px;
        line-height: 11px;
        color: #fff;
        font-weight: 600;
      }
    }

    .mt10px {
      margin-top: 10px;
    }

    .widget-wrapper {
      width: 100%;
      background: linear-gradient(to bottom, #34476a, #2b3b5b);
      border-radius: 17px;
      padding: 2px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
      margin-bottom: 10px;

      .widget {
        width: 100%;
        display: flex;
        flex-direction: column;
        background: linear-gradient(135deg, #213348, #142232 20%, #142232);
        min-height: 255px;
        border-radius: 17px;
        padding: 12px 16px 10px;

        .prize-info-images {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;

          .prize-image {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 100%;
              display: block;
            }

            &.left {
              width: 80px;
              height: 80px;

              img {
                width: 80px;
                height: 80px;
              }
            }

            &.center {
              width: 110px;
              height: 110px;

              img {
                width: 110px;
                height: 110px;
              }

              .txt .title {
                font-size: 28px;
                line-height: 28px;
                position: relative;
              }
            }

            &.right {
              width: 80px;
              height: 80px;

              img {
                width: 80px;
                height: 80px;
              }
            }

            .txt {
              position: absolute;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              .subtitle {
                font-size: 9px;
                line-height: 9px;
                color: #DFE3E7;
                font-weight: bold;
              }

              .title {
                font-size: 15px;
                line-height: 15px;
                color: #DFE3E7;
                font-weight: bold;
                text-shadow: 1px 2px rgb(0, 0, 0);
              }
            }
          }
        }

        .prize-info-texts {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: -10px;
          z-index: 1;

          .prize-info {
            font-size: 12px;
            color: #b9bdc2;
            font-weight: bold;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;

            display: flex;

            &.left {
              width: 70px;
            }

            &.center {
              width: 100px;
            }

            &.right {
              width: 70px;
            }

            .qm {
              margin-right: 5px;
              border-radius: 50%;
              height: 15px;
              min-width: 15px;
              max-width: 15px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 10px;
              font-weight: bold;
              color: #B9BDC2;
              border: 1px solid rgba(249, 249, 249, .23);
            }
          }
        }

        .progress-timer-wrapper {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-bottom: 18px;



          .counter-texts {
            margin: 30px 0 20px;

            .counter-header {
              font-size: 12px;
              font-weight: normal;
              color: #898989;
              text-align: center;
            }

            .timers {
              display: flex;
              align-items: center;
              justify-content: center;

              .timer {
                min-width: 40px;
                max-width: 40px;
                font-size: 24px;
                font-weight: bold;
                color: #fff;
                text-align: center;
              }
            }

            .timers-labels {
              display: flex;
              align-items: center;
              justify-content: center;

              .timer {
                min-width: 40px;
                max-width: 40px;
                font-size: 11px;
                font-weight: normal;
                color: #fff;
                text-align: center;
              }
            }

            .not-started {
              font-size: 20px;
              font-weight: 600;
              color: #fff;
              margin-bottom: 10px;
              text-align: center;
            }
          }

          .progress-wrapper {
            max-width: 200px;
            min-width: 200px;
            height: 5px;
            background: rgba(255, 255, 255, .07);
            border-radius: 3px;
            margin-bottom: 2px;

            .progress-br {
              height: 5px;
              background: linear-gradient(to right, #F98D00, #FFCA1E);
              border-radius: 3px;
            }
          }

          .mb25px {
            margin-bottom: 25px;
          }

          .mb30px {
            margin-bottom: 30px;
          }

          .progress-text {
            font-size: 12px;
            color: #FF5B00;
            font-weight: bold;
            text-align: center;

            .muted {
              color: #8D441B;
            }

            .days {
              color: #FF975D;
            }
          }
        }

        .buttons {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .tm-btn {
            margin: 0 12px;
            height: 50px;
            background: linear-gradient(to right, #919CB2 0%, #424F65 100%);
            padding: 2px;
            border-radius: 5px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            &.muted {
              opacity: .7;
              pointer-events: none;
              filter: grayscale(.8);
            }

            .inner {
              height: 46px;
              background: linear-gradient(to right, #0A1520 0%, #213348 100%);
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              white-space: nowrap;
              width: 100%;
            }
          }

          .small {
            min-width: 50px;
            max-width: 50px;
            margin: 0;
          }

          .tm-btn.larger {
            flex: 1 1 auto;
            background: linear-gradient(to right, #FFD457 0%, #FF6800 100%);

            .inner {
              background: linear-gradient(to right, #0A1520 0%, #213348 100%);
              font-size: 18px;
              font-weight: bold;
              color: #fff;
              text-transform: uppercase;
              padding: 0 30px;
              white-space: nowrap;
              width: 100%;

              img {
                display: block;
                margin-right: 10px;
              }
            }
          }

          &.calendar {
            justify-content: center;
          }
        }
      }

      &.info {
        border-radius: 7px;
        padding: 1px;
        box-shadow: none;

        .widget {
          border-radius: 7px;
          background: linear-gradient(135deg, #213348, #142232 20%, #142232);
          display: flex;
          flex-direction: column;
          min-height: 100px;

          .header-text {
            font-size: 12px;
            color: #DFE3E7;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 4px;
            margin-bottom: 6px;
          }

          .description {
            font-size: 12px;
            color: #8D92A0;
            font-weight: normal;
            margin-bottom: 5px;
          }

          .icons {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0 15 10px;

            img {
              display: block;
              width: 100%;
              max-height: 56px;
              object-fit: contain;
            }
          }
        }
      }
    }

    .hr-line {
      border-bottom: 2px solid #38485b;
      margin: 20px -10px 30px;
      height: 0;
      overflow: visible;
      display: flex;
      align-items: center;
      justify-content: center;

      &.winner-logo {
        margin-top: 30px;
      }
    }

    .header-line {
      font-size: 10px;
      color: #fff;
      font-weight: normal;
      opacity: .59;
    }

    .recommended-line {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 15px;

      .recommended {
        aspect-ratio: 103/187;
        background: linear-gradient(to bottom, rgba(0, 0, 0, .0), rgba(0, 0, 0, .52) 75%, rgba(0, 0, 0, .57));
        border-radius: 11px;
        margin: 0 5px;
        cursor: pointer;

        &:first-child {
          margin-left: 0px;
        }

        &:last-child {
          margin-right: 0px;
        }

        .img {
          position: relative;
          top: -8px;
          margin: -2px;


          transition: transform .25s ease;


          &:hover {
            position: relative;
            transform-origin: center;
            transform: translateZ(0) scale(1.05);
            z-index: 1;
          }

          img {
            width: 100%;
          }

          .free-bet-sticker {
            position: absolute;
            top: 10px;
            left: 0px;
            right: 0px;
            height: 17px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFFFFF;
            font-size: 10px;
            font-weight: bold;
            border: 1px solid #FFC6C6;
            background: linear-gradient(to right, #FF4E00, #CE0031 80%, #80001F);
            border-radius: 3px;
            padding-bottom: 2px;
          }
        }

        .rank {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          top: -3px;

          img {
            display: block;
            margin: 0 1px;
            height: 20px;
            width: auto;
          }
        }
      }
    }

    .tm-lister {
      width: 100%;
      display: grid;
      overflow: visible;
      white-space: nowrap;
      pointer-events: all;
      padding: 0;

      grid-column-gap: 5px;
      grid-row-gap: 10px;
      grid-template-columns: 1fr 1fr 1fr;

      .tm-lister-item {
        //min-width: 104px;
        width: 100%;
        margin-right: 2px;
        cursor: pointer;

        &.empty {
          max-width: 104px;
        }

        .outer {
          width: 100%;
          padding-top: 146%;
          position: relative;

          .inner {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }

        .inner>div {
          transition: transform .25s ease;
        }

        .inner>div:hover {
          position: relative;
          transform-origin: center;
          transform: translateZ(0) scale(1.05);
          z-index: 1;
        }

        img {
          width: 100%;
          object-fit: contain;
        }

        .free-bet-sticker {
          position: absolute;
          top: 10px;
          left: 2px;
          right: 2px;
          height: 17px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #FFFFFF;
          font-size: 10px;
          font-weight: bold;
          border: 1px solid #FFC6C6;
          background: linear-gradient(to right, #FF4E00, #CE0031 80%, #80001F);
          border-radius: 3px;
          padding-bottom: 2px;
        }

        &:last-child {
          margin-right: 0px;
        }
      }

      .tm-lister-item.promote-1x2 {
        grid-column: span 2/auto;

        .outer {
          padding-top: 70.9%;
        }
      }

      .tm-lister-item.promote-1x3 {
        grid-column: span 3/auto;

        .outer {
          padding-top: 46.9%;
        }
      }

      .tm-lister-item.promote-2x2 {
        grid-column: span 2/auto;
        grid-row: span 2/auto;
      }

      .tm-lister-item.promote-2x3 {
        grid-column: span 3/auto;
        grid-row: span 2/auto;

        .outer {
          padding-top: 94.5%;
        }
      }

      .tm-lister-item.promote-3x3 {
        grid-column: span 3/auto;
        grid-row: span 3/auto;
      }

      @media screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

    }

    .tm-ranking {
      border-radius: 7px;
      background: #213348;

      .empty {
        font-size: 12px;
        text-align: center;
        padding: 10px 0;
        font-weight: normal;
        color: #ffffffaa;
      }

      .tm-row {
        display: flex;
        align-items: center;
        justify-content: stretch;
        /*cursor: pointer;*/

        padding: 0 10px;

        width: 100%;
        height: 48px;
        background: #213348;
        border-bottom: 1px solid #304154;

        &.tm-row-0 {
          background: #4e5c6d;
        }

        &.tm-row-1 {
          background: #404f61;
        }

        &.tm-row-2 {
          background: #2c3d51;
        }

        &>div {
          flex: 1 1 auto;
        }

        .tm-header {
          color: #979DAB !important;
          font-size: 9px !important;
          font-weight: bold !important;
          padding: 0 2px;
        }

        .tm-col.tm-left {
          text-align: left;
        }

        .tm-col.tm-right {
          text-align: right;
        }

        .tm-col.tm-center {
          text-align: center;
        }

        .tm-col1 {
          width: 25px;
          min-width: 25px;
          max-width: 25px;

          &>div {
            font-size: 10px;
            font-weight: 600;
            color: #8D92A0;
            text-align: center;
            border-radius: 50%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 1px;
          }

          .tm-gold {
            background: linear-gradient(to right, #FF971D, #FFB400);
            border: 1px solid #FCC735;
            width: 17px;
            height: 17px;
            color: #FFF;
          }

          .tm-silver {
            background: linear-gradient(to right, #B5C9DC, #9AC7F2);
            border: 1px solid #81A0BE;
            width: 17px;
            height: 17px;
            color: #FFF;
          }

          .tm-bronze {
            background: linear-gradient(to right, #EB7120, #C65F05);
            border: 1px solid #F6A169;
            width: 17px;
            height: 17px;
            color: #FFF;
          }

          .tm-regular {
            border: 1px solid rgba(255, 255, 255, 0);
            height: 16px;
          }
        }

        .tm-col2 {
          width: 40px;
          min-width: 40px;
          max-width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            display: block;
            width: 27px;
            height: 27px;
            transform: translateZ(0);
          }
        }

        .tm-col3 {
          padding: 0 10px;
          color: #C2C4C8;
          font-size: 12px;
          font-weight: 600;
          text-align: left;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .tm-col4 {
          width: 50px;
          min-width: 50px;
          max-width: 50px;
          color: rgba(255, 255, 255, .26);
          font-size: 10px;
          font-weight: bold;
        }

        .tm-col5 {
          width: 80px;
          min-width: 80px;
          max-width: 80px;
          color: #BEC0C5;
          font-size: 10px;
          font-weight: 600;
        }

        &.tm-show-more {
          display: flex;
          align-items: center;
          justify-content: center;
          height: auto;
          cursor: pointer;

          .tm-col-full {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px 10px;
            color: #17214D;
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            img {
              width: auto;
              height: 25px;
              margin-right: 10px;
            }
          }
        }
      }

      .tm-row.tm-header {
        height: 29px;
      }

      .tm-row.tm-header {
        border-radius: 7px 7px 0 0;
      }

      .tm-row:last-child {
        border-radius: 0 0 7px 7px;
      }

      .tm-row.tm-active {
        background: linear-gradient(to right, #FF7700, #FFC400);
        padding: 0 5px 0 10px;
        border-bottom: 1px solid transparent;

        position: sticky;
        bottom: 0;
        top: 0;
        z-index: 1;

        .tm-col1>div {
          color: #fff;
        }

        .tm-col2 {
          width: 35px;
          min-width: 35px;
          max-width: 35px;

          img {
            display: block;
            width: 35px;
            height: 35px;
            transform: translateZ(0);
          }
        }

        .tm-col3 {
          font-size: 14px;
          color: #fff;
        }

        .tm-col4 {
          width: auto;
          min-width: unset;
          max-width: unset;
          font-size: 16px;
          color: #fff;
          font-weight: bold;
          white-space: nowrap;
          padding-right: 5px;
        }

        .tm-col5 {
          width: auto;
          min-width: unset;
          max-width: unset;
          border-radius: 11px;
          background: #fff;
          color: #17214D;
          font-size: 12px;
          text-align: center;
          height: 22px;
          line-height: 22px;
        }

        .tm-edit-nickname {
          white-space: nowrap;
          display: flex;
          align-items: center;
          cursor: pointer;

          .tm-edit-icon {
            border-radius: 50%;
            width: 22px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            display: inline-block;
            margin-left: 5px;

            svg {
              display: block;
              width: 18px;
              height: 18px;
              position: relative;
              top: 1px;
            }
          }
        }
      }
    }

    .timeline {
      padding-left: 30px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 14px;
        width: 2px;
        top: 18px;
        bottom: 0px;
        background: #3069AC;
      }

      &>div {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          left: -19px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          top: 9px;
          background: #F6A700;
          z-index: 1;
        }

        &>div.tournament-wrapper::after {
          content: "";
          position: absolute;
          left: -16px;
          width: 2px;
          height: 35px;
          top: -4px;
          background: linear-gradient(to bottom,
              rgba(246, 123, 0, 0),
              rgba(246, 158, 0, .57),
              #F6A700,
              #F6A700,
              rgba(246, 158, 0, .57),
              rgba(246, 123, 0, 0));
          z-index: 1;
        }
      }

      .header {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          left: -19px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          top: 18px;
          background: #3069AC;
        }

        .title {
          font-size: 27px;
          font-weight: 600;
          color: #fff;
        }

        .subtitle {
          font-size: 12px;
          font-weight: normal;
          color: #A6ADB6;
        }
      }

      .note {
        position: relative;
        margin-top: 10px;

        &::after {
          content: "";
          position: absolute;
          left: -19px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          top: 8px;
          background: #3069AC;
        }

        .description {
          font-size: 10px;
          font-weight: 600;
          color: #959BA2;
          background: #142232;
          border-radius: 4px;
          padding: 4px 6px;
        }
      }

      .tournament {
        margin-top: 10px;

        .tournament-wrapper {
          .date-range-wrapper {
            position: relative;
            z-index: 1;
            display: inline-block;
            background: linear-gradient(to right, #F6A700, #661DB2);
            padding: 1px;
            border-radius: 8px;

            .date-range {
              border-radius: 8px;
              background: linear-gradient(135deg, #4511A7, #270862);
              margin: 0;
              height: 24px;
              padding: 0 12px 0 8px;
            }
          }

          .tournament-wrapper-info {
            position: relative;
            top: -15px;
            margin-bottom: -15px;
            display: flex;
            align-items: center;
            justify-content: center;

            margin-left: 40px;

            width: calc(100% - 40px);
            background: linear-gradient(to bottom, #34476a, #2b3b5b);
            border-radius: 17px;
            padding: 2px;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);

            .tournament-info {
              width: 100%;

              background: linear-gradient(135deg, #213348, #142232 20%, #142232);
              min-height: 110px;
              border-radius: 17px;
              padding: 7px 12px 7px;

              display: flex;
              flex-direction: column;
              align-items: flex-end;
              justify-content: flex-start;

              .starting-time {
                display: flex;
                align-items: center;
                color: #B0B0B0;
                font-size: 12px;
                font-weight: normal;

                .timer {
                  color: #FF5B00;

                  .days {
                    color: #FF975D;
                  }
                }

                .qm {
                  margin-left: 5px;
                  border-radius: 50%;
                  height: 15px;
                  width: 15px;
                  min-width: 15px;
                  max-width: 15px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 10px;
                  font-weight: bold;
                  color: #B9BDC2;
                  border: 1px solid rgba(249, 249, 249, .23);
                }
              }



              .objectives {
                width: calc(100% + 55px);
                display: flex;
                align-items: center;
                justify-content: space-between;

                &.mt0 {
                  margin-top: 0px;
                }

                .objective {
                  flex: 0 0 25%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: relative;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                  }

                  &.first {
                    padding: 0px;
                  }

                  &.first.bigger {
                    img {
                      width: 110%;
                      height: 110%;
                      object-fit: contain;
                    }
                  }

                  .txt {
                    position: absolute;
                    bottom: -6px;
                    font-size: 10px;
                    font-weight: bold;
                    color: #fff;
                    text-transform: uppercase;
                  }

                  .txt-award {
                    position: absolute;

                    .subtitle {
                      text-align: center;
                      font-size: 9px;
                      line-height: 9px;
                      font-weight: bold;
                      color: #fff;
                    }

                    .title {
                      text-align: center;
                      font-size: 15px;
                      line-height: 15px;
                      font-weight: bold;
                      color: #fff;
                    }
                  }

                  &.stats {
                    flex-direction: column;
                    background: radial-gradient(closest-side, rgba(72, 231, 255, .5), rgba(0, 14, 29, 0) 70%);

                    img {
                      width: 25px;
                      height: auto;
                    }

                    .first-text {
                      font-size: 11px;
                      line-height: 11px;
                      font-weight: bold;
                      color: #4BE7FF;
                      white-space: nowrap;
                    }

                    .second-text {
                      font-size: 9px;
                      line-height: 9px;
                      font-weight: normal;
                      color: #82EEFF;
                      white-space: nowrap;
                    }
                  }
                }

                .objective-full {
                  flex: 0 0 75%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: relative;
                  padding-left: 15px;

                  .summaries {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;

                    .summary {
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;

                      .award {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;

                        &.no-award {
                          filter: grayscale(1);
                          opacity: .5;
                        }

                        img {
                          width: 70px;
                          height: 70px;
                          object-fit: contain;
                        }

                        .txt {
                          position: absolute;
                          font-size: 15px;
                          line-height: 15px;
                          color: #DFE3E7;
                          font-weight: bold;
                          text-shadow: 1px 2px rgb(0, 0, 0);
                        }
                      }

                      .award-txt {
                        font-size: 10px;
                        color: rgba(255, 255, 255, .7);
                        font-weight: bold;
                        text-transform: uppercase;
                        margin-top: -10px;
                      }

                      .podium {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 10px;
                        width: 100%;

                        &.no-award {
                          opacity: 0;
                        }

                        .podium-text {
                          display: flex;
                          align-items: flex-end;
                          justify-content: center;
                          margin-bottom: 5px;

                          img {
                            width: 16px;
                            height: 16px;
                            object-fit: contain;
                            display: block;
                            margin-right: 5px;
                          }

                          color: #F6A700;
                          font-size: 12px;
                          font-weight: 600;
                          line-height: 10px;
                        }

                        .podium-bar {
                          width: calc(100% - 30px);
                          height: 2px;
                          border-radius: 90px;
                          background: linear-gradient(to right,
                              #f67b0000,
                              rgba(246, 158, 0, .569) 34.3%,
                              #f6a700 39.1%,
                              #f6a700 62.1%,
                              rgba(246, 158, 0, .569) 67%,
                              #f67b0000);
                        }
                      }

                      .tm-btn {
                        margin: 0 12px;
                        height: 39px;
                        background: linear-gradient(to right, #919CB2 0%, #424F65 100%);
                        padding: 2px;
                        border-radius: 5px;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .inner {
                          width: 100%;
                          height: 35px;
                          background: linear-gradient(to right, #0A1520 0%, #213348 100%);
                          border-radius: 5px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          white-space: nowrap;
                          position: relative;

                          .has-claim-available {
                            position: absolute;
                            top: -8px;
                            right: -8px;
                            height: 15px;
                            width: 15px;
                            border-radius: 50%;
                            border: 1px solid #fff;
                            background: #ff005f;

                            display: flex;
                            align-items: center;
                            justify-content: center;

                            font-size: 9px;
                            color: #fff;
                            font-weight: 600;
                          }
                        }
                      }

                      .tm-btn.larger {
                        background: linear-gradient(to right, #FFD457 0%, #FF6800 100%);
                        width: calc(100% - 10px);
                        margin: 0 5px;

                        .inner {
                          background: linear-gradient(to right, #0A1520 0%, #213348 100%);
                          font-size: 13px;
                          font-weight: bold;
                          color: #fff;
                          text-transform: uppercase;
                          *padding: 0 30px;
                          width: 100%;

                          img {
                            display: block;
                            margin-right: 10px;
                          }
                        }
                      }
                    }

                    .summary:last-child {
                      flex: 1 1 auto;
                    }
                  }
                }
              }

              .objectives-texts {
                width: calc(100% + 55px);
                display: flex;
                align-items: center;
                justify-content: space-between;

                margin-top: -8px;
                margin-bottom: 0px;


                .txt {
                  width: 80px;
                  flex: 1 1 25%;
                  text-align: center;
                  font-size: 10px;
                  font-weight: bold;
                  color: rgba(255, 255, 255, .7);
                  text-transform: uppercase;
                }
              }
            }
          }
        }
      }

      .tournament.history {
        .tournament-wrapper .tournament-wrapper-info {
          top: -15px;
          margin-bottom: 0;

          .tournament-info {
            padding-top: 20px;
          }
        }
      }
    }
  }

  .info-panel {
    background: rgba(33, 51, 72, .3);
    border-radius: 11px;
    padding: 16px;
    margin: 20px -10px 10px;

    .info-panel-content {
      position: relative;
      font-size: 12px;

      &::after {
        content: " ";
        position: absolute;
        top: -46px;
        left: 50%;
        margin-left: -15px;
        border-width: 15px;
        border-style: solid;
        border-color: transparent transparent rgba(33, 51, 72, .3) transparent;
      }

      &.left::after {
        left: 10%;
      }

      &.right::after {
        left: 90%;
      }

      ol {
        list-style: decimal;
        padding-inline-start: 2em;
      }

      ul {
        list-style: circle;
        padding-inline-start: 2em;
      }

      strong {
        font-weight: 600;
      }

      sup {
        vertical-align: super;
        font-size: smaller;
      }

      sub {
        vertical-align: sub;
        font-size: smaller;
      }

      h1 {
        font-size: 2em;
        font-weight: bold;
      }

      h2 {
        font-size: 1.5em;
        font-weight: bold;
      }

      h3 {
        font-size: 1.17em;
        font-weight: bold;
      }

      h4 {
        font-size: 1em;
        font-weight: bold;
      }

      h5 {
        font-size: 0.83em;
        font-weight: bold;
      }

      h6 {
        font-size: 0.67em;
        font-weight: bold;
      }

      .ql-align-center {
        text-align: center;
      }

      .ql-align-right {
        text-align: right;
      }

      .ql-align-left {
        text-align: left;
      }

      .ql-align-justify {
        text-align: justify;
      }

      .ql-indent-1 {
        text-indent: 1em;
      }

      .ql-indent-2 {
        text-indent: 2em;
      }

      .ql-indent-3 {
        text-indent: 3em;
      }

      .ql-indent-4 {
        text-indent: 4em;
      }

      .ql-indent-5 {
        text-indent: 5em;
      }
    }
  }

  &.sport-tournament {
    background-color: #0A1520;
    background-image: url("../assets/sport-bg@3x.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center 52px;

    .tabs {
      padding-bottom: 2px;
      border-bottom: 2px solid rgba(255, 255, 255, .35);

      .tab.selected {
        position: relative;
        overflow: visible;
      }

      .tab.selected::after {
        position: absolute;
        content: "";
        border-bottom: 2px solid #c2bed3;
        left: 0;
        right: 0;
        bottom: -4px;
      }
    }

    .hr-line {
      border-bottom: 1px solid rgba(255, 255, 255, .1);
      flex-direction: column;
      margin-top: -5px;
      margin-bottom: 50px;

      &>div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #0a1520;
        padding: 0 20px;
        position: relative;
        top: 15px;

        div {
          color: rgba(255, 255, 255, .59);
          font-size: 10px;
          text-align: center;
          text-transform: uppercase;
        }

        svg {
          rect {
            fill: #0a1520;
          }
        }
      }
    }

    .content {
      background: transparent;
      margin: 0 5px;

      .widget-wrapper {
        background: transparent;

        .widget {
          background: transparent;
        }
      }

      .widget-wrapper.info {
        .widget {
          min-height: auto;
        }
      }
    }

    .tm-ranking {
      margin: 20px 10px 0;
    }

    .filter-elements {
      .filter-element-wrapper {
        background: linear-gradient(to right, #919CB2, #424F65);
        padding: 2px;
        height: 45px;
        border-radius: 4px;
        margin-bottom: 5px;

        .filter-element {
          background: linear-gradient(to right, #0A1520, #213348);
          height: 41px;
          border-radius: 4px;

          text-decoration: none;
          white-space: nowrap;

          display: flex;
          align-items: center;
          justify-content: space-between;

          padding: 0 10px;

          .icon {
            margin-right: 5px;

            &>div {
              border-radius: 6px;
              background: #FF196E;
              width: 34px;
              height: 18px;
              display: flex;
              align-items: center;
              justify-content: center;
              text-transform: uppercase;
              font-style: italic;
              color: #fff;
              font-size: 8px;
              font-weight: 600;
            }
          }

          .name {
            flex: 1 1 100%;
            text-align: left;
            color: #E7E7E9;
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
          }

          .arrow {
            color: #E7E7E9;
          }
        }
      }
    }
  }
}

body.in-tournaments-missions {
  background: #000;

  .App {
    background: #000;
  }
}

.cordova-app {
  .tournaments-missions-main-view {
    .header-wrapper {
      height: calc(52px + var(--notch-top-offset, 0px)) !important;

      .header {
        padding-top: calc(0px + var(--notch-top-offset, 0px)) !important;
        height: calc(50px + var(--notch-top-offset, 0px)) !important;
      }
    }

    padding-bottom: 90px;
  }
}

.rich-text {
  text-align: left;

  ol {
    list-style: decimal;
    padding-inline-start: 2em;
  }

  ul {
    list-style: disc;
    padding-inline-start: 2em;
  }

  li {
    display: list-item;
    line-height: normal;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: bold;
  }

  sup {
    vertical-align: super;
    font-size: smaller;
  }

  sub {
    vertical-align: sub;
    font-size: smaller;
  }

  h1 {
    font-size: 2em;
    font-weight: bold;
  }

  h2 {
    font-size: 1.5em;
    font-weight: bold;
  }

  h3 {
    font-size: 1.17em;
    font-weight: bold;
  }

  h4 {
    font-size: 1em;
    font-weight: bold;
  }

  h5 {
    font-size: 0.83em;
    font-weight: bold;
  }

  h6 {
    font-size: 0.67em;
    font-weight: bold;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-right {
    text-align: right;
  }

  .ql-align-left {
    text-align: left;
  }

  .ql-align-justify {
    text-align: justify;
  }

  .ql-indent-1 {
    text-indent: 1em;
  }

  .ql-indent-2 {
    text-indent: 2em;
  }

  .ql-indent-3 {
    text-indent: 3em;
  }

  .ql-indent-4 {
    text-indent: 4em;
  }

  .ql-indent-5 {
    text-indent: 5em;
  }
}