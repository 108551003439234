.maintenance-modal {
  z-index: 1301 !important;

  * {
    box-sizing: border-box;
  }

  & .MuiPaper-rounded {
    border-radius: 15px;
    margin: 16px;
    max-height: calc(100% - 32px);
    width: calc(100% - 32px);
  }

  .mm-content {
    padding: 20px 0 40px;

    .mm-provider-image {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 25px 5px;

      img {
        width: 85px;
        height: auto;
        display: block;
        border-radius: 5px;
      }
    }

    .mm-title {
      color: #285C98;
      font-size: 22px;
      text-align: center;
      font-weight: 600;
      margin: 0 25px 20px;
    }

    .mm-sep {
      border-top: 1px solid #707070;
      opacity: .2;
      margin: 20px;
    }

    .mm-name {
      margin-top: -10px;
      color: #FF196E;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
    }

    .mm-slot-image {
      background: url(./assets/game-bg.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 20px;

      .mm-outer {
        border-radius: 9px;
        background: linear-gradient(180deg, #F1C504 0%, #EABF06 55%, #E9B300 100%);
        padding: 3px;

        .mm-middle {
          border-radius: 9px;
          background: #080405;
          padding: 2px;

          .mm-inner {
            border-radius: 9px;
            background: linear-gradient(180deg, #F1C504 0%, #DAB200 100%);
            padding: 2px;

            img {
              border-radius: 9px;
              width: 100px;
              height: auto;
              display: block;
            }
          }
        }
      }
    }

    .mm-question {
      border-top: 1px solid rgba(112, 112, 112, .22);
      padding: 5px 0 0;
      font-size: 14px;
      font-weight: bold;
      color: #FF196E;
      margin: 0 25px 20px;
      text-align: center;
    }

    .mm-paragraph {
      margin: 20px 25px 30px;
      color: #555B72;
      font-size: 12px;
      font-weight: normal;
      text-align: left;
    }

    .mm-button {
      background: linear-gradient(to right, #295A91, #2174E0);
      color: #fff;
      //width: 100%;
      padding: 20px 0;
      font-size: 15px;
      font-weight: bold;
      border-radius: 3px;
      text-align: center;
      margin: 0 25px 0px;
      text-transform: uppercase;
      cursor: pointer;
    }

    .mm-link {
      color: #737B83;
      font-size: 12px;
      font-weight: normal;
      text-align: center;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}