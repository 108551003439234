.bet-filter-wrapper {
  //overflow-x: auto;
  margin-top: -10px;
  padding-bottom: 10px;


  .bet-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 5px;
    padding-top: 5px;

    box-shadow: 0 0.125rem 0.25rem 0.125rem rgba(0, 0, 0, .12);

    .bet-group {
      color: #17214D;
      cursor: pointer;
      height: 26px;
      margin: 3px;
      padding: 0 12px;
      font-size: 11px;
      font-weight: 600;

      padding-top: 3px;
      white-space: nowrap;
      border-radius: 10px;
      background-color: #fff;
      border: 2px solid #fff;
    }

    .bet-group2 {
      //display: flex;
      flex-grow: 1;
      width: 0;
      max-width: 200px;
      justify-content: center;
      align-items: unset;
      color: #000;
      cursor: pointer;
      height: 40px;
      line-height: 3.2;
      color: #000;
      margin-right: .0625rem;
      font-weight: 400;
      box-shadow: inset 0 -0.125rem 0 0 transparent;
      font-size: 14px;
      text-transform: capitalize;

      .more-svg {
        vertical-align: middle;
        position: relative;
        top: 10px;
        right: 5px;
      }

      span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding: 0 1rem;
      }
    }

    @media (hover: hover) and (pointer: fine) {
      .bet-group:hover {
        background: hsla(0, 0%, 100%, .15);
      }
    }


    &.live .bet-group {
      background-color: var(--ns-bets-odds-bg-color);
      color: #fff;
      border-color: #404040;
    }

    &.live .bet-group.active {
      color: #ff6300;
    }

    .bet-group.active {
      border: 2px solid #ff6300;
      background-color: #fff;
      color: #ff6300;
      font-weight: 600;
    }

    &.live .bet-group.active {
      background-color: var(--ns-bets-odds-bg-color);
    }

  }
}

@media (max-width: 847px) {
  .bet-filter-wrapper {
    .bet-filter {
      .bet-group {
        flex: 0 0 auto;
        width: auto;
      }
    }
  }
}