.component-claim_bonus {
  margin: 10px auto;
  padding-bottom: 5px;
  max-width: 1528px;

  .cgl-super-header {
    margin: 0 10px;

    .category {
      color: #353540;
      font-size: clamp(10px, 1vw, 12px);
      font-weight: bold;
      text-align: left;
    }
  }

  .cgl-header {
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      color: #353540;
      font-size: 18px;
      font-size: clamp(18px, 1.8vw, 26px);
      font-weight: 600;
      text-align: left;
    }

    .link {
      color: #353540;
      font-size: 12px;
      font-size: clamp(12px, 1.2vw, 17px);
      font-weight: 600;
      text-align: left;
      cursor: pointer;
      border: 1px solid #BEC9D6;
      border-radius: 11px;
      padding: 2px 12px 3px;

      span {
        padding: 0 5px;
      }

      svg {
        position: relative;
        top: -1px;
      }
    }
  }

  .cgl-list {
    overflow-y: hidden;
    overflow-x: auto;
    margin-top: 10px;

    .cgl-grid {
      width: 100%;
      display: grid;
      overflow: visible;
      white-space: nowrap;
      pointer-events: all;
      padding-bottom: 10px;

      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      &.centered {
        justify-content: center;
      }

      &::before,
      &::after {
        content: '\00a0';
        min-width: 9px;
        max-width: 9px;
      }

      .cgl-item {
        min-width: 320px;
        max-width: 489.33px;
        width: 100%;
        margin-right: 21px;
        cursor: pointer;
        border-radius: 7px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .5);

        &.empty {
          max-width: 186px;
        }

        .outer {
          width: 100%;
          padding-top: 40%;
          position: relative;

          .inner {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 7px;
        }

        .cgl-text-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          .cgl-footer-wrapper {
            position: absolute;
            bottom: 14px;
            left: 10px;
            right: 12px;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            .cgl-btn {
              cursor: pointer;
              font-size: 15px;
              font-weight: 600;
              padding: 8px 26px;
              color: #fff;
              border-radius: 7px;
              border: 2px solid #fff;
              background: #2E3192;
            }
          }

        }


        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}