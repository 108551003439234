body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat";
  min-width: 1280px;
}

/*---------------------------------------------*/
input,
input:required
{
    outline: none;
    border: none;
    box-shadow: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus, input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
input:focus::-moz-placeholder { color:transparent; }
input:focus:-ms-input-placeholder { color:transparent; }

textarea:focus::-webkit-input-placeholder { color:transparent; }
textarea:focus:-moz-placeholder { color:transparent; }
textarea:focus::-moz-placeholder { color:transparent; }
textarea:focus:-ms-input-placeholder { color:transparent; }

input::-webkit-input-placeholder { color: #555555;}
input:-moz-placeholder { color: #555555;}
input::-moz-placeholder { color: #555555;}
input:-ms-input-placeholder { color: #555555;}

textarea::-webkit-input-placeholder { color: #555555;}
textarea:-moz-placeholder { color: #555555;}
textarea::-moz-placeholder { color: #555555;}
textarea:-ms-input-placeholder { color: #555555;}

label {
  margin: 0;
  display: block;
}

#menu-data
{
  display: none;
}

button:focus,
a:focus,
a:active,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: none !important;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

/*-------------------------*/

/*------------ START - INPUT ELEMENT WITH MATERIAL DESIGN LIKE -------------*/
.input-centered
{
    width: 100%;
}

.input-centered .input-group
{
    position: relative;
    width: 100%;
}

.input-centered .input-group input,
.input-centered .input-group input:focus,
.input-centered .input-group input:active,
.input-centered .input-group input:valid
{
    width: 100%;
    height: 30px;
    line-height: 30px;
    border-bottom: 2px solid #cfcfcf !important;
}

.input-centered .input-group label
{
    position: absolute;
    top: 0;
    transition: all 0.3s ease;
    font-size: 20px;
    color: #bdbdbd;
    line-height: 30px;
    pointer-events: none;
}

.input-centered .input-group input:focus~label,
.input-centered .input-group input:valid~label {
  top: -30px;
  color: #0071bc;
  font-size: 14px;
}

.input-centered .input-group .input-bar
{
    background-color: #cfcfcf;
    width: 100%;
    height: 2px;
    content: "";
    position: absolute;
    pointer-events: none;
    top: 28px;
    left: 0;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.input-centered .input-group input:focus~.input-bar/*,
.input-centered .input-group input:valid~.input-bar*/
{
    background-color: #0088de;
    width: 100%;
}
/*------------ END - INPUT ELEMENT WITH MATERIAL DESIGN LIKE -------------*/

.test-item
{
  padding: 5px 10px;
}


.account-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    box-sizing: border-box;
    margin-right: 20px;
    max-width: auto;
    width: auto;
}


.account-menu .account-button
{
  line-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.account-button .btn span.txt {
  font-size: 13px;
}

@media (min-width: 1020px) {
  #root {
    min-width: 1020px;
  }
}

@media (max-width: 770px) {
  #root {
    min-width: 770px;
  }
}
