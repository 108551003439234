
.MenuItem {
    overflow: hidden;
}

.MenuItem a {
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    /* width: 100%; */
    display: block;
    padding-top: 30px;
    height: 85px;
    line-height: 85px;
}

.MenuItem a:hover {
    text-decoration: none;
}

.MenuItem a span
{
    width: 100%;
    line-height: 23px;
    font-size: 16px;
}



.MenuItem a .IconSVG
{
    vertical-align: top!important;
    margin-right: 10px;
}
@media screen and (max-width: 1615px) {
    .MainMenu .topMenu .MenuItem {
        text-align: center;
        width: 115px;
        padding: 0px 8px;

    }

    .MainMenu .logoSupport {
        min-width: 145px;
        width: 145px;
        margin-right: 25px;
    }

    .MainMenu .topMenu .MenuItem a {
        padding-top: 15px;
    }
    .MenuItem a span {
        margin-top: -50px;
        font-size: 12px;
        line-height: 1;

    }
    .MenuItem a div.coming-soon {
        top: 52px;
        width: 100%;
        font-size: 8px;
        position: relative;
        left: 0;
        line-height: 1;
        height: auto;
        opacity: .3;
    }
    .MenuItem a span,.MenuItem a div {
        display: block;
    }
    .MenuItem a svg.IconSVG {
        width: 27px;
        height: 27px;
        margin: 0 auto;
    }
}
.MenuItem a .IconSVG.live_sports
{
    margin-top: -5px;
}

.MenuItem a .IconSVG.virtual_sports
{
    margin-top: -2px;
}

.MenuItem a .IconSVG.table_game
{
    height: 27px;
}
.MenuItem a .IconSVG.table_game.cls-1
{
    fill:#fff;
}

.MenuItem a .coming-soon
{
    font-size: 11px;
    font-weight: normal;
    font-style: italic;
    position: relative;
    left: 30px;
    /*top: -22px;*/
    top: -52px;
    height: 14px;
    line-height: 14px;
    display: block;
    text-align: left;
}

.MenuItem a .coming-soon span {
    line-height: 9px;
    text-transform: uppercase;
    border: 1px solid #322f0b;
    border-radius: 20px;
    padding: 2px 7px;
    color: #565109;
    font-size: 9px;
    font-weight: normal;
    font-style: italic;
    margin-top: -48px;
    text-align: center;
}

/*@media only screen and (max-width: 1280px) {*/
    /*.MenuItem a .item-text,*/
    /*.MenuItem a .coming-soon {*/
        /*display: none;*/
    /*}*/

    /*.MenuItem a {*/
        /*padding-left: 10px;*/
        /*padding-right: 10px;*/
    /*}*/

    /*.MenuItem a .IconSVG {*/
        /*margin-right: 0px;*/
    /*}*/
/*}*/
