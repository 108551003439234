$bets-bg-image: url(../../assets/bets/bg-prematch.png);
$bets-q-mark-bg:#133AA2;
$bets-q-mark-brd: #06D2BD;

.bets-prematch-win-panel-root {
  position: fixed;
  padding-top: 5px;
  left: 0 !important;
  top: 0 !important;
  right: 0 !important;
  z-index: 10001;
  padding-bottom: 40px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, .65) 0, rgba(0, 0, 0, 0.576) 75%, rgba(0, 0, 0, 0) 100%);

  @media screen and (min-width:1025px) {
    right: 400px !important;
    background-image: none;
  }

  @media screen and (min-width:1281px) {
    right: 90px !important;
  }

  .bets-prematch-win-panel {
    max-width: 360px;
    height: 91px;
    margin: 0 auto;
    background: $bets-bg-image;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, .4);
    border-radius: 0 0 8px 8px;

    @media screen and (min-width:1025px) {
      box-shadow: 0px 20px 30px rgba(0, 0, 0, .6);
    }

    .hh-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .header-tag-wrapper {
        width: 108px;
        position: relative;
        text-align: center;
        line-height: 22px;
        color: #fff;
        font-size: 10px;
        font-weight: 600;
        font-style: italic;
        height: 20px;
      }

      .title {
        height: 21px;
        min-width: 0;
        flex: 1 1 auto;
        margin: 0px 9px 0 0px;
        padding-top: 9px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        & div {
          min-width: 0;
          flex: 1 1 auto;
          color: #fff;
          font-size: 11px;
          font-weight: 600;
          padding-left: 11px;
          text-align: left;
          text-transform: uppercase;
        }

        .q-mark {
          min-width: 21px;
          max-width: 21px;
          width: 21px;
          height: 21px;
          border-radius: 50%;
          background-color: $bets-q-mark-bg;
          border: 1px solid $bets-q-mark-brd;
          color: #fff;
          font-size: 15px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          padding-left: 1px;
          margin-right: 17px;
          cursor: pointer;
        }
      }
    }

    .hh-prizes {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 6px;

      .hh-prize-current {
        margin: 0 10px;
        height: 51px;
        min-width: 300px;
        background-color: rgba(80, 18, 135, 0.48);
        border: 1px solid rgba(255, 199, 219, .48);
        border-radius: 31px;

        .hh-flx {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 4px;

          .hh-img {
            svg {
              width: 25px;
              height: auto;
              position: relative;
              left: -4px;
              vertical-align: middle;
            }

            img {
              width: 25px;
              height: auto;
              position: relative;
              left: -4px;
            }
          }

          .hh-txt {
            color: #FFC400;
            font-size: 22px;
            font-weight: bold;
          }
        }

        .hh-bet {
          color: #dcc9c9;
          text-align: center;
          font-size: 12px;
          font-weight: normal;
          line-height: 12px;

          .gold {
            color: #FFC100;
          }
        }
      }

      .hh-special-prize-front.hidden,
      .hh-prize-before.hidden,
      .hh-prize-after.hidden {
        opacity: 0;
      }

      .hh-special-prize-front {
        width: 73px;
        height: 73px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        overflow: visible;

        .hh-sp-img {
          display: block;
          max-width: 73px;
          height: auto;
          position: absolute;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          top: 0px;
        }

        .hh-sp-title-front {
          color: #F4C850;
          font-size: 11px;
          font-weight: bold;
          text-align: center;
        }
      }
    }
  }
}