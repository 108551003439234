.hh-root {
  height: 122px;
  background-image: url(../../assets/bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 7px;
  position: relative;
  overflow: hidden;

  .hh-front-new {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;

    .hh-sp-win-portrait,
    .hh-win-portrait {
      background-image: none;

      .hh-win-container {
        margin-top: 6px;
      }
    }
  }

  &.grey {
    filter: grayscale(1);
  }

  .hh-pills {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin-top: 9px;
    flex: 1 1 0px;

    .hh-pill {
      width: 12px;
      height: 8px;
      border: 1px solid transparent;
      opacity: 1;
      background: #5D0B0B;
      margin: 0 3px;
      border-radius: 4px;

      &.op-49 {
        opacity: 0.49;
      }

      &.op-21 {
        opacity: 0.21;
      }

      &.active {
        width: 16px;
        height: 10px;
        border: 1px solid #fff;
        background: #EE3416;
        margin: 0;
      }
    }
  }

  .hh-pills-text {
    line-height: 17px;
    text-align: center;

    .hh-current-prize {
      font-size: 13px;
      font-weight: bold;
      color: #fff;
      padding-left: 20px;
    }

    .hh-all-prizes {
      font-size: 10px;
      font-weight: bold;
      color: #FFC400;
    }
  }

  .hh-prizes {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin-top: 6px;

    .hh-prize-after,
    .hh-prize-before {
      height: 41px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #E3E7E9;
      font-size: 13px;
      font-weight: bold;
      min-width: 73px;
      background-color: rgba(80, 18, 135, 0.48);
      border: 1px solid #FFC7DB;
      border-radius: 31px;
    }

    .hh-prize-after {
      color: #FFD759;
    }

    .hh-prize-current {
      margin: 0 8px;
      height: 61px;
      min-width: 144px;
      background-color: rgba(80, 18, 135, 0.48);
      border: 1px solid #FFC7DB;
      border-radius: 31px;

      .hh-flx {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 8px;

        .hh-img {
          svg {
            width: 25px;
            height: auto;
            position: relative;
            left: -4px;
            vertical-align: middle;
          }

          img {
            width: 25px;
            height: auto;
            position: relative;
            left: -4px;
          }
        }

        .hh-txt {
          color: #FFC400;
          font-size: 22px;
          font-weight: bold;
        }
      }

      .hh-bet {
        color: #dcc9c9;
        text-align: center;
        font-size: 13px;
        font-weight: normal;
        line-height: 13px;
      }
    }



    .hh-special-prize {
      width: 73px;
      height: 62px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      overflow: visible;

      .hh-sp-img {
        display: block;
        max-width: 55px;
        height: auto;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top: 0px;
      }

      .hh-sp-title {
        color: #F4C850;
        font-size: 11px;
        font-weight: bold;
        text-align: center;
      }
    }

    .hh-special-prize.hidden,
    .hh-prize-before.hidden,
    .hh-prize-after.hidden {
      opacity: 0;
    }
  }

  .hh-footer,
  .hh-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 18px 24px 0 24px;
    position: relative;

    .hh-name {
      width: 60px;
      overflow: visible;
      font-size: 12px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
      text-align: left;
      line-height: 14px;
    }

    .hh-timer {
      display: flex;
      align-items: center;
      justify-content: center;

      .hh-hour,
      .hh-min,
      .hh-sec {
        .hh-top {
          font-size: 18px;
          font-weight: 600;
          color: #fff;
          text-align: center;
          line-height: 18px;
        }

        .hh-bottom {
          font-size: 8px;
          font-weight: normal;
          color: #fcaac9;
          text-align: center;
        }
      }

      .hh-min {
        margin: 0 5px;
      }

      .disabled {
        opacity: 0.4;
      }
    }

    &.nomrg {
      margin: 0;
      justify-content: center;

      .hh-name {
        position: absolute;
        top: 7px;
        left: 20px;
      }

      .hh-timer {
        position: absolute;
        top: 14px;
        right: 20px;
        width: auto !important;
      }
    }

    .hh-sp-left {
      flex: 1 1 0px;

      .hh-name {
        width: 100% !important;
      }

      .hh-timer {
        left: 20px;
        right: auto;
        top: 20px;
      }
    }

    .hh-sp-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1 1 0px;
      height: 0px;
      overflow: visible;
      padding-top: 10px;

      .hh-sp-title {
        opacity: 0.72;
        font-size: 12px;
        font-weight: bold;
        color: #fff;
        text-transform: uppercase;
        line-height: 14px;
        text-align: center;
      }

      .hh-sp-name {
        font-size: 11px;
        font-weight: 600;
        color: #FFD23F;
        text-align: center;
        line-height: 5px;
      }
    }
  }
}

.hh-portrait {
  height: 89px;
  border-radius: 0;
  border: 0;
  overflow: hidden;
  background-image: url(../../assets/bg-small2.png);
  position: relative;

  &.hh-special {
    background-image: none;
    background-color: #08121E;

    .hh-special-prize {
      display: flex;
      align-items: center;

      .hh-sp-image {
        position: absolute;
        top: 0;
        bottom: 18px;
        left: 0;
        right: 0;
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: center;

        &>img {
          height: auto;
          width: 346px;
          display: block;
        }
      }

      .hh-sp-title {
        width: 60px;
        overflow: visible;
        font-size: 12px;
        font-weight: bold;
        color: #fff;
        text-transform: uppercase;
        text-align: left;
        line-height: 14px;
        padding-bottom: 18px;
        margin-left: 35px;
        position: relative;
      }

      @media screen and (min-width: 1200px) {
        .hh-sp-title {
          width: 100px;
        }
      }

      .hh-sp-footer {
        height: 18px;
        background: linear-gradient(to right, #1b375e 0%, #1a4075 38.5%, #152843 72.2%, #03152e 100%);

        text-align: center;
        line-height: 18px;
        color: #F2C44E;
        font-size: 11px;
        font-weight: 600;

        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
      }

      .hh-sp-help {
        width: 21px;
        height: 21px;

        border: 2px solid #08121E;
        background: #3B6093;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        font-weight: bold;
        font-size: 15px;
        border-radius: 50%;
        cursor: pointer;

        position: absolute;
        left: 10px;
        bottom: 6px;
      }
    }
  }

  .w100 {
    width: 100% !important;
  }

  .hh-header {
    margin: 4px 0 8px;
    align-items: flex-start;
    width: 100%;
    position: relative;

    .hh-name {
      opacity: 0.72;
      max-width: 80px;
      position: absolute;
      top: 1px;
      left: 5px;
    }

    .hh-pills {
      margin: 0;

      .hh-pill {
        width: 12px;
        height: 8px;

        &.active {
          width: auto;
          height: 16px;
          line-height: 13px;
          white-space: nowrap;
          margin: 0 6px;
          padding: 0 3px;
          line-height: 13px;

          .hh-current-prize {
            font-size: 13px;
            font-weight: bold;
            color: #fff;
          }

          .hh-all-prizes {
            font-size: 10px;
            font-weight: bold;
            color: #debfcb;
          }
        }
      }
    }

    .hh-timer {
      position: absolute;
      top: 0px;
      right: 40px;
      width: auto !important;
    }

    .hh-sp-left {
      .hh-timer {
        left: 5px;
        right: auto;
        top: 13px;
        max-width: 80px;
        width: 80px !important;

        .hh-hour,
        .hh-min,
        .hh-sec {
          .hh-top {
            font-size: 11px;
            line-height: 11px;
          }

          .hh-bottom {
            font-size: 5px;
          }
        }
      }
    }
  }

  .hh-prizes {
    margin-top: -6px;
  }

  .hh-sfooter-txt {
    position: absolute;
    bottom: 10px;
    width: 37px;
    right: 0;
    top: 10px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;


    .hh-ft {
      min-width: 0;
      width: 100%;
    }

    .hh-fi {
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, .5);
      border: 1px solid rgba(255, 255, 255, .57);
      border-radius: 50%;
      cursor: pointer;

      svg {
        vertical-align: middle;
      }
    }
  }

  &.warning {
    filter: grayscale(1);

    .hh-sfooter-txt {
      display: none;
    }

    .hh-prizes {

      .hh-prize-before,
      .hh-prize-after {
        padding-top: 3px;
      }

      .hh-prize-current {
        .hh-bet {
          opacity: 0;
        }
      }
    }
  }
}

.hh-parent {
  height: 89px;
  position: relative;

  &.hh-spc {
    .hh-sfooter-txt {
      position: absolute;
      bottom: 10px;
      width: 37px;
      right: 0;
      top: 10px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;


      .hh-ft {
        min-width: 0;
        width: 100%;
      }

      .hh-fi {
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, .5);
        border: 1px solid rgba(255, 255, 255, .57);
        border-radius: 50%;
        cursor: pointer;

        svg {
          vertical-align: middle;
        }
      }
    }
  }

  .hh-footer-txt {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 27px;
    flex-wrap: nowrap;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);

    .hh-ft {
      min-width: 0;
      width: 100%;
      padding-left: 37px;
      color: #ff0000;
      font-size: 13px;
      font-weight: bold;
    }

    .hh-fi {
      min-width: 37px;

      svg {
        vertical-align: middle;
      }
    }
  }

  &.warning {
    .hh-footer-txt {
      display: flex;
    }
  }
}

.hh-portrait-collapsed {
  height: 42px;
  overflow: hidden;
  //background-image: linear-gradient(180deg, #5b007b 0, #ff196e 30%, #9f002b 65%, #3e075d 100%);
  background-image: linear-gradient(to right, #91070f 0, #b10b18 5.9%, #cb064f 55.5%, #f70 100%);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;

  .hh-name {
    width: 60px;
    overflow: visible;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: left;
    line-height: 14px;
    word-break: initial;
  }

  .hh-content {
    height: 33px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    border-radius: 31px;
    background: rgba(80, 18, 135, 0.48);
    border: 1px solid #FFC7DB;

    .hh-pills-text {
      line-height: 13px;

      .hh-current-prize {
        font-size: 13px;
        font-weight: bold;
        color: #fff;
      }

      .hh-all-prizes {
        font-size: 10px;
        font-weight: bold;
        color: #FFC400;
      }
    }

    .hh-flx {
      display: flex;
      align-items: center;
      margin: 0 20px;

      .hh-img {
        svg {
          width: 25px;
          height: auto;
          position: relative;
          //top: 7px;
          vertical-align: middle;
        }

        img {
          width: 20px;
          height: auto;
          position: relative;
          left: -4px;
          top: 2px;
        }
      }

      .hh-txt {
        color: #ffd23f;
        font-size: 18px;
        font-weight: bold;
      }
    }

    .hh-timer {
      display: flex;
      align-items: center;
      justify-content: center;

      .hh-hour,
      .hh-min,
      .hh-sec {
        .hh-top {
          font-size: 13px;
          font-weight: 600;
          color: #fff;
          text-align: center;
          line-height: 18px;
        }
      }

      .hh-min {
        margin: 0 5px;
      }

      .disabled {
        opacity: 0.4;
      }
    }
  }

  .hh-fi {
    width: 29px;
    height: 29px;
    vertical-align: middle;

    #Ellipse_206 {
      opacity: .35;

      &>circle+circle {
        fill: #000000;
      }
    }
  }
}

.hh-parent-simple {
  .hh-footer-txt {
    display: none;
  }
}

.hh-parent-simple.warning {
  position: relative;

  .hh-portrait-collapsed {
    filter: grayscale(1);

    .hh-fi {
      opacity: 0;
    }
  }

  .hh-footer-txt {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 27px;
    flex-wrap: nowrap;
    align-items: center;
    background: rgba(0, 0, 0, 0.8);

    &.hh-full-height {
      background: rgba(0, 0, 0, 0.8);
      top: 0;
    }

    .hh-ft {
      min-width: 0;
      width: 100%;
      padding-left: 37px;
      color: #ff0000;
      font-size: 13px;
      font-weight: bold;
    }

    .hh-fi {
      min-width: 37px;
      line-height: 0px;

      svg {
        vertical-align: middle;
      }
    }
  }
}

.hh-win-portrait {
  height: 153px;
  overflow: hidden;
  background-image: linear-gradient(180deg,
      rgba(91, 0, 123, 1) 0,
      rgba(85, 4, 34, 1) 30%,
      rgba(117, 8, 38, 0.2) 65%,
      rgba(62, 7, 93, 0) 100%);



  &.win {
    background: rgba(0, 0, 0, .41);
    height: 100%;
    position: absolute;
    left: 0 !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;

    @media screen and (orientation:portrait) {
      padding-top: calc(30vh - 137px);
    }

    @media screen and (orientation:landscape) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .hh-win-container {
    min-width: 280px;
    max-width: 360px;
    height: 128px;
    margin: 7px auto 0;
    background-image: linear-gradient(180deg, #5D4F5C 0, #2C1F2B 100%);
    border: 1px solid #b42b74;
    border-radius: 14px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px 0 24px;
    position: relative;

    @media screen and (min-width: 1025px) {
      max-width: initial;
    }

    .hh-fi {
      position: absolute;
      top: 2px;
      right: 4px;

      svg {
        width: 29px;
        height: 29px;
      }
    }

    &.win {
      height: 137px;
      margin-top: 7px;
      background-image: url("../../assets/win-anim_bg@3x.gif");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      border: 0;

      .hh-fi {
        top: 12px;
        right: 12px;
      }
    }

    .hh-win-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .hh-win-top {
        color: #fff;
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
      }

      .hh-win-bottom {
        margin-top: 8px;
        display: flex;
        justify-content: center;
        width: 120px;
        overflow: visible;

        img {
          height: 48px;
        }

        img.winner {
          height: 90px;
          margin-top: -15px;
        }
      }

      @media (orientation: landscape) {
        .hh-win-bottom {
          width: 150px;
        }
      }

      .hh-win-bottom.winner {
        height: 60px;
      }
    }

    .hh-win-right {
      margin-left: 10px;

      .hh-win-top {
        color: #ffc935;
        font-size: 12px;
        font-weight: bold;
      }

      .hh-win-bottom {
        margin-top: 5px;
        min-width: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 31px;
        background: rgba(0, 0, 0, 0.41);
        height: 45px;
        line-height: 0;
        color: #ffc935;
        font-size: 23px;
        font-weight: bold;

        .hh-img {
          svg {
            width: 30px;
            vertical-align: middle;
            position: relative;
            left: -4px;
          }
        }
      }
    }

    &.win {
      .hh-win-left {
        margin-top: 20px;

        /*.hh-win-top {
          background-image: radial-gradient(closest-side, rgba(180, 2, 255, 0.5), rgba(182, 0, 250, 0));
        }*/

        .hh-win-bottom {
          margin-top: 2px;
        }
      }

      .hh-win-right {
        margin-top: 20px;

        .hh-win-bottom {
          margin-top: 5px;
          height: 45px;
          line-height: 0;
        }
      }
    }
  }
}

.hh-landscape {
  height: 100vh;
  width: 140px;
  overflow: hidden;
  position: relative;

  @media screen and (min-width: 1200px) {
    height: calc(100vh - 50px);
  }

  background-image: linear-gradient(0deg, #600006 0, #920813 28.7%, #cb064f 43.9%, #f6640d 87.7%, #a44d00 100%);

  .hh-fi {
    text-align: center;
    margin-top: 9px;

    svg {
      width: 29px;
      height: 29px;

      #Ellipse_206 {
        &>circle+circle {
          fill: #000000;
        }
      }
    }
  }

  .hh-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 15px 0;

    .hh-fi-close,
    .hh-fi-dots {
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, .5);
      border: 1px solid rgba(255, 255, 255, .57);
      border-radius: 50%;
      cursor: pointer;

      svg {
        vertical-align: middle;
      }
    }
  }

  .hh-name {
    margin-top: 6px;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
  }

  .hh-pills {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin-top: 28px;

    .hh-pill {
      width: 12px;
      min-width: 12px;
      height: 8px;
      border: 1px solid transparent;
      opacity: 1;
      background: #5D0B0B;
      margin: 0 3px;
      border-radius: 4px;

      &.op-20 {
        opacity: 0.2;
      }

      &.op-35 {
        opacity: 0.35;
      }

      &.op-49 {
        opacity: 0.49;
      }

      &.active {
        width: 16px;
        min-width: 16px;
        height: 10px;
        border: 1px solid #fff;
        background: #EE3416;
        margin: 0;
      }
    }
  }

  .hh-pills-text {
    line-height: 17px;
    text-align: center;

    .hh-current-prize {
      font-size: 13px;
      font-weight: bold;
      color: #fff;
      //padding-left: 20px;
    }

    .hh-all-prizes {
      font-size: 10px;
      font-weight: bold;
      color: #FFC400;
    }

    .hh-all-prizes.hidden {
      opacity: 0;
    }
  }

  .hh-timer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 9px;

    .hh-hour,
    .hh-min,
    .hh-sec {
      .hh-top {
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        text-align: center;
        line-height: 18px;
      }

      .hh-bottom {
        font-size: 8px;
        font-weight: normal;
        color: #fcaac9;
        text-align: center;
      }
    }

    .hh-min {
      margin: 0 5px;
    }

    .disabled {
      opacity: 0.4;
    }
  }

  .hh-prize-current {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(80, 18, 135, 0.48);
    border: 1px solid #FFC7DB;
    border-radius: 11px;
    margin: 4px 8px 4px;
    padding: 5px 10px;

    .hh-img {
      svg {
        width: 38px;
        height: 38px;
      }

      img {
        width: 38px;
        height: 38px;
      }
    }

    .hh-txt {
      color: #ffd23f;
      font-size: 22px;
      font-weight: bold;
    }

    .hh-bet {
      color: #dcc9c9;
      font-size: 13px;
      font-weight: normal;
    }
  }

  &.hh-landscape-special-prize {
    width: 140px;
    background-image: linear-gradient(180deg, #08121e 0%, #08121e 23.8%, #0a284b 78.1%, #08121e 87.9%, #08121e 100%);

    .hh-sp-footer {
      content: " ";
      height: 18px;
      background: linear-gradient(to right, #1b375e 0%, #1a4075 38.5%, #152843 72.2%, #03152e 100%);

      text-align: center;
      line-height: 18px;
      color: #F2C44E;
      font-size: 11px;
      font-weight: 600;

      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .hh-sp-help {
      width: 21px;
      height: 21px;

      border: 2px solid #08121E;
      background: #3B6093;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      font-weight: bold;
      font-size: 15px;
      border-radius: 50%;
      cursor: pointer;

      position: absolute;
      left: 10px;
      bottom: 6px;
    }

    .hh-timer {

      .hh-hour,
      .hh-min,
      .hh-sec {
        .hh-bottom {
          color: #5C7897;
        }
      }
    }

    .hh-prizes {
      height: calc(100vh - 110px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .hh-special-prize {
        background: transparent;

        .hh-img {
          img {
            max-width: 140px;
            width: 100%;
            height: auto;
          }
        }
      }
    }

    @media screen and (min-width: 1200px) {
      .hh-prizes {
        height: calc(100vh - 160px);
      }
    }
  }


}

.hh-landscape-parent {
  position: relative;
  max-width: 140px;

  .hh-footer-txt {
    display: none;
  }
}

.hh-landscape-parent.warning {
  .hh-footer-txt {
    display: block;
    background: rgba(0, 0, 0, 0.6);

    .hh-ft {
      width: 100%;
      color: #ff0000;
      font-size: 13px;
      font-weight: bold;
      padding: 10px 0;
    }
  }

  .hh-landscape {
    filter: grayscale(1);
  }
}

.hh-landscape-collapsed {
  height: 42px;
  overflow: hidden;
  //background-image: linear-gradient(180deg, #5b007b 0, #ff196e 30%, #9f002b 65%, #3e075d 100%);
  background-image: linear-gradient(to right, #91070f 0, #b10b18 5.9%, #cb064f 55.5%, #f70 100%);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;

  .hh-name {
    width: 60px;
    overflow: visible;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: left;
    line-height: 14px;
    word-break: initial;
  }

  .hh-pills-text {
    line-height: 13px;

    .hh-current-prize {
      font-size: 13px;
      font-weight: bold;
      color: #fff;
    }

    .hh-all-prizes {
      font-size: 10px;
      font-weight: bold;
      color: #FFC400;
    }
  }

  .hh-content {
    height: 33px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    border-radius: 31px;
    background: rgba(80, 18, 135, 0.48);
    border: 1px solid #FFC7DB;

    .hh-special-prize-img {
      img {
        display: block;
        width: 30px;
        height: auto;
      }
    }

    .hh-txt {
      line-height: 0px;

      .hh-val {
        font-size: 13px;
        font-weight: bold;
        color: #c0c0c0;
      }

      .hh-currency {
        font-size: 10px;
        font-weight: bold;
        color: #c0c0c0;
      }

      .gold {
        color: #efb001;
      }

      .gt {
        color: #ffffff;
        font-weight: bold;
        font-size: 20px;
        opacity: 0.2;
        display: block;
        padding: 0 20px;
        position: relative;
        top: -2px;
      }
    }

    .hh-flx {
      display: flex;
      align-items: center;

      line-height: 0;

      .hh-img {
        margin-right: 5px;

        svg {
          width: 25px;
          height: auto;
          position: relative;
          //top: 7px;
          vertical-align: middle;
        }

        img {
          width: 25px;
          height: auto;
          position: relative;
          left: -2px;
        }
      }

      .hh-txt {
        color: #ffd23f;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  .hh-timer {
    display: flex;
    align-items: center;
    justify-content: center;

    .hh-hour,
    .hh-min,
    .hh-sec {
      .hh-top {
        font-size: 13px;
        font-weight: 600;
        color: #fff;
        text-align: center;
        line-height: 18px;
      }
    }

    .hh-min {
      margin: 0 5px;
    }

    .disabled {
      opacity: 0.4;
    }
  }

  .hh-fi {
    line-height: 0;

    svg {
      width: 29px;
      height: 29px;
      vertical-align: middle;

      #Ellipse_206 {
        opacity: .35;

        &>circle+circle {
          fill: #000000;
        }
      }
    }
  }

  &.hh-landscape-special-prize {
    position: relative;
    background-image: none;
    background-color: transparent;

    .hh-sp-help {
      width: 21px;
      height: 21px;

      border: 2px solid #08121E;
      background: #3B6093;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      font-weight: bold;
      font-size: 15px;
      border-radius: 50%;
      cursor: pointer;
    }

    .hh-pills-text {
      padding-left: 40px;
    }

    .hh-timer {
      padding-right: 40px;
    }

    .hh-content {
      background: transparent;
      min-width: 0;
      flex: 1 1 auto;
      background: transparent;
      border: 0;
    }
  }
}

.hh-landscape-parent-simple {
  position: relative;

  &.hh-landscape-special-prize {
    background-color: #0A1625;

    .hh-special-prize-collapsed-img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 100%;
        width: auto;
      }
    }
  }

  .hh-footer-txt {
    display: none;
  }
}

.hh-landscape-parent-simple.warning {
  position: relative;

  .hh-portrait-collapsed {
    filter: grayscale(1);

    .hh-fi {
      opacity: 0;
    }
  }

  .hh-landscape-collapsed {
    filter: grayscale(1);

    .hh-fi {
      opacity: 0;
    }
  }

  .hh-footer-txt {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 27px;
    flex-wrap: nowrap;
    align-items: center;
    background: rgba(0, 0, 0, 0.8);

    &.hh-full-height {
      background: rgba(0, 0, 0, 0.8);
      top: 0;
      height: auto;
    }

    .hh-ft {
      min-width: 0;
      width: 100%;
      padding-left: 37px;
      color: #ff0000;
      font-size: 13px;
      font-weight: bold;
    }

    .hh-fi {
      min-width: 37px;
      line-height: 0px;

      svg {
        width: 25px;
        vertical-align: middle;
      }
    }
  }
}

.hh-win-landscape {
  height: 86px;
  overflow: hidden;
  background: #1d191f;
  border-radius: 14px;

  .hh-win-container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 7px 0 18px;
    height: 100%;

    .hh-win-left {
      width: 132px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-image: radial-gradient(closest-side, rgba(72, 249, 18, 0.23), rgba(72, 249, 18, 0));

      .hh-win-top {
        color: #76ff31;
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
      }

      .hh-win-bottom {
        margin-top: 6px;
        color: #76ff31;
        font-size: 31px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .hh-win-right {
      .hh-win-top {
        color: #ffc935;
        font-size: 12px;
        font-weight: bold;
      }

      .hh-win-bottom {
        margin-top: 5px;
        min-width: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 31px;
        background: rgba(255, 255, 255, 0.23);
        height: 45px;
        line-height: 0;
        color: #ffc935;
        font-size: 23px;
        font-weight: bold;

        .hh-img {
          svg {
            width: 30px;
            vertical-align: middle;
            position: relative;
            left: -4px;
          }
        }
      }
    }

    &.win {
      .hh-win-left {
        .hh-win-top {
          background-image: radial-gradient(closest-side, rgba(180, 2, 255, 0.5), rgba(182, 0, 250, 0));
        }

        .hh-win-bottom {
          margin-top: 2px;
        }
      }

      .hh-win-right {
        .hh-win-bottom {
          margin-top: 5px;
          height: 45px;
          line-height: 0;
        }
      }
    }
  }
}

.hh-ended {
  position: absolute;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;

  * {
    box-sizing: border-box;
  }

  &.hh-landscape {
    align-items: center;
  }

  &.hh-portrait {
    align-items: start;
    padding-top: 6px;
  }

  .hh-wrapper {
    background: linear-gradient(180deg, #ff8800 0, #ff2f88 100%);
    padding: 4px;
    border-radius: 12px;
    min-width: 280px;

    @media screen and (min-width:1024px) {
      transform: scale(1.5);
    }

    .hh-innerWrapper {
      height: 100%;
      background: linear-gradient(180deg, #1e002d 0, #39006d 100%);
      position: relative;
      padding: 17px 27px 32px 27px;
      border-radius: 8px;

      &>.hh-fi {
        position: absolute;
        top: 14px;
        right: 14px;

        svg {
          width: 29px;
          height: 29px;
        }
      }

      &>.hh-title {
        background-image: radial-gradient(closest-side, rgba(180, 2, 255, 0.5), rgba(182, 0, 250, 0));
        color: #ffc4d6;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        max-width: 270px;
        margin: 0 auto;
      }

      &>.hh-subtitle {
        margin-top: 4px;
        color: #ffffff;
        text-align: center;
        font-size: 12px;
        font-weight: normal;

        .hh-semibold {
          font-weight: 600;
        }
      }

      .hh-prizes {
        display: flex;
        align-items: center;
        height: 130px;
        justify-content: center;
        margin-top: 39px;

        .hh-prize-miss,
        .hh-prize-won {
          min-width: 140px;
          height: 100%;
          padding: 7px 20px 17px;
          background: rgba(0, 0, 0, 0.41);
          border-radius: 11px;

          &>.hh-title {
            text-align: center;
            font-size: 12px;
            font-weight: normal;
            color: #ffc4d6;
          }

          &>.hh-gift {
            margin-top: 15px;
            text-align: center;

            img {
              width: 40px;
              height: 38px;
            }
          }

          &>.hh-amount {
            margin-top: 0px;
            white-space: nowrap;
            text-align: center;
            font-size: 22px;
            font-weight: bold;
            color: #ffd23f;
          }
        }

        .hh-prize-miss {
          margin-right: 6px;

          &>.hh-amount {
            color: #cfcfcf;
          }
        }

        .hh-prize-won {
          margin-left: 6px;
        }
      }
    }
  }

  &.hh-portrait {
    padding: 6px 4px 0 4px;

    .hh-wrapper {
      width: 100%;
    }
  }

  &.hh-landscape {
    .hh-wrapper {
      .hh-innerWrapper {
        &>.hh-fi {
          top: 5px;
        }
      }
    }
  }
}

.hh-desktop {
  background-image: url(../../assets/bg-desktop.png);
  height: 210px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 34px;
  font-family: OpenSans, "Open Sans";
  background-size: 100% 100%;
  background-repeat: no-repeat;
  max-width: 1352px;
  margin: 15px auto 30px;
  overflow: visible;

  .hh-wrapper {
    height: 100%;
    overflow: visible;
    display: flex;
    align-items: center;

    .hh-root {
      background-image: url(../../assets/bg-front-desktop.png);
      height: 215px;
      width: 669px;
      box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, .5);

      .hh-header {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: 0;

        .hh-name {
          position: absolute;
          top: 20px;
          left: 40px;
          font-size: 23px;
          font-weight: bold;
          word-break: initial;
          width: 150px;
          line-height: 23px;
        }

        .hh-timer {
          position: absolute;
          top: 20px;
          right: 40px;

          .hh-hour,
          .hh-min,
          .hh-sec {
            .hh-top {
              font-size: 34px;
            }

            .hh-bottom {
              font-size: 15px;
              margin-top: 5px;
            }
          }
        }

        .hh-pills {
          .hh-pill {
            width: 23px;
            height: 15px;
            margin: 0 5px;
          }

          .hh-pill.active {
            width: 30px;
            height: 19px;
            margin: 0 7px;
          }

          .hh-pill.op-88 {
            opacity: .88;
          }

          .hh-pill.op-60 {
            opacity: .60;
          }

          .hh-pill.op-29 {
            opacity: .29;
          }
        }
      }

      .hh-pills-text {
        margin-top: 8px;

        .hh-current-prize {
          font-size: 24px;
          padding-left: 0;
        }

        .hh-all-prizes {
          font-size: 18px;
        }

        .hh-all-prizes.hidden {
          opacity: 0;
        }
      }

      .hh-prizes {
        margin-top: 20px;

        .hh-prize-current {
          height: 110px;
          min-width: 310px;
          margin: 0 15px;

          .hh-img {
            img {
              width: 50px;
            }
          }

          .hh-txt {
            font-size: 42px
          }

          .hh-bet {
            font-size: 25px;
          }
        }

        .hh-prize-before,
        .hh-prize-after {
          margin-top: 15px;
          height: 80px;
          font-size: 25px;
          padding: 22px 28px;
        }
      }
    }
  }

  .hh-campaign-info {
    min-width: 397px;
    width: 397px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
    padding: 20px 0 30px 0;

    .hh-title {
      font-size: 14px;
      font-weight: normal;
      color: #4E4E4E;
      text-decoration: underline;
      cursor: pointer;
    }

    .hh-cta {
      width: 397px;
      min-height: 60px;
      height: 60px;
      background-image: url(../../assets/bg-cta-button.png);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      font-weight: 600;
      color: #fff;
      text-transform: uppercase;
      cursor: pointer;
      position: relative;
      overflow: visible;
      box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.57);
      border-radius: 5px;

      .hh-date {
        position: absolute;
        right: 10px;
        top: -10px;
        border: 1px solid #fff;
        background-color: #FFC400;
        font-size: 13px;
        font-weight: bold;
        color: #000000;
        padding: 0 10px;
        border-radius: 3px;

        .disabled {
          opacity: .5;
        }
      }
    }
  }
}

.hh-special-prize-collapsed {
  background-image: none;
  background-color: #08121E;


  .hh-sp-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    min-width: 0;
    flex: 1 1 auto;
    padding-right: 10px;

    .hh-sp-help {
      position: relative;
      width: 21px;
      height: 21px;

      border: 2px solid #08121E;
      background: #3B6093;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      font-weight: bold;
      font-size: 15px;
      border-radius: 50%;
      cursor: pointer;
    }

    .hh-sp-image {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: visible;
      display: flex;
      justify-content: center;
      align-items: center;

      &>img {
        height: auto;
        width: 100%;
        display: block;
      }
    }

    .hh-sp-image.collapsed {
      &>img {
        height: 42px;
        width: auto;
        display: block;
      }
    }

    .hh-timer {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .hh-hour,
      .hh-min,
      .hh-sec {
        .hh-top {
          font-size: 13px;
          font-weight: 600;
          color: #fff;
          text-align: center;
          line-height: 18px;
        }
      }

      .hh-min {
        margin: 0 5px;
      }

      .disabled {
        opacity: 0.4;
      }
    }
  }
}

.hh-win-portrait.hh-sp-win-portrait {
  height: 153px;
  overflow: hidden;
  background-image: linear-gradient(180deg,
      rgba(91, 0, 123, 1) 0,
      rgba(85, 4, 34, 1) 30%,
      rgba(117, 8, 38, 0.2) 65%,
      rgba(62, 7, 93, 0) 100%);



  &.win {
    background: rgba(0, 0, 0, .41);
    height: 100%;
    position: absolute;
    left: 0 !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;

    @media screen and (orientation:portrait) {
      padding-top: 200px;
    }

    @media screen and (orientation:landscape) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .hh-win-container {
    min-width: 280px;
    max-width: 360px;
    height: 90px;
    margin: 7px auto 0;
    background-image: linear-gradient(to right,
        #08121e 0,
        #08121e 11.6%,
        #0a284b 50%,
        #08121e 89.2%,
        #08121e 100%);
    border: 2px solid #C18918;
    border-radius: 14px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px 0 24px;
    position: relative;

    @media screen and (min-width: 1025px) {
      max-width: initial;
    }

    .hh-win-bg-circles {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }



    .hh-fi {
      position: absolute;
      top: 3px;
      right: 6px;

      svg {
        width: 23px;
        height: 23px;
      }
    }

    &.win {
      padding: 0;
      max-width: 372px;
      min-width: 280px;
      height: auto;
      margin-top: 0;
      background-image: url("../../assets/win-special-prize.png");
      //background-size: 100% 100%;
      background-repeat: no-repeat;
      border: 0;

      .hh-win-prop {
        width: 100%;
        height: auto;
      }


      .hh-fi {
        top: 14px;
        right: 16px;

        svg {
          width: 29px;
          height: 29px;
        }

        svg #Ellipse_206 {
          opacity: 1;

          &>circle+circle {
            fill: #FF5635;
          }
        }
      }
    }

    .hh-win-wrapper {
      position: relative;
      width: 100%;
    }

    .hh-win-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .hh-win-center-top {
        color: #FFFFFF;
        font-size: 10px;
        font-weight: bold;
        opacity: .74;
        margin-bottom: 4px;
        text-transform: uppercase;
      }

      .hh-win-center-bottom {
        color: #ffffff;
        font-size: 16px;
        font-weight: 600;

        span.hh-win-gold {
          color: #FFC100;
        }
      }
    }

    &.win .hh-win-center {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      .hh-win-center-top {
        color: #FFFFFF;
        font-size: 14px;
        position: absolute;
        opacity: 1;
        top: 17%;
        left: 19%;
      }

      .hh-win-center-bottom {
        color: #FFFFFF;
        font-size: 18px;
        position: absolute;
        font-weight: bold;
        bottom: 11%;
        left: 0;
        right: 0;
        text-align: center;
      }
    }
  }
}

.hh-front-sp-root {
  height: 122px;
  border-radius: 7px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: #08121E;

  * {
    box-sizing: border-box;
  }

  .hh-image {
    position: absolute;
    top: 0;
    bottom: 18px;
    left: 0;
    right: 0;
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;

    &>img {
      height: auto;
      width: 346px;
      display: block;
    }
  }

  .hh-title {
    width: 60px;
    overflow: visible;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: left;
    line-height: 14px;
    padding-bottom: 18px;
    margin-left: 35px;
    position: relative;
  }

  @media screen and (min-width: 1200px) {
    .hh-title {
      width: 100px;
    }
  }

  .hh-footer {
    height: 18px;
    background: linear-gradient(to right, #1b375e 0%, #1a4075 38.5%, #152843 72.2%, #03152e 100%);

    text-align: center;
    line-height: 18px;
    color: #F2C44E;
    font-size: 11px;
    font-weight: 600;

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .hh-help {
    width: 21px;
    height: 21px;

    border: 2px solid #08121E;
    background: #3B6093;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 15px;
    border-radius: 50%;
    cursor: pointer;

    position: absolute;
    left: 10px;
    bottom: 6px;
  }
}

.hh-root.hh-portrait>.hh-special-prize {
  height: 91px;
  border-radius: 7px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: #08121E;

  * {
    box-sizing: border-box;
  }

  .hh-image {
    position: absolute;
    top: 0;
    bottom: 18px;
    left: 0;
    right: 0;
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;

    &>img {
      height: auto;
      width: 346px;
      display: block;
    }
  }

  .hh-title {
    width: 60px;
    overflow: visible;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: left;
    line-height: 14px;
    padding-bottom: 18px;
    margin-left: 35px;
    position: relative;
  }

  @media screen and (min-width: 1200px) {
    .hh-title {
      width: 100px;
    }
  }

  .hh-footer {
    height: 18px;
    background: linear-gradient(to right, #1b375e 0%, #1a4075 38.5%, #152843 72.2%, #03152e 100%);

    text-align: center;
    line-height: 18px;
    color: #F2C44E;
    font-size: 11px;
    font-weight: 600;

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .hh-help {
    width: 21px;
    height: 21px;

    border: 2px solid #08121E;
    background: #3B6093;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 15px;
    border-radius: 50%;
    cursor: pointer;

    position: absolute;
    left: 10px;
    bottom: 6px;
  }
}