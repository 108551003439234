.tournaments-missions-dialog-switch-tournament {
  box-sizing: border-box;
  z-index: 10000 !important;

  * {
    box-sizing: border-box;
  }

  .MuiBackdrop-root {
    backdrop-filter: blur(5px);
    background-color: #ffffff10;
  }

  .MuiPaper-root {
    margin: 18px 2px 18px;
    background: linear-gradient(to bottom, #213348, #1F3044 65%, #142232);
    border-radius: 15px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    border: 2px solid rgba(136, 162, 252, .2);
    color: #B5BFD0;
    min-width: 300px;
    min-height: 300px;
    padding: 10px 2px;
    overflow: visible;

    .dg-header {
      height: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: -10px;

      .dg-close {
        background: linear-gradient(to bottom, #3B526E, #020E1C);
        box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 38px;
        max-width: 38px;
        height: 38px;
        cursor: pointer;

        .dg-close-wrapper {
          color: #F72226;
          background: #213246;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 34px;
          height: 34px;
          cursor: pointer;
        }
      }
    }

    .tm-tournaments {
      padding: 20px 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .tm-timer {
      color: #FF5B00;
      font-size: 12px;
      font-weight: bold;

      .muted {
        color: #8D441B;
      }

      .days {
        color: #FF975D;
      }
    }

    .tm-tournament {
      width: 285px;
      margin: 10px 0 0;

      .tm-wrapper {
        margin-left: 30px;
        background: linear-gradient(to bottom, #34476a, #2b3b5b);
        box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
        border-radius: 17px;
        padding: 2px;

        .tm-wrapper-inner {
          border-radius: 17px;
          background: linear-gradient(135deg, #213348, #142232 20%, #142232);

          display: flex;
          align-items: center;
          justify-content: space-between;

          padding: 10px 10px 10px 0px;
        }

      }




      .tm-top {
        flex: 0 0 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-left: -35px;

        img {
          width: 70px;
          height: 70px;
          object-fit: contain;
          display: block;
        }

        .tm-txt-award {
          position: absolute;

          .tm-subtitle {
            text-align: center;
            font-size: 9px;
            line-height: 9px;
            font-weight: bold;
            color: #fff;
          }

          .tm-title {
            text-align: center;
            font-size: 15px;
            line-height: 15px;
            font-weight: bold;
            color: #fff;
          }
        }
      }

      .tm-wrapper-right {
        flex: 1 1 auto;
        margin-left: 1px;

        .tm-hd {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .tm-left {
            display: flex;
            align-items: center;

            img {
              display: block;
              height: 40px;
              width: auto;
            }

            .tm-name {
              color: #fff;
              font-size: 11px;
              font-weight: bold;
              text-transform: uppercase;
              opacity: .51;
            }
          }
        }

        .tm-foot {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .tm-prizes {
            .tm-prize {
              color: #C4CBD4;
              font-size: 11px;
              font-weight: normal;
            }
          }

          .tm-buttons {
            .activated {
              display: block;
              height: 45px;
              width: 45px;
              object-fit: cover;
            }

            .tm-btn {
              margin: 0 0 0 12px;
              height: 35px;
              background: linear-gradient(to right, #FFD457 0%, #FF6800 100%);
              padding: 2px;
              border-radius: 5px;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;

              .inner {
                padding: 0 20px;
                height: 31px;
                background: linear-gradient(to right, #0A1520 0%, #213348 100%);
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                white-space: nowrap;

                font-size: 11px;
                color: #fff;
                font-weight: bold;
              }
            }
          }
        }
      }

      &.hh {
        .tm-wrapper {
          padding: 0;
          min-height: 93px;


          .tm-wrapper-inner {
            background-image: url('../assets/happy-hour-bg.png');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            padding: 0;
            min-height: 93px;

            .tm-wrapper-right {
              margin-right: 15px;

              .tm-hd {
                margin-bottom: 15px;
              }

              .tm-hd .tm-left .tm-name {
                opacity: 1;
              }

              .tm-foot .tm-prizes .tm-prize {
                color: #fff;
              }
            }

          }
        }
      }
    }
  }

  @media screen and (orientation: landscape) {
    .MuiPaper-root {
      margin-top: 19px;
    }
  }
}