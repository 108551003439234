.deposit-ticket-dialog {
  font-family: "Open Sans", OpenSans;

  .MuiPaper-rounded {
    border-radius: 15px;
  }

  .deposit-ticket-dialog-body {
    padding: 0 20px;

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 28px;
      margin-bottom: 10px;
    }

    .logo.starting {
      margin-top: 38px;
    }

    .title {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
    }

    .title.starting {
      color: #285C98;
    }

    .title.success {
      color: #558707;
    }

    .title.fail {
      color: #C72525;
    }

    .title.in-progress {
      color: #CC4514;
    }

    .prg-wrapper {
      margin: 90px 0 140px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .prg-title {
        text-align: center;
        color: #000000;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .prg .MuiLinearProgress-root {
        width: 200px;
      }
    }

    .content-text {
      color: #003D6A;
      font-size: 12px;
      font-weight: normal;
      margin-top: 36px;
      margin-bottom: 120px;
    }

    .content-text.fail,
    .content-text.in-progress {
      text-align: center;
    }

    .link {
      color: #939393;
      font-size: 13px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 26px;
      text-decoration: underline;
      text-transform: uppercase;
    }

    .btn {
      background: linear-gradient(to right, #295A91, #2174E0);
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      font-weight: bold;
      color: #fff;
      border-radius: 3px;
      margin-bottom: 30px;
      text-transform: uppercase;
    }

  }
}