.flux-aml-root {
  z-index: 1301 !important;

  & .MuiPaper-rounded {
    border-radius: 13px;
    width: calc(100% - 16px);
    margin: 0px;
  }

  .dialogTitle {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #003D6A;
    padding: 34px 0 16px;
    position: relative;

    .content-title-primary {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      color: #285C98;
    }

    .content-title {
      font-size: 12px;
      font-weight: normal;
      text-align: center;
      padding: 5px 20px;
    }
  }

  .dialogContent {
    padding: 8px 12px;


    .MuiFormControlLabel-root {
      align-items: flex-start;
      margin-bottom: 10px;
    }
  }

  .row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin: 0;
    margin-bottom: 27px;

    .label {
      text-align: left;
      font-size: 10px;
      color: #586682;
      font-weight: 600;
      padding-right: 5px;
    }
  }

  .clear {
    position: absolute;
    top: 16px;
    right: 13px;
    cursor: pointer;
    border-radius: 50%;
    background: #F4F4F4;
    color: #AA0808;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      width: 16px;
      position: relative;
    }
  }

  .check-label-green {
    text-align: left;
    color: #009245;
    font-size: 13px;
    font-weight: bold;
    line-height: 16px;
  }

  .check-label {
    text-align: left;
    color: #000;
    font-size: 11px;
    font-weight: normal;
    line-height: 14px;
  }

  .dialogOK {
    width: 100%;
    padding: 13px 0;
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    margin: .5em 0 1em;
    background: linear-gradient(90deg, #295A91 0%, #2174E0 100%);
    flex: 1 1 auto;
    min-width: 0;

    &.disabled {
      filter: grayscale(1);
    }
  }
}