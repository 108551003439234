@import "../../../../../variables";

.currency-value {
    .formatted {
      text-transform: capitalize;
      small {
        font-weight: inherit;
        font-size: 60%;
        color: $grayColor;
      }
    }
  &.fractional-part-size-normal {
    .formatted {
      small {
        font-size: 100%;
      }
    }
  }

}

.bonus-widget {
  .currency-value {
    display: inline-block;
  }
}