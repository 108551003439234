.winter-fun-modal-season-end {
  z-index: 1304 !important;

  & * {
    box-sizing: border-box;
  }

  & .MuiPaper-rounded {
    border-radius: 13px;
  }

  & .MuiDialog-paperFullWidth {
    width: calc(100% - 16px);
    min-width: calc(100% - 16px);
  }

  @media (min-width:400px) {
    & .MuiDialog-paperFullWidth {
      min-width: 400px;
      max-width: 400px;
    }
  }

  .wf-dialog-content {
    background-color: #fff;
    background: url(./assets/modal-bg@3x.png);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100% auto;
    padding: 8px 35px;
  }

  .wf-modal-header {
    margin: -20px -35px 10px;
    background-image: url("../../assets/img-bg-season-title@3x.png");
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100% auto;


    .wf-h-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 26px;
      min-height: 48px;

      .wf-h-w-left {
        display: flex;
        flex-direction: column;

        .wf-h-w-l-title {
          font-size: 20px;
          line-height: 20px;
          color: #fff;
          font-weight: 600;
          margin-top: 5px;
        }

        .wf-h-w-l-subtitle {
          font-size: 12px;
          color: #fff;
          font-weight: normal;
          margin-bottom: 5px;
        }
      }

      .wf-h-w-right {
        padding-left: 18px;
        color: #fff;
        display: flex;
        align-items: center;

        svg {
          width: 18px;
          height: auto;
        }
      }
    }
  }

  .wf-header-icon {
    width: 90px;
    height: 90px;
    display: block;
    margin: 0 auto 8px;
  }

  .wf-title {
    font-size: 20px;
    color: #131727;
    font-weight: bold;
    text-align: center;
    margin-bottom: 23px;
  }

  .wf-subtitle {
    font-size: 16px;
    color: #000000;
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;
  }

  .wf-raffle {
    padding: 5px 10px;
    background: #fff;
    border-radius: 18px;
    min-width: 280px;
    margin-bottom: 70px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .wf-top {
      font-size: 16px;
      line-height: 16px;
      color: #000;
      font-weight: bold;
      text-align: center;
    }

    .wf-middle {
      font-size: 53px;
      line-height: 53px;
      color: #000;
      font-weight: bold;
      text-align: center;
    }

    .wf-bottom {
      font-size: 16px;
      line-height: 16px;
      color: #000;
      font-weight: bold;
      opacity: .58;
      text-align: center;
    }
  }

  .wf-button-wrapper {
    display: flex;
    justify-content: center;

    .wf-button {
      background: linear-gradient(to right, #0A44E4, #006FFF);
      color: #fff;
      min-width: 260px;

      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      padding: 13px;
      border-radius: 10px;
      margin-bottom: 26px;
      margin-top: 50px;
    }
  }
}