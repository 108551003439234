#NewPassword {
  padding: 5rem 1.5rem 0;
   .messages {
      margin-top: 0;
   }
  input {

  }
  .password-strength {
    .list {
      .title{
        margin-left: 0;
      }
    }
  }
}