.component-providers {
  margin: 10px auto;
  padding-bottom: 10px;
  max-width: 1528px;
  background: linear-gradient(to bottom, #FFFFFF, #F6F8FB);
  padding: 20px 0;

  .cgl-header {
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      color: #353540;
      font-size: 18px;
      font-size: clamp(18px, 1.8vw, 26px);
      font-weight: 600;
      text-align: left;
    }

    .link {
      color: #353540;
      font-size: 12px;
      font-size: clamp(12px, 1.2vw, 17px);
      font-weight: 600;
      text-align: left;
      cursor: pointer;
      border: 1px solid #BEC9D6;
      border-radius: 11px;
      padding: 2px 12px 3px;

      span {
        padding: 0 5px;
      }

      svg {
        position: relative;
        top: -1px;
      }
    }
  }

  .cgl-list-wrapper {
    position: relative;
  }

  .cgl-list {
    overflow-y: hidden;
    overflow-x: auto;

    .cgl-grid {
      width: 100%;
      display: grid;
      white-space: nowrap;
      pointer-events: all;

      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      padding-top: 10px;

      &::before,
      &::after {
        content: '\00a0';
        min-width: 9px;
        max-width: 9px;
      }

      .cgl-item {
        min-width: 118px;
        max-width: 235px;
        width: 100%;
        margin-right: 12px;
        border-radius: 10px;
        overflow: visible;
        cursor: pointer;
        transition: transform .25s ease;

        &.empty {
          max-width: 118px;
        }

        .outer {
          width: 100%;
          padding-top: 123%;
          position: relative;
          overflow: visible;

          .inner {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;


            .image-wrapper {
              border-radius: 10px;
              box-shadow: 0 3px 6px rgba(0, 0, 0, .2);
              padding: 2px;
              width: 100%;
              height: 76%;
              background-color: #fff;

              .image-holder {
                height: 100%;
                border-radius: 10px;
                background: linear-gradient(135deg, rgba(0, 0, 0, .1), rgba(255, 255, 255, 1) 70%);
              }
            }

            .provider-name {
              margin-top: 6px;
              color: #4A4A4A;
              font-size: clamp(10px, 1vw, 17px);
              font-weight: bold;
              text-align: center;
            }
          }


        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          padding: 10px;
        }

        &:last-child {
          margin-right: 0px;
        }

        &:hover {
          position: relative;
          transform-origin: center;
          transform: translateZ(0) scale(1.05);
        }
      }
    }

    @media screen and (min-width: 1528px) {
      .cgl-grid {
        .cgl-item {
          margin-right: 20px;

          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }
  }

  .cgl-footer {
    color: #8D8DA1;
    font-size: clamp(11px, 1vw, 15px);
    font-weight: 600;
    text-align: left;
    margin: 0px 17px 0;
  }


}

@media screen and (min-width: 1528px) {
  .cgl-grid {
    overflow: hidden;
  }

  .component-providers {
    padding-left: 20px;
    padding-right: 20px;
  }
}