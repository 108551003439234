.virtuals-cs {
  top: 0 !important;
}

.virtuals-game-cs {
  .-iframe {
    width: 100%;
    height: 1055px;
    //padding-top: 50px;
  }

  .-id-luckysix {
    height: 1055px;
  }

  .-id-luckyx {
    height: 1021px;
  }

  .-id-nextsix {
    height: 1321px;
  }

  .-id-greyhound {
    height: 1103px;
  }

  .-id-vgreyhound {
    height: 1118px;
  }

  .-id-vhorse {
    height: 1199px;
  }

  .-id-vms {
    height: 1007px;
  }
}

.virtual-screen-title {
  height: 50px;
  position: absolute;
  top: 0;
  left: 0px;
  width: 50px;
  //background-color: #151d26;
  z-index: 99999;
}

.virtual-screen-title .close {
  position: absolute;
  left: 15px;
  top: 5px;
  opacity: 1;
}
