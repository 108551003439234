.tournaments-groups-main-view-dialog {

  &.over {
    z-index: 10000 !important;
  }

  .MuiPaper-root {
    border-radius: 10px;
    background-color: transparent;
    width: 500px;
    margin: 6px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    .tournaments-missions-main-view {
      min-height: 0;
      padding-bottom: 0;

      .header-wrapper {
        z-index: 1;
        position: relative;
      }

      .cnt-wrapper {
        height: calc(100vh - 125px);
        overflow: auto;
        padding-bottom: 10px;

        &::-webkit-scrollbar {
          width: 5px;
          height: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #c1c1c1;
          border-radius: 9px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #c1c1c1;
        }

        .content {
          .recommended-line {
            .recommended {
              max-width: 159px;
            }
          }

          .widget-wrapper .widget {

            .prize-info-images,
            .prize-info-texts {
              min-width: 330px;
              max-width: 330px;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}