.AboutUsScreen {

  .Banner{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    .customerSupportLogo{
      fill: #3fa9f5;
      width: 130px;
      margin-top: 20px;
      margin-left: 20px;

    }

    .BannerDescription{
      width: 100%;
      background-color: #efeff4;
      border-top: 1px solid #ceced1;
      border-bottom: 1px solid #ceced1;
      padding: 20px 30px;
      box-sizing: border-box;

      p{
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        color: #6d6d71;
        margin: 0px;
      }
    }

    .ActionButtons{
      padding: 30px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      gap: 20px;
      #live-chat-contact-button {
        min-height: 7rem;
      }
      p{
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        color: #6d6d71;
        margin: 0px;
      }

      button{
        margin-top: 30px;
        margin-bottom: 10px;

        svg{
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .AboutUsContent{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: 30px;
    box-sizing: border-box;

    .PageTitle{
      margin-bottom: 20px;
    }

    span{
      color: #777;
      text-decoration: underline;
    }

    p{
      font-size: 14px;
      text-align: left;
      margin: 0px 0px 15px 0px;
      font-weight: 500;
      color: #777;

      span{
        text-decoration: none;
      }
    }
  }
}