@import "./../../../variables";

.emptyCasinoPage {
  min-height: 300px;
  display: block;
  padding-top: 20px;
  padding-bottom: 50px;
}

.specialComingSoon {
  height: 200vh;
  background-repeat: repeat-y;
  background-size: contain;
  margin-top: -21px;
  position: relative;

  .header {
    height: 90vh;
    background-repeat: repeat-y;
    background-size: contain;
    width: 100%;
    text-align: center;

    .banner {
      height: 70%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top center;
      color: #c3f8f8;

      .baner-shadow-top {
        background-repeat: repeat-x;
        background-position: top;
        height: 100%;

        .banner-shadow-bottom {
          height: 100%;
          background-repeat: repeat-x;
          background-position: bottom;

          .coming-soon-label {
            top: -32px;
          }
        }
      }
    }

    .text-message {
      height: 150px;
      background-repeat: no-repeat;
      background-position: bottom;
      color: $buttonYellow;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        position: relative;
        font-size: 38px;
        font-weight: 600;
        margin: 0;
      }
    }

    .bottom-space {
      height: 14%;
    }
  }

  .body {
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;

    .title-name {
      width: 100%;
      height: 14vh;
      background-repeat: no-repeat;
      background-position: bottom center;

      h1 {
        line-height: 14vh;
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 38px;
        font-weight: 800;
        text-transform: capitalize;
        color: #f2f2f2;
      }
    }

    .coming-soon-label {
      width: 100%;
      margin-top: 5vh;
    }

    .compass {
      padding-top: 12vh;
      min-height: 311px;
      display: block;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  p {
    text-align: center;
  }

  .coming-soon-label {
    position: relative;

    p {
      width: 240px;
      height: 47px;
      font-style: italic;
      text-transform: uppercase;
      margin: 0 auto;
      color: $firstColor;
      font-size: 22px;
      line-height: 47px;
      font-weight: 600;
    }
  }
}