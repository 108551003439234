@import "./../../../../variables";

.WithdrawModal {
    z-index: 99998;
    //min-width: 615px;
    width: 100%;
    align-items: center;
    justify-content: center;
    max-width: 100%;

    .modal-content {
        border-radius: 0px;
        border-color: rgba(0, 0, 0, 0.0);
        position: relative;
        z-index: 99999;
        animation: normalModal .5s ease-in;
        width: 615px;

        .modal-header {
            background-color: $withdrawModalGreen;
            border-radius: 0;
            text-align: center;
            padding: 1.5rem 1rem;
            height: 74px;
            position: relative;
            animation: showModalHead .5s ease-in;

            .modal-title {
                text-align: center;
                font-size: 0.9rem;
                line-height: 1.6rem;
                font-weight: 700;
                text-transform: uppercase;
                width: 100%;
            }

            .close {
                position: relative;
            }
        }

        .WithdrawModalBody {
            overflow: auto;
            padding: 0;
            background-repeat: no-repeat;
            background-position: center center;
            background-color: #ffffff;

            .withdrawAmount {
                padding-left: 25px;
                padding-right: 25px;
                background-color: #ffffff;
            }

            .LocationsScreen {
                display: none;
                opacity: 0;
                animation: hideLocationContent 1s ease-in;
            }

            .waSection {
                padding-left: 25px;
                padding-right: 25px;
            }
        }

        .stepSection {
            color: #000;
        }

        .stepTitle {
            font-size: 30px;
            margin-bottom: 16px;
            font-weight: 400;
            padding-left: 8px;
        }

        .sTopMessage {
            padding: 20px;

            .sTopTitle {
                padding: 40px 0;
                font-weight: 600;
                font-size: 18px;
            }

            .LocationOnHeader {
                position: relative;
                margin: 0 -70px 20px;
                padding: 40px 70px 60px;
                border-bottom: 18px solid $withdrawHorLine;
            }
        }

        .sSmallAmount {
            padding-bottom: 60px;
        }

        .sAmountsArea {
            .icon {
                &.tconditions{
                    cursor: pointer;
                }
                .IconSVG {
                    height: 14px;

                    .cls-1{fill:#017048;}
                }
            }

            .text {
                font-size: 12px;
                padding-left: 5px;
                color: $iconGrayTwo;
            }

            .tbAmount {
                color: $thirdColor;
                font-weight: bold;
                font-size: 0.8rem;

                &:last-child {
                    .currency-value {
                        color: $redColor;
                    }
                }
            }

            .text-right {
                .text {
                    padding-right: 5px;
                    padding-left: 0;
                }
            }

            hr {
                margin-top: 5px;
            }
        }

        .sInputArea {
            padding-left: 3.6rem;
            padding-right: 3.6rem;
            padding-top: 40px;

            .inputFieldMaterial {
                text-align: center;
                font-size: 24px;
                font-weight: bold;
            }

            .amount-label {
                padding-top: 6px;
                width: 100%;

                p,
                div {
                    padding: 0px;
                    margin: 0px;
                    color: $iconGrayTwo;
                    font-size: 14px;

                    &.error {
                        color: $redColor;
                    }
                }
            }
        }

        .amountToReceive {
            display: flex;
            justify-content: left;
            font-weight: 500;
            font-size: 14px;
            line-height: 26px;

            .currency-value {
                padding-left: 5px;
                padding-right: 5px;
            }
        }

        .sAmountButtons {
            padding-top: 30px;
            padding-bottom: 25px;

            .buttonContainer {
                padding: 4px;

                button {
                    height: 56px;
                    //width: 164px;
                    font-weight: bold;
                    border-color: $thirdColor;
                    color: $thirdColor;

                    &:hover {
                        background-color: $thirdColor;
                        color: $white;
                    }

                    &.selected {
                        background-color: $thirdColor;
                        color: $white;
                    }
                }
            }
        }

        .sBigButton {
            padding-top: 5px;
            padding-left: 4px;
            padding-right: 4px;
            padding-bottom: 20px;
            display: block;

            button {
                height: 86px;
                font-weight: bold;
                font-size: 1.4rem;
                margin-bottom: 1rem;

                &.btn-secondary {
                    color: #999999;
                    background-color: #f2f2f2;

                    &:hover {
                        background-color: darken(#f2f2f2, 10%);
                        color: #ffffff;
                    }
                }

                &.btn-danger {
                    background-color: #c1272d;

                    &:hover {
                        background-color: lighten(#c1272d, 5%);
                    }
                }
                &.safecharge {
                    border: none;
                    font-size: 1rem;
                    background: none;
                    height: auto;
                    &:not(:disabled) {
                        text-decoration: underline;
                    }
                }
            }

            .btn-link {
                color: $white;
            }
        }

        .sLocationArea {
            border-top: 1px solid $withdrawHorLine;
            padding-top: 40px;
            padding-bottom: 60px;
            border-bottom: 1px solid $withdrawHorLine;
            margin-bottom: 40px;
        }

        .location-wrap {
            width: 100%;
            //height: 60px;
            text-align: center;
            border: 1px solid $thirdColor;
            border-radius: 10px;
            display: block;
            overflow: hidden;
            box-shadow: 3px 3px 4px 0px rgba(0,0,0,0.18);
            cursor: pointer;
            margin: 0 auto;

            .emptyLocation {
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 82px;

                p {
                    justify-content: center;
                    padding: 0;
                    margin: 0;
                    height: 24px;
                }
            }

            &.wLoc {
                min-height: 82px;

                .lwRow {
                    padding-left: 12px;
                }
            }

            .locationDetails,
            .locationEtas {
                text-align: left;
                p {
                    margin: 0;
                    text-align: left;
                }
            }
            .locationAddress {
                background-color: #e6e6e6;
                p {
                    margin: 0px;
                    color: $firstColor;
                    text-align: left;
                    min-height: 26px;
                    font-size: 14px;
                    line-height: 26px;
                    font-weight: 500;
                }
            }
            .locationSupport {
                padding-left: 6px;
                padding-top: 6px;
                display: flex;

                .pinMapNew {
                    height: 32px;
                    margin-top: 5px;
                }
                .locationDetails {
                    padding-bottom: 10px;
                    width: 65%;

                    &.lwRow {
                        padding-left: 5px;
                    }

                    p.cls-ld,
                    div.cls-ld {
                        color: $firstColor;
                        font-weight: 500;
                        font-size: 22px;
                        padding-top: 5px;
                    }
                }
                .locationChange {
                    width: 30%;

                    p {
                        font-weight: bold;
                        color: $thirdColor;
                        padding-right: 10px;
                        line-height: 55px;
                        height: 55px;
                        margin: 0px;
                        text-transform: capitalize;
                        text-align: right;

                        .profile-menu-arrow {
                            height: 14px;
                            padding-left: 4px;
                        }
                    }
                }
                .pinPoint {
                    //float: left;
                    width: 40px;

                    .withdrawIcon {
                        margin-top: 4px;
                        height: 40px;
                        .withdraw-1{fill:$thirdColor;}
                        .withdraw-2{fill:$white;}
                    }
                }
                .locationEtas {
                    font-size: 13px;
                    color: #b3b3b3;

                    .carEta {
                        height: 13px;
                    }
                    .walkEta {
                        height: 14px;
                        margin-left: 10px;
                    }
                }
            }
        }
    }

    &.bigSizeModal {
        .modal-content {
            height: 100%;
            width: 100%;
            animation: growModal .5s ease-in;

            .modal-header {
                height: 0px;
                padding-top: 0rem;
                padding-bottom: 0rem;
                animation: hideModalHead .5s ease-in;
            }

            .WithdrawModalBody {
                overflow: hidden;

                .LocationsScreen {
                    display: flex;
                    opacity: 1;
                    animation: showLocationContent 1s ease-in;
                }
            }
        }
    }

    button,
    a {
        &:focus,
        &:active {
            outline: none !important;
            box-shadow: none !important;
        }
    }

    .IconSVG {
        &.pinMapNew {
            height: 32px;

            .cls-1{fill:#ed1c24;}.cls-2{fill:#fff;}
        }
    }
}


.DepositOnlineScreen-final {
    text-align: center;
    color: initial;
    min-height: 300px;
    .ReturnMessage{
        padding: 1rem;
        margin: 3rem auto;
    }
    button.nextSCButton {
        font-size: 1rem;
        color: white;
        border: none;
        padding: .5rem 1rem;
        border-radius: .25rem;
        min-width: 50%;
        margin-bottom: 10px;
        background: rgba(223,85,25,1);
        background: -moz-linear-gradient(60deg, rgba(223,85,25,1) 0%, rgba(157,20,188,1) 100%);
        background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(223,85,25,1)), color-stop(100%, rgba(157,20,188,1)));
        background: -webkit-linear-gradient(60deg, rgba(223,85,25,1) 0%, rgba(157,20,188,1) 100%);
        background: -o-linear-gradient(60deg, rgba(223,85,25,1) 0%, rgba(157,20,188,1) 100%);
        background: -ms-linear-gradient(60deg, rgba(223,85,25,1) 0%, rgba(157,20,188,1) 100%);
        background: linear-gradient(60deg, rgba(223,85,25,1) 0%, rgba(157,20,188,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#df5519', endColorstr='#9d14bc', GradientType=1 );

    }
}

@keyframes growModal {
    0% {
        height: 60%;
        width: 615px;
    }
    100% {
        height: 100%;
        width: 100%;
    }
}

@keyframes normalModal {
    0% {
        height: 100%;
        width: 100%;
    }
    100% {
        height: 60%;
        width: 615px;
    }
}

@keyframes hideModalHead {
    0% {
        height: 74px;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
    100% {
        height: 0px;
        padding-top: 0rem;
        padding-bottom: 0rem;
    }
}

@keyframes showModalHead {
    0% {
        height: 0px;
        padding-top: 0rem;
        padding-bottom: 0rem;
    }
    100% {
        height: 74px;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
}

@keyframes showLocationContent {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: flex;
        opacity: 0;
    }
    100% {
        display: flex;
        opacity: 1;
    }
}

@keyframes hideLocationContent {
    0% {
        display: flex;
        opacity: 1;
    }
    99% {
        display: flex;
        opacity: 0;
    }
    100% {
        display: none;
        opacity: 0;
    }
}

.deposit-disabled {
    text-align: center;
    padding: 3rem 0;
    color: initial;
}