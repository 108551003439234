.DinamicImageContainer{
    display: grid;
    position: relative;
    width: 100%;
    overflow: hidden;

    .PlaceholderImage{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    .MainImage {
        max-width: 100%;
        object-fit: cover;
    }
    
    &.Loading{
        .PlaceholderImage{
            filter: blur(2vw);
            transform: scale(1.1);
            opacity: 1;
        }
    }

    &.Loaded{
        .PlaceholderImage{
            will-change: filter, transform, opacity;
            animation: reveal 1s ease-out;
        }
    }

    .loading-spinner {
        display: inline-block;
        position: absolute;
        z-index: 11;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 40px;
        height: 40px;
        opacity: 0;

        &.Loading{
            opacity: 1;
        }

        &.Loaded{
            will-change: opacity;
            animation: hide-loading .5s ease-out;
        }
    
        div {
           box-sizing: border-box;
           display: block;
           position: absolute;
           width: 32px;
           height: 32px;
           margin: 4px;
           border: 4px solid #ffb400;
           border-radius: 50%;
           animation: loading-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
           border-color: #ffb400 transparent transparent transparent;
    
           &:nth-child(1) {
             animation-delay: -0.45s;
           }
           &:nth-child(2) {
             animation-delay: -0.3s;
           }
           &:nth-child(3) {
             animation-delay: -0.15s;
           }
         }
    }
}
  
@keyframes reveal {
    0% { filter: blur(2vw); transform: scale(1.1); opacity: 1; }
    100% { filter: blur(0); transform: scale(1); opacity: 0; }
}

@keyframes hide-loading {
    0% { opacity: 1; }
    100% { opacity: 0; }
}


  @keyframes loading-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  