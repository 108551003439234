.virtuals-cs {
  top: 0 !important;
}

.virtuals-main-cs {
  margin-top: 40px;

  .virtuale-game-tile {
    flex: 1 1 22%;
    margin: 10px 5px;
  }

  #GamesList {
    padding-bottom: 60px;
  }

  .GamesCards {
    display: grid;
    padding-left: 2rem;
    padding-right: 2rem;
    grid-template-columns: repeat(6, 3fr);
    grid-template-rows: repeat(auto-fill, 1fr);
    grid-gap: 1.2rem;
    grid-row-gap: 0.9rem;
    grid-auto-rows: minmax(10.3rem, auto);
  }
}