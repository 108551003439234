.bets-component-winnerfunboost-button {
  display: none;
  position: absolute;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: -15px;
  right: 75px;

  .bcwb-header {
    border-radius: 13px;
    background: linear-gradient(to right, #FFB011, #FD6130);
    color: #fff;
    font-weight: bold;
    font-size: 8px;
    border: 3px solid #fff;
    margin-bottom: 2px;
    padding: 3px 0;
    width: 100px;
    text-align: center;

    &.bcwb-active {
      background: linear-gradient(to right, #65D8F5, #37DCE9);
    }
  }

  img {
    width: 38px;
    height: 38px;
  }
}

.scip {
  position: relative;

  &.active-boost {
    background: linear-gradient(to right, #F1F8FC, #AFFFFF 70%, #AFFFFF);

    .scip-first {
      .tov {
        color: #564DF5;
      }
    }

    .scip-third {
      background: linear-gradient(to right, #29F8FF, #1894E5 30%, #A902FF);
      background-size: 100% 55px;
      background-repeat: no-repeat;
      background-position: 0px 0px;
      animation: none !important;
    }
  }

  &.has-boost {
    margin-top: 20px;

    .scip-third {
      animation: none !important;

      &>div {
        margin-right: 15px;
        text-align: right;
      }
    }

    .bets-component-winnerfunboost-button {
      display: flex;
    }
  }
}