@import "../../variables.scss";

$animation: all .5s ease-in-out 0.5s;
$animation2: all .5s ease-in-out 0.5s;

#settings {
  //-webkit-transition: $animation;
  //-moz-transition: $animation;
  //-ms-transition: $animation;
  //-o-transition: $animation;
  //transition: $animation;
  position: fixed;
  top: 11.5rem;
  width: 0;//4rem;
  right: 0;
  z-index: 999;
  .content {
    //-webkit-transition: $animation;
    //-moz-transition: $animation;
    //-ms-transition: $animation;
    //-o-transition: $animation;
    //transition: $animation;
    box-shadow: 1px 1px 5px 0 rgba(0,0,0,.75);
    border-radius: 1.5rem 0 0 1.5rem;
    background-color: #f2f2f2;
    position: relative;
    width: 4rem;
    padding-bottom: 1px;
    right: 0;
    overflow: hidden;
    &:before {
      position: absolute;
      content: " ";
      width: 0;
      height: 70%;
      background-color: $thirdColor;
      top: 15%;
      opacity: 0;
      //-webkit-transition: $animation2;
      //-moz-transition: $animation2;
      //-ms-transition: $animation2;
      //-o-transition: $animation2;
      //transition: $animation2;
      left: -2rem;
    }
  }

  &:not(.expanded) {
    .content {
      width: 0;//.75rem;
      right: -3.25rem;
      &:before {
        opacity: 1;
        width: .25rem;
        left: 0.35rem;
      }
    }
  }
}