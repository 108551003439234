@import "./../../../../../variables";

.LocationsScreen {
    width: 100%;
    height: 100vh;
    background-color: #ffffff;
    display: flex;
    min-width: $minPageWidth;

    .locationsLeft {
        width: 26%;
        border-right: lighten($firstColor, 90%) 0px solid;
        height: 100%;
        box-shadow: 0px 0px 20px $firstColor;
        z-index: 3;
        min-width: 350px;

        .leftTitle {
            background-color: $withdrawModalGreen;
            width: 100%;
            color: #ffffff;
            height: 74px;
            line-height: 74px;
            display: flex;

            .titleButton {
                background: transparent;
                border: 0;
                color: #ffffff;
                width: 100%;

                .IconSVG {
                    height: 22px;
                    position: relative;

                    path {
                        fill: #ffffff;
                    }
                }

                .title-info {
                    .cls-1{fill:#fff;}
                    .cls-2{fill:#0a3d30;}
                }

                &:hover {
                    .IconSVG {
                        top: 2px;
                    }
                }

                &:active {
                    .IconSVG {
                        top: 3px;
                    }
                }
            }

            .titleBack {
                width: 20%;
                text-align: center;
            }

            .titleName {
                width: 60%;
                text-align: center;
                font-weight: 600;
                text-transform: uppercase;
            }

            .titleInfo {
                width: 20%;
            }
        }

        .LocationsList {
            height: 100%;
            overflow: hidden;
            color: #000;
            width: 100%;
            background-color: $bgListLocations;

            .rcs-custom-scroll {
                .rcs-custom-scrollbar {
                    width: 2px;
                    right: -2px;
                }

                .rcs-inner-handle {
                    background-color: #888888;
                    border-radius: 0px;

                }

                .rcs-inner-container {
                    & > div {
                        margin-right: 0 !important;
                    }
                }
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                padding-bottom: 85px;

                li {
                    border-bottom: 2px solid $withdrawSideLine;

                    &:last-child {
                        margin-bottom: 140px;
                    }

                    .SideLocation {
                        padding-top: 10px;
                        border-left: 5px solid #ffffff;
                        border-right: 7px solid #ffffff;
                        animation: normalBorderColor 500ms ease-out;
                        background-color: #ffffff;

                        .top {
                            display: flex;
                            cursor: pointer;

                            .left {
                                width: 80%;

                                .details {
                                    width: 100%;
                                    padding-left: 14px;
                                    display: flex;
                                    min-height: 64px;

                                    h1 {
                                        font-size: 13px;
                                        margin-bottom: 0;
                                    }

                                    p {
                                        font-size: 11px;
                                        color: $withdrawSideGray;
                                        padding-right: 8px;
                                    }

                                    .icon {
                                        overflow: hidden;
                                        width: 0px;
                                        animation: normalPin 200ms ease-in;
                                        position: relative;
                                        //left: -5px;

                                        .IconSVG {
                                            height: 30px;
                                            fill: $withdrawPin;
                                            //margin-right: 8px;
                                        }
                                    }
                                }

                                .side-location-eta {
                                    padding-left: 14px;
                                    padding-top: 6px;
                                    display: flex;
                                    justify-content: stretch;
                                    color: $grayColor;
                                    font-weight: normal;

                                    .carEta,
                                    .walkEta {
                                        height: 12px;

                                        .cls-1 {
                                            fill: $grayColor;
                                        }
                                    }

                                    .walkEta {
                                        height: 14px;
                                    }

                                    span {
                                        padding-left: 5px;
                                        font-size: 11px;
                                    }

                                    p {
                                        &:first-child {
                                            span {
                                                padding-right: 20px;
                                            }
                                        }
                                    }
                                }
                            }

                            .right {
                                padding-right: 14px;
                            }
                        }

                        .button {
                            height: 0;
                            overflow: hidden;
                            animation: normalElem 500ms ease-out;
                            cursor: pointer;
                            width: 100%;
                            padding-left: 12px;
                            padding-right: 14px;

                            .selectArea {
                                display: flex;
                                height: 75px;
                                position: relative;
                                bottom: 0;
                                background-color: $buttonYellow;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                font-weight: 600;
                                width: 100%;
                                //margin: 10px auto;
                                border-radius: 6px;
                                border: 1px solid darken($buttonYellow, 4%);

                                &:hover {
                                    background-color: darken($buttonYellow, 2%);
                                }

                                &:active {
                                    bottom: -2px;
                                }

                                .wIcon {
                                    width: 20%;

                                    .IconSVG {
                                        height: 50px;

                                        .withdraw-1{fill:$fourthColor;}
                                        .withdraw-2{fill:$buttonYellow;}
                                    }
                                }

                                .wText {
                                    width: 40%;
                                }

                                .wAmount {
                                    width: 40%;
                                    color: $fourthColor;
                                    font-size: 26px;

                                    .currency-value {
                                        padding-right: 10px;
                                        white-space: pre-line;
                                    }
                                }
                            }
                        }

                        &.selected {
                            border-left: 5px solid $thirdColor;
                            border-right: 7px solid $thirdColor;
                            animation: selectedBorderColor 1000ms ease-out;

                            .button {
                                height: 95px;
                                //animation: selectedElem 400ms cubic-bezier(.17,.67,.83,.67);
                                animation: selectedElem 500ms ease-out;
                            }

                            .top {
                                cursor: default;

                                .details {
                                    .icon {
                                        width: 38px;
                                        animation: selectedPin 200ms ease-in;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .locationsMap {
        width: 74%;
        height: 100%;
        display: block;
        z-index: 1;
    }
}

@keyframes normalElem {
    0% {
        height: 95px;
    }
    100% {
        height: 0;
    }
}

@keyframes selectedElem {
    0% {
        height: 0;
    }
    100% {
        height: 95px;
    }
}

@keyframes normalPin {
    0% {
        width: 38px;
    }
    100% {
        width: 0;
    }
}

@keyframes selectedPin {
    0% {
        width: 0;
    }
    100% {
        width: 38px;
    }
}

@keyframes selectedBorderColor {
    0% {
        border-left-color: #ffffff;
        border-right-color: #ffffff;
    }
    100% {
        border-left-color: $thirdColor;
        border-right-color: $thirdColor;
    }
}

@keyframes normalBorderColor {
    0% {
        border-left-color: $thirdColor;
        border-right-color: $thirdColor;
    }
    100% {
        border-left-color: #ffffff;
        border-right-color: #ffffff;
    }
}