/** Sterge **/
.OldSiteLayoutMessages
{
  background-color: #feb400;
  color: white;
  display: block;
  font-size: .75rem;
  padding: 0 2rem;
  z-index: 1019;
  animation-name: blink;
  animation-duration: 2s;
  animation-delay: 5s;
  animation-fill-mode: forwards;
  position: sticky;
  text-align: center;
  top: 85px;
  &.hide {
    width: 100%;
    margin: 0 auto;
    display: none;
    //animation: close 3s;
    //animation-delay: 0s;
    //animation-fill-mode: forwards;
  }
  .close {
    right: 1.5rem;
    position: absolute;
    top: 0.45rem;
    cursor: pointer;
    svg {
      fill: white;
      max-width: .5rem;
    }
  }
}

.OldSiteLayoutMessages p
{
  padding: 0;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}
.OldSiteLayoutMessages a {
  color: white;
}
@keyframes blink {
  0% {
    background-color: #1D45DB;
  }
  9% {
    background-color: #1D45DB;
  }
  10% {
    background-color: #feb400;
  }
  19% {
    background-color: #feb400;
  }
  20% {
    background-color: #1D45DB;
  }
  29% {
    background-color: #1D45DB;
  }
  30% {
    background-color: #feb400;
  }
  39% {
    background-color: #feb400;
  }
  40% {
    background-color: #1D45DB;
  }
  49% {
    background-color: #1D45DB;
  }
  50% {
    background-color: #feb400;
  }
  59% {
    background-color: #feb400;
  }
  60% {
    background-color: #1D45DB;
  }
  69% {
    background-color: #1D45DB;
  }
  70% {
    background-color: #feb400;
  }
  79% {
    background-color: #feb400;
  }
  80% {
    background-color: #1D45DB;
  }
  89% {
    background-color: #1D45DB;
  }
  90% {
    background-color: #1D45DB;
  }
  100% {
    background-color: #1D45DB;
  }
}

@keyframes close {
  15% {
    width: calc(100% - 20px);
    margin: auto 20px;
    top: 0;
    z-index: 1021;
    background-color: #1D45DB;
  }
  32% {
    top: -100px;
    background-color: #1D45DB;
  }
  99% {
    width: 200px;
    top: -100px;
    z-index: 1021;
    background-color: #1D45DB;
  }
  100% {
    height: 0;
    top: -100px;
    z-index: 1019;
    margin: auto 20px;
    background-color: #1D45DB;
  }
}