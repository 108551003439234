$activeColor: #007048;
$color: #cccccc;
$fontColor: #333333;
.exclusion-choice {
  width: calc(33% - 2rem);
  display: inline-block;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1rem;
  padding: 2rem;
  border: 2px solid $color;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  border-radius: .5rem;
  color: $fontColor;
  -webkit-transition: all .2s ease-in-out ;
  -moz-transition: all .2s ease-in-out ;
  -ms-transition: all .2s ease-in-out ;
  -o-transition: all .2s ease-in-out ;
  transition: all .2s ease-in-out ;
  &.active {
    color: $activeColor;
    border-color: $activeColor;
  }
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px;
    cursor: pointer;
  }
  &.disabled {
    pointer-events: none;
  }
}