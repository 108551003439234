.wf-gratuities-boost {
  .wf-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 22px;

    .wf-icon img {
      display: block;
      width: 26px;
      height: 26px;
      margin-right: 4px;
    }

    .wf-title {
      color: #fff;
      text-align: left;
      font-size: 22px;
      font-weight: bold;
      line-height: 22px;
    }
  }

  .wf-notification {
    border-radius: 12px;
    background: linear-gradient(to right, #0B0C6E, #4041B1);
    padding: 3px;
    margin-bottom: 5px;

    .wf-wrapper {

      border-radius: 12px;
      background: linear-gradient(to right, #1416BA, #0D0E83);
      color: #AEC1F2;
      font-size: 12px;
      font-weight: normal;
      text-align: center;
      padding: 5px;
    }

  }

  .wf-body {
    //background-image: url(../../assets/gratuities-hourly-bg@3x.png);
    background-position: center center;
    //border-radius: 12px;
    background-size: 100% 100%;

    display: flex;
    align-items: center;
    padding: 0 0 7px;
    justify-content: space-between;

    //box-shadow: -6px -6px 15px 0 #3B3CBC, 6px 6px 20px 0 rgba(1, 0, 17, .8);
    //border: 1px solid #341EDB;



    .wf-first {
      width: 50%;
      text-align: center;
      cursor: pointer;

      .wf-wrapper {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .24) 60%, rgba(0, 0, 0, .6));
        border-radius: 28px;
        padding: 6px 12px;

        .wf-top {
          margin-bottom: 0px;
          margin-top: 5px;

          img {
            width: 150px;
          }
        }

        .wf-bottom {
          .wf-boosts {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .wf-boost {
              width: 41px;
              height: 41px;
              background: url(./assets/boost-unclaimed.png);
              background-repeat: no-repeat;
              background-position: left top;
              background-size: 100% 100%;
              position: relative;
            }

            .wf-boost.wf-claimed {
              background-image: url(./assets/boost-claimed.png);
            }

            .wf-boost.wf-claim {
              background-image: url(./assets/boost-claim.png);

              &.pulse-anim {
                box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
                animation: pulse_anim_keys 3s infinite;
                border-radius: 50%;
              }

              @keyframes pulse_anim_keys {
                0% {
                  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
                }

                50% {
                  box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
                }

                100% {
                  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
                }
              }
            }

            .wf-boost.wf-locked {
              background-image: url(./assets/boost-locked.png);
            }
          }
        }
      }
    }


    .wf-second {
      width: 50%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;

      .wf-timer-text {
        text-align: center;
        font-size: 14px;
        font-weight: normal;
        color: rgba(255, 255, 255, 0.45);
      }

      .wf-timer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 158px;
        margin: 0 auto;

        .wf-days {
          font-size: 35px;
          color: #fff;
          font-weight: 600;
          text-align: center;
        }

        &>div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 0 5px;



          .wf-title {
            color: #fff;
            font-size: 35px;
            text-align: center;
            font-weight: 600;
          }

          .wf-subtitle {
            color: rgba(255, 255, 255, 0.45);
            font-size: 14px;
            text-align: center;
            font-weight: normal;
          }

          .wf-title.off {
            color: rgba(255, 255, 255, 0.22);
          }
        }

        &>div:first-child {
          margin-left: 0;
        }

        &>div:last-child {
          margin-right: 0;
        }
      }

      .wf-title {
        color: #FF8900;
        font-size: 14px;
        font-weight: normal;
        text-align: center;
      }

      .wf-text {
        color: rgba(255, 255, 255, .75);
        font-size: 11px;
        font-weight: 600;
        text-align: center;
        margin: 8px 10px 8px;
      }

      .wf-collect {
        background: linear-gradient(to right, #9B9B9B, #B9B9B9);
        pointer-events: none;
        text-align: center;
        font-size: 11px;
        font-weight: 600;
        color: #fff;
        padding: 6px 20px;
        margin-top: 17px;
        cursor: pointer;

        border-radius: 7px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16);
        max-width: 104px;
        white-space: nowrap;
        display: flex;
        align-items: center;

        &.wf-ready {
          background: linear-gradient(to right, #FF8800, #FFD500);
          pointer-events: all;
          box-shadow: -6px -6px 10px 0 #0D0D4A, 0 2px 2px 0 rgba(0, 0, 0, .16);
        }

        .wf-loader {
          display: none;
          margin-left: 10px;
          position: relative;
          top: 2px;

          &>div {
            display: inline-block;
            width: 16px !important;
            height: 16px !important;
            color: #fff
          }
        }

        &.wf-loading {
          max-width: 160px;
          pointer-events: none;

          .wf-loader {
            display: inline-block;
          }
        }
      }
    }
  }
}