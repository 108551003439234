@import './../../../../variables.scss';

.DocumentsPage {
    padding-left: 3rem;
    padding-right: 3rem;

    .playerDetails {
        border-bottom: 1px solid $iconGray;
        padding-bottom: 3rem;

        h1 {
            color: $black;
            font-weight: bold;
            font-size: 24px;
            text-transform: capitalize;
            margin: 0;
            padding: 0;
            padding-bottom: 10px;
            padding-left: 10px;
        }

        p {
            font-size: 13px;
            color: $grayColor;
            margin: 0;
            padding: 0;
            padding-left: 10px;
        }
        span {
            &.verified {
                color: green;
            }
            &.not-verified {
                color: red;
            }
        }

        .SendEmail{
            margin-top: 10px;
            cursor: pointer;
            font-weight: bold;
            padding: 10px 26px;
            transition: all 0.1s ease-in;
            position: relative;
            top: 0;
            color: #ff971d;
            border-color: #ff971d;
            font-size: 0.75rem;
            width: 250px;
            text-transform: uppercase;
            &:hover{
                background-color: #ff971d;
                border-color: #ff971d;
                color: #fff;
            }
            &:active{
                top: 2px;
                background-color: #ffa640;
                border-color: #ffa640;
                color: #fff;
            }
        }
        
        .SendEmailDone{
            text-transform: uppercase;
            margin-top: 10px;
            font-weight: bold;
            padding: 10px 26px;
            transition: all 0.1s ease-in;
            position: relative;
            top: 0;
            color: #155724;
            background-color: #d4edda;
            border-color: #c3e6cb;
            font-size: 0.75rem;
            width: 250px;
        }
    }
    
    .docsSections {
        list-style: none;
        margin: 0;
        padding: 0;
        
        .safe-message {
            text-align: center;
            padding-top: 3rem;
            padding-bottom: 3rem;

            a {
                color: $thirdColor;
                font-size: 12px;
                text-align: center;
            }
        }
    }
}