.stepSmsCode {
    .step-footer {
        position: relative;

        .buttonsSupport {
            position: relative;
            top: 0;
            transition: .2s ease-in all;

            &.hide {
                top: 160px;
            }
        }
    }
}