$bets-bg: linear-gradient(to right, #E42313 0%, #D41101 100%);
$bets-del: #df1d0d;
$bets-tag-bg:linear-gradient(to right, #06D2BD 0%, #009ED9 100%);
$bets-tag-del: #009ED9;
$bets-title-bg:#9B0F04;
$bets-q-mark-bg:#7F0B02;
$bets-q-mark-brd: #D41101;

.bets-prematch-front {
  height: 122px;
  background: url(../../assets/bets/bg.png), $bets-bg;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 7px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, .4);

  * {
    box-sizing: border-box;
  }

  .hh-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .header-tag-wrapper {
      width: 108px;
      position: relative;
      margin-right: 16px;
      overflow: visible;
      border-bottom: 2px solid #fff;

      .header-tag {
        width: 108px;
        height: 20px;
        text-align: center;
        line-height: 18px;
        color: #fff;
        font-size: 10px;
        font-weight: 600;
        font-style: italic;
        position: relative;

        background: $bets-tag-bg;

        .delimiter {
          position: absolute;
          top: 0;
          right: -16px;

          .delimiter-bg {
            fill: #fff;
          }

          .delimiter-left {
            fill: $bets-tag-del;
          }

          .delimiter-right {
            fill: $bets-del;
          }
        }
      }
    }

    .title {
      background-color: $bets-title-bg;
      border-radius: 10px;
      height: 21px;
      min-width: 0;
      flex: 1 1 auto;
      margin: 1px 9px 0 0px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      & div {
        min-width: 0;
        flex: 1 1 auto;
        color: #fff;
        font-size: 11px;
        font-weight: 600;
        padding-left: 11px;
        text-align: left;
      }

      .q-mark {
        min-width: 21px;
        max-width: 21px;
        width: 21px;
        height: 21px;
        border-radius: 50%;
        background-color: $bets-q-mark-bg;
        border: 1px solid $bets-q-mark-brd;
        color: #fff;
        font-size: 15px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        padding-left: 1px;
      }
    }
  }

  .hh-info-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 7px;
    padding: 0 10px;

    &>div {
      text-align: center;
      flex: 1 1 0px;
    }

    .name {
      color: #fff;
      font-size: 12px;
      font-weight: bold;
      text-align: left;
      width: 60px;
      min-width: 60px;
      max-width: 60px;
      text-transform: uppercase;
    }

    .hh-timer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      min-width: 80px;
      max-width: 80px;

      .hh-hour,
      .hh-min,
      .hh-sec {
        .hh-top {
          font-size: 18px;
          font-weight: 600;
          color: #fff;
          text-align: center;
          line-height: 18px;
        }

        .hh-bottom {
          font-size: 8px;
          font-weight: normal;
          color: #FFFFFF;
          text-align: center;
          opacity: .5;
        }
      }

      .hh-min {
        margin: 0 5px;
      }

      .disabled {
        opacity: 0.4;
      }
    }

    .hh-pills {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;

      .hh-pill {
        width: 12px;
        height: 8px;
        border: 1px solid transparent;
        opacity: 1;
        background: #000;
        margin: 0 3px;
        border-radius: 4px;

        &.op-6 {
          opacity: 0.06;
        }

        &.op-20 {
          opacity: 0.2;
        }


        &.ml-5 {
          margin-left: 5px !important;
        }

        &.mr-5 {
          margin-right: 5px !important;
        }

        &.m-3 {
          margin: 0 3px !important;
        }

        &.active {
          width: 16px;
          height: 10px;
          border: 1px solid #fff;
          background: #E33114;
          margin: 0;
          border-radius: 5px;
        }
      }
    }

    .hh-pills-text {
      line-height: 17px;
      text-align: center;

      .hh-current-prize {
        font-size: 13px;
        font-weight: bold;
        color: #fff;
        padding-left: 20px;
      }

      .hh-all-prizes {
        font-size: 10px;
        font-weight: bold;
        color: #fff;
        opacity: .3;
      }
    }
  }

  .hh-prizes {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 6px;

    .hh-prize-after,
    .hh-prize-before {
      height: 29px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #C0C0C0;
      font-size: 13px;
      font-weight: bold;
      min-width: 64px;
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 31px;
    }

    .hh-prize-after {
      color: #FFD759;
    }

    .hh-prize-current {
      margin: 0 8px;
      height: 51px;
      min-width: 148px;
      background-color: rgba(0, 0, 0, 0.37);
      border: 1px solid #AAA8D4;
      border-radius: 31px;

      .hh-flx {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 4px;

        .hh-img {
          svg {
            width: 25px;
            height: auto;
            position: relative;
            left: -4px;
            vertical-align: middle;
          }

          img {
            width: 25px;
            height: auto;
            position: relative;
            left: -4px;
          }
        }

        .hh-txt {
          color: #FFC400;
          font-size: 22px;
          font-weight: bold;
        }
      }

      .hh-bet {
        color: #dcc9c9;
        text-align: center;
        font-size: 12px;
        font-weight: normal;
        line-height: 12px;
      }
    }

    .hh-special-prize-front.hidden,
    .hh-prize-before.hidden,
    .hh-prize-after.hidden {
      opacity: 0;
    }

    .hh-special-prize-front {
      width: 70px;
      height: 50px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      overflow: visible;

      .hh-sp-img {
        display: block;
        max-width: 40px;
        height: auto;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top: 0px;
      }

      .hh-sp-title-front {
        color: #F4C850;
        font-size: 11px;
        font-weight: bold;
        text-align: center;
      }
    }
  }


  .hh-info-row.has-sp {

    .name {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      text-align: center;

      font-size: 12px;
    }

    .name-wrapper {
      .sp-name {
        color: #FFD23F;
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
        text-align: center;
      }
    }


    .hh-timer {
      width: 100%;
      min-width: 100%;
      max-width: 100%;

      .hh-hour,
      .hh-min,
      .hh-sec {
        .hh-top {
          font-size: 14px;
        }

        .hh-bottom {
          font-size: 6px;
        }
      }
    }
  }
}