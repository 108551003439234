.wheel-award-dialog {
  .award-dialog-body {
    padding-top: var(--notch-top-offset, 0px);
    position: relative;

    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #0A0F8D, rgba(14, 20, 155, .7) 43.9%, rgba(21, 31, 184, .6) 55%, #0F1678);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: left top;

    .close-header {
      display: flex;
      justify-content: flex-end;
      padding: 10px 10px 0;
      background: url("../assets/dialog-bg-header.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: left bottom;
    }

    .award-dialog-title {
      display: flex;
      justify-content: center;
      color: #C4C6FF;
      font-size: 27px;
      font-weight: bold;
    }

    .award-dialog-description {
      margin-top: 50px;
      padding: 0 20px;
      background: linear-gradient(to right, rgba(56, 140, 24, 0), #34AA06, #34AA06, rgba(56, 140, 24, 0));
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .subtitle {
        margin-top: 3px;
        color: #FFF;
        font-size: 15px;
        font-weight: 800;
      }

      .title {
        color: #FFF;
        font-size: 30px;
        font-weight: 800;
      }
    }

    .awards-content {
      padding: 25px 40px 0;

      img {
        max-width: 100%;
      }
    }

    .awards-footer {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      background: linear-gradient(to bottom, #2D34AE, #0C115F);

      .title {
        color: rgba(255, 255, 255, .7);
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
        margin-top: 8px;
      }

      .subtitle {
        color: #FFF;
        font-size: 15px;
        font-weight: 600;
        text-align: center;
        margin-top: 15px;
        line-height: 25px;
        padding: 0 20px;
      }

      .skip {
        padding: 20px 34px 30px;
        display: flex;
        justify-content: center;

        .exterior {
          max-width: 220px;
          background: linear-gradient(to right, rgba(230, 111, 31, .17), rgba(248, 21, 120, .17));
          border-radius: 12px;
          padding: 4px;

          .middle {
            background: linear-gradient(to right, #E66F1F, #FF3E92);
            border-radius: 11px;
            padding: 4px;

            .interior {
              background: linear-gradient(to right, #171B58, #3535E8);
              border: 1px solid #fff;
              border-radius: 9px;
              height: 52px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 40px;

              font-size: 14px;
              font-weight: bold;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.wheel-award-dialog-body {
  .wheel-award-dialog {
    .MuiPaper-root {
      background-color: transparent;
    }
  }
}