.AccountModal.show {
    transition: opacity .4s ease-in-out;
    z-index: 1350!important;
}

.AccountModal.show .modal-dialog
{
    transition: transform .6s ease-in-out;
    -webkit-transition: -webkit-transform .5s ease-in-out;
}

.AccountModal .modal-dialog .modal-content
{
    border-radius: 0px;
    min-width: 328px;
    width: 450px;
}

.AccountModal .modal-dialog .modal-content .modal-header
{
    border-radius: 0px;
}

.AccountModal .modal-dialog .modal-content .modal-header .modal-title
{
    width: 450px;
    text-transform: uppercase;
    font-weight: normal;
    position: fixed;
    padding-right: 2rem;
    font-size: 16px;
    line-height: 36px;
}

.AccountModal .modal-dialog .modal-content .modal-header .close
{
    margin: -1rem -1em -1rem auto;
    padding-top: 0rem;
    padding-bottom: 0rem;
    position: relative;
}

.AccountModal .modal-dialog .modal-content .modal-header .close span
{
    font-size: 4rem;
    font-weight: normal;
}

.AccountModal .modal-dialog .modal-content .modal-footer
{
    border-radius: 0px;
    padding-top: 30px;
}

.AccountModal .modal-dialog .modal-content .modal-footer .modal-footer-bottom .info-text
{
    line-height: 24px;
    font-size: 11px;
    font-weight: bold;
}

.AccountModal .modal-dialog .modal-content .modal-footer .modal-footer-body .sms-action-message
{
    font-size: 12px;
    height: 59px;
}

.AccountModal .modal-dialog .modal-content .modal-footer .modal-footer-body .sms-action-message span
{
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 5px;
}

.AccountModal .modal-dialog .modal-content .modal-footer .modal-footer-body .sms-action-promo
{
    font-weight: bold;
}

.AccountModal .modal-header .modal-back
{
    border: none;
    background: transparent;
    -webkit-appearance: none;
    cursor: pointer;
    padding: 1rem;
    line-height: 1;
    text-shadow: 0 1px 0 #ffffff;
    margin: -1rem auto -1rem -1rem;
    float: left;
    z-index: 99;
    padding-top: 1.3rem;
}

.AccountModal .modal-dialog .modal-content .modal-body
{
    overflow: hidden;
    width: 448px;
    padding: 1rem 0 0 0;
}

.AccountModal .modal-dialog .modal-content .modal-body .modal-body-scene .step-wizard_2cyMY
{
    min-height: 300px;
}

/**
.AccountModal .modal-dialog .modal-content .modal-body .modal-body-scene
{
    width: 890px;
    position: relative;
    left: -450px;
}
*/