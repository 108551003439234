$firstColor: #0d0d0d;
$secondColor: #f4e200;
$thirdColor: #017048;
$fourthColor: #941b20;
$white: #ffffff;
$grayColor: #808080;
$black: #000000;
$iconGray: #cccccc;
$redColor: #c61113;
$warningColor: #c20002;
$infoColor: #0071bc;
$dangerColor: #f7931e;
$buttonGreen: #0a4230;
$buttonYellow: #fba819;
$docsUploadModalHeader: #09d58a;
$iconGrayTwo: #999999;
$almostBlack: #111111;
$magenta: #9b36aa;
$backgroundColor: #ffffff;
$secondBackgroundColor: #e6f0f7;
$gradientStartColor: rgba(223, 83, 26, 1);
$gradientEndColor: rgba(151, 15, 183, 1);

$rewardBoxColor: #48cc01;

$footerTextColor: #d5ad03;

$minPageWidth: 1020px;

$hideMenuMaxWidth: 1240px;

$waitingBackground: #fde9d2;
$failedBackground: #f3cccc;
$processedColor: #09ba8a;
$processedBackground: #cef1e8;

$orangeColor: #ff530d;

/* Transactions History */
$historyGray: #888888;
$historyBlack: #333333;
$historyPositive: #017048;
$historyNegative: #c20002;
$historyTransactionSuccess: #09ba8a;
$historyTransactionPending: #f7931e;
$historyTransactionFailed: #c20002;
$historyBottomLine: #f0f0f0;

/* Withdraw new modal -- pentru ca este alta schema de culori in PLM */
$withdrawModalGreen: #0a4230;
$withdrawModalLightGreen: #d0d6d3;
$withdrawSideGray: #959595;
$withdrawSideDark: #474747;
$withdrawSideLine: #e5e5e5;
$withdrawPin: #ed1c24;
$withdrawHorLine: #e6e6e6;

/* Deposits */
$bgListLocations: #f2f2f2;

/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;600;700;800');
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,600');
@import url('https://fonts.googleapis.com/css?family=Raleway:700');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

$exampleBg: #ebf2f7;
$exampleFont: #217cb5;

// TitleBar Settings
$titleBarBGColor: rgb(13, 23, 33);
$titleBarFontColor: rgb(255, 255, 255);