.component-competition_promotion_2 {
  margin: 10px auto;
  padding-bottom: 5px;
  max-width: 1528px;

  .cgl-super-header {
    margin: 0 10px;

    .category {
      color: #353540;
      font-size: clamp(10px, 1vw, 12px);
      font-weight: bold;
      text-align: left;
    }
  }

  .cgl-header {
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      color: #353540;
      font-size: 18px;
      font-size: clamp(18px, 1.8vw, 26px);
      font-weight: 600;
      text-align: left;
    }

    .link {
      color: #353540;
      font-size: 12px;
      font-size: clamp(12px, 1.2vw, 17px);
      font-weight: 600;
      text-align: left;
      cursor: pointer;
      border: 1px solid #BEC9D6;
      border-radius: 11px;
      padding: 2px 12px 3px;
    }
  }

  .cgl-list {
    overflow-y: hidden;
    overflow-x: auto;
    margin-top: 10px;

    .cgl-grid {
      width: 100%;
      display: grid;
      overflow: visible;
      white-space: nowrap;
      pointer-events: all;
      padding-bottom: 10px;

      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      &::before,
      &::after {
        content: '\00a0';
        min-width: 9px;
        max-width: 9px;
      }

      .cgl-item {
        min-width: 320px;
        max-width: 320px;
        width: 100%;
        margin-right: 10px;
        cursor: pointer;
        border-radius: 7px;
        background-color: #fff;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .5);

        &.empty {
          max-width: 186px;
        }

        .outer {
          width: 100%;
          padding-top: 40%;
          position: relative;

          .inner {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 7px;
        }

        .cgl-text-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          .cgl-title {
            position: absolute;
            left: 10px;
            right: 10px;
            top: 27px;
            color: #fff;
            font-size: 19px;
            font-weight: 600;
            text-align: left;
          }

          .cgl-footer-wrapper {
            position: absolute;
            bottom: 6px;
            left: 10px;
            right: 6px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-end;

            .cgl-date {
              text-align: left;
              font-size: 8px;
              font-weight: bold;
              color: #192640;
              padding-bottom: 5px;

              &>div {
                display: inline-block;
                padding: 2px 10px;
                background-color: #FFC400;
                border-radius: 3px;
              }
            }

            .cgl-footer-title {
              padding-bottom: 5px;
              font-size: 15px;
              line-height: 18px;
              font-weight: 600;
              color: #fff;
              text-align: left;
              white-space: normal;
            }

            .cgl-footer-subtitle {
              padding-bottom: 5px;
              font-size: 11px;
              font-weight: 600;
              color: #fff;
              text-align: left;
              white-space: normal;
            }

            .cgl-btn {
              cursor: pointer;
              font-size: 11px;
              font-weight: 600;
              padding: 10px 22px;
              color: #fff;
              border-radius: 8px;
              //box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.5);
              background: linear-gradient(90deg, #06D2BD 0%, #1F83E6 100%);
            }
          }

        }


        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}