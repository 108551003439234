.bets-winner-fun-welcome-wrapper {
  overflow: hidden;
  background: #EDF0F0;

  .bets-winner-fun-welcome {
    margin: 10px 10px 6px;

    .wf-wrapper {
      cursor: pointer;
      margin: 0 auto;
      border-radius: 10px;
      background: linear-gradient(45deg, #FF7800, #FFC300);
      padding: 6px;
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, .5);

      .wf-inner-wrapper {
        background: url(../../assets/winner-fun/winner-fun-welcome@3x.png);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: cover;

        border-radius: 10px;
        background-color: #fff;
        min-height: 100px;
        display: flex;
        align-items: flex-start;
        justify-content: stretch;
        padding: 10px;

        &>* {
          align-self: stretch;
        }

        .wf-logo {
          margin-right: 22px;

          img {
            display: block;
            width: 60px;
            height: 60px;
            margin-top: 3px;
          }
        }

        .wf-content {
          flex: 1 1 100%;
          margin-right: 5px;

          .wf-header {
            text-align: left;
            color: #151D26;
            font-size: 16px;
            font-weight: bold;
          }

          .wf-text {
            text-align: left;
            color: #151D26;
            font-size: 12px;
            font-weight: normal;
          }
        }

        .wf-close {
          color: #000;
          width: 30px;
          min-width: 30px;
          max-width: 30px;
          border-left: 2px solid rgba(0, 0, 0, .1);

          &>div {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            svg {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }
  }
}