@import "./../../../../../../../variables";

.TakeABreakModal {
    .modal-content {
        border-radius: 0;
        border-color: rgba(0, 0, 0, 0.0);

        .TakeABreakModalBody {
            height: 500px;
            overflow: hidden;
            color: $black;

            .step-header {
                text-align: center;
                padding-top: 20px;

                h2 {
                    font-weight: normal;
                    font-size: 26px;

                }
            }

            .step-content {
                height: 300px;
            }

            .step-message {
                height: 80px;
            }

            .step-footer {
                .nextButton {
                    color: $buttonGreen;
                    font-weight: bold;
                    background-color: $buttonYellow;

                    .fillMe{
                        fill: $buttonGreen !important;
                    }
        
                    &:hover {
                        background-color: lighten($buttonYellow, 5%);
                    }
        
                    .text {
                        padding-left: 5px;
                    }
                    .animate {
                        display: inline-block;
	                    animation: rotate-center 1s linear infinite both;
                    }
                    
                   
                }
            }
        }

        .TakeABreakModalHeader {
            color: $black;
            text-align: center;
            text-transform: uppercase;
            border-bottom: 0;

            h5 {
                width: 100%;
                font-size: 14px;
                font-weight: bold;
            }

            button.close {
                position: absolute;
                top: -5px;
                right: 0;
                font-size: 60px;
                font-weight: normal;
                opacity: 1;
                color: $thirdColor;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}

@keyframes rotate-center {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}