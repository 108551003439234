.cql-simple-timer {
  background: rgba(255, 255, 255, .22);
  border: 1px solid rgba(255, 255, 255, .3);
  border-radius: 10px;
  padding: 3px 10px 4px;

  .timer-counter {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    &>div {
      padding-right: 20px;
    }

    &>div:last-child {
      padding-right: 0;
    }

    .timer-title {
      color: #fff;
      font-size: 11px;
      font-weight: bold;
      text-align: center;
    }

    .timer-subtitle {
      color: #fff;
      font-size: 5px;
      font-weight: 600;
      text-align: center;
      opacity: .8;
    }
  }

  .timer-live,
  .timer-calendar {
    font-size: 8px;
    font-weight: bold;
    color: #fff;

    .muted {
      opacity: .8;
    }
  }

  @media screen and (min-width: 768px) {
    padding: 5px 15px 6px 15px;

    .timer-counter {
      .timer-title {
        font-size: 13px;
      }

      .timer-subtitle {
        font-size: 7px;
      }
    }

  }
}