.scalable-text {
  display: block;

  span.sport-tournament-points {
    font-size: 35px;
    font-weight: 700;
    color: #586682;
    line-height: 35px;
    font-family: OpenSans, "Open Sans";
  }

  span.sport-tournament-points.five {
    font-size: 28px;
  }

  span.sport-tournament-points.seven {
    font-size: 20px;
  }

  span.sport-tournament-points.nine {
    font-size: 20px;
  }

  text {
    font-size: 28px;
    font-weight: bold;
    font-family: OpenSans, "Open Sans";
    fill: #fff;
  }
}

svg.scalable-text {
  width: 100%;
  height: auto;
  max-width: 44px;
  max-height: 38px;
}