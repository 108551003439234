.component-games-list-promoted {
  margin: 10px auto;
  padding-bottom: 10px;
  max-width: 1528px;
  width: 100%;

  position: relative;
  overflow-y: hidden;
  overflow-x: auto;

  .gcl-inner-wrapper {
    display: inline-block;
    margin: 0 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .2);
    border-radius: 10px;
    background-color: #fff;
  }

  .cgl-header {
    height: 45px;
    margin: 0 0 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    max-width: calc(100vw - 40px);
    position: sticky;
    left: 20px;

    .title {
      color: #fff;
      font-size: 18px;
      font-size: clamp(18px, 1.8vw, 26px);
      font-weight: 600;
      text-align: left;
    }

    .link {
      color: #fff;
      font-size: 12px;
      font-size: clamp(12px, 1.2vw, 17px);
      font-weight: 600;
      text-align: left;
      cursor: pointer;
      white-space: nowrap;
      border: 1px solid #BEC9D6;
      border-radius: 11px;
      padding: 2px 12px 3px;

      span {
        padding: 0 5px;
      }

      svg {
        position: relative;
        top: -1px;
      }
    }
  }

  .cgl-header-wrapper {
    height: 45px;
    background: linear-gradient(to right, #FF2751, #F68200);
    border-radius: 10px 10px 0 0;
  }

  .cgl-list {
    overflow: visible;
    margin: 0;

    .cgl-grid {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      pointer-events: all;
      position: relative;
      padding: 10px 0;

      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      &::before,
      &::after {
        content: '\00a0';
        min-width: 9px;
        max-width: 9px;
      }

      .cgl-item {
        min-width: 115px;
        width: 100%;
        margin-right: 2px;
        cursor: pointer;
        overflow: visible;

        &.empty {
          max-width: 115px;
        }

        .outer {
          width: 100%;
          padding-top: 146%;
          position: relative;
          overflow: visible;

          .inner {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: visible;
          }
        }

        &:not(.more) {
          .inner>div {
            transition: transform .25s ease;
          }

          .inner>div:hover {
            position: relative;
            transform-origin: center;
            transform: translateZ(0) scale(1.05);
            z-index: 1;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;

        }

        &.more {
          .inner>div {
            margin: 4px 2px;
            height: calc(100% - 8px);
            background: linear-gradient(to bottom, #001A6D, #0A122B);
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 30px;
            font-weight: bold;
            white-space: nowrap;
            border-radius: 11px;
          }

          @media screen and (min-width:1528px) {
            display: none;
          }
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    .cgl-grid.large {

      &::before,
      &::after {
        min-width: 6px;
        max-width: 6px;
      }


      .cgl-item {
        min-width: 164px;

        &.empty {
          max-width: 164px;
        }
      }
    }
  }

  .gcl-footer {
    margin-bottom: 10px;
    max-width: calc(100vw - 40px);
    position: sticky;
    left: 15px;

    .description {
      padding: 0 10px;
      color: #8D8DA1;
      font-size: clamp(11px, 1.1vw, 15px);
      font-weight: 600;
      text-align: left;
      display: flex;
      align-items: center;
      height: 30px;
    }
  }

  @media screen and (min-width: 992px) {
    .gcl-inner-wrapper {
      width: calc(100% - 4px);
      margin: 0 0;
    }

    .cgl-header,
    .gcl-footer {
      max-width: calc(100% - 40px);
    }
  }

  .lobby-link-arrow {
    .lobby-link-arrow-1 {
      fill: rgba(255, 255, 255, 1);
    }

    .lobby-link-arrow-2 {
      fill: rgba(255, 255, 255, .34);
    }
  }
}

@media screen and (min-width:1528px) {
  .component-games-list-promoted {
    .cgl-list {
      overflow: hidden;
    }
  }
}