@import "../../variables.scss";

#reset-password {
  background-color: $secondBackgroundColor;
  padding: 3rem 0 6rem;

  .container {
    max-width: 90%;
    margin: 0 auto;
    width: 33.7rem;
    background-color: $backgroundColor;
    padding: 0;
    border-radius: .25rem;
    overflow: hidden;
    padding-bottom: 6rem;
  }
  .header-wrapper {
    background-color: $thirdColor;
    padding: 2.2rem;
    color: $white;

    .title {
      display: inline-block;
      font-weight: 500;
      font-size: 1.5rem;
    }
    button {
      background-color: transparent;
      border: none;
      display: inline-block;
      &:not(.disabled),
      &:not(disabled) {
        cursor: pointer;
      }
      &.left {
        float: left;
      }
      &.right {
        float: right;
      }
      .mback {
        path {
          fill: $white;
        }
      }
      &.hide {
        display: none;
      }
      &.close {
        opacity: 1;
        .close {
          opacity: 1;
          path {
            fill: $white;
          }
        }
      }
    }
  }
  .actions {
    padding: 1.5rem;
    .btn {
      width: 100%;
      background-color: $buttonYellow;
      padding: 1rem;
      margin-bottom: .5rem;
      font-size: 1.5rem;
      color: $white;
      position: relative;
      &.ghost-btn {
        width: auto;
        background-color: transparent;
        color: $thirdColor;
        font-size: 1rem;
      }

      &.disabled {
        pointer-events: none;
        cursor: none;
        background-color: $iconGrayTwo;
      }

      .input-loader {
        position: absolute;
        top: .75rem;
        right: .75rem;
        &.hide {
          display: none;
        }
      }
    }
  }
  .description {
    padding: 2rem 1.5rem 1rem;
    text-align: left;
    font-weight: 500;
  }
}