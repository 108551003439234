.verify-ticket {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  flex-wrap: nowrap;
  padding: 0 10px 0 10px;
  margin-top: 7px;

  &.live {
    padding: 0;
    margin-bottom: 7px;
  }

  .input {
    position: relative;
    color: var(--ns-search-input-color);
    height: 38px;
    padding-left: 0;
    overflow: hidden;
    width: calc(70%);

    .textbox {
      width: 100%;
      height: 38px;
      margin: 0;
      padding: 5px 10px;
      float: left;
      background-color: #69718F;
      border: 1px solid #69718F;
      color: #fff;
      outline: 0;
      font-size: 16px;
      transition: right 0.4s linear;
      z-index: 1;
      border-radius: 2px 0 0 2px;

      &::placeholder {
        color: rgba(255, 255, 255, .50);
      }
    }
  }

  &.live {
    .input {
      width: 70%;
    }
  }

  .button {
    width: 30%;
    height: 38px;
    padding: 8px 10px 0;
    background: var(--ns-sold-card-controls-important-bg);
    color: var(--ns-sold-card-controls-important-color);
    font-size: 15px;
    text-align: center;
    border-radius: 0 2px 2px 0;
    cursor: pointer;
  }
}