.GameScreen {
    position: fixed;
    top: 0;
    left: 0;
    /* width: 100%;
    height: 100%; */
    width: 1%;
    height: 1%;
    z-index: 9999;
    display: none;
    background: rgb(209, 31, 3);
    background: -moz-linear-gradient(135deg, rgba(209, 31, 3, 1) 0%, rgba(48, 0, 88, 1) 100%);
    background: -webkit-linear-gradient(135deg, rgba(209, 31, 3, 1) 0%, rgba(48, 0, 88, 1) 100%);
    background: linear-gradient(135deg, rgba(209, 31, 3, 1) 0%, rgba(48, 0, 88, 1) 100%);
}

.GameScreen .GameScreenBg {
    width: 100%;
    height: 100%;
    opacity: 0.36;
    background-position: center center;
    background-size: cover;
    z-index: -1;
    position: absolute;
}

.GameScreen .gameScreenLoader {
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: 99997;
    opacity: 0;
    pointer-events: none;
}

.GameScreen .screen-body .gameScreenLoader div {
    background-color: #017048;
    height: 30px;
    width: 30px;
}

/*
.GameScreen .screen-body iframe {
    z-index: 99999;
    max-height: calc(100% - 50px);
    margin-top: 50px;
    border: none;
}
*/

.GameScreen .screen-title {
    height: 50px;
    position: absolute;
    top: 0;
    right: 0px;
    width: 100%;
    background-color: #151d26;
    z-index: 99999;
}

.GameScreen .screen-title .close {
    position: absolute;
    right: 15px;
    top: 5px;
    opacity: 1;
}

.GameScreen .screen-title .close:focus,
.GameScreen .screen-title .close:active {
    outline: none !important;
    box-shadow: none !important;
}

.GameScreen .screen-title .close span .closeScreenGame {
    fill: #fff;
    height: 40px;
}

.GameScreen .screen-title2 {
    height: 50px;
    position: absolute;
    top: 0;
    right: 0px;
    width: 100%;
    background-color: #060811;
    z-index: 99999;
}

.GameScreen .screen-title2 .close {
    height: 48px;
    position: absolute;
    bottom: 0;
    right: 10px;
    width: 43px;
    background: linear-gradient(180deg, #363636 0%, #1A1A1A 100%);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;

    z-index: 99999;
}

.GameScreen .screen-title2 .close:focus,
.GameScreen .screen-title2 .close:active {
    outline: none !important;
    box-shadow: none !important;
}

.GameScreen .screen-title2 .close span .closeScreenGame {
    fill: #fff;
    height: 40px;
}

.GameScreen .screen-title2 h5 {
    display: none;
}


#SignIn445Butt .st61 {
    fill: #fff;
}

.GameScreen .screen-title h5 {
    margin-bottom: 0;
    line-height: 40px;
    font-weight: bold;
    color: #017048;
    display: none;
}

.GameScreen .screen-body {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.GameScreen .screen-body .guestLogin {
    width: 80%;
    //margin-top: 50px;

    .actualGameTitle {
        color: #fff;
    }


    &.withDescription {
        width: 100%;
        height: 100%;

        .bg {
            width: 100%;
            height: 100%;
            background-size: 100%;
            background-position: top center;
            background-repeat: no-repeat;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .infoArea {
            max-width: 500px;
            margin: 10px auto;
            width: calc(100% - 20px);
            display: flex;
            align-items: flex-end;
            justify-content: space-around;

            .rightSide {
                width: 158px;
                position: relative;
                border-radius: 10px;
                border: 3px solid rgba(255, 255, 255, .05);

                .icon-wrapper {
                    width: 100%;
                    border-radius: 10px;
                    padding: 4px;

                    &::before {
                        content: "";
                        position: absolute;
                        inset: -4px;
                        border-radius: 10px;
                        background: rgba(255, 255, 255, .1);
                        *filter: blur(8px) brightness(70%);
                        overflow: hidden;
                    }

                    .DinamicImageContainer {
                        width: 100%;
                        max-width: 100%;
                        box-shadow: none;
                        margin: 0;
                        border-radius: 0;

                        .MainImage {
                            width: 100%;
                            max-width: none;
                        }
                    }
                }
            }
        }

        .longDescription {
            padding: 10px;
            max-width: 500px;
            margin: 0 auto;
        }

        .rich-text {
            & * {
                color: #fff;
                text-align: left;
                font-weight: normal;
                font-size: 14px;
                box-shadow: none;
                text-shadow: none;
            }

            ol {
                list-style: decimal;
                padding-inline-start: 2em;
            }

            ul {
                list-style: disc;
                padding-inline-start: 2em;
            }

            li {
                display: list-item;
                line-height: normal;
            }

            em {
                font-style: italic;
            }

            strong {
                font-weight: bold;
            }

            sup {
                vertical-align: super;
                font-size: smaller;
            }

            sub {
                vertical-align: sub;
                font-size: smaller;
            }

            h1 {
                font-size: 2em;
                font-weight: bold;
            }

            h2 {
                font-size: 1.5em;
                font-weight: bold;
            }

            h3 {
                font-size: 1.17em;
                font-weight: bold;
            }

            h4 {
                font-size: 1em;
                font-weight: bold;
            }

            h5 {
                font-size: 0.83em;
                font-weight: bold;
            }

            h6 {
                font-size: 0.67em;
                font-weight: bold;
            }

            .ql-align-center {
                text-align: center;
            }

            .ql-align-right {
                text-align: right;
            }

            .ql-align-left {
                text-align: left;
            }

            .ql-align-justify {
                text-align: justify;
            }

            .ql-indent-1 {
                text-indent: 1em;
            }

            .ql-indent-2 {
                text-indent: 2em;
            }

            .ql-indent-3 {
                text-indent: 3em;
            }

            .ql-indent-4 {
                text-indent: 4em;
            }

            .ql-indent-5 {
                text-indent: 5em;
            }
        }
    }
}

.GameScreen .screen-body .guestLogin .actualGameImage {
    width: 35%;
    max-width: 300px;
    height: auto;
    border-radius: 10px;
    margin: 0 auto;
    -webkit-box-shadow: -5px 5px 15px -5px rgba(0, 0, 0, 0.9);
    -moz-box-shadow: -5px 5px 15px -5px rgba(0, 0, 0, 0.9);
    box-shadow: -5px 5px 15px -5px rgba(0, 0, 0, 0.9);

    img {
        max-width: 80%;
        margin: 0 auto;
        display: block;
    }
}

.GameScreen .screen-body .guestLogin .actualGameTitle {
    margin: 30px 0px;
    font-size: 30px;
    text-shadow: -2px 2px 10px rgba(0, 0, 0, 0.8);
}

.GameScreen .screen-body .guestLogin .account-button .btn-warning,
.GameScreen .screen-body .guestLogin .account-button .btn-warning:hover {
    max-width: 400px;
    padding: 15px 10px;
    width: 100%;
    font-size: 1.5rem;
    margin-bottom: 25px;
    background: #ff7701;
    background: -webkit-linear-gradient(11deg, #ff7701, #ffc200);
    background: linear-gradient(79deg, #ff7701, #ffc200);
    border: none;
}

.GameScreen .screen-body .guestLogin .account-button .btn-connect,
.GameScreen .screen-body .guestLogin .account-button .btn-connect:hover {
    max-width: 400px;
    padding: 15px 10px;
    width: 100%;
    font-size: 1.5rem;
    background: #0e8541;
    background: -webkit-linear-gradient(11deg, #0e8541, #15d600);
    background: linear-gradient(79deg, #0e8541 0%, #15d600 100%);
    border: none;
}

.GameScreen .screen-body .guestLogin .account-button .btn-warning .txt,
.GameScreen .screen-body .guestLogin .account-button .btn-connect .txt {
    font-weight: 600;
    color: #ffffff;
}

.GameScreen .screen-body .content-error {
    position: relative;
    top: 45%;
}

.GameScreen .screen-body .content-error h5,
.GameScreen .screen-body .content-error p {
    color: brown;
}

.GameScreen .screen-body .content-error h5 {
    font-size: 24px;
    font-weight: bold;
}

.GameScreen .screen-body .content-error p {
    font-size: 24px;
    margin-bottom: 5px;
}

.GameScreen .screen-body .flash-game {
    opacity: 0;
}

.GameScreen .screen-body {
    @media screen and (orientation: landscape) {
        .re-flow {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            position: relative;
            z-index: 99999;
            height: calc(100vh - 50px);
            width: 100vw;
            margin-top: 50px;
            border: none;
            font-family: "Open Sans", OpenSans;

            &>.if {
                position: relative;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .hh-win-portrait {
                position: absolute;
                top: 0px;
                right: 3px;
                min-width: 370px;
                background-image: none;
                overflow: visible;

                .hh-win-container {
                    transform: scale(1.5);
                    transform-origin: right top;
                }

                .hh-win-container.win {
                    transform-origin: center center;
                    margin-bottom: 100px;
                }
            }
        }

        .re-flow.column {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;

            & .hp {
                width: 100%;
            }

            & .if {
                width: 100%;
                height: 100%;
                min-height: 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    @media screen and (orientation: portrait) {
        .re-flow {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
            position: relative;
            z-index: 99999;
            height: calc(100vh - 50px);
            width: 100vw;
            margin-top: 50px;
            border: none;
            font-family: "Open Sans", OpenSans;

            & .hp {
                width: 100%;
                flex: 0 0 auto;
            }

            & .if {
                position: relative;
                width: 100%;
                height: 100%;
                flex: 1 1 auto;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .hh-win-portrait {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
            }
        }
    }
}