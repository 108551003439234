@import "../../../variables";

#cookieAgreement {
  width: 100%;
  background-color: rgba(0,0,0,0.9);
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 1.5rem 0;
  font-size: 1.125rem;
  color: $footerTextColor;
  text-align: left;
  z-index: 99;
  line-height: 1.2;
  a {
    color: $footerTextColor;
    text-decoration: underline;
  }
  .btn-success {
    background-color: $rewardBoxColor;
    padding-right: 2rem;
    padding-left: 2rem;
    font-size: 1.25rem;
    font-weight: 500;
  }
}