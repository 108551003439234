.landscape-top-gallery {
  width: 140px;
  height: 100vh;
  position: relative;
  box-sizing: border-box;
  padding-top: 70px;

  * {
    box-sizing: border-box;
  }


  background-image: url('../assets/player-gallery-bg-landscape.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .header-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    .menus {
      display: flex;
      width: 140px;
      align-items: center;
      justify-content: space-between;
      padding: 3px 15px 0;

      .menu {
        width: 31px;
        height: 31px;
        border-radius: 50%;
        border: 2px solid #5D097A;
        background: #6c1ea8;
        color: #fff;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          display: block;

        }
      }

      .menu.close {
        border: 2px solid rgba(256, 256, 256, 0.5);
        background: rgba(0, 0, 0, 0.5);
      }
    }

    .header {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 0;

      .tmr {
        display: flex;
        align-items: center;
        color: #BDBDBD;
        font-size: 10px;
        font-weight: 600;
        white-space: nowrap;

        img {
          display: block;
          margin-left: 4px;
          margin-right: 5px;
          width: 21px;
          height: 21px;
          opacity: .6;
        }
      }
    }

    .hr {
      height: 2px;
      width: 100%;
      background: linear-gradient(to right,
          #14248a00,
          rgba(28, 200, 232, .3) 40%,
          #1ef1ffcc 50%,
          rgba(31, 197, 230, .3) 60%,
          #232a8f00);
    }
  }

  .gallery-wrapper {
    width: 100%;
    height: calc(100vh - 70px);
    overflow: hidden;
    user-select: none;

    margin-right: 0;
    position: relative;
    display: flex;

    .gallery {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      max-width: 140px;
      min-width: 140px;
    }

    .item {
      pointer-events: none;
      width: 40px;
      min-width: 40px;
      max-width: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transform-origin: center;
      margin-bottom: 10px;

      .avatar {
        width: 54px;
        height: 54px;
        border-radius: 50%;
        background: linear-gradient(to bottom,
            #E494FF,
            #6B22A7);

        img {
          margin: 2px;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          display: inline-block;
        }
      }

      .pos {
        width: 32px;
        height: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -6px;

        img {
          width: 32px;
          height: 11px;
          object-fit: contain;
        }

        .no {
          position: absolute;

          color: #BFBFBF;
          font-size: 10px;
          font-weight: bold;
          padding-bottom: 2px;
          white-space: nowrap;
        }
      }

      .score {
        margin-top: 3px;
        color: #00FFFF;
        font-size: 11px;
        font-weight: bold;
        line-height: 11px;
        white-space: nowrap;
      }
    }

    .item.big {

      transform: translate(0, -3px) scale(1.2);
      z-index: 1;

      .score {
        margin-top: 1px;
        color: #fff;
        text-shadow: 0 1px 4px rgba(0, 255, 255, .75);
      }
    }

    .item.bigger {
      transform: translate(0, -3px) scale(1.2);


      .pos {
        opacity: 0;
      }

      .score {
        opacity: 0;
        margin-top: 1px;
        color: #fff;
        text-shadow: 0 1px 4px rgba(0, 255, 255, .75);
      }
    }

    .item.float {
      top: 26px;
      position: absolute;
      margin-left: calc(50% - 20px - 14px);
    }

    .tm-btn-wrapper {
      width: 100%;
      padding: 0;
      flex: 1 1 100%;
      margin: 10px 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      background: rgba(0, 0, 0, .44);

      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: -25px;
        left: 0;
        border-bottom: 25px solid rgba(0, 0, 0, .44);
        border-right: 140px solid transparent;
      }

      .tm-btn {
        margin: 5px 12px 10px;
        height: 32px;
        background: linear-gradient(to right, #FFD457 0%, #FF6800 100%);
        padding: 2px;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        .inner {
          height: 28px;
          background: linear-gradient(to right, #0A1520 0%, #213348 100%);
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          width: 100%;

          font-size: 11px;
          font-weight: bold;
          color: #fff;
          text-transform: uppercase;
          padding: 0 12px 0 13px;

          img {
            margin-right: 9px;
            height: 12px;
            width: auto;
            display: block;
          }
        }
      }
    }
  }
}