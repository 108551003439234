.deposit-ticket-main-view {
  position: relative;
  padding-top: 56px;

  .content {
    margin-top: 10px;
  }

  .title-bar {
    background: #0d1721;
    color: #fff;
    padding: 0 16px;
    display: flex;
    align-items: center;
    height: 56px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);

    &>div {
      position: relative;
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .title {
      font-size: 20px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1.334;
      letter-spacing: 0em;
    }

    .returnButton {
      flex: 1 1;
      position: absolute;
      left: 0;
      padding: 12px;

      svg {
        width: 25px;
        height: auto;
      }
    }
  }

  .empty_wrapper {
    padding-right: 1rem;
  }
}