@import "./../../../variables";

.SubMenu {
  height: 65px;
  line-height: 65px;
  position: relative;
  /* box-shadow: inset 1px 3px 4px rgba(0, 0, 0, 0.4); */
  box-shadow: inset 1px 9px 14px rgba(0, 0, 0, 0.3);
  overflow: hidden;

  span {
    line-height: 1.5;
  }

  .promotions {
    margin-right: 30px;
    background-position: center right;
    background-repeat: no-repeat;

    .SubMenuItem {
      //padding-right: 30px;
      //padding-left: 30px;

      .promoGift {
        height: 30px;
      }
    }
  }
}

.submenuSupport {
  display: flex;
}