.rsc-notification {
  height: 21px;

  .rsc {
    height: 0;
    width: 0;
    display: none;
  }

  .rsl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 37px;
    height: 21px;
    background: #91DC1B;
    border-radius: 10px;
    border: 1px solid #75B116;
    position: relative;
    transition: background-color .2s, border-color .2s;

    .rls-icon {
      font-size: 11px;
      font-weight: 600;
      color: #979DAB;
      width: 24px;
    }

    .rls-icon.first {
      padding-left: 10px;
    }

    .rls-icon.second {
      text-align: right;
      padding-right: 10px;
    }
  }

  .rsl.off {
    background: #FF4A1D;
    border-color: #C23917;
  }

  .rsl .rsb {
    position: absolute;
    text-align: center;
    line-height: 19px;
    top: 0px;
    left: 0px;
    width: 24px;
    height: 19px;
    border-radius: 10px;
    transition: 0.2s;
    background: #fff;
  }

  .rsc:checked+.rsl .rsb {
    transform: translateX(11px);
    background-color: #fff;
  }
}