.systems {
  padding: 0 5px 5px;
  color: var(--ns-systems-color);
  background: var(--ns-systems-bg);

  div {
    float: left;
  }

  .systems-wrap {

    .system {
      display: block;
      position: relative;
      float: left;
      padding: 7px 7px;
      margin-top: 5px;
      margin-right: 4px;
      cursor: pointer;
      text-decoration: none;
      font-size: 14px;
      color: var(--ns-system-color);
      background: var(--ns-system-bg);
      border-radius: 2px;
      min-width: 120px;
      max-width: 120px;
      flex: 1;

      &.active {
        color: var(--ns-system-active-color);
        background: var(--ns-system-active-bg);
      }

    }
  }

  .no-combinations {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    font-size: 12px;
    color: var(--ns-systems-no-combinations-color);
    width: 100%;
  }
}