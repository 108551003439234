@import "./../../variables";

.Footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100vw - 17px);
    background-color: #151d26;
    border-top: 2px solid #ffb202;
    color: #BFBFBF;
    box-sizing: border-box;
    padding: 0px 0px 50px 0px;
    z-index: 200;
    &.hide {
        display: none;
    }
    .FooterContent{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        max-width: 1280px;
        
        .FooterHeader{
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          width: 100%;
          padding: 10px 15px;
          border-bottom: 1px solid rgba(191, 191, 191, 0.3);

          .FooterLogo{
            max-width: 145px;
          }
        }

        .Links{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            text-align: left;
            padding: 20px 15px;
            margin: 0px 15px;
            width: calc(100% - 30px);
            border-bottom: 1px solid rgba(191, 191, 191, 0.3);
            box-sizing: border-box;

            > div {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                box-sizing: border-box;
                min-width: 100px;
                h3{
                    font-size: 16px;
                    font-weight: 600;
                    margin-top: 0px;
                    margin-bottom: 10px;
                }
        
                ul{
                    list-style-type: none;
                    padding: 0px;
                    margin: 0px;
                    
                    li{
                      margin: 0px;
                      a{
                        font-size: 12px;
                        font-weight: 500;
                        text-decoration: none;
                        color: #BFBFBF;
                        cursor: pointer;
                        text-transform: capitalize;

                        &.DisabledLink{
                            color: #6d7277;
                            cursor: not-allowed;
                            &:hover{
                                color: #6d7277;
                            }
                        }
                        
                        &:hover{
                            color: #ffb202;
                        }
                      }
                    }
                }
            }
        }

        .PaymentMethods{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;
            text-align: left;
            background-color: #fff;
            border-bottom: 1px solid rgba(191, 191, 191, 0.3);
            box-sizing: border-box;
            width: 100%;
            padding: 10px 30px;

            h3{
                display: flex;
                font-size: 13px;
                font-weight: 600;
                margin: 0;
                color: #151d26;
            }
            
            .IconsContainer{
                display: flex;
                flex: 1;
                justify-content: center;
                align-items: center;
                width: 100%;
                

                .paymentIcon{
                    height: 40px;
                    margin: 0 40px;
                }
            }
        }
        .footer-images {
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 100%;
        }
        .Images{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-align: left;
            padding: 20px 30px;
            width: 100%;
            box-sizing: border-box;
            a{
                height: 35px;
            }
            img{
                max-height: 35px;
                margin-right: 15px;
            }
            &.ANPC {
                display: grid;
                grid-template-columns: 1fr 150px 150px;
                a {
                    height: 40px;
                }
                img {
                    max-height: 40px;
                }
                .text {
                    text-align: right;
                    color: rgba(191, 191, 191, 0.7);
                    font-size: 12px;
                    padding-right: 1rem;
                }
            }
            p{
                font-size: 10px;
                font-weight: 500;
                margin: 0px;
                color: rgba(191, 191, 191, 0.7);
                text-align: left;
            }
        }

        .Text{
            padding: 0px 30px;
            box-sizing: border-box;
            p{
                font-size: 10px;
                font-weight: 500;
                margin: 0px;
                color: rgba(191, 191, 191, 0.7);
                
                a{
                    color: rgba(191, 191, 191, 0.9);
                    font-weight: 600;
                }
            }
        }
    }
}

@media (max-width: 800px){
    .Footer{
        .FooterContent{
            .PaymentMethods{
                flex-direction: column;
                h3{
                    margin-bottom: 20px;
                }
                .IconsContainer{
                    justify-content: space-between;
                    .paymentIcon{
                        margin: 0;
                    }
                }
            }
        }
    } 
}


