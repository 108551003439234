.nsoft-excel {
  @media (hover: hover) and (pointer: fine) {
    .n-page .tournaments .match-row .odd-container .odd:not(.disabled):hover {
      color: #000;
      background: #FF9419 !important;
    }
  }

  .n-page .tournaments .match-row .odd-container .odd.active:not(.disabled) {
    color: #000;
    background: #FF9419 !important;
  }

  .n-page .odds-container .outcome .value {
    color: rgb(149, 149, 149);
  }

  .n-page.prematch .odds-container .button-wrapper.active .button {
    background: #FF9419;
    color: #000;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .2);
    border: 1px #FF9419;

    .value {
      color: #000
    }
  }

  .n-page.live .odds-container .button-wrapper.active .button {
    background: #FF9419;
    color: #000;

    .value {
      color: #000
    }
  }

  .bet-slip-footer {
    left: auto;
    bottom: 20px;
    right: 10px;
    border-radius: 50%;
    border: 3px solid #FFD9AC;
    background: #FF9419;
    display: none;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 28px;
    font-weight: 600;
    width: 68px;
    height: 68px;

    &>div {
      position: relative;
      top: -2px;
    }
  }

  .n-page {
    .verify-ticket {
      display: none !important;
    }
  }

  .n-page .sport-header {
    background: #3F415E;
  }

  .n-page .match-header {
    background: #3F415E;
  }

  .sport-color-1 {
    background: #3F415E
  }

  .sport-color-2 {
    background: #3F415E
  }

  .sport-color-3 {
    background: #3F415E
  }

  .sport-color-4 {
    background: #3F415E
  }

  .sport-color-5 {
    background: #3F415E
  }

  .sport-color-6 {
    background: #3F415E
  }

  .sport-color-7 {
    background: #3F415E
  }

  .sport-color-9 {
    background: #3F415E
  }

  .sport-color-10 {
    background: #3F415E
  }

  .sport-color-11 {
    background: #3F415E
  }

  .sport-color-12 {
    background: #3F415E
  }

  .sport-color-13 {
    background: #3F415E
  }

  .sport-color-16 {
    background: #3F415E
  }

  .sport-color-17 {
    background: #3F415E
  }

  .sport-color-19 {
    background: #3F415E
  }

  .sport-color-20 {
    background: #3F415E
  }

  .sport-color-21 {
    background: #3F415E
  }

  .sport-color-22 {
    background: #3F415E
  }

  .sport-color-23 {
    background: #3F415E
  }

  .sport-color-26 {
    background: #3F415E
  }

  .sport-color-29 {
    background: #3F415E
  }

  .sport-color-31 {
    background: #3F415E
  }

  .sport-color-32 {
    background: #3F415E
  }

  .sport-color-37 {
    background: #3F415E
  }

  .sport-color-34 {
    background: #3F415E
  }

  .sport-color-43 {
    background: #3F415E
  }

  .sport-color-44 {
    background: #3F415E
  }

  .sport-color-46 {
    background: #3F415E
  }

  .sport-color-61 {
    background: #3F415E
  }

  .sport-color-109 {
    background: #3F415E
  }

  .sport-color-110 {
    background: #3F415E
  }

  .sport-color-111 {
    background: #3F415E
  }

  .sport-color-117 {
    background: #3F415E
  }

  .match-header.sport-color-1 {
    background: #3F415E;
  }

  .match-header.sport-color-2 {
    background: #3F415E;
  }

  .match-header.sport-color-3 {
    background: #3F415E;
  }

  .match-header.sport-color-4 {
    background: #3F415E;
  }

  .match-header.sport-color-5 {
    background: #3F415E;
  }

  .match-header.sport-color-6 {
    background: #3F415E;
  }

  .match-header.sport-color-7 {
    background: #3F415E;
  }

  .match-header.sport-color-9 {
    background: #3F415E;
  }

  .match-header.sport-color-10 {
    background: #3F415E;
  }

  .match-header.sport-color-11 {
    background: #3F415E;
  }

  .match-header.sport-color-12 {
    background: #3F415E;
  }

  .match-header.sport-color-13 {
    background: #3F415E;
  }

  .match-header.sport-color-16 {
    background: #3F415E;
  }

  .match-header.sport-color-17 {
    background: #3F415E;
  }

  .match-header.sport-color-19 {
    background: #3F415E;
  }

  .match-header.sport-color-20 {
    background: #3F415E;
  }

  .match-header.sport-color-21 {
    background: #3F415E;
  }

  .match-header.sport-color-22 {
    background: #3F415E;
  }

  .match-header.sport-color-23 {
    background: #3F415E;
  }

  .match-header.sport-color-26 {
    background: #3F415E;
  }

  .match-header.sport-color-29 {
    background: #3F415E;
  }

  .match-header.sport-color-31 {
    background: #3F415E;
  }

  .match-header.sport-color-32 {
    background: #3F415E;
  }

  .match-header.sport-color-37 {
    background: #3F415E;
  }

  .match-header.sport-color-34 {
    background: #3F415E;
  }

  .match-header.sport-color-43 {
    background: #3F415E;
  }

  .match-header.sport-color-44 {
    background: #3F415E;
  }

  .match-header.sport-color-46 {
    background: #3F415E;
  }

  .match-header.sport-color-61 {
    background: #3F415E;
  }

  .match-header.sport-color-109 {
    background: #3F415E;
  }

  .match-header.sport-color-110 {
    background: #3F415E;
  }

  .match-header.sport-color-111 {
    background: #3F415E;
  }

  .match-header.sport-color-117 {
    background: #3F415E;
  }


  .n-page .search.prematch .input {
    width: 390px;

    .textbox {
      width: 100%;
    }
  }

  .n-page .search.prematch .controls {
    right: 420px;
  }


  @media (max-width: 847px) {
    .bet-slip-footer {
      display: flex;
    }
  }
}

.device-ios .nsoft-excel {
  .n-page.prematch.mobile .left-nav.prematch.expanded {
    height: calc(100% - 57px);
    z-index: 1303;
  }
}

.nsoft-excel {
  .n-page.live {
    @media screen and (min-width: 848px) {

      .frame .sidebar {
        overflow: auto;

        .simple,
        .system {
          display: flex;
          flex-direction: column;
          max-height: calc(100vh - 160px);

          .items {
            flex: 1 1 100%;
            overflow-y: auto;
          }
        }

      }
    }
  }

  .n-page.prematch {
    @media screen and (min-width: 848px) {

      .frame .sidebar {
        overflow: auto;

        .simple,
        .system {
          display: flex;
          flex-direction: column;
          max-height: calc(100vh - 225px);

          .items {
            flex: 1 1 100%;
            overflow-y: auto;
          }
        }

      }
    }
  }
}