.registerV2 {
  .step-5 {
    &:after {
      width: 100vw;
      height: 100vh;
      position: fixed;
      top:0;
      left: 0;
      z-index: 100;
    }
  }
}