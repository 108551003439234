/** Sterge **/
.LayoutMessages
{
  background-color: #ffc107;
  color: #10452f;
  font-weight: bold;
  display: block;
  position: absolute;
  width: 100%;
}

.LayoutMessages p
{
  padding: 0;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}
.LayoutMessages a {
  color: white;
}
/** Sterge **/