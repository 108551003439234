@import './../../../variables';

#ProfilePage {
    padding-top: 50px;

    .page-header {
        padding-bottom: 70px;

        .page-header-top {
            display: block;
            width: 100%;
            text-align: left;
    
            .pageIcon {
                float: left;
                text-align: right;
                width: 3rem;
    
                .icon {
                    width: 26px;
                    height: 26px;
                    float: right;
            
                    .account-1,
                    .account-3 {
                        fill: $thirdColor;
                    }
                    
                    .account-1 {
                        stroke: $thirdColor;
                        stroke-miterlimit:10;
                        stroke-width:0.26px;
                    }
                    
                    .account-2 {
                        fill: $white;
                    }

                    .wallet-1 {
                        fill: $thirdColor;
                    }

                    .withdraw-1{fill:#017048;}
                    .withdraw-2{fill:#fff;}

                    .pendingWithdrawals {
                        height: 36px;
                    }

                    .account {
                        height: 30px;
                    }
                }
            }
    
            .pageName {
                display: inline-block;
                text-align: left;
                padding-left: 16px;
                text-transform: uppercase;
                color: $thirdColor;
                font-weight: bold;
                font-size: 14px;
            }
    
            .clear {
                clear: both;
            }
        }
    
        .pageDescription {
            text-align: left;
            padding-left: 3rem;
    
            .description {
                padding-left: 16px;
                font-size: 12px;
                color: $grayColor;
                text-transform: capitalize;
            }
        }
    }

    .exclamationPoint,
    .id_warning {
        height: 25px;
        width: 25px;
        position: absolute;
        top: -3px;
        left: 12px;

        .cls-1 {
            fill: $warningColor;
        }
        .cls-2{
            fill:#f7f7f7;
        }
    }
}

.idCardPopover {
    border-radius: 3px;
    box-shadow: 3px 3px 4px 1px rgba(0,0,0,0.28);
    left: 53px !important;
    border: 1px solid rgba(0,0,0,0.02);

    .popover-body {
        padding: 0 20px 0px 20px;
    }

    // .arrow {
    //     box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.28);
    //     width: 1rem;
    //     left: calc((1rem + 1px) * -1);
    // }

    .IdWarningBody {
        font-size: 12px;
        font-style: italic;

        .close-button {
            right: -15px;
            position: relative;
            font-size: 35px;
            font-weight: normal;
            color: $warningColor;
            opacity: 1;

            &:hover {
                opacity: 0.8;
                color: $warningColor;
            }
        }

        h2 {
            font-size: 14px;
            padding-top: 30px;
        }
    }
}
