.big-counter2 {
	padding-top: 4px;
	display: inline-block;
	//position: relative;
	//vertical-align: middle;
	border-radius: 0.34em;
	//padding: 0.2em 0 0;
	font-weight: bold;
	color: #f6a700;

	.counter-wrapper {
		display: flex;
		align-items: flex-end;
	}

	.digit-wrapper {
		height: 29px;
		line-height: 29.75px;
		overflow-y: hidden;
		background-image: linear-gradient(#3a006f, #290046);
		background-size: 100%;
		text-align: center;
		padding: 3px 5px 3px;
		border-radius: 5px;
		border: 1px solid #4e2174;
		margin-right: 1px;

		.disabled {
			color: #442665;
		}
	}

	.digit {
		line-height: 22px;
	}

	.radix {
		height: 29px;
		line-height: 29.75px;
		display: inline-block;
		padding: 3px 1px 3px;
		overflow: visible;
		z-index: 1;
		background-image: linear-gradient(#3a006f, #290046);
		border: 1px solid #4e2174;
		border-radius: 5px;
		margin-right: 1px;
	}
}