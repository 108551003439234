.winter-time-error {
  z-index: 1304 !important;

  & .MuiPaper-rounded {
    border-radius: 13px;
  }

  & .MuiDialog-paperFullWidth {
    width: calc(100% - 20px);
    margin: 10px;
  }

  .dialogTitle {
    padding-top: 32px;
    padding-bottom: 0;

    .dh-logo {
      width: 69px;
      height: 78px;
      display: block;
      margin: 0 auto;
    }

    .dh-text {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      color: #131727;
      padding: 10px 0 0;
    }

    .muted {
      padding-top: 0;
      color: #909092;
      font-size: 13px;
      font-weight: normal;
    }
  }

  .dialogContent {
    font-size: 12px;
    color: #17214D;
    font-weight: bold;
    text-align: center;
    padding-top: 23px;
    padding-bottom: 30px;

    .dialogBody {
      .dh-text {
        text-align: center;
        font-size: 13px;
        font-weight: normal;
        color: #000000;
        padding: 10px 0 20px;
      }

      ul {
        list-style: none;
        padding-left: 1em;
      }

      li {
        &:before {
          content: '';
          display: inline-block;

          position: relative;
          top: 1px;
        }

        div {
          color: #006D0E;
          font-size: 13px;
          font-weight: 600;
          text-align: left;
          margin-bottom: 22px;
        }
      }
    }

    .dg-row {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-start;

      div.icon {
        height: 12px;
        width: 12px;
        background-image: url(../assets/rule-bullet.svg);
        background-size: contain;
        background-repeat: no-repeat;

        margin-top: 4px;
        min-width: 12px;
      }

      div.txt {
        color: #006D0E;
        font-size: 13px;
        font-weight: 600;
        text-align: left;
        margin-bottom: 22px;
        padding-left: 5px;

        min-width: 0;
        flex: 1 1 auto;
      }
    }

    .db-link {
      color: #939393;
      font-size: 11px;
      text-decoration: underline;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      cursor: pointer;
      margin-top: 30px;
      margin-bottom: 20px;
      display: block;
    }

    .dialogOK {
      width: 100%;
      padding: 13px 0;
      border-radius: 3px;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      margin: 0 0 0;
      background: linear-gradient(90deg, #295A91 0%, #2174E0 100%);
      height: 45px;
      margin-left: 5px;
      text-transform: uppercase;
    }
  }
}