.notifications-preference {
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-grow: 1;
  flex-direction: column;
  flex-basis: 100%;
  min-height: 100vh;
  width: 100vw;
  background-color: #fff;
  position: relative;
  z-index: 0;

  & * {
    box-sizing: border-box;
  }

  >div:first-child {
    min-height: 56px;
  }

  .notifications-preference-content {
    .header {
      color: #003D6A;
      font-size: 19px;
      font-weight: bold;
      text-align: left;
      padding: 33px 30px 6px 30px;
      background-color: #EFEFF4;
    }

    .subheader {
      color: #003D6A;
      font-size: 17px;
      font-weight: bold;
      text-align: left;
      padding: 10px 20px 20px;
      background-color: #FEFEFE;
    }

    .settings {
      padding: 13px 27px 13px 14px;
    }
  }
}