@import "./../../../../../../variables";

.pending-section {
  .head-area {
    display: flex;

    .icon {
      .IconSVG {
        height: 54px;
      }
    }

    .texts-support {
      padding-left: 17px;
      color: #000;
      padding-top: 7px;
      padding-bottom: 30px;

      .title {
        font-size: 17px;
        font-weight: bolder;
        color: $firstColor;
      }

      .subtitle {
        font-size: 13px;
        color: $grayColor;
        padding-top: 3px;
      }
    }
  }

  .list-area {
    .wlist {
      padding-left: 74px;
      padding-right: 74px;

      .amount {
        color: $thirdColor;
        font-weight: bolder;
        font-size: 17px;
        display: block;
        float: left;
        .currency-value {
          display: inline-block;
        }
        .tax {
          margin-left: 1rem;
          font-size: .75rem;
          color: #aaa;
        }
      }
      .date {
        color: #aaa;
        font-size: .825rem;
        display: block;
        float: right;
      }
      .method {
        color: $grayColor;
        font-size: 13px;
        display: block;
      }

      .buttons {
        display: flex;
        padding-top: 20px;

        .cancelButton {
          border-color: $warningColor;
          color: $warningColor;
          border-width: 2px;
          font-weight: 600;

          &:hover {
            color: $white;
            background-color: $warningColor;
          }
        }

        .buttonPlace {
          &:first-child {
            padding-right: 10px;
          }

          &:last-child {
            padding-left: 10px;
          }
        }

        .actionBtn {
          border-color: $thirdColor;
          background-color: $thirdColor;
          font-weight: 600;

          &:hover {
            background-color: lighten($thirdColor, 8%);
            border-color: lighten($thirdColor, 8%);
            //border-right: 2px;
          }
        }

        .infoBtn {
          border-color: #0a3d30;
          background-color: #0a3d30;

          &:hover {
            background-color: darken(#0a3d30, 8%);
            border-color: darken(#0a3d30, 8%);
          }

          .btnInfo {
            width: 20px;

            .cls-1{
              fill:$white;
            }
          }
        }
      }
    }
  }

  .btn {
    position: relative;
    top: 0;
    transition: all 0.1s ease-in;

    &:focus,
    &:active {
      outline: none !important;
      box-shadow: none !important;
    }

    &:active {
      top: 2px;
    }
  }
}

.pendingPopover {
  border-radius: 3px;
  box-shadow: 3px 3px 4px 1px rgba(0,0,0,0.28);
  border: 1px solid rgba(0,0,0,0.02);
  left: 5px !important;
  top: 0px;

  .popover-body {
    padding: 0 20px 0px 20px;
  }

  .pendingBody {
    font-size: 12px;
    font-style: italic;

    .close-button {
      right: -15px;
      position: relative;
      font-size: 35px;
      font-weight: normal;
      color: $dangerColor;
      opacity: 1;

      &:hover {
        opacity: 0.8;
        color: $dangerColor;
      }

      &:focus,
      &:active {
        outline: none !important;
        box-shadow: none !important;
      }

      &:active {
        top: 2px;
      }
    }

    h2 {
      font-size: 14px;
      padding-top: 30px;
    }
  }
}