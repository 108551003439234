@import "./../../variables";

@keyframes openOverlay {
  0% {
    opacity: 0;
    display: none;
  }
  1% {
    opacity: 0;
    display: flex;
  }
  100% {
    display: flex;
    opacity: 0.6;
  }
}

@keyframes closeOverlay {
  0% {
    display: flex;
    opacity: 0.6;
  }
  99% {
    opacity: 0;
    display: flex;
  }
  100% {
    display: none;
    opacity: 0;
  }
}

.menuOvarlay {
  z-index: 1059;
  background-color: #000000;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  //transition: all .4s ease-in;
  animation: closeOverlay 600ms ease-in;

  &.open {
    display: flex;
    opacity: 0.6;
    animation: openOverlay 600ms ease-out;
  }
}