.puzzle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -80px;
  overflow: visible;
  margin-bottom: 5px;
  position: relative;
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;

  .prize-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 82px;
    overflow: hidden;

    &.cut-half {
      right: unset;
      width: 50%;
    }

    &.cut-third {
      right: unset;
      width: 75%;
    }

    img {
      display: block;
      height: 82px;
      width: auto;
    }
  }

  .no {
    border-radius: 50%;
    border: 2px solid #fff;
    color: #fff;
    font-size: 19px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 39px;
    height: 39px;
    position: absolute;
    top: calc(50% - 19.5px);
    left: calc(50% - 19.5px);
  }

  .puzzle1 {
    width: auto;
    height: 82px;
    position: relative;
    z-index: 1;

    img {
      width: auto;
      height: 82px;
    }
  }

  .puzzle2 {
    width: auto;
    height: 82px;
    margin: 0 -30px;
    position: relative;


    img {
      width: auto;
      height: 82px;
    }
  }

  .puzzle3 {
    width: auto;
    height: 82px;
    position: relative;

    img {
      width: auto;
      height: 82px;
    }
  }

  &>div.animate {
    animation: grow-disappear;
    animation-duration: 1400ms;
    animation-timing-function: ease-in-out;
    z-index: 1;
    position: relative;
    opacity: 1;
    transform-origin: center;
    animation-fill-mode: forwards;
  }

  @keyframes grow-disappear {
    0% {
      transform: scale(1);
    }

    57% {
      opacity: 1;
      transform: scale(1.3) rotateX(16deg) rotateY(-11deg);
    }

    100% {
      opacity: 0;
      transform: scale(1) translateY(-20%) rotateX(45deg) rotateZ(25deg);
    }
  }

  .info {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: -60px;
    height: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .txt {
      border: 1px solid #FFCD03;
      background-color: #1F1F7D;
      max-width: 320px;
      min-width: 320px;
      border-radius: 14px;
      color: #fff;
      font-size: 13px;
      font-weight: 600;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 56px;
      max-height: 56px;
      text-align: center;
      width: 100%;
      padding: 0 70px;
    }

    .arrow-down {
      margin-top: -10px;

      img {
        width: auto;
        height: 40px;
      }
    }
  }
}