    .CardListContainer{
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
        .CardInfo{
            display: inline-flex !important;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 145px;
            height: 90px;
            border: 2px solid #d7dceb;
            border-radius: 5px;
            padding: 5px;
            box-sizing: border-box;
            background: #edf0fe;

            &.Selected{
                border: 3px solid #92a6ff;
                background: rgb(238,241,255);
                background: linear-gradient(140deg, rgba(238,241,255,1) 0%, rgba(235,238,253,1) 37%, rgba(228,232,249,1) 38%, rgba(212,218,237,1) 100%);

                .CardNo{
                    color: #2c3e78;
                }
            }

            &.NewCard{
                display: inline-flex !important;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 60px;
                animation: minimize 0.3s ease both;

                p{
                    font-size: 40px;
                    font-weight: 700;
                    margin: 0 10px;
                    color: #2c3e78;
                }

                &.Selected{
                    width: 140px;
                    animation: maximize 0.3s ease both;

                    p{
                        font-weight: 700;
                        font-size: 14px;
                    }
                }
            }

            .CardNo{
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 700;
                font-size: 14px;
                color: #6f777f;
                word-wrap: break-word;
                word-break: break-all;
                margin: 0;

                .HiddenNo{
                    margin: 5px 5px 0px 5px;
                    font-size: 10px;
                }
            }

            .CardRow{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .ExpirationDate, time{
                    font-size: 12px;
                    color: #9598a3;
                    font-weight: 600;
                }

                .CardFlag{
                    width: 40px;
                    height: 26px;
                }
            }

        }
        .slick-slide{
            margin-right: 10px;
            &:last-of-type{
                margin-right: 0;
            }
            div{
                outline: none;
            }
        }
        


        .slick-dots{
            display: flex !important;
            align-items: center;
            justify-content: center;
            list-style: none;
            margin: 0;
            padding: 0;
            width: 100%;
            margin: 15px 0px;

            li{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 10px;
                margin: 0 3px;

                button{
                    display: inline-block;
                    background-color: #ced1dc;
                    width: 5px;
                    height: 5px;
                    border-radius: 10px;
                    color: transparent;
                    border: none;
                    outline: none;
                    white-space: nowrap;
                    text-indent: -9999px;
                    transition: all .3s ease;
                }

                &.slick-active{
                    width: 20px;
                    button{
                        background-color: #001d8a;
                        width: 20px;
                    }
                }
               
                
            }
        }
    }

@keyframes maximize {
    0% {
        letter-spacing: -0.5em;
        width: 60px;
    }
    40% {
    }
    100% {
        width: 145px;
    }
}

@keyframes minimize {
    0% {
        width: 145px;
    }
    100% {
        width: 60px;
    }
}

