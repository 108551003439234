.lobby-search-popup {
  z-index: 1303 !important;

  & .MuiPaper-root {
    background-color: #fff;
  }

  &.h100vh {
    height: 100vh;
  }

  &.minHeight {
    & .MuiPaper-root.MuiDialog-paper {
      min-height: calc(100vh - 64px);
      width: 1024px
    }
  }

  .search-full-page {
    .title-bar {
      position: sticky;
      top: 0;

      background: linear-gradient(90deg, #14259B 0%, #070D29 100%);
      color: #fff;
      font-family: OpenSans;
      min-height: 56px;

      .back {
        padding: 24px 23px;
        fill: #fff;
      }

      .backSvg {
        height: 17px;
        width: auto;
      }

      .title {
        font-size: 13px;
        font-family: OpenSans;
      }
    }

    .returnButton {
      flex: 1;
      position: absolute;
      left: 15px;
      padding: 12px;

      .ArrowBackIcon {
        width: 25px;
        height: auto;
        fill: #fff;
      }
    }


    .search-content {
      text-align: left;


    }
  }


}