.n-page {

  .content {
    height: calc(100vh - 85px);
    padding-top: 0;
    padding-bottom: 0;
  }

  .footer {
    display: none !important;
  }

  .header {
    display: none !important;
  }
}

.n-page.prematch.mobile {
  overflow: unset;
  height: unset;

  .content {
    height: unset;

    .page-header {
      position: sticky;
      top: 85px;
      z-index: 1;
      background-color: #eeedf2
    }
  }

  .left-nav.prematch {
    top: 85px;
    position: fixed;
  }

  .left-nav.prematch.expanded {
    width: 100%;
    min-width: 214px;
    height: calc(100vh - 123px);
  }

  .match-details {
    height: calc(100vh - 148px);
    position: sticky;
    top: 143px;
    max-width: 610px;
    //min-width: 610px;
    margin-right: 2px;
  }

  @media screen and (min-width: 848px) {
    .left-nav.prematch {
      position: sticky;
      top: 138px;
      height: calc(100vh - 138px);
    }

    .frame .sidebar {
      position: sticky;
      top: 138px;
      height: calc(100vh - 138px);
      overflow: unset;
      min-width: 412px;

      >div {
        overflow: auto;
        height: 100%;

        scrollbar-color: var(--ns-scrollbar-thumb-bg) var(--ns-scrollbar-track-bg);
        scrollbar-width: thin;

        &.prematch {
          top: -56px;
          height: 0;
          z-index: 95;
        }

        &::-webkit-scrollbar {
          width: 5px
        }

        &::-webkit-scrollbar-track {
          background: var(--ns-scrollbar-track-bg);
          border-radius: 0px
        }

        &::-webkit-scrollbar-thumb {
          background: var(--ns-scrollbar-thumb-bg);
          border-radius: 3px
        }

      }
    }

    .content .frame {
      display: flex;

      .content-wrap {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .content .frame .content-wrap {
      min-height: calc(100vh - 138px);
      flex: 1 1 auto;
      overflow: unset;

      .flex {
        display: flex;
      }

      .content-flex-one {
        width: 100%;
        min-width: 0;
      }

      .content-flex-two {
        padding: 0 3px;
      }
    }

    .content .page-header {
      z-index: 100;
    }
  }
}


.n-page.live {
  .content {
    .content-wrap {
      .match-results {
        top: 85px;
      }
    }
  }

  .left-nav.lvl {
    top: 85px;
  }

  @media screen and (min-width: 848px) {
    .left-nav.lvl {
      top: 85px;
      height: calc(100vh - 85px);
    }

    .frame .sidebar {
      top: 85px;
      height: calc(100vh - 85px);
    }

    .content .frame .content-wrap {
      min-height: calc(100vh - 85px);
    }
  }
}