@import "./../../variables";

.MainContent
{
    display: block;
    width: 100%;
    color: #000000;
    min-height: 1000px;
    background-color: #ffffff;
    position: relative;
    min-width: 1280px;

    .pageLoader {
        div {
            div {
                background-color: #017048;
            }
        }
    }
}

.ContentPage {
    &.topPadding {
        padding-top: 20px;
    }
}

.ContentPage {
    &.betting-cs {
        top: 60px;

        .title-name {
            h1 {
                text-transform: none !important;
            }
        }
    }
}

.live-casino-cs {
    .title-name {
        h1 {
            text-transform: none !important;
        }
    }
}