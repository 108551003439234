.VerificationField {
    width: 335px;
    margin: 0 auto;
    position: relative;
    text-align: center;
    .simple {
        input {
            letter-spacing: 1rem;
            font-size: 1.75rem;
            text-align: center;
        }
    }


    .ColumnMessage {
        p {
            &.error {
                color: red !important;
                font-weight: bold !important;
                text-align: center;
            }
        }
    }
    .input-group {
        max-width: 65px;
        float: left;
        position: relative;
        display: block;

        input {
            //background-color: red;
            text-align: center;
            position: relative;
            max-width: 50px;
            height: 50px;
            border-bottom: 2px solid rgb(205,204,204);
            margin: 7px;
            font-size: 3.5rem;
            font-weight: bold;
            line-height: 2.5rem;

            &::-webkit-input-placeholder,
            &::-moz-placeholder,
            &:-ms-input-placeholder,
            &:-moz-placeholder,
            &:empty {
                font-size: 0.5rem;
                color: rgb(205,204,204) ;
            }
            &:focus {
                border-bottom: 2px solid var(--blue) !important;
            }
            &:valid {
                border-color: var(--green) !important;
                font-size: 3.5rem;
                line-height: 1.15rem;
                height: 3.7rem;
                margin-top: -0.1rem;
            }
        }
    }
    .reward-coins {
        position: relative;
        display: block;
        float: left;
        margin-top: 3rem;
        margin-left: -1rem;
        .reward {
            position: absolute;
            top: -2.1rem;
            width: 2.1rem;
            height: 2.6rem;
            .icon {
                width: 1.8rem;
                margin-top: 0rem;
            }
        }
    }
    .input-loader {
        position: absolute;
        right: calc(50% - 1rem);
        bottom: 0;
    }
}

.registerV2 {
    .VerificationField {
        width: 100%;
        padding: 0 2rem;
        .simple {
            width: 100%;
            input {
                width: 100%;
            }
        }
    }
}