
.bonusAvailability {
  .calendar {
    width: 1.7rem;
    position: relative;
    .remaining {
        font-weight: bold;
        position: absolute;
        width: 100%;
        font-size: 0.825rem;
        top: .6rem;
        text-align: center;
    }
  }
  .timer {
    width: fit-content;
    border: 1px solid #eeeeee;
    position: relative;
    padding: .5rem .5rem;
    border-radius: 1rem;
    .timer {
      border: none;
      padding: 0;
      margin-right: .5rem;
    }
    .IconSVG {
      width: 1.2rem;
      display: inline-block;

      .fillMe {
        color: darkred;
      }
    }
    .remaining {
      position: relative;
      display: inline-block;
      font-size: 0.75rem;
      font-weight: bold;
      -webkit-transition: all 1s ease-in ;
      -moz-transition: all 1s ease-in ;
      -ms-transition: all 1s ease-in ;
      -o-transition: all 1s ease-in ;
      transition: all 1s ease-in ;
    }
  }
}