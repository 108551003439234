.wheel-error-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .warning-header {
    padding: 0;
    position: fixed;
    width: 29px;
    height: 29px;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 100;
  }

  .warning-icon {
    display: block;
    width: 75px;
    height: auto;
  }

  .warning-title {
    margin-top: 5px;
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    white-space: normal;
    text-align: center;
  }

  .warning-body {
    width: 288px;
    margin-top: 46px;
    font-size: 12px;
    color: #fff;
    font-weight: normal;
    white-space: normal;
    text-align: center;
  }

  .warning-button {
    margin-top: 70px;
    border-radius: 9px;
    background: linear-gradient(to right, #1A33D9, #1664FF);
    box-shadow: 0px -3px 6px rgba(0, 0, 0, .16);
    height: 52px;
    display: flex;
    align-items: center;
    padding: 0 70px;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    white-space: normal;
    text-align: center;
  }

}