.promo-winter-cta-promotions-desktop {
  margin: 0;
  height: 136px;
  max-width: 380px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .30);

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;

  border-radius: 7px;
  position: relative;

  .calendar {
    position: absolute;
    left: 0;
    top: -10px;

    svg {
      width: 89px;
      height: auto;
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: rgba(0, 0, 0, .56);
    height: 45px;
    border-radius: 0 0 7px 7px;
    padding: 0 10px;

    .promo-count-down {
      display: flex;
      align-items: center;
      box-shadow: 1px 2px 4px rgba(0, 0, 0, .53);

      .txt {
        height: 28px;
        background: #2E3D4B;
        border-radius: 3px 0 0 3px;
        font-size: 13px;
        color: #fff;
        font-weight: normal;
        white-space: nowrap;
        padding: 0 7px 0 10px;
        display: flex;
        align-items: center;
      }

      .count {
        height: 28px;
        background: #FFC400;
        border-radius: 0 3px 3px 0;
        font-size: 16px;
        color: #000;
        font-weight: bold;
        white-space: nowrap;
        padding: 0 10px 0 7px;
        display: flex;
        align-items: center;
        min-width: 108px;
      }
    }

    .promo-now {
      display: flex;
      align-items: center;
      justify-content: center;

      .promo-button {
        font-size: 12px;
        color: #fff;
        font-weight: bold;
        white-space: nowrap;
      }

      .promo-arrow {
        border-radius: 4px;
        width: 36px;
        height: 36px;
        background: linear-gradient(to right, #FE1C6B, #F8961D);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        margin-left: 10px;
      }
    }
  }
}