.component-news {
  margin: 10px auto;
  padding-bottom: 5px;
  max-width: 1528px;

  .cgl-super-header {
    margin: 0 10px;

    .category {
      color: #353540;
      font-size: clamp(10px, 1vw, 12px);
      font-weight: bold;
      text-align: left;
    }
  }

  .cgl-header {
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      color: #353540;
      font-size: 18px;
      font-size: clamp(18px, 1.8vw, 26px);
      font-weight: 600;
      text-align: left;
    }

    .link {
      color: #353540;
      font-size: 12px;
      font-size: clamp(12px, 1.2vw, 17px);
      font-weight: 600;
      text-align: left;
      cursor: pointer;
      border: 1px solid #BEC9D6;
      border-radius: 11px;
      padding: 2px 12px 3px;
    }
  }

  .cgl-header-tags {
    margin: 10px 10px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .tag {
      color: #fff;
      font-size: 8px;
      font-weight: bold;
      text-align: left;
      margin-right: 11px;
      background-color: #009ED9;
      border-radius: 5px;
      padding: 5px 10px 4px;

    }
  }

  .cgl-list {
    overflow-y: hidden;
    overflow-x: auto;
    margin-top: 10px;

    .cgl-grid {
      width: 100%;
      display: grid;
      overflow: visible;
      white-space: nowrap;
      pointer-events: all;
      padding-bottom: 10px;

      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      &::before,
      &::after {
        content: '\00a0';
        min-width: 9px;
        max-width: 9px;
      }

      .cgl-item {
        min-width: 186px;
        max-width: 186px;
        width: 100%;
        margin-right: 12px;
        cursor: pointer;
        border-radius: 7px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .5);
        background-color: #fff;
        border-top: 1px solid #f0f0f0;

        &.empty {
          max-width: 186px;
        }

        .outer {
          width: 100%;
          padding-top: 123.66%;
          position: relative;

          .inner {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            padding: 10px;
          }
        }

        img {
          width: 100%;
          height: auto;
          max-height: 51%;
          object-fit: cover;
        }

        .cgl-tag-wrapper {
          display: flex;
        }

        .cgl-tag {
          border-radius: 5px;
          font-size: 8px;
          color: #fff;
          font-weight: bold;
          text-align: left;
          background-color: #06D2BD;
          padding: 5px 8px;
          display: inline-block;
          text-transform: uppercase;
          text-align: left;
        }

        .cgl-txt {
          margin-top: 5px;
          font-size: 12px;
          color: #22262D;
          font-weight: 600;
          text-align: left;
          white-space: normal;
          line-height: 16px;

          height: 49px;
          text-overflow: ellipsis;
          overflow: hidden;

          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        .cgl-date {
          margin-top: 10px;
          font-size: 8px;
          color: #22262D;
          font-weight: normal;
          text-align: left;
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    .cgl-grid.large {

      &::before,
      &::after {
        min-width: 9px;
        max-width: 9px;
      }


      .cgl-item {
        min-width: 164px;

        &.empty {
          max-width: 164px;
        }
      }
    }
  }
}