.match-header {
  position: relative;
  min-height: 90px;
  padding: 0 20px;
  margin-bottom: 10px;
  border: 1px solid var(--ns-content-element-border);
  text-align: center;
  white-space: normal;
  background-color: var(--ns-sport-color-default);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 15px 0;

  .time-tournament {
    padding: 0.35em 0;
    overflow: hidden;
    color: var(--ns-match-header-time-color);
    font-size: 0.9em;
    text-overflow: ellipsis;
  }

  .name {
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
    color: var(--ns-match-header-name-color);
    font-size: 1.4em;
    font-weight: 400;
    text-overflow: ellipsis;
  }

  .header-stats {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #fff;
    opacity: .7;

    &:hover {
      opacity: 1;
    }
  }

  @media (max-width: 1024px) {
    .name {
      font-size: 14px
    }

    .info-code {
      display: block;
      text-align: center;
      font-size: 16px;
    }

    .dn {
      display: none;
    }

    .info-title {
      display: block;
      text-align: center;
      font-size: 11px;
    }
  }
}

.match-header.sport-color-1 {
  background: var(--ns-sport-color-1)
}

.match-header.sport-color-2 {
  background: var(--ns-sport-color-2)
}

.match-header.sport-color-3 {
  background: var(--ns-sport-color-3)
}

.match-header.sport-color-4 {
  background: var(--ns-sport-color-4)
}

.match-header.sport-color-5 {
  background: var(--ns-sport-color-5)
}

.match-header.sport-color-6 {
  background: var(--ns-sport-color-6)
}

.match-header.sport-color-7 {
  background: var(--ns-sport-color-7)
}

.match-header.sport-color-9 {
  background: var(--ns-sport-color-9)
}

.match-header.sport-color-10 {
  background: var(--ns-sport-color-10)
}

.match-header.sport-color-11 {
  background: var(--ns-sport-color-11)
}

.match-header.sport-color-12 {
  background: var(--ns-sport-color-12)
}

.match-header.sport-color-13 {
  background: var(--ns-sport-color-13)
}

.match-header.sport-color-16 {
  background: var(--ns-sport-color-16)
}

.match-header.sport-color-17 {
  background: var(--ns-sport-color-17)
}

.match-header.sport-color-19 {
  background: var(--ns-sport-color-19)
}

.match-header.sport-color-20 {
  background: var(--ns-sport-color-20)
}

.match-header.sport-color-21 {
  background: var(--ns-sport-color-21)
}

.match-header.sport-color-22 {
  background: var(--ns-sport-color-22)
}

.match-header.sport-color-23 {
  background: var(--ns-sport-color-23)
}

.match-header.sport-color-26 {
  background: var(--ns-sport-color-26)
}

.match-header.sport-color-29 {
  background: var(--ns-sport-color-29)
}

.match-header.sport-color-31 {
  background: var(--ns-sport-color-31)
}

.match-header.sport-color-32 {
  background: var(--ns-sport-color-32)
}

.match-header.sport-color-37 {
  background: var(--ns-sport-color-37)
}

.match-header.sport-color-34 {
  background: var(--ns-sport-color-34)
}

.match-header.sport-color-43 {
  background: var(--ns-sport-color-43)
}

.match-header.sport-color-44 {
  background: var(--ns-sport-color-44)
}

.match-header.sport-color-46 {
  background: var(--ns-sport-color-46)
}

.match-header.sport-color-61 {
  background: var(--ns-sport-color-61)
}

.match-header.sport-color-109 {
  background: var(--ns-sport-color-109)
}

.match-header.sport-color-110 {
  background: var(--ns-sport-color-110)
}

.match-header.sport-color-111 {
  background: var(--ns-sport-color-111)
}

.match-header.sport-color-117 {
  background: var(--ns-sport-color-117)
}