.component-content-slider {
  margin: 10px auto;
  padding-bottom: 10px;
  max-width: 1528px;
  position: relative;

  .before {
    display: none;
    z-index: 1;
  }

  .after {
    display: none;
    z-index: 1;
  }

  .cgl-header {
    margin: 0 10px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    position: relative;

    .title {
      color: #353540;
      font-size: 18px;
      font-size: clamp(18px, 1.8vw, 26px);
      font-weight: 600;
      text-align: left;
    }

    .link {
      color: #353540;
      font-size: 12px;
      font-size: clamp(12px, 1.2vw, 17px);
      font-weight: 600;
      text-align: left;
      cursor: pointer;
      border: 1px solid #BEC9D6;
      border-radius: 11px;
      padding: 2px 12px 3px;

      span {
        padding: 0 5px;
      }

      svg {
        position: relative;
        top: -1px;
      }
    }
  }

  .cgl-list-wrapper {
    position: relative;
  }

  .cgl-list {
    overflow-y: hidden;
    overflow-x: auto;

    .cgl-grid {
      width: 100%;
      display: grid;
      overflow: visible;
      white-space: nowrap;
      pointer-events: all;
      padding-bottom: 10px;

      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      &::before,
      &::after {
        content: '\00a0';
        min-width: 9px;
        max-width: 9px;
      }

      .cgl-item {
        min-width: 353px;
        width: 100%;
        margin-right: 10px;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
        -webkit-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);

        &.empty {
          max-width: 104px;
        }

        .outer {
          width: 100%;
          padding-top: 45.89%;
          position: relative;
          border-radius: 10px;

          .inner {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
            border-radius: 10px;

            .item-text-wrapper {
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              padding: 5px 15px 0;
              background: radial-gradient(rgba(23, 32, 76, .8), rgba(0, 43, 183, .8), rgba(80, 7, 207, .8));
              height: 44px;
              white-space: initial;

              .item-text,
              .item-subtext {
                font-size: 11px;
                font-size: clamp(11px, 1.1vw, 13px);
                color: #fff;
                text-align: left;
                font-weight: 600;
                line-height: 18px;
              }

              .item-subtext {
                font-size: 9px;
                color: #DFE5EB;
                line-height: 12px;
              }
            }
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;

          transition: transform .25s ease;
        }

        &:hover {
          img {
            position: relative;
            transform-origin: center;
            transform: translateZ(0) scale(1.05);
          }
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  .cgl-footer {
    color: #8D8DA1;
    font-size: 11px;
    font-weight: 600;
    text-align: left;
    margin: 0 17px 0;
  }
}


@media screen and (min-width:1528px) {
  .component-content-slider {

    .cgl-grid::before,
    .cgl-grid::after {
      min-width: 1px;
      max-width: 1px;
    }
  }
}