.ns-rsc-ticket-switch {
  width: 100%;
  color: #000;

  text-align: center;

  .rsc {
    height: 0;
    width: 0;
    display: none;
  }

  .rsl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    cursor: pointer;
    width: 100%;
    height: 36px;
    padding: 3px;
    background: var(--ns-sold-card-switch-bg);
    position: relative;
    transition: background-color .2s;
    fill: var(--ns-sold-card-switch-color);
    color: var(--ns-sold-card-switch-color);

    .rls-icon {
      width: 50%;
      font-size: 10px;
      font-weight: bold;

      svg {
        height: 12px;
        position: relative;
        top: 2px;
        fill: var(--ns-sold-card-switch-color);
      }
    }
  }

  .rsl .rsb {
    display: block;
    position: absolute;
    text-align: center;
    line-height: 30px;
    top: 3px;
    left: 3px;
    width: 
    50%;
    height: 30px;
    transition: 0.2s;
    background-color: var(--ns-sold-card-switch-selected-bg);
    color: var(--ns-sold-card-switch-selected-color);
    fill: var(--ns-sold-card-switch-selected-color);
    box-shadow: 0 2px 3px var(--ns-sold-card-switch-shadow-color);
    font-size: 10px;
    font-weight: bold;

    svg {
      display: inline-block;
      margin-right: 5px;
      position: relative;
      height: 18px;
      top: 5px;
    }

  }

  .rsc:checked+.rsl .rsb {
    transform: translateX(calc(100% - 7px));
    background-color: var(--ns-sold-card-switch-selected-bg);
    color: var(--ns-sold-card-switch-selected-color);
    fill: var(--ns-sold-card-switch-selected-color);

    svg {
      display: inline-block;
      margin-right: 5px;
      position: relative;
      height: 18px;
      top: 5px;
    }
  }
}

.ns-rsc-ticket-switch:focus,
.ns-rsc-ticket-switch:active {
  outline: none;
}

.ns-rsc-ticket-switch.rsc-ticket-switch-disabled {
  pointer-events: none;
  opacity: .6;
}