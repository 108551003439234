.emptyDesktop {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .pulse {
    background-color: #eee;
  }

  .first {
    width: 68px;
    border-right: 1px solid #DFE7EF;
    padding-top: 30px;
    height: 100%;
    min-height: 82px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: -18px;
  }

  .second {
    padding-top: 10px;
    width: calc(45% - 120px);
    padding-left: 10px;
  }

  .third {
    width: 55%;
    padding-left: 10px;

    .bets {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      padding-top: 10px;

      .pulse {
        margin: 0 3px;
        width: 25%;
      }
    }
  }

  .fourth {
    border-left: 1px solid #DFE7EF;
    width: 65px;
    min-height: 82px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.emptyMobile {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .pulse {
    background-color: #eee;
  }

  .first {
    width: 50%;
  }

  .second {
    padding-top: 10px;
    width: 50%;
    padding-left: 10px;

    .bets {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;

      .pulse {
        margin: 0 3px;
        width: 33%;
      }
    }
  }
}

.emptyDesktop,
.emptyMobile {

  .text {
    height: auto;
    transform: scale(1, 0.60);
    margin-top: 0;
    border-radius: 4px;
    margin-bottom: 0;
    transform-origin: 0 60%;
  }

  .rect {
    border-radius: 4px;
  }

  .pulse {
    animation: pls 1.5s ease-in-out 0.5s infinite;
  }

  @keyframes pls {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.4;
    }

    100% {
      opacity: 1;
    }
  }

  .bts {
    background: #e7edf2
  }
}

.bets.dark .emptyDesktop,
.bets.dark .emptyMobile {
  .pulse {
    background-color: #11162d;
  }

  .first,
  .fourth {
    border-color: #202640
  }

  .bts {
    background: #1E2751
  }
}