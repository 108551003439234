$transitionDuration: 300ms;

.portrait-top-gallery {
  height: 89px;
  width: 100%;
  position: relative;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }


  background-image: url('../assets/player-gallery-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  .header-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    .header {
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 55px 0 0;

      .tmr {
        display: flex;
        align-items: center;
        color: #BDBDBD;
        font-size: 10px;
        font-weight: 600;
        white-space: nowrap;

        img {
          display: block;
          margin-left: 4px;
          margin-right: 5px;
          width: 21px;
          height: 21px;
          opacity: .6;
        }
      }

      .title {
        color: #fff;
        font-size: 10px;
        font-weight: 600;
        margin-right: 0;
        padding-left: 20px;
        white-space: nowrap;
        text-align: center;
        flex: 1 1 auto;

        .text-transition {
          white-space: nowrap;

          .text-transition_inner>div {
            text-align: right;
            width: 100%;
          }
        }

        .muted {
          color: #BDBDBD;
        }
      }

      @media screen and (min-width: 600px) {
        .title {
          margin-right: 97px;
          padding-left: 0;

          .text-transition {
            .text-transition_inner>div {
              text-align: center;
            }
          }
        }
      }

    }

    .hr {
      height: 2px;
      width: 100%;
      background: linear-gradient(to right,
          #14248a00,
          rgba(28, 200, 232, .3) 40%,
          #1ef1ffcc 50%,
          rgba(31, 197, 230, .3) 60%,
          #232a8f00);
    }
  }

  .gallery-wrapper {
    width: 100%;
    height: 90px;
    overflow: hidden;
    padding: 20px 0 0 0;

    margin-right: 0;
    position: relative;
    display: flex;
    justify-content: flex-end;
    user-select: none;

    .gallery {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      max-width: 370px;
      min-width: 320px;

      .container {
        position: absolute;
        width: 166px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .container.before {
        left: -189px;
      }

      .container.after {
        right: -189px;
      }
    }

    .item {
      pointer-events: none;
      width: 40px;
      min-width: 40px;
      max-width: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transform-origin: center;
      margin-right: 22px;


      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: linear-gradient(to bottom,
            #E494FF,
            #6B22A7);

        img {
          margin: 2px;
          border-radius: 50%;
          width: 36px;
          height: 36px;
          display: inline-block;
        }
      }

      .pos {
        width: 32px;
        height: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -6px;

        img {
          width: 32px;
          height: 11px;
          object-fit: contain;
        }

        .no {
          position: absolute;

          color: #BFBFBF;
          font-size: 8px;
          font-weight: bold;
          padding-bottom: 2px;
          white-space: nowrap;
        }
      }

      .score {
        margin-top: 3px;
        color: #00FFFF;
        font-size: 11px;
        font-weight: bold;
        line-height: 11px;
        white-space: nowrap;
      }
    }

    .item.big {

      transform: translate(0, -3px) scale(1.2);
      z-index: 1;

      .score {
        margin-top: 1px;
        color: #fff;
        text-shadow: 0 1px 4px rgba(0, 255, 255, .75);
      }
    }

    .item.bigger {
      transform: translate(0, -3px) scale(1.2);


      .pos {
        opacity: 0;
      }

      .score {
        opacity: 0;
        margin-top: 1px;
        color: #fff;
        text-shadow: 0 1px 4px rgba(0, 255, 255, .75);
      }
    }

    .item.float {
      top: 26px;
      position: absolute;
      margin-left: calc(50% - 20px - 14px);
    }

    .tm-btn-wrapper {
      padding: 22px 10px 0 0;
      flex: 1 1 auto;
      margin: -20px 0 0 0;
      background: rgba(0, 0, 0, .44);
      display: flex;
      align-items: center;
      height: 89px;
      justify-content: space-around;
      min-width: 170px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        left: -30px;
        border-top: 89px solid rgba(0, 0, 0, .44);
        border-left: 30px solid transparent;
      }

      .tm-btn {
        margin: 0 12px;
        height: 32px;
        background: linear-gradient(to right, #FFD457 0%, #FF6800 100%);
        padding: 2px;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        .inner {
          height: 28px;
          background: linear-gradient(to right, #0A1520 0%, #213348 100%);
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          width: 100%;

          font-size: 11px;
          font-weight: bold;
          color: #fff;
          text-transform: uppercase;
          padding: 0 12px 0 13px;

          img {
            margin-right: 9px;
            height: 12px;
            width: auto;
            display: block;
          }
        }
      }

      .tm-grouped {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: relative;
        top: -10px;
        height: 75px;

        .menu {
          width: 31px;
          height: 31px;
          border-radius: 50%;
          border: 2px solid #5D097A;
          background: #6c1ea8;
          color: #fff;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            display: block;

          }
        }

        .menu.close {
          bottom: unset;
          top: 8px;

          border: 2px solid rgba(256, 256, 256, 0.5);
          background: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }


}