.tournaments-slider {
  margin: 10px auto;
  max-width: 1528px;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  .cgl-super-header {
    margin: 0 10px;
    z-index: 2;
    position: relative;

    .category {
      color: #B5BFD0;
      font-size: clamp(10px, 1vw, 12px);
      font-weight: bold;
      text-align: left;
    }
  }

  .cgl-header {
    margin: 0 10px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    position: relative;

    .title {
      color: #D5DAE4;
      font-size: 18px;
      font-size: clamp(18px, 1.8vw, 26px);
      font-weight: 600;
      text-align: left;
    }

    .link {
      color: #646e7d;
      font-size: 12px;
      font-size: clamp(12px, 1.2vw, 17px);
      font-weight: 600;
      text-align: left;
      cursor: pointer;
      white-space: nowrap;
      border: 1px solid #646e7d;
      border-radius: 11px;
      padding: 0 12px;
      height: 22px;
      display: flex;
      align-items: center;
      line-height: 0;

      span {
        padding: 0 5px;
      }

      svg {
        position: relative;
        top: 1px;

        path {
          fill: #646e7d;
        }
      }
    }
  }

  .cgl-list {
    overflow-y: hidden;
    overflow-x: auto;
    padding-bottom: 0px;

    .cgl-grid {
      width: 100%;
      overflow: visible;
      white-space: nowrap;
      pointer-events: all;
      padding-bottom: 10px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      &::before,
      &::after {
        content: '\00a0';
        min-width: 9px;
        max-width: 9px;
      }

      .cgl-item {
        min-width: 130px;
        max-width: 130px;
        min-height: 240px;
        margin-right: 10px;
        //box-shadow: 0 3px 6px rgba(0, 0, 0, .2);
        overflow: visible;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        &.empty {
          min-width: 130px;
          max-width: 130px;
        }

        .header-tag-wrapper {
          margin-bottom: 5px;
          margin-left: 15px;
          text-align: left;

          .header-tag {
            height: 18px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 0 10px;

            font-size: 10px;
            font-weight: bold;
            color: #fff;
            text-transform: uppercase;

            background: #351097;
            border-radius: 4px;
          }
        }

        &.double .header-tag-wrapper .header-tag {
          background: #EEAD03;
        }

        .header {
          color: #B5BFD0;
          font-size: 12px;
          font-weight: 600;
          opacity: .53;
          padding: 0 15px 5px;
          text-align: left;
        }

        .body-wrapper {
          flex: 1 1 auto;
          height: 100%;
          padding: 2px;
          border-radius: 18px;
          display: flex;
          box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
          background: linear-gradient(135deg,
              #213348, #142232 20%);
          border: 2px solid #2d3e5e;

          .body {
            position: relative;
            z-index: 1;
            width: calc(100% + 8px);
            height: 218px;
            margin: -4px;
            border-radius: 18px;
            background: linear-gradient(to top,
                rgba(33, 51, 72, 0),
                #2a0d66 14.2%,
                #3b0f67 22.3%,
                rgba(29, 30, 62, 0) 60.6%,
                rgba(20, 34, 50, 0),
              );

            .body-header {
              display: flex;
              align-items: flex-start;
              justify-content: space-between;



              .logo {
                align-items: stretch;
                margin-top: 0;
                margin-left: 0;
                width: 43px;
                height: 50px;
                overflow: visible;

                img {
                  width: 62px;
                  height: 53px;
                  object-fit: contain;
                }
              }

              .prizes {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                padding-top: 8px;
                background: radial-gradient(at 50% 5px, rgba(72, 231, 255, .3) 0%, rgba(0, 14, 29, 0) 70%);
                position: relative;

                &::before {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: calc(50% - 16px);
                  width: 32px;
                  height: 2px;
                  border-radius: 5px;
                  background: linear-gradient(to right,
                      #48e7ff00,
                      #48e7ff 50.2%,
                      rgba(72, 231, 255, .937) 53.4%,
                      #48e7ff00);
                }

                .icon {
                  margin-top: 5px;
                  margin-right: 5px;

                  img {
                    display: block;
                    width: 30px;
                    height: 18px;
                    object-fit: contain;
                  }
                }

                .txt {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                  justify-content: center;

                  .title {
                    color: #4BE7FF;
                    font-size: 11px;
                    font-weight: bold;
                    line-height: 11px;
                    margin-bottom: 5px;
                  }

                  .subtitle {
                    color: #4BE7FF;
                    font-size: 9px;
                    font-weight: normal;
                    line-height: 9px;
                  }
                }
              }
            }

            .body-title {
              margin: 0 10px;
              border-bottom: 1px solid #5e718659;

              .txt {
                font-size: 12px;
                font-weight: 600;
                color: #B5BFD0;
                text-transform: uppercase;
                padding: 0 10px 5px;
                text-align: center;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }

            }

            .body-images {
              display: flex;
              align-items: center;
              justify-content: center;

              &.mt10px {
                margin-top: 10px;
              }

              &>div {
                margin: 5px;

                img {
                  width: 80px;
                  height: 80px;
                  object-fit: contain;
                }
              }

              .img-simple {
                position: relative;

                img {
                  margin-top: 5px;
                  width: 70px;
                  height: 70px;
                  object-fit: contain;
                }

                .txt {
                  position: absolute;
                  bottom: 0px;
                  left: 0;
                  right: 0;

                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: #fff;
                  font-size: 11px;
                  font-weight: bold;
                  text-shadow: 2px 2px #2d1354;
                }
              }

              .img-award {
                width: 80px;
                height: 80px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                &.desktop {
                  display: none;
                }

                .txt {
                  position: absolute;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;

                  .subtitle {
                    font-size: 9px;
                    line-height: 9px;
                    color: #DFE3E7;
                    font-weight: bold;
                  }

                  .title {
                    font-size: 15px;
                    line-height: 15px;
                    color: #DFE3E7;
                    font-weight: bold;
                    text-shadow: 1px 2px rgb(0, 0, 0);
                  }
                }

                .timer {
                  position: absolute;
                  bottom: 1px;
                  left: 0;
                  right: 0;
                  height: 18px;
                  background: linear-gradient(to right,
                      #13181E00,
                      rgba(19, 24, 30, 88) 20%,
                      rgba(19, 24, 30, 88) 80%,
                      #13181E00,
                    );

                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: #F36900;
                  font-size: 12px;
                  font-weight: bold;
                  white-space: nowrap;

                  .muted {
                    opacity: .55;
                  }

                  .days {
                    color: #FF975D;
                  }
                }
              }
            }

            .sticker {
              position: absolute;
              bottom: 31px;
              left: 0;
              right: 0;
              height: 17px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #fff;

              font-size: 10px;
              line-height: 11px;
              font-weight: 600;
              color: #fff;

              background: linear-gradient(to right,
                  #800d37,
                  #ff196e 17.6%,
                  #ff196e 82.2%,
                  #800d37);
            }

            .sticker.blocked {
              border: 0;
              height: 17px;
              background: linear-gradient(to right,
                  #494949,
                  #868686 17.6%,
                  #868686 82.2%,
                  #494949);
            }

            .timers-wrapper {
              margin-top: 10px;

              .header {
                font-size: 8px;
                line-height: 8px;
                font-weight: 600;
                color: #fff;
                text-align: center;
                text-transform: uppercase;
                opacity: 1;
              }

              .timers {
                display: flex;
                align-items: center;
                justify-content: center;

                .timer {
                  min-width: 22px;
                  max-width: 22px;
                  font-size: 12px;
                  line-height: 12px;
                  font-weight: 600;
                  color: #fff;
                  text-align: center;

                  &.muted {
                    opacity: .55;
                  }
                }
              }

              .timers-labels {
                display: flex;
                align-items: center;
                justify-content: center;

                .timer {
                  min-width: 22px;
                  max-width: 22px;
                  font-size: 6px;
                  font-weight: normal;
                  color: #A4A4A4;
                  text-align: center;

                  &.muted {
                    opacity: .55;
                  }
                }
              }
            }



            .button {
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              height: 29px;
              border-radius: 0 0 7px 7px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              font-size: 12px;
              font-weight: 600;
              color: #fff;
              text-transform: uppercase;

              background: linear-gradient(to right,
                  #EE034A, #3700F3);
            }

            .button.regular {
              background: linear-gradient(to right,
                  #EEB003, #F36900);
            }

            .button.blocked {
              background: linear-gradient(to right,
                  #8803EE, #3700F3);
            }

            .group-buttons {
              display: flex;
              align-items: center;

              .button:last-child {
                flex: 1 1 auto;
                left: 70px;
                border-bottom-left-radius: 0;
              }

              .button.random {
                width: 70px;
                right: unset;
                border-bottom-right-radius: 0;
                background: linear-gradient(to right,
                    #0A1520, #213348);
              }
            }
          }
        }
      }

      .cgl-item.double {
        min-width: 200px;
        max-width: 200px;
      }

      .cgl-item:last-child {
        margin-right: 0;
      }
    }
  }

  .extended-layout {
    position: relative;
    overflow: hidden;

    .bg-image {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;

      .wrapper {
        overflow: hidden;
        position: absolute;
        left: 10px;
        right: 10px;
        top: 0;
        bottom: 0;
        border-radius: 18px;
        width: calc(100% - 20px);
        border: 2px solid #292855;
        background: #12092d;
      }

      .img {
        border-radius: 18px;
        display: block;
        position: absolute;
        top: 0px;
        left: 10px;
        right: 10px;
        bottom: 0;
        width: calc(100% - 20px);
        height: auto;
        background-repeat: no-repeat;
        background-position: center top;
        background-size: cover;
      }

      .gradient-filter {
        position: absolute;
        left: 10px;
        right: 10px;
        top: 0;
        bottom: 0;
        border-radius: 18px;
        width: calc(100% - 20px);
        background: linear-gradient(to bottom,
            #12092d00 0,
            #40537313 138px,
            #405373e1 200px,
            #12092d81);
      }
    }

    .list-wrapper {
      padding-top: 175px;
      position: relative;

      .cgl-list {
        .cgl-grid {

          &::before,
          &::after {
            min-width: 18px;
            max-width: 18px;
          }

          .cgl-item {
            .header {
              color: #fff;
              opacity: 1;
            }
          }
        }
      }
    }

    .footer-line {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;

      //height: 30px;
      font-size: 14px;
      font-weight: 600;
      color: #fff;
      padding: 0 20px 10px;

      img {
        height: 20px;
        width: 20px;
        display: block;
        margin-left: 5px;
      }
    }
  }

  @media screen and (min-width: 1528px) {
    .cgl-list .cgl-grid {

      &::before,
      &::after {
        content: none
      }
    }

    .cgl-list .cgl-grid .cgl-item:not(.double) {
      min-width: 179.7px;
      max-width: 179.7px;
    }

    .cgl-list .cgl-grid .cgl-item .body-wrapper .body .body-images .img-award.desktop {
      display: flex;
      position: relative;
      top: 10px;
    }

    .extended-layout {
      overflow: visible;

      .cgl-grid {

        &::before,
        &::after {
          content: "";
        }

        .cgl-item:not(.double) {
          min-width: 174px;
          max-width: 174px;
        }
      }

      .bg-image {
        .wrapper {
          width: 100%;
          left: 0;
          right: 0;
        }

        .img {
          width: 100%;
          left: 0;
          right: 0;
        }

        .gradient-filter {
          width: 100%;
          left: 0;
          right: 0;
        }
      }
    }
  }
}