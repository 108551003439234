

  #ClaimBonus {
    max-height: 80vh;
    overflow: hidden;
    .centerLoading {
      width: 100%;
      text-align: center;
      .Loading {
        margin: 4rem auto;
      }
    }
    .bonusAvailable {
      overflow: auto;
      margin: 0 -2rem;
      max-height: 80vh;
      padding: 0 1rem 0 2rem;
    }
    .Content {

      text-align: center;
      p {
        color: initial;
      }
      svg {
        display: none;
      }
    }
  }
