.sport-header {
  height: 42px;
  margin: 0 0 10px 0;
  padding: 0 10px;
  color: var(--ns-sport-header-color);
  background: var(--ns-sport-color-default);
  font-size: 1.25em;
  font-weight: 300;
  line-height: 42px;
  border: 1px solid var(--ns-content-element-border);
}

.sport-color-1 {background: var(--ns-sport-color-1)}
.sport-color-2 {background: var(--ns-sport-color-2)}
.sport-color-3 {background: var(--ns-sport-color-3)}
.sport-color-4 {background: var(--ns-sport-color-4)}
.sport-color-5 {background: var(--ns-sport-color-5)}
.sport-color-6 {background: var(--ns-sport-color-6)}
.sport-color-7 {background: var(--ns-sport-color-7)}
.sport-color-9 {background: var(--ns-sport-color-9)}
.sport-color-10 {background: var(--ns-sport-color-10)}
.sport-color-11 {background: var(--ns-sport-color-11)}
.sport-color-12 {background: var(--ns-sport-color-12)}
.sport-color-13 {background: var(--ns-sport-color-13)}
.sport-color-16 {background: var(--ns-sport-color-16)}
.sport-color-17 {background: var(--ns-sport-color-17)}
.sport-color-19 {background: var(--ns-sport-color-19)}
.sport-color-20 {background: var(--ns-sport-color-20)}
.sport-color-21 {background: var(--ns-sport-color-21)}
.sport-color-22 {background: var(--ns-sport-color-22)}
.sport-color-23 {background: var(--ns-sport-color-23)}
.sport-color-26 {background: var(--ns-sport-color-26)}
.sport-color-29 {background: var(--ns-sport-color-29)}
.sport-color-31 {background: var(--ns-sport-color-31)}
.sport-color-32 {background: var(--ns-sport-color-32)}
.sport-color-37 {background: var(--ns-sport-color-37)}
.sport-color-34 {background: var(--ns-sport-color-34)}
.sport-color-43 {background: var(--ns-sport-color-43)}
.sport-color-44 {background: var(--ns-sport-color-44)}
.sport-color-46 {background: var(--ns-sport-color-46)}
.sport-color-61 {background: var(--ns-sport-color-61)}
.sport-color-109 {background: var(--ns-sport-color-109)}
.sport-color-110 {background: var(--ns-sport-color-110)}
.sport-color-111 {background: var(--ns-sport-color-111)}
.sport-color-117 {background: var(--ns-sport-color-117)}
