@import "./../../../../variables";

.WPermissionModal {
    z-index: 99998;
    //min-width: 615px;
    width: 100%;
    align-items: center;
    justify-content: center;
    max-width: 100%;

    .modal-content {
        border-radius: 0px;
        border-color: rgba(0, 0, 0, 0.0);
        position: relative;
        z-index: 99999;
        animation: normalModal .5s ease-in;
        width: 615px;

        .modal-header {
            background-color: $withdrawModalGreen;
            border-radius: 0;
            text-align: center;
            padding: 1.5rem 1rem;
            height: 74px;
            position: relative;
            animation: showModalHead .5s ease-in;

            .modal-title {
                text-align: center;
                font-size: 0.9rem;
                line-height: 1.6rem;
                font-weight: 700;
                text-transform: uppercase;
                width: 100%;
            }

            .close {
                position: relative;
            }
        }

        .WithdrawModalBody {
            overflow: auto;
            padding: 0;
            background-repeat: no-repeat;
            background-position: center center;
            background-color: #ffffff;
            color: $firstColor;
        }

        .modalMessage {
            padding-top: 40px;
            padding-bottom: 40px;
            text-align: center;
        }

        .modalButtons {
            padding-bottom: 20px;
            text-align: center;

            button {
                width: 60%;
                border-color: $thirdColor;
                color: #ffffff;
                background-color: $thirdColor;
                position: relative;
                top: 0px;
                font-weight: 600;
                font-size: 18px;
                text-transform: uppercase;
                padding-top: 15px;
                padding-bottom: 15px;
            }
        }
    }

    button,
    a {
        &:focus,
        &:active {
            outline: none !important;
            box-shadow: none !important;
        }
    }
}