.tournaments {
  margin-bottom: 10px;
  border-right: 1px solid var(--ns-content-element-border);
  border-left: 1px solid var(--ns-content-element-border);
  border-top: 1px solid var(--ns-content-element-border);

  .tournament-header {
    display: block;
    height: 28px;
    overflow: hidden;
    background-color: var(--ns-tournament-bg-color);
    background-image: var(--ns-tournament-bg-image);
    font-size: .8125em;
    line-height: 28px;
    text-decoration: none;
    border-bottom: 1px solid var(--ns-content-element-border);

    .tournament-name {
      display: inline-block;
      overflow: hidden;
      max-width: 85%;
      padding-left: 10px;
      color: var(--ns-tournament-color);
      font-weight: 700;
      text-overflow: ellipsis;
      text-decoration: none;
      text-transform: uppercase;
      white-space: nowrap;

      .flag {
        transform: scale(0.5);
        vertical-align: middle;
        position: relative;
        top: -1px;
      }
    }

    .pin {
      //display: inline;
      display: none;
      margin-left: 10px;
      font-size: .875em;
      line-height: 28px;
      cursor: pointer;

      svg {
        width: .7em;
        height: .7em;
        position: relative;
        top: 4px;
        opacity: .2;
      }
    }

    .stats {
      display: block;
      float: right;
      padding-right: 10px;
      text-decoration: none;
      cursor: pointer;

      svg {
        width: .7em;
        height: .7em;
        margin-top: 6px;
        opacity: .5;
      }

      &.nopointer {
        cursor: default;
      }

      &.bet-selector {
        color: #fff;

        svg {
          opacity: 1
        }
      }

      @media (min-width:768px) {
        &.bet-selector {
          display: none;
        }
      }
    }

    .pin:hover,
    .stats:hover {
      svg {
        opacity: .75;
      }
    }

    .bet-name {
      height: 28px;
      float: left;
      padding: 0 0.5em;
      overflow: hidden;
      color: var(--ns-tournament-color);
      font-size: 1em;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-left: 1px solid var(--ns-content-element-border);
      text-transform: inherit;
      cursor: pointer;

      svg {
        vertical-align: middle;
        width: 16px;
        position: relative;
        top: -1px;
      }
    }
  }

  .tournament-description {
    background-color: #45454a;
    font-size: .8125em;
    text-decoration: none;
    border-top: 1px solid var(--ns-content-element-border);
    margin-top: -1px;
    padding: 5px 10px 8px;
  }

  .date-header {
    height: 22px;
    overflow: hidden;
    color: #898989;
    background-color: #3d3d3d;
    background-image: linear-gradient(to bottom, #3d3d3d 0%, #343434 100%);
    font-size: .625em;
    line-height: 22px;
    border-bottom: 1px solid var(--ns-content-element-border);
    display: none;

    .date {
      padding-left: 10px;
      text-align: left;
    }

    .outcomes {
      float: left;
      overflow: hidden;
      border-left: 1px solid var(--ns-content-element-border);
      width: 100%;

      .outcome {
        min-height: 1px;
        float: left;
        text-align: center;
      }

      &.outcomes-2 .outcome {
        width: 50%
      }

      &.outcomes-3 .outcome {
        width: 33.3%
      }

      &.outcomes-4 .outcome {
        width: 25%
      }
    }
  }

  @media screen and (min-width: 768px) {
    .date-header {
      display: block;
    }
  }

  .match-row {
    position: relative;
    overflow: hidden;
    background: var(--ns-match-row-bg);
    height: 112px;
    white-space: nowrap;
    border-bottom: 1px solid var(--ns-content-element-border);
    cursor: pointer;

    .match-time {
      padding: 5px 10px;
      color: var(--ns-match-time-color);
      line-height: 1em;
      font-size: .8125em;
      cursor: pointer;
    }

    .match-category-iso {
      display: none;
      color: #898989;
      font-size: .8125em;
      cursor: pointer;

      .flag {
        width: 32px;
        height: 32px;
        display: inline-block;
        overflow: hidden;
      }
    }

    .match-display-name,
    .match-display-name-timeline {
      width: 100%;
      overflow: hidden;
      float: left;
      padding: 0 10px;
      color: #c8c8c8;
      font-size: .8125em;
      cursor: pointer;
      text-overflow: ellipsis;
      text-decoration: none;
      white-space: nowrap;
      position: relative;
      text-align: left;

      display: flex;

      .team {
        display: block;
        overflow: hidden;
        height: 1.5em;
        line-height: 1.5em;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 600;
      }

      .team-separator {
        height: 1.5em;
        line-height: 1.5em;
      }

      .match-special {
        position: absolute;
        right: 5px;
        top: 2px;

        .winPlus {
          height: 18px;
          width: auto;
          margin-right: 3px;
        }

        .winAdv {
          height: 18px;
          width: auto;
          margin-right: 3px;
        }

        .display-id {
          position: relative;
          color: #2F2F2F;
          font-size: 12px;
          font-weight: normal;
          top: -4px
        }
      }
    }

    @media screen and (min-width: 768px) {
      .match-display-name {
        display: block;

        .team {
          font-weight: normal;
          width: 100%;
        }

        .team-separator {
          display: none
        }
      }
    }

    .match-stats {
      position: absolute;
      right: 0;
      padding: 0 10px;
      overflow: hidden;
      height: inherit;
      line-height: inherit;
      text-decoration: none;
      color: #000;
      display: none;

      svg {
        width: .7em;
        height: .7em;
        margin-top: 6px;
      }
    }

    .odd-container {
      height: 50px;
      width: 100%;
      overflow: hidden;
      border-left: 1px solid var(--ns-content-element-border);
      padding: 5px;

      .odd {
        height: 40px;
        line-height: 16px;
        float: left;
        overflow: hidden;
        color: #fff;
        background: #383a3d;
        border-radius: .125rem;
        text-align: center;
        font-size: .8125em;
        margin-left: 5px;
        cursor: pointer;
        font-weight: bold;

        .odd-outcome-name {
          padding-top: 3px;
          font-size: 11px;
          line-height: 16px;
          display: inline-block;
          font-weight: normal;
        }
      }

      .odd:first-child {
        margin-left: 0;
      }

      .odd:last-child {
        border-right: none;
      }

      &.odds-1 .odd {
        width: 100%;
      }

      &.odds-2 .odd {
        width: calc((100% - 5px) / 2);
      }

      &.odds-3 .odd {
        width: calc((100% - 10px) / 3);
      }

      &.odds-4 .odd {
        width: calc((100% - 15px) / 4);
      }

      .odd.disabled {
        width: 100%;
        border-right: none;
      }

      @media (hover: hover) and (pointer: fine) {
        .odd:not(.disabled):hover {
          color: #fff;
          background-color: #164F76;
          background-image: linear-gradient(to bottom, #164F76 0%, #114468 100%);
        }


      }

      .odd.active:not(.disabled) {
        color: #fff;
        background-color: #164F76;
        background-image: linear-gradient(to bottom, #164F76 0%, #114468 100%);
      }
    }

    .bet-name {
      color: #38383d;
      font-size: 11px;
      padding: 0 10px;
    }

    .match-total-bets {
      color: #DE2635;
      font-size: .8125em;
      text-decoration: none;
      text-align: center;
      border-left: 1px solid var(--ns-content-element-border);
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      position: relative;
      top: -2px;
      min-width: 45px;

      svg {
        color: rgba(137, 137, 137, 0.4);
        width: .8125em;
        position: relative;
        top: -1px;
      }

      svg.active {
        color: #FDC138
      }
    }
  }

  .match-row:hover {
    background: var(--ns-match-row-bg-hover);
  }

  .match-description {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;

    background-color: #FFFFFF;
    color: #909090;
    font-style: italic;
    background-image: unset;
    border-bottom: 1px solid #EEF0F1;
    font-size: 10px;

    .icon {
      padding: 0 5px;

      svg {
        width: 12px;
      }
    }

    .desc {
      width: 100%;
      padding-top: 2px;
    }
  }

  .info-wrap {
    position: relative;
    width: 100%;
    overflow: hidden;
    text-align: left;
    cursor: pointer;
  }

  .bets-wrap {
    width: 100%;
  }

  .stats-wrap {
    position: absolute;
    top: 5px;
    right: 5px;
    font-weight: 600;
  }

  @media (max-width: 767px) {
    .tournament-header {
      .info-wrap {
        padding-right: 50px;
      }
    }

    .tournament-header {
      position: relative;

      .stats-wrap {
        top: 0;

        .brd-left {
          border: 0;

          svg {
            position: relative;
            left: 5px;
          }
        }
      }
    }
  }


  @media screen and (min-width: 768px) {
    .match-row {
      height: 30px;
      line-height: 30px;

      .match-time,
      .match-category-iso {
        display: block;
        position: absolute;
        padding: 0;
        left: 10px;
        line-height: inherit;
      }

      .match-category-iso {
        left: 50px;
        text-transform: uppercase;
      }

      .match-category-iso>.flag {
        float: left;
        transform: scale(0.5);
      }

      .match-display-name,
      .match-display-name-timeline {
        padding-top: 0;
        padding-left: 4em;
        padding-right: 95px;
        line-height: inherit;

        .team {
          display: inline;
          line-height: inherit;
        }

        .team-separator {
          display: inline;
        }

        .match-special {
          top: 3px;
          right: 30px;
        }
      }

      .match-display-name-timeline {
        padding-left: 9.5em;
      }

      .odd-container {
        height: 100%;
        float: left;
        padding: 0px;
        height: 30px;

        .odd {
          height: 100%;
          float: left;
          overflow: hidden;
          color: var(--ns-odd-color);
          text-align: center;
          font-size: .8125em;
          border-right: 1px solid var(--ns-content-element-border);
          cursor: pointer;
          background: transparent;
          border-radius: 0;
          margin-left: 0;
          font-weight: normal;
          line-height: 26px;
        }

        &.odds-2 .odd {
          width: 50%;
        }

        &.odds-3 .odd {
          width: 33.3%;
        }

        &.odds-4 .odd {
          width: 25%;
        }
      }
    }


    .match-row:hover {
      .match-stats {
        display: block;
      }
    }

    @media (hover: none) {
      .match-row {
        .match-stats {
          display: block;
        }
      }
    }

    .info-wrap {
      float: left;
      width: 60%;
      padding-left: 0;
    }

    .bets-wrap {
      width: 30%;
      display: block;
      float: left;
      overflow: hidden;
    }

    .stats-wrap {
      display: block;
      overflow: hidden;
      position: static;
      top: auto;
      right: auto;
      font-weight: normal;
    }
  }

  @media screen and (min-width: 1366px) {
    .info-wrap {
      width: 45%;
    }

    .bets-wrap {
      width: 45%;
    }

    .stats-wrap {
      width: 10%;
    }
  }

  @media screen and (min-width: 1920px) {

    .info-wrap {
      width: 40%;
    }

    .bets-wrap {
      width: 50%;
    }

    .stats-wrap {
      width: 10%;
    }
  }
}