@import './../../../variables';

$arrowColorNormal: transparent;
$arrowColorSelected: $thirdColor;

.ProfileMenu {
    .pmHeader {
        margin: 0;
        padding: 0;
        width: 100%;
        padding-top: 3rem;
        padding-bottom: 1rem;

        h1 {
            text-transform: uppercase;
            color: #b3b3b3;
            font-size: 13px;
            text-align: left;
            margin: 0;
            padding: 0;
        }

        div {
            margin: 0;
            padding: 0;
        }
    }

    ul {
        list-style: none;
        margin: 0 !important;
        padding: 0 !important;
        display: block;
        width: 100%;

        li {
            display: block;

            a {
                padding: 0;
                margin: 0;
                color: #888888;
                transition: all .1s ease-in;

                &.tconditions{
                    color: #888888;
                    &:hover,
                    &.selected {
                        text-decoration: none;
                        color: $thirdColor;
                        font-weight: bold;
                        cursor: pointer;
                    }
                }

                span {
                    margin: 0;
                    padding: 0;
                    line-height: 30px;
                }

                .icon {
                    width: 4rem;
                    border-left: 4px solid transparent;
                    min-width: 4rem;
                    transition: all .2s ease-in;
                    text-align: right;

                    .menu-info-icon,
                    .menu-warning-icon,
                    .menu-danger-icon {
                        width: 24px;
                        height: 24px;
                        margin-right: 10px;

                        .cls-2{
                            fill:#f7f7f7;
                        }
                    }

                    .menu-warning-icon {
                        .cls-1 {
                            fill: $warningColor;
                        }
                    }

                    .menu-info-icon {
                        .cls-1 {
                            fill: $infoColor;
                        }
                    }

                    .menu-danger-icon {
                        .cls-1 {
                            fill: $dangerColor;
                        }
                    }
                }

                .ptext {
                    text-align: left;
                    text-transform: capitalize;
                }

                .arrow {
                    margin: 0;
                    text-align: center;

                    .menuArrow {
                        fill: $arrowColorNormal;
                        transition: all .2s ease-in;
                    }
                    .profile-menu-arrow {
                        height: 14px;
                    }
                }

                &:hover,
                &.selected {
                    text-decoration: none;
                    color: $thirdColor;
                    font-weight: bold;

                    .icon {
                        border-left: 4px solid $thirdColor !important;
                        svg {
                            animation: wobble 1s;
                        }
                    }
                    .arrow {
                        .menuArrow {
                            fill: $arrowColorSelected;
                        }
                    }
                }
            }

            &.moreBonusSupport {
                .btn-secondary {
                    background-color: $magenta;
                    border-color: lighten($magenta, 7%);
                    font-weight: bold;
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    position: relative;
                    top: 0;
                    transition: all 0.1s ease-in;

                    &:hover {
                        background-color: lighten($magenta, 7%);
                    }

                    &:active {
                        background-color: darken($magenta, 8%);
                    }

                    &:focus,
                    &:active {
                        outline: none !important;
                        box-shadow: none !important;
                    }

                    &:active {
                        top: 2px;
                    }
                }
            }
        }
    }
}