.wf-landing-page {
  width: 100vw;
  height: 100vh;

  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: #000;

  .wf-body {
    background: url(./assets/bg-page.png);
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 370px;
    padding: 30px;

    .wf-logo {
      text-align: center;

      img {
        width: 217px;
        height: auto;
      }
    }

    .wf-center {
      &>img {
        display: block;
        width: 260px;
        height: auto;
        margin: 0 auto -60px;
      }

      .wf-wrapper {
        position: relative;
        background: linear-gradient(to bottom, #fcf81e 0%, #fca913 25.6%, #fcb915 42%, #b75f23 57.4%, #fcaa13 88.7%, #fce61b 100%);
        padding: 2px;
        border-radius: 27px;
        height: 60px;

        .wf-inner-wrapper {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: stretch;
          border-radius: 27px;
          background: radial-gradient(circle, rgba(209, 64, 204, 1) 0%, rgba(27, 20, 100, 1) 67%, rgba(27, 20, 100, 1) 100%);
          padding: 0 4px;

          img {
            width: 50px;
            height: 50px;
            display: block;
            margin-right: 5px;
            position: relative;
            top: 2px;
          }

          .wf-text {
            color: #fff;
            font-size: 39px;
            font-weight: 600;
            text-shadow: 1px 1px rgba(0, 0, 0, 1);
            width: 100%;
            text-align: center;
            padding-right: 49px;

          }
        }

        &.wf-full .wf-inner-wrapper {
          background: radial-gradient(circle, #DA494E 0%, #A51419 67%, rgb(102, 0, 4) 100%);
        }
      }
    }

    .wf-button {
      margin-top: 36px;
      height: 76px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 23px;
      font-weight: bold;
      color: #fff;
      cursor: pointer;
      background: linear-gradient(to right, #FF0000, #A52EFF);
      text-transform: uppercase;
      border-radius: 7px;
    }
  }
}

body.wf-welcome {
  #root>.App>* {
    display: none
  }

  #root>.App>.App-main {
    display: block
  }
}