.wf-calendar-summary {
  .wf-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 22px;

    .wf-icon img {
      display: block;
      width: 26px;
      height: 26px;
      margin-right: 4px;
    }

    .wf-title {
      color: #fff;
      text-align: left;
      font-size: 22px;
      font-weight: bold;
      line-height: 22px;
    }
  }

  .wf-body {
    .wf-event {
      background-color: #fff;
      /*background: url(../../assets/simplified-calendar-bg@3x.jpg);*/
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border-radius: 15px;

      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: stretch;
      min-width: 100%;

      &>div {
        align-self: stretch;
      }

      .wf-icon {
        width: 61px;
        max-width: 61px;
        min-width: 61px;
        padding: 20px 0 0;

        img {
          display: block;
          width: 42px;
          height: auto;
          margin: 0 auto;
        }
      }

      .wf-info {
        width: 100%;
        padding: 20px 10px 0 0px;

        .wf-summary {
          margin-right: 10px;

          .wf-title {
            font-size: 12px;
            font-weight: bold;
            text-align: left;
            color: #17214D;
          }

          .wf-subtitle {
            font-size: 10px;
            font-weight: normal;
            text-align: left;
            color: #979DAB;
          }

          .wf-days {
            .wf-text {
              color: #6E737D;
              font-size: 8px;
              font-weight: 600;
            }

            .wf-days-text {
              color: #000;
              font-size: 14px;
              font-weight: 600;
            }
          }
        }

        .wf-progress {
          margin: 10px 0 10px;

          .MuiLinearProgress-colorPrimary {
            background-color: #D6DCE2;
            border-radius: 3px;
          }

          .MuiLinearProgress-barColorPrimary {
            border-radius: 3px;
            background: linear-gradient(to right, #F98D00, #FFCA1E);
          }
        }

        .wf-details {
          background: radial-gradient(#4053FF, #2A18C9);
          text-align: center;
          font-size: 11px;
          font-weight: 600;
          color: #fff;
          padding: 6px 20px;
          margin-bottom: 10px;
          border-radius: 7px;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .16);
        }


      }
    }

    .wf-button {
      background: linear-gradient(to right, #FF7700, #FFC400);
      border-radius: 10px;
      padding: 13px 0;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      color: #fff;
      cursor: pointer;
    }
  }
}