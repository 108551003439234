@import "./../../../../../variables";

.LocationTabs {
    background-color: $withdrawModalLightGreen;
    display: flex;

    button {
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        border-top: 0;
        border-bottom: 5px solid $withdrawModalLightGreen;
        text-transform: uppercase;
        text-align: center;
        width: 50%;
        height: 74px;
        font-size: 14px;
        font-weight: 600;
        animation: tabBorderNormal 200ms ease-in;

        span {
            position: relative;
            top: 0px;
            color: $firstColor;
            animation: tabNormal 200ms ease-in;
        }

        &.selected {
            border-bottom-color: $withdrawModalGreen;
            animation: tabBorderPressed 200ms ease-in;

            span {
                top: 5px;
                color: $withdrawModalGreen;
                animation: tabPressed 200ms ease-in;
            }
        }

        &:hover,
        &:active {
            border-bottom-color: $withdrawModalGreen;
            animation: tabBorderPressed 200ms ease-in;

            span {
                top: 5px;
                color: $withdrawModalGreen;
                animation: tabPressed 200ms ease-in;
            }
        }
    }
}

@keyframes tabNormal {
    from { top: 5px; color: $withdrawModalGreen; }
    to { top: 0px; color: $firstColor; }
}

@keyframes tabPressed {
    from { top: 0px; color: $firstColor; }
    to { top: 5px; color: $withdrawModalGreen; }
}

@keyframes tabBorderNormal {
    from { border-bottom-color: $withdrawModalGreen; }
    to { border-bottom-color: $withdrawModalLightGreen; }
}

@keyframes tabBorderPressed {
    from { border-bottom-color: $withdrawModalLightGreen; }
    to { border-bottom-color: $withdrawModalGreen; }
}