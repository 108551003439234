.bonus-widget {
  &.claim-prize {
    .title,.value {
      text-align: left;
      text-indent: .35rem;
    }
  }
}
.claim-prize-modal {
  &.error {
    svg {

      .cls-2 {
        fill: white;
      }
      .cls-1 {
        fill: #c62525;
      }
    }
  }
  &.success {
    svg {
      .cls-1 {
        fill: green;
      }
    }
  }

}