.elements {
  position: relative; 
  
  .brd-left {
    display: block;
    text-decoration: none;
    border-left: 1px solid var(--ns-content-element-border);
  }

  .bets-3 .outcomes,
  .bets-3 .odd-container,
  .bets-3 .bet-name {
    width: 100%;
  }

  @media screen and (min-width: 1366px) {

    .bets-3 .outcomes,
    .bets-3 .odd-container,
    .bets-3 .bet-name {
      width: 50%;
    }
  }

  @media screen and (min-width: 1920px) {

    .bets-3 .outcomes,
    .bets-3 .odd-container,
    .bets-3 .bet-name {
      width: 33.3%;
    }
  }

  .empty {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 0.8125em;
    color: var(--ns-tournament-color);
    background-color: var(--ns-tournament-bg-color);
    background-image: var(--ns-tournament-bg-image);
    border: 1px solid var(--ns-content-element-border);

    svg {
      width: auto;
      height: 16px;
      vertical-align: middle;
      position: relative;
      top: -2px;
      margin-right: 5px;
    }
  }

}