.all-bonuses {
  .bonuses-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 27px;
    margin-bottom: 10px;

    .left,
    .right {
      color: #989898;

      svg {
        width: 32px;
        height: 32px;
      }
    }

    .center {
      flex: 1 1 0;
      min-width: 220px;
      max-width: 220px;
      color: #151D26;
      font-size: 19px;
      text-align: center;
      font-weight: bold;
    }
  }

  .bonuses-sep {
    margin-bottom: 20px;
    height: 1px;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 35%, rgba(0, 0, 0, 1) 65%, rgba(0, 0, 0, 0) 100%);
  }
}